import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FullCalendarPage from '../../FullCalendar/FullCalendarPage';
import TaskFilters from '../TaskFilters/TaskFilters';
import TaskList from '../TaskList/TasklList';
import LeftBar from '../../LeftBar/LeftBar';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'deepmerge';
import { useRequest, urls } from '../../Common/ApiServices';
import { getMappedTasks, getThisWeek } from '../utils';
import { initialTasksParams, getInitialTasksParams } from '../constants';

const TaskPage = (props) => {
  const defaultLocation = useSelector(
    (state) => state.taskOptions.defaultLocation
  );

  const [filters, setFilters] = useState(null);
  const [view, setView] = useState({ list: true, calendar: false });
  const [taskSections, setTaskSections] = useState([]);
  const [taskDate, setTaskDate] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [taskParams, setTaskParams] = useState(initialTasksParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCalendarTask, setSelectedCalendarTask] = useState('');
  const [activeLocation, setActiveLocation] = useState(defaultLocation);

  const { isLoading, sendRequest } = useRequest({});

  const locations = useSelector((state) => state.taskOptions.locations);
  const trials = useSelector((state) => state.taskOptions.trials);
  const taskTypes = useSelector((state) => state.taskOptions.types);
  const products = useSelector((state) => state.taskOptions.products);
  const taskStatuses = useSelector((state) => state.taskOptions.statuses);
  const taskManagers = useSelector((state) => state.taskOptions.employees);

  const fetchTasks = async () => {
    const requestData = {
      url: urls.SEARCH_TASKS,
      method: 'POST',
      data: taskParams ? taskParams : getInitialTasksParams(defaultLocation.id),
    };

    const response = await sendRequest(requestData);
    if (response) {
      const tasks = getMappedTasks(response.data.results);
      setTasks(tasks);
      setTotalRecords(response?.data?.page?.total);
    }

    return response;
  };

  const updateFilters = (newFilters) => {
    if (!newFilters) {
      return null;
    } else {
      const newStatuses = newFilters?.selectedStatuses
        ? newFilters?.selectedStatuses?.map((status) => status.status)
        : null;
      const newSections = newFilters?.selectedSections
        ? newFilters?.selectedSections?.map((section) => section.id)
        : null;
      const newManagers = newFilters?.selectedManagers
        ? newFilters?.selectedManagers?.map((manager) => manager.id)
        : null;
      const newTypes = newFilters.selectedTypes
        ? newFilters?.selectedTypes?.map((type) => type.type)
        : null;
      const newTrials = newFilters.selectedTrials
        ? newFilters?.selectedTrials?.map((trial) => trial.id)
        : null;
      const newLocation = newFilters.selectedLocation
        ? newFilters?.selectedLocation.id
        : defaultLocation?.id;
      let newDateFrom = null;
      let newDateTo = null;
      if (newFilters.taskDate) {
        newDateFrom = moment(newFilters.taskDate[0]).format('YYYY-MM-DD');
        newDateTo = newDateFrom;
        if (newFilters.taskDate[1]) {
          newDateTo = moment(newFilters.taskDate[1]).format('YYYY-MM-DD');
        }
      }
      setTaskParams((oldParams) => {
        let params = cloneDeep(oldParams);
        if (newStatuses) {
          const statusParams = { query: { task: { status: { in: null } } } };
          params = merge(params, statusParams);
          params.query.task.status.in = newStatuses;
        } else if (!newStatuses && params?.query?.task?.status) {
          if (params.query.task.status) delete params.query.task;
        }
        if (newSections) {
          const sectionParams = {
            query: { section: { id: { in: null } } },
          };
          params = merge(params, sectionParams);
          params.query.section.id.in = newSections;
        } else if (!newSections && params?.query?.section) {
          if (params.query.section) delete params.query.section;
        }
        if (newTypes) {
          const typesParams = { query: { task: { type: { in: null } } } };
          params = merge(params, typesParams);
          params.query.task.type.in = newTypes;
        } else if (!newTypes && params?.query?.task?.type) {
          if (params.query.task.type) delete params.query.task;
        }
        if (newDateFrom) {
          const dateParams = {
            query: {
              task: { startDate: { from: newDateFrom, to: newDateTo } },
            },
          };
          params = merge(params, dateParams);
        } else if (!newDateFrom && params?.query?.task?.startDate) {
          if (params.query.task.startDate) delete params.query.task;
        }
        if (newManagers) {
          const managerParams = {
            query: { taskManager: { id: { in: null } } },
          };
          params = merge(params, managerParams);
          params.query.taskManager.id.in = newManagers;
        } else if (!newManagers && params?.query?.taskManager) {
          if (params.query.taskManager.id) delete params.query.taskManager;
        }
        if (newTrials) {
          const trialParams = { query: { trial: { id: { in: null } } } };
          params = merge(params, trialParams);
          params.query.trial.id.in = newTrials;
        } else if (!newTrials && params?.query?.trial) {
          if (params.query.trial) delete params.query.trial;
        }
        if (newLocation) {
          const locationParams = { query: { location: { id: { in: null } } } };
          params = merge(params, locationParams);
          params.query.location.id.in = [newLocation];
        } else if (!newLocation && params?.query?.location) {
          if (params.query.location) delete params.query.location;
        }
        return params;
      });
    }
  };

  const getTaskSections = async () => {
    const sectionsParams = {
      query: {},
    };

    const requestData = {
      url: urls.SEARCH_SECTIONS,
      method: 'POST',
      data: sectionsParams,
    };
    const response = await sendRequest(requestData);
    const res = response.data.results.map((section) => {
      return { id: section.section.id, name: section.section.name };
    });
    setTaskSections(res);

    return response;
  };

  const onApplyNewFilters = (
    selectedLocation,
    selectedTaskDate,
    selectedTrials,
    selectedTypes,
    selectedManagers,
    selectedStatuses,
    selectedSections
  ) => {
    setFilters({
      taskDate: selectedTaskDate,
      selectedTrials:
        selectedTrials && selectedTrials.length > 0 ? selectedTrials : null,
      selectedLocation: selectedLocation ? selectedLocation : null,
      selectedTypes:
        selectedTypes && selectedTypes.length > 0 ? selectedTypes : null,
      selectedManagers:
        selectedManagers && selectedManagers.length > 0
          ? selectedManagers
          : null,
      selectedStatuses:
        selectedStatuses && selectedStatuses.length > 0
          ? selectedStatuses
          : null,
      selectedSections:
        selectedSections && selectedSections.length > 0
          ? selectedSections
          : null,
    });
  };

  const taskView = view.list ? (
    <TaskList
      loading={isLoading}
      newFilters={filters}
      setView={setView}
      trials={trials}
      totalRecords={totalRecords}
      tasks={tasks}
      products={products}
      taskManagers={taskManagers}
      taskTypes={taskTypes}
      taskStatuses={taskStatuses}
      taskSetions={taskSections}
      taskDate={taskDate}
      taskParams={taskParams}
      setTaskParams={setTaskParams}
      setSelectedCalendarTask={setSelectedCalendarTask}
      fetchTasks={fetchTasks}
      initialLocation={activeLocation}
    />
  ) : (
    <FullCalendarPage
      dateFormat={activeLocation.dateFormat}
      firstDayOfWeek={activeLocation.firstDay}
      setView={setView}
      tasks={tasks}
      trials={trials}
      products={products}
      taskManagers={taskManagers}
      taskTypes={taskTypes}
      taskStatuses={taskStatuses}
      taskSections={taskSections}
      fetchTasks={fetchTasks}
      setSelectedCalendarTask={setSelectedCalendarTask}
      selectedCalendarTask={selectedCalendarTask}
      initialLocation={activeLocation}
    />
  );

  const clearLocationState = () => {
    window.history.replaceState(null, '');
  };

  useEffect(() => {
    getThisWeek();
    getTaskSections();
    props?.location?.state?.showCalendar &&
      setView(props.location.state.showCalendar);
  }, []);

  useEffect(() => {
    defaultLocation.id &&
      setTaskParams(
        props?.location?.state?.showThisWeek
          ? getThisWeek(defaultLocation.id)
          : getInitialTasksParams(defaultLocation.id)
      );
  }, [defaultLocation]);

  useEffect(() => {
    taskParams.query.location && fetchTasks();
  }, [taskParams]);

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (props?.location?.state?.showThisWeek) {
      let today = moment();
      let start = today.clone().weekday(0).toDate();
      let end = today.clone().weekday(6).toDate();
      setInitialDate([start, end]);
    }
  }, [props.location.state]);

  return (
    <>
      <LeftBar>
        <TaskFilters
          setActiveLocation={setActiveLocation}
          clearLocationState={clearLocationState}
          locations={locations}
          trials={trials}
          types={taskTypes}
          managers={taskManagers}
          statuses={taskStatuses}
          sections={taskSections}
          setTaskDate={setTaskDate}
          initialDate={initialDate}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      {taskView}
    </>
  );
};

export default TaskPage;
