import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { useAuth0 } from '@auth0/auth0-react';
import { isAuth } from '../../../auth/auth-service';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import NewLocationForm from '../NewLocation/NewLocationForm';
import countries from 'iso3166-2-db/i18n/dispute/UN/en';
import { getFilteredCountries, getFormattedDate } from '../../Common/utils';
import ShowDetails from '../../Common/showDetails/showDetails';
import { setIsFirstLogin } from '../../../reduxStore/isFirstLogin/actions';
import { getTaskOptions } from '../../../reduxStore/taskOptions/actions';
import {
  dateFormat,
  popUp,
  showDetailsFields,
} from '../../Common/globalConstants';
import { isEmpty } from 'lodash';
import { urls, useRequest } from '../../Common/ApiServices';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import styles from './LocationList.module.scss';

const LocationList = (props) => {
  const initialLocationParams = useMemo(
    () => ({
      navigation: {
        sort: [
          {
            key: 'name',
            order: 'ASC',
          },
        ],
        page: {
          from: 0,
          size: 20,
        },
      },
    }),
    []
  );

  const [locations, setLocations] = useState([]);
  const [locationParams, setLocationParams] = useState(initialLocationParams);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [displayLocationModal, setDisplayLocationModal] = useState(false);
  const [locationIsCreated, setLocationIsCreated] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [deleteLocationDialog, setDeleteLocationDialog] = useState(false);
  const [locationIsDeleted, setLocationIsDeleted] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(270);
  const [filteredCountries, setFilteredCountries] = useState({});

  const tableRef = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const dispatch = useDispatch();

  const isFirstLogin = useSelector((state) => state.isFirstLogin);
  const permissions = useSelector((state) => state.permissions);

  const { isLoading, sendRequest } = useRequest({});

  const actionData = useMemo(
    () => ({
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuth(),
      getAccessTokenSilently: getAccessTokenSilently,
    }),
    [dispatch, getAccessTokenSilently, logout]
  );

  const fetchLocations = useCallback(async () => {
    const requestData = {
      url: urls.BROWSE_LOCATIONS,
      method: 'POST',
      data: locationParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      const locations = await response.data.results.map(({ location }) => ({
        id: location.id,
        name: location.name,
        type:
          location.type &&
          location.type[0] + location.type.slice(1).toLowerCase(),
        address: location.address,
        address2: location.address2,
        city: location.city,
        state: filteredCountries[location.countryId]?.regions.filter(
          (region) => region.iso === location.stateId
        )
          ? filteredCountries[location.countryId].regions.filter(
              (region) => region.iso === location.stateId
            )[0]?.name
          : null,
        postalCode: location.postalCode,
        country: filteredCountries[location.countryId]?.name,
        primaryPhone: location.primaryPhone,
        description: location.description,
        createDate: getFormattedDate(
          location.createDate,
          dateFormat[location.dateFormat.datePattern]
        ),
      }));
      setLocations(locations);
      setTotalRecords(response.data.page.total);
      setLocationIsDeleted(false);
      setLocationIsCreated(false);
    }
  }, [filteredCountries, locationParams, sendRequest]);

  const nameBodyTemplate = (rowData) => {
    return <div className={styles.linkStyle}>{rowData.name}</div>;
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onLocationSelect = useCallback(
    (event) => {
      if (event.field === 'remove') {
        return confirmDeleteLocation(event.rowData);
      }
      const locationInfoPage = 'location';
      const locationName = event.rowData.name;
      const locationId = event.rowData.id;
      history.push(`/${locationInfoPage}/${locationId}`);
      localStorage.setItem('locationName', locationName);
      localStorage.setItem('locationId', locationId);
    },
    [history]
  );

  const sortFunc = () => {
    return tableRef?.current?.props.value || locations;
  };

  const onNewLocationCreate = () => {
    setDisplayLocationModal(true);
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="New Location"
          icon="pi pi-plus"
          onClick={() => onNewLocationCreate()}
        />
      </div>
    </div>
  );

  const footer = useCallback(() => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage =
          'You don’t have locations yet. Please create a location.';
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  }, [pagination, totalRecords]);

  const confirmDeleteLocation = (location) => {
    setLocationToDelete(location);
    setDeleteLocationDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteLocation(rowData)}
        />
      </React.Fragment>
    );
  };

  const hideDeleteLocationDialog = () => {
    setDeleteLocationDialog(false);
  };

  const hideFirstLoginDialog = () => {
    dispatch(setIsFirstLogin(false));
  };

  const deleteLocation = async () => {
    setDeleteLocationDialog(false);
    const locationParams = {
      id: {
        in: [locationToDelete.id],
      },
    };

    const requestData = {
      url: urls.DELETE_LOCATIONS,
      method: 'POST',
      data: locationParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      setLocationIsDeleted(true);
      dispatch(getTaskOptions(actionData));
    }
  };

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'name') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const deleteLocationsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteLocationDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteLocation}
      />
    </React.Fragment>
  );

  const deleteFirstLoginDialogFooter = (
    <React.Fragment>
      <Button
        label="OK"
        icon="pi pi-check"
        className="p-button-label p-c"
        onClick={hideFirstLoginDialog}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [{ label: 'Locations' }];

  useEffect(() => {
    if (sort) {
      setLocationParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    setLocationParams((oldParams) => {
      let params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    if (locationIsCreated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New location was created.',
          life: 5000,
          sticky: null,
        })
      );
      fetchLocations();
    } else if (locationIsDeleted) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Location ${locationToDelete.name} was deleted.`,
          life: 5000,
          sticky: null,
        })
      );
      fetchLocations();
    }
  }, [
    dispatch,
    fetchLocations,
    locationIsCreated,
    locationIsDeleted,
    locationToDelete?.name,
  ]);

  useEffect(() => {
    !isLoading && !isEmpty(filteredCountries) && fetchLocations();
  }, [locationParams, filteredCountries, fetchLocations]);

  useEffect(() => {
    setFilteredCountries(getFilteredCountries(countries));
  }, []);

  return (
    <div className={`${styles.locationList} list-generic`}>
      <NewLocationForm
        plants={props.plants}
        sections={props.sections}
        startForms={props.startForms}
        displayLocationModal={displayLocationModal}
        setDisplayLocationModal={setDisplayLocationModal}
        setLocationIsCreated={setLocationIsCreated}
        locationIsCreated={locationIsCreated}
      />
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className="table-generic p-datatable-sm"
        reorderableColumns
        value={locations}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={selectedLocations}
        onSelectionChange={(e) => setSelectedLocations(e.value)}
        selectionMode="single"
        cellSelection
        onCellSelect={onLocationSelect}
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
        loading={isLoading}
      >
        <Column
          className="p-text-nowrap p-text-truncate"
          field="name"
          columnKey="name"
          header="Location Name"
          body={nameBodyTemplate}
          headerStyle={{ width: '270px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="type"
          columnKey="type"
          header="Type"
          headerStyle={{ height: '48px', width: '150px' }}
          bodyStyle={{ height: '50px' }}
        />
        <Column
          field="createDate"
          columnKey="createDate"
          header="Create Date"
          headerStyle={{ width: '150px' }}
        />
        <Column
          field="country"
          columnKey="country"
          header="Country"
          headerStyle={{ width: '150px' }}
        />
        <Column
          field="state"
          columnKey="state"
          header="State"
          headerStyle={{ width: '150px' }}
        />
        <Column
          field="city"
          columnKey="city"
          header="City"
          headerStyle={{ width: '150px' }}
        />
        <Column
          field="description"
          columnKey="description"
          header="Description"
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          headerStyle={{ width: '250px' }}
          body={(rowData) =>
            ShowDetails(rowData[showDetailsFields.DESCRIPTION])
          }
        />
        <Column
          field="remove"
          header="Remove"
          headerStyle={{ width: '70px' }}
          body={actionBodyTemplate}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={deleteLocationDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        footer={deleteLocationsDialogFooter}
        onHide={hideDeleteLocationDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {locationToDelete && (
            <span>
              Are you sure you want to delete location{' '}
              <b>{locationToDelete.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={isFirstLogin && permissions.includes('company:manage')}
        className="confirmDialog"
        header="Please create your first location."
        footer={deleteFirstLoginDialogFooter}
        onHide={hideFirstLoginDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          <p>
            Congratulations on a successful registration!
            <br />
            To create a new location please click the
            <br />
            &quot;New Location&quot; button and fill the form.
          </p>
        </div>
      </Dialog>
    </div>
  );
};

export default LocationList;
