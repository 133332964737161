import { errorCodes } from '../Common/globalConstants';
import { employeeErrorCodes } from './constants';

export const getErrorMessage = (error) => {
  let errorMessage = errorCodes.DEFAULT_MESSAGE.text;
  if (
    error.response.data.errors &&
    error.response.data.errors[0]?.code ===
      employeeErrorCodes.NOT_VALID_IMAGE_TYPE.code
  ) {
    errorMessage = employeeErrorCodes.NOT_VALID_IMAGE_TYPE.text;
  } else if (
    error.response.data.error?.code === employeeErrorCodes.CONFLICT.code
  ) {
    errorMessage = employeeErrorCodes.CONFLICT.text;
  }

  return errorMessage;
};
