import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import styles from './SubscriptionCard.module.scss';

const SubscriptionCard = ({
  color,
  description,
  prices,
  name,
  toCustomerPortal,
}) => {
  const header = (
    <div className={styles.cardHeader}>
      <h1 style={{ color: color }}>{name}</h1>
      <h3>
        ${prices.price}/{prices.interval}
      </h3>
      <Button
        label={'Get Started'}
        onClick={() => toCustomerPortal(prices.id)}
      />
    </div>
  );

  return (
    <Card className={`${styles.subscriptionCard} p-shadow-12`} header={header}>
      <div className={styles.subTitle}>What&apos;s included</div>
      <Divider />
      <ul>
        {description.split('.').map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </Card>
  );
};

export default SubscriptionCard;
