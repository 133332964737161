import * as yup from 'yup';

export const initialPlantGoalsBatchFormSchema = (plantGoalsInfo) => {
  return {
    field: plantGoalsInfo.field,
    daysAfterPlanting: plantGoalsInfo.daysAfterPlanting,
    goal: plantGoalsInfo.goal,
    isEvent: plantGoalsInfo.isEvent,
  };
};

export const plantGoalsBatchFormSchema = yup.object().shape({
  daysAfterPlanting: yup
    .number()
    .max(999, 'Max value 999.')
    .min(1, 'Min value 1.')
    .when('isEvent', {
      is: true,
      then: yup.number().nullable().notRequired(),
      otherwise: yup.number().required('Field is required.'),
    }),
  goal: yup
    .string()
    .required('Field should be provided.')
    .max(255, 'Maximum length exceeded.'),
});
