export const initialEmployeeInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  title: '',
  roles: [],
  department: '',
  status: '',
  note: '',
  picture: '',
};

export const employeeParams = (id) => ({
  query: {
    employee: {
      id: {
        is: id,
      },
    },
  },
});

export const employeeErrorCodes = {
  NOT_PROVIDED: 'This field should be provided.',
  MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  NOT_UNIQUE: 'This field should be unique.',
  ENTITY_LIMIT_EXCEEDED: 'ENTITY_LIMIT_EXCEEDED',
  CONFLICT: {
    code: 'CONFLICT',
    text: 'This employee has been updated by another user. Please refresh the page and then save your updates.',
  },
  NOT_VALID_IMAGE_TYPE: {
    code: 'NOT_VALID_IMAGE_TYPE',
    text: 'Invalid image type. Supported image types are PNG, JPG, JPEG',
  },
};

export const employeeState = {
  DEFAULT: 'DEFAULT',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
  INVITED: 'INVITED',
};
