import React, { useCallback } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import styles from './FileUploader.module.scss';

const FileUploader = ({
  onSelect,
  onValidationFail,
  uploading,
  selectedImage,
  picture,
  isImageDeleted,
  onImageRemove,
  onTemplateRemove,
  disabled,
}) => {
  const headerTemplate = useCallback(
    (options) => {
      const { className, chooseButton } = options;
      const progressBar = uploading && (
        <div className="p-d-flex p-jc-end">
          <h4>Uploading photo...</h4>
          <ProgressBar
            className={classNames(styles.progressBar, 'p-ml-5 p-as-center')}
            mode="indeterminate"
          />
        </div>
      );
      return (
        <div
          className={classNames(
            styles.headerTemplate,
            className,
            'p-jc-between'
          )}
        >
          {chooseButton}
          {progressBar}
        </div>
      );
    },
    [uploading]
  );

  const itemTemplate = useCallback((file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className={classNames('p-d-flex p-ai-center', styles.template)}>
          <img
            className={styles.employeeImage}
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={150}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  }, []);

  const emptyTemplate = useCallback(() => {
    if (picture && !isImageDeleted) {
      return (
        <div className="p-d-flex p-ai-center p-flex-wrap">
          <div className={classNames('p-d-flex p-ai-center', styles.template)}>
            <img
              className={styles.employeeImage}
              role="presentation"
              src={picture.full}
              width={250}
            />
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={onImageRemove}
          />
        </div>
      );
    }
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-image p-mt-1 p-p-3"
          style={{
            fontSize: '3em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        />
        <span
          style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }}
          className="p-my-2"
        >
          Drag and Drop Photo Here
        </span>
      </div>
    );
  }, [isImageDeleted, picture]);

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: `custom-choose-btn p-button-rounded p-button-outlined
    ${(selectedImage || (picture && !isImageDeleted)) && 'p-disabled'}`,
  };

  return (
    <FileUpload
      name="employeeImage"
      accept=".jpeg,.jpg,.png"
      maxFileSize={1000000}
      onSelect={onSelect}
      onValidationFail={onValidationFail}
      headerTemplate={headerTemplate}
      itemTemplate={itemTemplate}
      emptyTemplate={emptyTemplate}
      chooseOptions={chooseOptions}
      disabled={disabled}
    />
  );
};
export default FileUploader;
