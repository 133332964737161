import * as yup from 'yup';

export const initialApplicationFormSchema = (applicationInfo) => {
  return {
    code: applicationInfo.code,
    quantity: applicationInfo.quantity,
    mixerQuantity: applicationInfo.mixerQuantity,
    note: applicationInfo.note || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const applicationFormSchema = yup.object().shape({
  code: yup
    .string()
    .required('Application code should be provided.')
    .max(32, lengthError),
  quantity: yup
    .number()
    .test(
      'len',
      { lengthError },
      (val) => !val || (val && val.toString().length <= 32)
    )
    .positive('Quantity must be a positive number')
    .required('Quantity should be provided.'),
  mixerQuantity: yup
    .number()
    .test(
      'len',
      { lengthError },
      (val) => !val || (val && val.toString().length <= 32)
    )
    .positive('Mixer quantity must be a positive number')
    .required('Mixer quantity should be provided.'),
  note: yup.string().max(1024, lengthError),
});
