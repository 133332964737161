import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import DatePicker from 'datepicker-special-week-numbers';
import {
  capitalize,
  checkLimitExceeded,
  uploadImage,
} from '../../Common/utils';
import {
  employeeFormSchema,
  initialEmployeeFormSchema,
} from './employeeFormValidation';
import { urls, useRequest } from '../../Common/ApiServices';
import PromptIfDirty from '../../Common/PromptIfDirty';
import FileUploader from './FileUploader/FileUploader';
import {
  initialEmployeeInfo,
  employeeParams,
  employeeErrorCodes,
  employeeState,
} from '../constants';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import { popUp } from '../../Common/globalConstants';
import { getErrorMessage } from '../utils';
import styles from './EmployeeForm.module.scss';

const EmployeeForm = ({
  employeeId,
  displayEmployeeModal,
  employeeIs,
  setEmployeeIs,
  setDisplayEmployeeModal,
  setNewEmployeeId,
  setSelectedEmployee,
}) => {
  const [employeeInfo, setEmployeeInfo] = useState(initialEmployeeInfo);
  const [isActive, setIsActive] = useState(true);
  const [initialRoles, setInitialRoles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [createdImage, setCreatedImage] = useState(null);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [validationFail, setValidationFail] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [roles, setRoles] = useState([]);

  const dispatch = useDispatch();
  const { error, sendRequest } = useRequest({});

  const getEmployeeInfo = useCallback(async () => {
    const requestData = {
      url: urls.EXTRACT_EMPLOYEE,
      method: 'POST',
      data: employeeParams(employeeId),
    };

    const response = await sendRequest(requestData);

    if (response) {
      const dataEmployee = response.data.employee;
      dataEmployee.roles = dataEmployee.roles
        ?.map((role) => {
          return roles.find((role_) => role_.code === role);
        })
        .filter(Boolean);
      setEmployeeIs(employeeState.DEFAULT);
      setEmployeeInfo(dataEmployee);
      setIsActive(dataEmployee.status === 'ACTIVE');
      setInitialRoles(dataEmployee.roles);
    }
  }, [employeeId]);

  const createEmployeeImage = useCallback(async () => {
    const imageType = selectedImage.type.slice(6);
    const requestData = {
      url: urls.CREATE_EMPLOYEE_PICTURE,
      method: 'POST',
      data: { picture: { type: imageType } },
    };

    const response = await sendRequest(requestData);

    response && setCreatedImage(response.data.picture);
  }, [selectedImage]);

  const createEmployee = useCallback(
    async (employeeParams) => {
      const requestData = {
        url: urls.CREATE_EMPLOYEE,
        method: 'POST',
        data: employeeParams,
      };
      createdImage &&
        (await uploadImage(
          setUploading,
          selectedImage.objectURL,
          createdImage.uploadUrl,
          setIsError
        ));
      const response = await sendRequest(requestData);
      if (response) {
        setNewEmployeeId(response.data.id);
        setEmployeeIs(employeeState.CREATED);
      }
      return response;
    },
    [createdImage, selectedImage]
  );

  const updateEmployee = useCallback(
    async (employeeParams) => {
      const requestData = {
        url: urls.UPDATE_EMPLOYEE,
        method: 'POST',
        data: employeeParams,
      };

      createdImage &&
        (await uploadImage(
          setUploading,
          selectedImage.objectURL,
          createdImage.uploadUrl,
          setIsError
        ));

      const response = await sendRequest(requestData);
      if (response) {
        setEmployeeIs(employeeState.UPDATED);
      }
    },
    [createdImage, selectedImage]
  );

  const accept = () => {
    onHide();
  };

  const confirm = useCallback(
    (dirty) => {
      if (dirty || selectedImage || isImageDeleted) {
        confirmDialog({
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          message: 'Are you sure you want to discard the changes?',
          accept,
        });
      } else {
        accept();
      }
    },
    [accept, isImageDeleted, selectedImage]
  );

  const onHide = () => {
    setDisplayEmployeeModal(false);
    formik.resetForm();
    setEmployeeInfo(initialEmployeeInfo);
    setSelectedImage(null);
    setCreatedImage(null);
    setIsImageDeleted(false);
    setInitialRoles([]);
    setIsActive(true);
    setEmployeeIs(employeeState.DEFAULT);
    setSelectedEmployee(null);
    setIsError(false);
  };

  const onTemplateSelect = (e) => {
    setSelectedImage(e.files[0]);
  };

  const onTemplateRemove = () => {
    setSelectedImage(null);
    setCreatedImage(null);
  };

  const onImageRemove = () => {
    setIsImageDeleted(true);
    setSelectedImage(null);
    setCreatedImage(null);
  };

  const onValidationFail = () => {
    setValidationFail(true);
  };

  const formik = useFormik({
    initialValues: initialEmployeeFormSchema(employeeInfo),
    enableReinitialize: true,
    validationSchema: employeeFormSchema,
    onSubmit: (values) => {
      const currentImage =
        !isImageDeleted && employeeInfo.picture
          ? employeeInfo.picture?.id
          : null;
      let newEmployeeParams = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone || null,
        title: values.title || null,
        roles: !isActive
          ? initialRoles?.map((role) => role?.code)
          : values.roles?.map((role) => role?.code),
        department: values.department || null,
        status: values.status ? 'ACTIVE' : 'INACTIVE',
        note: values.note || null,
        pictureId: currentImage || createdImage?.id || null,
      };
      if (employeeId) {
        newEmployeeParams = {
          ...newEmployeeParams,
          ...{
            id: employeeId,
            versionNumber: employeeInfo.version?.number || 0,
          },
        };
        updateEmployee(newEmployeeParams);
      } else {
        delete newEmployeeParams.status;
        createEmployee(newEmployeeParams);
      }
    },
  });

  const uniqueRoleByName = useCallback((roles) => {
    let seen = {};
    return roles.filter((role) => {
      return {}.hasOwnProperty.call(seen, role?.name)
        ? false
        : (seen[role?.name] = true);
    });
  }, []);

  const activeEmployeeCheckbox = employeeId && (
    <>
      <div className="p-field p-grid p-ai-start">
        <div className={`${styles.checkbox} p-field-checkbox p-offset-3`}>
          <Checkbox
            id="status"
            onChange={formik.handleChange}
            value={formik.values.status}
            checked={formik.values.status}
          />
          <label htmlFor="status">Active</label>
        </div>
      </div>
    </>
  );

  const sendInvite = useCallback(async () => {
    const requestData = {
      url: urls.SEND_WELCOME_EMAIL,
      method: 'POST',
      data: { employeeId: employeeId },
    };

    const response = await sendRequest(requestData);
    if (response) {
      setEmployeeIs(employeeState.INVITED);
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: "Invitation was sent to the employee's email.",
          life: 5000,
        })
      );
    }
  }, [dispatch, employeeId]);

  const sendInviteButton = employeeId && (
    <Button
      className={`${styles.button} ${
        employeeInfo.lastLoginDate !== null ||
        employeeInfo.emailVerified ||
        !isActive
          ? styles.hidden
          : null
      }`}
      label="Send Invite"
      type="button"
      icon="pi pi-send"
      disabled={employeeIs === employeeState.INVITED}
      onClick={sendInvite}
    />
  );

  const CustomCalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      label={value}
      className={`${styles.calendarButton} p-button-text p-button-plain`}
      icon="pi pi-calendar"
      iconPos="right"
      disabled
      type="button"
    />
  ));

  const employeeDates = employeeId && (
    <>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="lastLoginDate" className="p-col-12 p-md-3">
          Last Login Date
        </label>
        <div className="p-col-8">
          <DatePicker
            id="lastLoginDate"
            selected={formik.values.lastLoginDate}
            dateFormat={formik.values.dateFormat}
            customInput={<CustomCalendarInput />}
          />
        </div>
      </div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="inactiveDate" className="p-col-12 p-md-3">
          Inactive Date
        </label>
        <div className="p-col-8">
          <DatePicker
            id="inactiveDate"
            selected={formik.values.inactiveDate}
            dateFormat={formik.values.dateFormat}
            customInput={<CustomCalendarInput />}
          />
        </div>
      </div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="lastInvitationDate" className="p-col-12 p-md-3">
          Last Invitation Date
        </label>
        <div className="p-col-8">
          <DatePicker
            id="lastInvitationDate"
            selected={formik.values.lastInvitationDate}
            dateFormat={formik.values.dateFormat}
            customInput={<CustomCalendarInput />}
          />
        </div>
      </div>
    </>
  );

  useEffect(async () => {
    const requestData = {
      url: urls.GET_ROLES,
      method: 'POST',
      data: {},
    };

    const response = await sendRequest(requestData);
    if (response) {
      const roles = response.data.roles?.map((role) => ({
        code: role.name,
        name: capitalize(role.name.replace('PPT_', '').replaceAll('_', ' ')),
      }));
      setRoles(roles);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      getEmployeeInfo();
    }
  }, [employeeId]);

  useEffect(() => {
    if (
      !isError &&
      (employeeIs === employeeState.CREATED ||
        employeeIs === employeeState.UPDATED)
    ) {
      onHide();
    }
  }, [isError, employeeIs]);

  useEffect(() => {
    if (selectedImage) {
      createEmployeeImage();
      setValidationFail(false);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (validationFail) {
      setCreatedImage(null);
      setSelectedImage(null);
    }
  }, [validationFail]);

  useEffect(() => {
    if (employeeId) {
      setIsActive(formik.values.status);
    }
  }, [formik.values.status, employeeId]);

  useEffect(() => {
    if (error) {
      const errorMessage = getErrorMessage(error);

      setEmployeeIs(employeeState.DEFAULT);
      if (error.response?.data?.errors) {
        error.response.data.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, employeeErrorCodes[err.code])
        );
        if (
          error.response.data.errors[0]?.code ===
          employeeErrorCodes.NOT_VALID_IMAGE_TYPE.code
        ) {
          onTemplateRemove();
        }
      } else if (
        error?.response?.data?.error?.code ===
        employeeErrorCodes.ENTITY_LIMIT_EXCEEDED
      ) {
        checkLimitExceeded(dispatch, true);
      }
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: errorMessage,
          life: 5000,
        })
      );
    }
  }, [error]);

  return (
    <Fragment>
      <Dialog
        className={styles.employeeFormDialog}
        header="Employee Information"
        visible={displayEmployeeModal}
        onHide={() => confirm(formik.dirty)}
      >
        <PromptIfDirty dirty={formik.dirty} />
        <Tooltip
          target=".custom-choose-btn"
          content="Choose"
          position="bottom"
        />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="firstName"
                className="p-col-12 p-md-3 p-text-bold"
              >
                First Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  aria-describedby="firstName-invalid"
                  className={formik.errors.firstName ? 'p-invalid' : null}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <small id="firstName-invalid" className="p-error p-d-block">
                    {formik.errors.firstName}
                  </small>
                ) : (
                  <small>Employee First Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="lastName" className="p-col-12 p-md-3 p-text-bold">
                Last Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  aria-describedby="lastName-invalid"
                  className={formik.errors.lastName ? 'p-invalid' : null}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <small id="lastName-invalid" className="p-error p-d-block">
                    {formik.errors.lastName}
                  </small>
                ) : (
                  <small>Employee Last Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="email" className="p-col-12 p-md-3 p-text-bold">
                Email*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  aria-describedby="email-invalid"
                  className={formik.errors.email ? 'p-invalid' : null}
                />
                {formik.touched.email && formik.errors.email ? (
                  <small id="email-invalid" className="p-error p-d-block">
                    {formik.errors.email}
                  </small>
                ) : (
                  <small>Employee Email is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="phone" className="p-col-12 p-md-3">
                Phone
              </label>
              <div className="p-col-12 p-md-9">
                <InputMask
                  className={formik.errors.phone ? 'p-invalid' : null}
                  id="phone"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  aria-describedby="phone-invalid"
                />
                {formik.touched.phone && formik.errors.phone && (
                  <small id="phone-invalid" className="p-error p-d-block">
                    {formik.errors.phone}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="title" className="p-col-12 p-md-3">
                Title
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="title"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  aria-describedby="title-invalid"
                  className={formik.errors.title ? 'p-invalid' : null}
                />
                {formik.touched.title && formik.errors.title && (
                  <small id="title-invalid" className="p-error p-d-block">
                    {formik.errors.title}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="department" className="p-col-12 p-md-3">
                Department
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="department"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                  aria-describedby="department-invalid"
                  className={formik.errors.department ? 'p-invalid' : null}
                />
                {formik.touched.department && formik.errors.department && (
                  <small id="department-invalid" className="p-error p-d-block">
                    {formik.errors.department}
                  </small>
                )}
              </div>
            </div>
            {activeEmployeeCheckbox}
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="roles" className="p-col-12 p-md-3">
                Roles
              </label>
              <div className="p-col-12 p-md-9">
                <MultiSelect
                  id="roles"
                  value={
                    !isActive
                      ? initialRoles
                      : uniqueRoleByName(formik.values.roles, name)
                  }
                  aria-describedby="roles-invalid"
                  options={roles}
                  onChange={formik.handleChange}
                  optionLabel="name"
                  display="chip"
                  disabled={!isActive}
                />
              </div>
            </div>
            {employeeDates}
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <FileUploader
            onSelect={onTemplateSelect}
            onValidationFail={onValidationFail}
            uploading={uploading}
            selectedImage={selectedImage}
            picture={employeeInfo.picture}
            isImageDeleted={isImageDeleted}
            onImageRemove={onImageRemove}
            onTemplateRemove={onTemplateRemove}
            disabled={Boolean(
              selectedImage || (employeeInfo.picture && !isImageDeleted)
            )}
          />
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            {sendInviteButton}
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                (!formik.dirty && !selectedImage && !isImageDeleted) ||
                  uploading ||
                  !formik.values.firstName ||
                  !formik.values.lastName ||
                  !formik.values.email ||
                  formik.errors.title ||
                  formik.errors.department ||
                  formik.errors.phone ||
                  formik.errors.note
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              disabled={Boolean(uploading)}
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </Fragment>
  );
};
export default EmployeeForm;
