import React from 'react';
import Menu from '../../Common/Menu/Menu';

const LocationMainMenu = () => {
  const items = [{ link: '/locations', value: 'Locations' }];

  return <Menu items={items} />;
};

export default LocationMainMenu;
