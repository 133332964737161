import * as actionTypes from './actions';

const initialState = false;

export const isTrialTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_TRIAL_TRACKER:
      return action.payload;
    default:
      return state;
  }
};
