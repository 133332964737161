import Cookies from 'universal-cookie';

const cookies = new Cookies();
const IS_AUTH_COOKIE_NAME = 'sppt.is.authenticated';

const isAuthCookie = () => {
  return Boolean(cookies.get(IS_AUTH_COOKIE_NAME));
};

const updateAuthCookieExpiration = () => {
  cookies.set(IS_AUTH_COOKIE_NAME, true, {
    path: '/',
    expires: new Date(Date.now() + 35 * 60000),
  });
};

const removeAuthCookie = () => {
  cookies.remove(IS_AUTH_COOKIE_NAME, {
    path: '/',
  });
};

export { removeAuthCookie, updateAuthCookieExpiration, isAuthCookie };
