import React from 'react';
import LeftBar from '../../LeftBar/LeftBar';
import SettingsMenu from '../SettingsMenu/SettingsMenu';
import LabelTemplates from '../LabelsTemplates/LabelsTemplateList';

function SettingsPage() {
  return (
    <>
      <LeftBar>
        <SettingsMenu />
      </LeftBar>
      <LabelTemplates />
    </>
  );
}

export default SettingsPage;
