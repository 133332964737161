import React from 'react';
import Menu from '../../Common/Menu/Menu';

const EmployeeMainMenu = () => {
  const items = [{ link: '/employees', value: 'Employees' }];

  return <Menu items={items} />;
};

export default EmployeeMainMenu;
