import React from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { fieldOrigin } from '../LabelsEditor/constants';
import styles from './CustomSelect.module.scss';

const CustomSelect = ({
  setAddNewFieldDialog,
  value,
  options,
  placeholder,
  disabled,
  onChange,
  onDeleteCustomOption,
  isDropdownOptionsOpen,
  setIsDropdownOptionsOpen,
  setDefaultSettings,
}) => {
  const toggleOptions = () => {
    setIsDropdownOptionsOpen(!isDropdownOptionsOpen);
    setDefaultSettings();
  };
  const addNewFieldHandler = (e) => {
    e.preventDefault();
    setAddNewFieldDialog(true);
  };

  return (
    <div className={styles.customSelect}>
      <div className={styles.container}>
        <Button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isDropdownOptionsOpen}
          label={value ? value.prettyName : placeholder}
          className={styles.dropDown}
          onClick={toggleOptions}
          iconPos="right"
          disabled={disabled}
          icon={
            isDropdownOptionsOpen ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
        />

        <ul
          className={classNames(
            styles.options,
            isDropdownOptionsOpen ? styles.show : ''
          )}
          role="listbox"
          tabIndex={-1}
        >
          <button className={styles.addNewField} onClick={addNewFieldHandler}>
            Add New Field
          </button>
          {options?.length
            ? options.map((option, index) => {
                const isDefaultField = option.origin === fieldOrigin.SYSTEM;
                return (
                  <li
                    id={option.id}
                    key={index}
                    role="option"
                    aria-disabled={option.disabled}
                    tabIndex={0}
                    data-name={option.name}
                    data-val={option.val}
                    data-disabled={option.disabled}
                    data-typeof={option.type}
                    data-showprettyname={option.showPrettyName}
                    data-prettyname={option.prettyName}
                    data-examplevalue={option.exampleValue}
                    data-origin={option.origin}
                    className={classNames(
                      styles.optionItem,
                      option.disabled && styles.disabled
                    )}
                    onClick={(e) => {
                      onChange(e.currentTarget);
                      setIsDropdownOptionsOpen(false);
                    }}
                  >
                    <span className={styles.fieldName}>
                      {option.prettyName}
                      {!isDefaultField && !option.disabled && (
                        <Button
                          icon="pi pi-times"
                          type="button"
                          className={classNames(
                            'p-button-rounded p-button-danger p-button-text',
                            styles.deleteOptionBtn
                          )}
                          aria-label="Cancel"
                          onClick={(e) => onDeleteCustomOption(e, option.val)}
                        />
                      )}
                    </span>
                    <span
                      className={styles.fieldType}
                    >{`( ${option.type} )`}</span>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </div>
  );
};

export default CustomSelect;
