import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import styles from './MultiSelectFilter.module.scss';

const MultiSelectFilter = (props) => {
  return (
    <div
      className={classNames(styles.multiSelectFilter)}
      style={props.filterWrapperStyles ? props.filterWrapperStyles : {}}
    >
      <div
        className={styles.labelText}
        style={props.filterLabelStyles ? props.filterLabelStyles : {}}
      >
        {props.label}
      </div>
      <MultiSelect
        className={`p-multiselect ${styles.multiselectInput}`}
        panelClassName={props.panelClassName && styles.selectAll}
        value={props.selectedItems}
        options={props.items ? props.items : []}
        onChange={(e) => props.setSelectedItems(e.value)}
        optionLabel="name"
        filter={props.filter}
        placeholder={props.filterName}
        itemTemplate={props.itemTemplate}
        showClear={true}
      />
    </div>
  );
};

export default MultiSelectFilter;
