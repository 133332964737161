import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import moment from 'moment';
import DatePicker from 'datepicker-special-week-numbers';
import {
  cropFormSchema,
  initialCropFormSchema,
} from './cropBatchFormValidation';
import { getWeekNumber } from '../../../Common/utils';
import { locales, popUp } from '../../../Common/globalConstants';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import urls from '../../../Common/ApiServices/urls';
import { useRequest } from '../../../Common/ApiServices';
import { initialBatchCropInfo, modals } from '../constants';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import styles from './CropBatchForm.module.scss';

const CropBatchForm = ({
  displayModal,
  setDisplayModal,
  setSelectedCrops,
  selectedCrops,
  startForms,
  potSizes,
  fetchCrops,
  trialId,
}) => {
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [currentRefTableName, setCurrentRefTableName] = useState('');

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const cropSections = useSelector((state) => state.crop.sections);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const firstDayOfWeek = useSelector(
    (state) => state.crop?.location?.firstDayOfWeek?.day
  );
  const dateFormatting = useSelector(
    (state) => state.crop?.location?.dateFormat?.datePattern
  );

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const CustomCalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      label={value}
      className={`${styles.calendarButton} p-button-text p-button-plain`}
      icon="pi pi-calendar"
      iconPos="right"
      onClick={onClick}
      ref={ref}
      disabled={isTrialTracker}
      type="button"
    />
  ));

  const sections = cropSections
    ? cropSections.map(({ section }) => ({
        name: section.name,
        id: section.id,
        description: section.description || null,
      }))
    : [];

  const updateCropBatch = async (updatedParams) => {
    const requestData = {
      url: urls.BATCH_CROP_UPDATE,
      method: 'POST',
      data: {
        ids: selectedCrops.map((crop) => {
          return { id: crop.id, versionNumber: crop.versionNumber || 1 };
        }),
        ...(updatedParams.sectionId && {
          sectionId: updatedParams.sectionId,
        }),
        ...(updatedParams.startFormId && {
          startFormId: updatedParams.startFormId,
        }),
        ...(updatedParams.potSizeId && {
          potSizeId: updatedParams.potSizeId,
        }),
        ...(updatedParams.startDate && {
          startDate: updatedParams.startDate,
        }),
        ...(updatedParams.plantDate && {
          plantDate: updatedParams.plantDate,
        }),
        ...(updatedParams.transplantDate && {
          transplantDate: updatedParams.transplantDate,
        }),
        ...(updatedParams.spaceDate1 && {
          spaceDate1: updatedParams.spaceDate1,
        }),
        ...(updatedParams.spaceDate2 && {
          spaceDate2: updatedParams.spaceDate2,
        }),
        ...(updatedParams.quantity && {
          quantity: updatedParams.quantity,
        }),
        ...(updatedParams.plantsPerPot && {
          plantsPerPot: updatedParams.plantsPerPot,
        }),
        ...(updatedParams.note && {
          note: updatedParams.note,
        }),
      },
    };

    const response = await sendRequest(requestData);
    if (response) {
      setSelectedCrops([]);
      setDisplayModal('');
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Batch of plants was updated.',
          life: 5000,
          sticky: null,
        })
      );
      fetchCrops();
    }
    return response;
  };

  const formik = useFormik({
    initialValues: initialCropFormSchema(initialBatchCropInfo),
    enableReinitialize: true,
    validationSchema: cropFormSchema,
    onSubmit: (values) => {
      const updatedParams = {
        sectionId: values.section && values.section.id,
        startFormId: values.startForm && values.startForm.id,
        potSizeId: values.potSize && values.potSize.id,
        startDate: values.startDate
          ? moment(values.startDate).format('YYYY-MM-DD')
          : null,
        plantDate: values.plantDate
          ? moment(values.plantDate).format('YYYY-MM-DD')
          : null,
        transplantDate: values.transplantDate
          ? moment(values.transplantDate).format('YYYY-MM-DD')
          : null,
        spaceDate1: values.spaceDate1
          ? moment(values.spaceDate1).format('YYYY-MM-DD')
          : null,
        spaceDate2: values.spaceDate2
          ? moment(values.spaceDate2).format('YYYY-MM-DD')
          : null,
        quantity: values.quantity || null,
        plantsPerPot: values.plantsPerPot || null,
        note: values.note || null,
      };
      updateCropBatch(updatedParams);
    },
  });

  const accept = () => {
    setDisplayModal('');
    formik.handleReset();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const refTableHeader = () => {
    if (currentRefTableName === 'startforms') {
      return 'Start forms';
    } else if (currentRefTableName === 'pot-sizes') {
      return 'Pot Sizes';
    }
    return currentRefTableName;
  };

  const refTableValues = {
    [urls.START_FORMS]: startForms,
    [urls.POT_SIZES]: potSizes,
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  useEffect(() => {
    if (error) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: '',
          life: 5000,
          sticky: null,
        })
      );
      setDisplayModal('');
    }
  }, [error]);

  return (
    <>
      <Dialog
        className={styles.batchFormDialog}
        header="Update Information"
        visible={displayModal === modals.BATCH}
        onHide={() => confirm()}
      >
        <PromptIfDirty dirty={formik.dirty} />
        <div className={styles.cropForm}>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid">
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="plantDate" className="p-col-12 p-md-3">
                  Plant Date
                </label>
                <div className="p-col-5">
                  <DatePicker
                    id="plantDate"
                    onChange={(val) => {
                      formik.setFieldValue('plantDate', val);
                    }}
                    selected={formik.values.plantDate}
                    dateFormat={dateFormatting}
                    weekLabel={'Wk'}
                    showWeekNumbers
                    locale={locales[firstDayOfWeek]}
                    customInput={<CustomCalendarInput />}
                    disabled={isTrialTracker}
                  />
                  {formik.touched.plantDate && formik.errors.plantDate && (
                    <small
                      id="plantDate-invalid"
                      className="p-col-7 p-md-3 p-xl-2 p-error p-d-block"
                    >
                      {formik.errors.plantDate}
                    </small>
                  )}
                </div>
                <label
                  className={`${styles.weekLabel} p-col-fixed`}
                  style={{ width: '75px' }}
                >
                  Week
                </label>
                <div className="p-col-fixed" style={{ width: '70px' }}>
                  <InputText
                    value={getWeekNumber(
                      formik.values.plantDate,
                      firstDayOfWeek
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="section" className="p-col-12 p-md-3">
                  Section
                </label>
                <div className="p-col-12 p-md-9">
                  <Dropdown
                    id="section"
                    onChange={formik.handleChange}
                    onBlur={() => {
                      formik.handleBlur({ target: { name: 'section' } });
                    }}
                    value={formik.values.section}
                    aria-describedby="section-invalid"
                    className={`${
                      (error ||
                        (formik.errors.section && formik.touched.section)) &&
                      'p-invalid'
                    }`}
                    options={sections}
                    optionLabel="name"
                    filter
                    disabled={isTrialTracker}
                  />
                  {formik.touched.section && formik.errors.section && (
                    <small id="section-invalid" className="p-error p-d-block">
                      {formik.errors.section}
                    </small>
                  )}
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="quantity" className="p-col-12 p-md-3">
                  Quantity
                </label>
                <div className="p-col-12 p-md-9">
                  <InputText
                    id="quantity"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantity}
                    disabled={isTrialTracker}
                    aria-describedby="quantity-invalid"
                    className={`${
                      (error ||
                        (formik.errors.quantity && formik.touched.quantity)) &&
                      'p-invalid'
                    }`}
                  />
                  {formik.touched.quantity && formik.errors.quantity && (
                    <small id="quantity-invalid" className="p-error p-d-block">
                      {formik.errors.quantity}
                    </small>
                  )}
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="startForm" className="p-col-12 p-md-3">
                  Start Form
                </label>
                <div className="p-col-9 p-md-7">
                  <Dropdown
                    id="startForm"
                    onChange={formik.handleChange}
                    value={formik.values.startForm}
                    options={startForms}
                    optionLabel="name"
                    showClear
                    disabled={isTrialTracker}
                  />
                </div>
                <div className="p-col-3 p-md-2">
                  <Button
                    className="p-button-rounded p-button-info p-button-outlined"
                    type="button"
                    icon="pi pi-pencil"
                    style={{ display: showElements }}
                    onClick={() => onOpenRefTable('startforms')}
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="startDate" className="p-col-12 p-md-3">
                  Start Date
                </label>
                <div className="p-col-5">
                  <DatePicker
                    id="startDate"
                    onChange={(val) => {
                      formik.setFieldValue('startDate', val);
                    }}
                    selected={formik.values.startDate}
                    dateFormat={dateFormatting}
                    weekLabel={'Wk'}
                    showWeekNumbers
                    locale={locales[firstDayOfWeek]}
                    isClearable={!isTrialTracker}
                    customInput={<CustomCalendarInput />}
                    disabled={isTrialTracker}
                  />
                </div>
                <label
                  className={`${styles.weekLabel} p-col-fixed`}
                  style={{ width: '75px' }}
                >
                  Week
                </label>
                <div className="p-col-fixed" style={{ width: '70px' }}>
                  <InputText
                    value={getWeekNumber(
                      formik.values.startDate,
                      firstDayOfWeek
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="transplantDate" className="p-col-12 p-md-3">
                  Transplant Date
                </label>
                <div className="p-col-5">
                  <DatePicker
                    id="transplantDate"
                    onChange={(val) => {
                      formik.setFieldValue('transplantDate', val);
                    }}
                    selected={formik.values.transplantDate}
                    dateFormat={dateFormatting}
                    weekLabel={'Wk'}
                    showWeekNumbers
                    locale={locales[firstDayOfWeek]}
                    isClearable={!isTrialTracker}
                    customInput={<CustomCalendarInput />}
                    disabled={isTrialTracker}
                  />
                </div>
                <label
                  className={`${styles.weekLabel} p-col-fixed`}
                  style={{ width: '75px' }}
                >
                  Week
                </label>
                <div className="p-col-fixed" style={{ width: '70px' }}>
                  <InputText
                    value={getWeekNumber(
                      formik.values.transplantDate,
                      firstDayOfWeek
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="spaceDate1" className="p-col-12 p-md-3">
                  Space Date 1
                </label>
                <div className="p-col-5">
                  <DatePicker
                    id="spaceDate1"
                    onChange={(val) => {
                      formik.setFieldValue('spaceDate1', val);
                    }}
                    selected={formik.values.spaceDate1}
                    dateFormat={dateFormatting}
                    weekLabel={'Wk'}
                    showWeekNumbers
                    locale={locales[firstDayOfWeek]}
                    isClearable={!isTrialTracker}
                    customInput={<CustomCalendarInput />}
                    disabled={isTrialTracker}
                  />
                </div>
                <label
                  className={`${styles.weekLabel} p-col-fixed`}
                  style={{ width: '75px' }}
                >
                  Week
                </label>
                <div className="p-col-fixed" style={{ width: '70px' }}>
                  <InputText
                    value={getWeekNumber(
                      formik.values.spaceDate1,
                      firstDayOfWeek
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="spaceDate2" className="p-col-12 p-md-3">
                  Space Date 2
                </label>
                <div className="p-col-5">
                  <DatePicker
                    id="spaceDate2"
                    onChange={(val) => {
                      formik.setFieldValue('spaceDate2', val);
                    }}
                    selected={formik.values.spaceDate2}
                    dateFormat={dateFormatting}
                    weekLabel={'Wk'}
                    showWeekNumbers
                    locale={locales[firstDayOfWeek]}
                    isClearable={!isTrialTracker}
                    customInput={<CustomCalendarInput />}
                    disabled={isTrialTracker}
                  />
                </div>
                <label
                  className={`${styles.weekLabel} p-col-fixed`}
                  style={{ width: '75px' }}
                >
                  Week
                </label>
                <div className="p-col-fixed" style={{ width: '70px' }}>
                  <InputText
                    value={getWeekNumber(
                      formik.values.spaceDate2,
                      firstDayOfWeek
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="potSize" className="p-col-12 p-md-3">
                  Pot Size
                </label>
                <div className="p-col-9 p-md-7">
                  <Dropdown
                    id="potSize"
                    onChange={formik.handleChange}
                    value={formik.values.potSize}
                    options={potSizes}
                    optionLabel="name"
                    showClear
                    disabled={isTrialTracker}
                  />
                </div>
                <div className="p-col-3 p-md-2">
                  <Button
                    className="p-button-rounded p-button-info p-button-outlined"
                    type="button"
                    icon="pi pi-pencil"
                    style={{ display: showElements }}
                    onClick={() => onOpenRefTable('pot-sizes')}
                  />
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="plantsPerPot" className="p-col-12 p-md-3">
                  Plants per Pot
                </label>
                <div className="p-col-12 p-md-9">
                  <InputText
                    className={formik.errors.plantsPerPot ? 'p-invalid' : null}
                    id="plantsPerPot"
                    type="text"
                    value={formik.values.plantsPerPot}
                    onChange={formik.handleChange}
                    aria-describedby="plantsPerPot-invalid"
                    disabled={isTrialTracker}
                  />
                  {formik.errors.plantsPerPot ? (
                    <small
                      id="plantsPerPot-invalid"
                      className="p-error p-d-block"
                    >
                      {formik.errors.plantsPerPot}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="p-field p-grid p-ai-start">
                <label htmlFor="note" className="p-col-12 p-md-3">
                  Crop Note
                </label>
                <div className="p-col-12 p-md-9">
                  <InputTextarea
                    className={formik.errors.note ? 'p-invalid' : null}
                    id="note"
                    type="text"
                    rows="4"
                    onChange={formik.handleChange}
                    aria-describedby="note-invalid"
                    value={formik.values.note}
                    disabled={isTrialTracker}
                  />
                  {formik.errors.note ? (
                    <small id="note-invalid" className="p-error p-d-block">
                      {formik.errors.note}
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0"
              style={{ display: showElements }}
            >
              <Button
                className={styles.button}
                label="Save"
                type="submit"
                disabled={!formik.dirty}
                icon="pi pi-check"
                autoFocus
              />
              <Button
                className={`p-button-secondary ${styles.button}`}
                label="Cancel"
                type="button"
                icon="pi pi-times"
                onClick={() => confirm(formik.dirty)}
              />
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        className={'confirmDialog'}
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
          trialId={trialId}
        />
        <div />
      </Dialog>
    </>
  );
};

export default CropBatchForm;
