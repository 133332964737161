import React, { useState, useEffect } from 'react';
import MenuDivider from '../../../Common/Menu/MenuDivider';
import MultiSelectFilter from '../../../Filters/MultiSelectFilter';
import DropdownFilter from '../../../Filters/DropdownFilter';
import FilterControls from '../../../Filters/FilterControls';

const ReportFilters = (props) => {
  const [initialPlants, setInitialPlants] = useState(null);
  const [initialTrialFields, setInitialTrialFields] = useState(null);
  const [initialWeeks, setInitialWeeks] = useState(null);

  const [selectedPlants, setSelectedPlants] = useState(null);
  const [selectedTrialField, setSelectedTrialField] = useState(null);
  const [selectedStartWeek, setSelectedStartWeek] = useState(null);
  const [selectedEndWeek, setSelectedEndWeek] = useState(null);

  const onResetFilters = () => {
    setSelectedPlants(null);
    setSelectedTrialField(null);
    setSelectedStartWeek(null);
    setSelectedEndWeek(null);
    props.applyNewFilters(null, null, null, null);
  };

  useEffect(() => {
    setInitialPlants(props.plants);
    setInitialTrialFields(props.trialFields);
    setInitialWeeks(props.logWeeks);
  }, [props.plants, props.trialFields, props.logWeeks]);

  const onApplyFilters = () => {
    props.applyNewFilters(
      selectedPlants,
      selectedTrialField,
      selectedStartWeek,
      selectedEndWeek
    );
  };

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <div className="filtersInputs">
        <MultiSelectFilter
          label="Plants"
          items={initialPlants}
          selectedItems={selectedPlants}
          setSelectedItems={setSelectedPlants}
          filter
          filterName="Select Plants"
        />
        <DropdownFilter
          label="Trial field"
          items={initialTrialFields}
          selectedItem={selectedTrialField}
          setSelectedItem={setSelectedTrialField}
          optionLabel="label"
          filterName="Select trial field"
        />
        <DropdownFilter
          label="Start week"
          items={initialWeeks}
          selectedItem={selectedStartWeek}
          setSelectedItem={setSelectedStartWeek}
          optionLabel="name"
          filterName="Select start week"
        />
        <DropdownFilter
          label="End week"
          items={initialWeeks}
          selectedItem={selectedEndWeek}
          setSelectedItem={setSelectedEndWeek}
          optionLabel="name"
          filterName="Select end week"
        />
      </div>
    </div>
  );
};

export default ReportFilters;
