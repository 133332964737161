import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportFilters } from '../../../../reduxStore/reportFilters/actions';
import ReportFilters from '../ReportFilters/ReportFilters';
import LeftBar from '../../../LeftBar/LeftBar';
import ReportList from '../../Reports/ReportList/ReportList';
import { useAuth0 } from '@auth0/auth0-react';
import { isAuth } from '../../../../auth/auth-service';
import { useParams } from 'react-router-dom';

const ReportPage = () => {
  const { id } = useParams();

  const [filters, setFilters] = useState(null);
  const plants = useSelector((state) => state.reportFilters.plants);
  const trialFields = useSelector((state) => state.reportFilters.trialFields);
  const logWeeks = useSelector((state) => state.reportFilters.logWeeks);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const onApplyNewFilters = useCallback(
    (plants, trialField, startWeek, endWeek) => {
      const newFilters =
        !plants || !trialField || !startWeek || !endWeek
          ? null
          : {
              plants: plants && plants.length > 0 ? plants : null,
              trialField: trialField,
              startWeek: startWeek,
              endWeek: endWeek,
            };

      setFilters(newFilters);
    },
    []
  );

  const actionData = useMemo(
    () => ({
      id: id,
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuth(),
      getAccessTokenSilently: getAccessTokenSilently,
    }),
    [dispatch, getAccessTokenSilently, id, logout]
  );

  useEffect(() => {
    dispatch(getReportFilters(actionData));
  }, [actionData, dispatch]);

  return (
    <React.Fragment>
      <LeftBar>
        <ReportFilters
          plants={plants}
          trialFields={trialFields}
          logWeeks={logWeeks}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      <ReportList filters={filters} setFilters={setFilters} />
    </React.Fragment>
  );
};

export default ReportPage;
