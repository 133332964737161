import * as yup from 'yup';

export const initialPlantGoalsFormSchema = (plantGoalsInfo) => {
  return {
    plantName: plantGoalsInfo.plantName,
    field: plantGoalsInfo.field,
    daysAfterPlanting: plantGoalsInfo.daysAfterPlanting,
    goal: plantGoalsInfo.goal,
  };
};

export const plantGoalsFormSchema = yup.object().shape({
  plantName: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        description: yup.string().nullable(),
      })
    )
    .min(1, 'At least one plant required')
    .required('Plant Name should be provided.'),
  field: yup.object().required('Field should be provided.'),
  daysAfterPlanting: yup
    .number()
    .max(999, 'Max value 999.')
    .min(1, 'Min value 1.')
    .when('field.eventType', {
      is: 'PROGRESS',
      then: yup.number().required('Field is required.'),
      otherwise: yup.number().nullable().notRequired(),
    }),
  goal: yup
    .string()
    .required('Field should be provided.')
    .max(255, 'Maximum length exceeded.'),
});
