export const GET_PRODUCT_FILTERS = 'GET_PRODUCT_FILTERS';
export const PRODUCT_FILTERS_REQUEST = 'PRODUCT_FILTERS_REQUEST';
export const PRODUCT_FILTERS_SUCCESS = 'PRODUCT_FILTERS_SUCCESS';
export const PRODUCT_FILTERS_FAIL = 'PRODUCT_FILTERS_FAIL';
export const getProductFilters = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_PRODUCT_FILTERS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const productFiltersRequest = () => ({ type: PRODUCT_FILTERS_REQUEST });

export const productFiltersSuccess = (
  categories,
  classifications,
  manufacturers,
  mixers,
  applicationMethods,
  measureUnits,
  mixerMeasureUnits
) => ({
  type: PRODUCT_FILTERS_SUCCESS,
  categories: categories,
  classifications: classifications,
  manufacturers: manufacturers,
  mixers: mixers,
  applicationMethods: applicationMethods,
  measureUnits: measureUnits,
  mixerMeasureUnits: mixerMeasureUnits,
});

export const productFiltersFail = (error) => ({
  type: PRODUCT_FILTERS_FAIL,
  error: error,
});
