import React from 'react';
import LeftBar from '../../../LeftBar/LeftBar';
import TrialMenu from '../../Menu/TrialMenu';
import FieldList from '../FieldList/FieldList';

const FieldPage = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <TrialMenu />
      </LeftBar>
      <FieldList />
    </React.Fragment>
  );
};

export default FieldPage;
