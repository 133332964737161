import * as actionTypes from './actions';

const initialState = {
  loading: false,
  plants: null,
  sections: null,
  startForms: [],
  potSizes: [],
  location: null,
};

export const cropReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CROP_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.CROP_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        plants: action.plants,
        sections: action.sections,
        startForms: action.startForms,
        potSizes: action.potSizes,
        location: action.location,
      };
    case actionTypes.CROP_OPTIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
