import * as actionTypes from './actions';

const initialState = {
  currentPage: 'login',
};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SWITCH_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    default:
      return state;
  }
};
