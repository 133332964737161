import * as yup from 'yup';

export const initialLocationFormSchema = (locationInfo) => {
  return {
    name: locationInfo.name,
    type: locationInfo.type,
    firstDay: locationInfo.firstDayOfWeek,
    address: locationInfo.address,
    address2: locationInfo.address2 || '',
    city: locationInfo.city,
    state: locationInfo.state,
    postalCode: locationInfo.postalCode,
    country: locationInfo.country,
    primaryPhone: locationInfo.primaryPhone || '',
    description: locationInfo.description || '',
  };
};

export const locationFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Location Name should be provided.')
    .max(20, 'Maximum length exceeded.'),
  type: yup.object().required('Location Type should be provided.'),
  address: yup
    .string()
    .required('Address Line 1 should be provided.')
    .max(512, 'Maximum length exceeded.'),
  address2: yup.string().max(128, 'Maximum length exceeded.'),
  city: yup
    .string()
    .required('City should be provided.')
    .max(255, 'Maximum length exceeded.'),
  state: yup.object().required('State should be provided.'),
  postalCode: yup
    .string()
    .required('Postal Code should be provided.')
    .max(32, 'Maximum length exceeded.'),
  country: yup.object().required('Country should be provided.'),
  description: yup.string().max(256, 'Maximum length exceeded.'),
  primaryPhone: yup.string().max(15, 'Maximum length exceeded.'),
});
