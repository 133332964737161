import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import DatePicker from 'datepicker-special-week-numbers';
import { urls, useRequest } from '../../../Common/ApiServices';
import { locales } from '../../../Common/globalConstants';
import { getWeekNumber } from '../../../Common/utils';
import { initialCropInfo } from '../constants';
import { getMappedCropResponse } from '../utils';
import styles from './CropModal.module.scss';

const CropModal = ({ selectedCropId, setSelectedCrop }) => {
  const [cropInfo, setCropInfo] = useState(initialCropInfo);

  const firstDayOfWeek = { name: 'Sunday', day: 'SUNDAY' };

  const { sendRequest } = useRequest({});

  const extractCropInfo = useCallback(async () => {
    const requestData = {
      url: urls.EXTRACT_CROP,
      method: 'POST',
      data: {
        query: {
          crop: {
            id: {
              is: selectedCropId,
            },
          },
        },
      },
    };

    const response = await sendRequest(requestData);

    if (response) {
      setCropInfo(getMappedCropResponse(response.data));
    }
    return response;
  }, [selectedCropId, sendRequest]);

  const onHide = () => {
    setSelectedCrop(null);
  };

  useEffect(() => {
    extractCropInfo();
  }, [extractCropInfo]);

  return (
    <Dialog
      className={styles.cropModal}
      header="Crop Information"
      visible={selectedCropId}
      onHide={onHide}
    >
      <Divider />

      <div className="p-fluid">
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="cropNumber" className="p-col-12 p-md-3 p-text-bold">
            Crop Number
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="cropNumber"
              className={styles.readOnly}
              type="text"
              value={cropInfo.number}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="plantName" className="p-col-12 p-md-3 p-text-bold">
            Plant Name*
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="plantName"
              type="text"
              className={styles.readOnly}
              value={cropInfo.plant?.name}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="plantDate" className="p-col-12 p-md-3 p-text-bold">
            Plant Date*
          </label>
          <div className={classNames('p-col-fixed', styles.datepicker)}>
            <DatePicker
              id="plantDate"
              selected={cropInfo.plantDate}
              dateFormat={cropInfo.dateFormat}
              weekLabel={'Wk'}
              showWeekNumbers
              locale={locales[firstDayOfWeek]}
              disabled
            />
          </div>
          <label className={classNames(styles.weekLabel, 'p-col-fixed')}>
            Week
          </label>
          <div className={classNames('p-col-fixed', styles.week)}>
            <InputText
              value={getWeekNumber(cropInfo.plantDate, firstDayOfWeek)}
              disabled
            />
          </div>
        </div>

        <div className="p-field p-grid p-ai-start">
          <label htmlFor="section" className="p-col-12 p-md-3 p-text-bold">
            Section*
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="section"
              type="text"
              className={styles.readOnly}
              value={cropInfo.section?.name}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="quantity" className="p-col-12 p-md-3 p-text-bold">
            Quantity*
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="quantity"
              className={styles.readOnly}
              value={cropInfo.quantity}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="startForm" className="p-col-12 p-md-3">
            Start Form
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="startForm"
              className={styles.readOnly}
              value={cropInfo.startForm?.name}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="startDate" className="p-col-12 p-md-3">
            Start Date
          </label>
          <div className={classNames('p-col-fixed', styles.datepicker)}>
            <DatePicker
              id="startDate"
              selected={cropInfo.startDate}
              dateFormat={cropInfo.dateFormat}
              weekLabel={'Wk'}
              showWeekNumbers
              locale={locales[firstDayOfWeek]}
              disabled
            />
          </div>
          <label
            className={`${styles.weekLabel} p-col-fixed`}
            style={{ width: '75px' }}
          >
            Week
          </label>
          <div className="p-col-fixed" style={{ width: '70px' }}>
            <InputText
              value={getWeekNumber(cropInfo.startDate, firstDayOfWeek)}
              disabled
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="transplantDate" className="p-col-12 p-md-3">
            Transplant Date
          </label>
          <div className={classNames('p-col-fixed', styles.datepicker)}>
            <DatePicker
              id="transplantDate"
              selected={cropInfo.transplantDate}
              dateFormat={cropInfo.dateFormat}
              weekLabel={'Wk'}
              showWeekNumbers
              locale={locales[firstDayOfWeek]}
              disabled
            />
          </div>
          <label
            className={`${styles.weekLabel} p-col-fixed`}
            style={{ width: '75px' }}
          >
            Week
          </label>
          <div className="p-col-fixed" style={{ width: '70px' }}>
            <InputText
              value={getWeekNumber(cropInfo.transplantDate, firstDayOfWeek)}
              disabled
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="spaceDate1" className="p-col-12 p-md-3">
            Space Date 1
          </label>
          <div className={classNames('p-col-fixed', styles.datepicker)}>
            <DatePicker
              id="spaceDate1"
              selected={cropInfo.spaceDate1}
              dateFormat={cropInfo.dateFormat}
              weekLabel={'Wk'}
              showWeekNumbers
              locale={locales[firstDayOfWeek]}
              disabled
            />
          </div>
          <label
            className={`${styles.weekLabel} p-col-fixed`}
            style={{ width: '75px' }}
          >
            Week
          </label>
          <div className="p-col-fixed" style={{ width: '70px' }}>
            <InputText
              value={getWeekNumber(cropInfo.spaceDate1, firstDayOfWeek)}
              disabled
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="spaceDate2" className="p-col-12 p-md-3">
            Space Date 2
          </label>
          <div className={classNames('p-col-fixed', styles.datepicker)}>
            <DatePicker
              id="spaceDate2"
              selected={cropInfo.spaceDate2}
              dateFormat={cropInfo.dateFormat}
              weekLabel={'Wk'}
              showWeekNumbers
              locale={locales[firstDayOfWeek]}
              disabled
            />
          </div>
          <label
            className={`${styles.weekLabel} p-col-fixed`}
            style={{ width: '75px' }}
          >
            Week
          </label>
          <div className="p-col-fixed" style={{ width: '70px' }}>
            <InputText
              value={getWeekNumber(cropInfo.spaceDate2, firstDayOfWeek)}
              disabled
            />
          </div>
        </div>

        <div className="p-field p-grid p-ai-start">
          <label htmlFor="lotNumber" className="p-col-12 p-md-3">
            Lot Number
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="lotNumber"
              className={styles.readOnly}
              value={cropInfo.lotNumber}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="potSize" className="p-col-12 p-md-3">
            Pot Size
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="potSize"
              className={styles.readOnly}
              value={cropInfo.potSize?.name}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="plantsPerPot" className="p-col-12 p-md-3">
            Plants per Pot
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="plantsPerPot"
              className={styles.readOnly}
              value={cropInfo.plantsPerPot}
              readOnly
            />
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="note" className="p-col-12 p-md-3">
            Crop Note
          </label>
          <div className="p-col-12 p-md-9">
            <InputTextarea
              id="note"
              type="text"
              className={styles.readOnly}
              rows="4"
              value={cropInfo.note}
              readOnly
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CropModal;
