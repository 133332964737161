import React from 'react';
import BackDrop from '../BackDrop/BackDrop';
import styles from './Preview.module.scss';
function Preview({ url, hidePreview }) {
  return (
    <div className={styles.previewContainer}>
      <BackDrop close={hidePreview} />
      <img src={url} alt="" />
    </div>
  );
}
export default Preview;
