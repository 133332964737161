import * as actionTypes from './actions';

const initialState = {
  products: null,
  plants: null,
  sections: null,
};

export const historyOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HISTORY_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.HISTORY_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products,
        plants: action.plants,
        sections: action.sections,
      };
    case actionTypes.HISTORY_OPTIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
