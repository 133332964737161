import { put } from 'redux-saga/effects';
import * as actions from '../profileInfo/actions';
import { checkBlocked } from '../../auth/auth-service';
import { checkSubscription } from '../../components/Common/utils';
import urls from '../../components/Common/ApiServices/urls';
import { makeRequest } from '../../components/Common/ApiServices';
import ReactGA from 'react-ga4';

export function* getProfileInfoSaga(action) {
  yield put(actions.profileInfoRequest());

  const actionData = action.actionData;

  const profileData = {
    url: urls.EXTRACT_PROFILE,
    method: 'POST',
    data: {},
  };

  try {
    const profileInfo = yield makeRequest(profileData, actionData);
    ReactGA.set({ userId: profileInfo.data.employee.id });
    yield put(actions.profileInfoSuccess(profileInfo.data));
  } catch (error) {
    checkBlocked(error, action.logout);
    checkSubscription(error, action.dispatch);
    yield put(actions.profileInfoFail(error.response));
  }
}
