import React from 'react';
import classNames from 'classnames';
import styles from './BackDrop.module.scss';

const BackDrop = ({ close }) => {
  return (
    <div className={styles.backDrop} onClick={() => close('')}>
      <i className={classNames('pi pi-times-circle')} />
    </div>
  );
};

export default BackDrop;
