import React from 'react';
import LeftBar from '../../../LeftBar/LeftBar';
import LocationMenu from '../../Menu/LocationMenu';
import SectionsList from '../SectionsList/SectionsList';

const SectionsPage = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <LocationMenu />
      </LeftBar>
      <SectionsList />
    </React.Fragment>
  );
};

export default SectionsPage;
