import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { getProductFilters } from '../../../../reduxStore/productFilters/actions';
import {
  initialProductFormSchema,
  productFormSchema,
} from './productFormValidation';
import { errorFields, errorMessages, refTableHeaders } from '../../constants';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import { urls, useRequest } from '../../../Common/ApiServices';
import { getProductParams } from '../../constants';
import { InputMask } from 'primereact/inputmask';
import { errorCodes, popUp } from '../../../Common/globalConstants';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { isAuth } from '../../../../auth/auth-service';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import styles from './ProductForm.module.scss';

const ProductForm = ({ setProductName }) => {
  const [productInfo, setProductInfo] = useState({});
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const { error, sendRequest } = useRequest({});

  const history = useHistory();

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.productFilters.categories);
  const classifications = useSelector(
    (state) => state.productFilters.classifications
  );
  const manufacturers = useSelector(
    (state) => state.productFilters.manufacturers
  );
  const mixers = useSelector((state) => state.productFilters.mixers);
  const applicationMethods = useSelector(
    (state) => state.productFilters.applicationMethods
  );
  const measureUnits = useSelector((state) =>
    state.productFilters.measureUnits.map((unit) => {
      return { description: unit.description, unit: unit.unit };
    })
  );
  const mixerMeasureUnits = useSelector((state) =>
    state.productFilters.mixerMeasureUnits.map((unit) => {
      return { description: unit.description, unit: unit.unit };
    })
  );
  const productFiltersReady = useSelector(
    (state) => state.productFilters.productFiltersReady
  );

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const { logout, getAccessTokenSilently } = useAuth0();
  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const accept = () => {
    formik.handleReset();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const getErrorMessage = (code, fieldName) => {
    if (code === 'NOT_UNIQUE') {
      return errorMessages[fieldName];
    }
  };

  const refTableValues = {
    [urls.PRODUCT_CATEGORIES]: categories,
    [urls.PRODUCT_CLASSIFICATIONS]: classifications,
    [urls.PRODUCT_MANUFACTURERS]: manufacturers,
    [urls.PRODUCT_MIXERS]: mixers,
    [urls.PRODUCT_APPLICATION_METHODS]: applicationMethods,
  };

  const setFormikError = () => {
    error.response.data.errors.forEach((err) => {
      return formik.setFieldError(
        errorFields[err.fieldName],
        getErrorMessage(err.code, err.fieldName)
      );
    });
  };

  const responseFailed = () => {
    if (error?.response?.data?.errors) {
      error?.response?.data?.errors.forEach((err) =>
        formik.setFieldError(err.fieldName, errorCodes[err.code])
      );
    }
  };

  const breadCrumbItems = [
    {
      label: 'Products',
      command: () => {
        history.push('/products');
      },
    },
    { label: productInfo.tradeName },
  ];

  const getProductInfo = async () => {
    const productId = localStorage.getItem('productId');

    const requestData = {
      url: urls.EXTRACT_PRODUCT,
      method: 'POST',
      data: getProductParams(productId),
    };

    const response = await sendRequest(requestData);

    const res = response.data.product;

    const productInfo = {
      applicationMethod: res.applicationMethod,
      category: res.category,
      classification: res.classification,
      productCode: res.code,
      epaRegistrationNumber: res.epaRegistrationNumber,
      equipment: res.equipment,
      id: res.id,
      labelUrl: res.labelUrl,
      manufacturer: res.manufacturer,
      measureUnit: res.measureUnit,
      mixerMeasureUnit: res.mixerMeasureUnit,
      mixer: res.mixer,
      productName: res.name,
      note: res.note,
      phone: res.phone,
      sdsNumber: res.sdsNumber,
      sdsUrl: res.sdsUrl,
      tradeName: res.tradeName,
      versionInfo: res.versionInfo,
    };
    setProductInfo(productInfo);
    setProductName(productInfo.tradeName);

    return response;
  };

  const updateProduct = async (data) => {
    const requestData = {
      url: urls.UPDATE_PRODUCT,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);

    response &&
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Product was updated.',
          life: 5000,
          sticky: null,
        })
      );
    await getProductInfo();
    !productFiltersReady && dispatch(getProductFilters(actionData));

    return response;
  };

  const refTableHeader = () => {
    return currentRefTableName && refTableHeaders[currentRefTableName];
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  const formik = useFormik({
    initialValues: initialProductFormSchema(productInfo),
    enableReinitialize: true,
    validationSchema: productFormSchema,
    onSubmit: (values) => {
      const newProductParams = {
        code: values.productCode,
        name: values.productName || null,
        tradeName: values.tradeName,
        categoryId: values?.category?.id || null,
        classificationId: values?.classification?.id || null,
        manufacturerId: values?.manufacturer?.id || null,
        note: values.note || null,
        id: productInfo.id,
        versionNumber: productInfo?.versionInfo?.number || 0,
        mixerId: values?.mixer?.id || null,
        measureUnit: values?.measureUnit?.unit || null,
        mixerMeasureUnit: values?.mixerMeasureUnit?.unit || null,
        applicationMethodId: values?.applicationMethod?.id || null,
        sdsNumber: values.sdsNumber || null,
        sdsUrl: values.sdsUrl || null,
        epaRegistrationNumber: values.epaRegistrationNumber || null,
        labelUrl: values.labelUrl || null,
        equipment: values.equipment || null,
        phoneNumber: values.phone || null,
      };

      updateProduct(newProductParams);
    },
  });

  useEffect(() => {
    getProductInfo();
    !productFiltersReady && dispatch(getProductFilters(actionData));
  }, []);

  useEffect(() => {
    if (error) {
      error.response.data.errors ? setFormikError() : responseFailed();
    }
  }, [error]);

  return (
    <div className={styles.productInfo}>
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={formik.dirty} />
      <div className={styles.productForm}>
        <h3>Product Information</h3>
        <Divider />
        <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="productCode"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Product Code*
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="productCode"
                  type="text"
                  value={formik.values.productCode}
                  disabled={isTrialTracker}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="productCode-invalid"
                  className={formik.errors.productCode ? 'p-invalid' : null}
                />
                {formik.touched.productCode && formik.errors.productCode ? (
                  <small id="productCode-invalid" className="p-error p-d-block">
                    {formik.errors.productCode}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="tradeName"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Trade Name*
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="tradeName"
                  type="text"
                  value={formik.values.tradeName}
                  disabled={isTrialTracker}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="tradeName-invalid"
                  className={formik.errors.tradeName ? 'p-invalid' : null}
                />
                {formik.touched.tradeName && formik.errors.tradeName ? (
                  <small id="tradeName-invalid" className="p-error p-d-block">
                    {formik.errors.tradeName}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="productName" className="p-col-12 p-md-3 p-xl-2">
                Product Name
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="productName"
                  type="text"
                  value={formik.values.productName}
                  disabled={isTrialTracker}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="productName-invalid"
                  className={formik.errors.productName ? 'p-invalid' : null}
                />
                {formik.touched.productName && formik.errors.productName ? (
                  <small id="productName-invalid" className="p-error p-d-block">
                    {formik.errors.productName}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="category" className="p-col-12 p-md-3 p-xl-2">
                Category
              </label>
              <div className="p-col-7 p-md-5 p-xl-4">
                <Dropdown
                  id="category"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  disabled={isTrialTracker}
                  options={categories}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable(urls.PRODUCT_CATEGORIES)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="classification"
                className="p-col-12 p-md-3 p-xl-2"
              >
                Classification
              </label>
              <div className="p-col-7 p-md-5 p-xl-4">
                <Dropdown
                  id="classification"
                  onChange={formik.handleChange}
                  value={formik.values.classification}
                  disabled={isTrialTracker}
                  options={classifications}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable(urls.PRODUCT_CLASSIFICATIONS)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="manufacturer" className="p-col-12 p-md-3 p-xl-2">
                Manufacturer
              </label>
              <div className="p-col-7 p-md-5 p-xl-4">
                <Dropdown
                  id="manufacturer"
                  onChange={formik.handleChange}
                  value={formik.values.manufacturer}
                  disabled={isTrialTracker}
                  options={manufacturers}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable(urls.PRODUCT_MANUFACTURERS)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="measureUnit" className="p-col-12 p-md-3 p-xl-2">
                Unit of Measure
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <Dropdown
                  id="measureUnit"
                  onChange={formik.handleChange}
                  value={formik.values.measureUnit}
                  disabled={isTrialTracker}
                  options={measureUnits}
                  optionLabel="description"
                  filter
                  showClear
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="measureUnit" className="p-col-12 p-md-3 p-xl-2">
                Mixer Unit of Measure
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <Dropdown
                  id="mixerMeasureUnit"
                  onChange={formik.handleChange}
                  value={formik.values.mixerMeasureUnit}
                  disabled={isTrialTracker}
                  options={mixerMeasureUnits}
                  optionLabel="description"
                  filter
                  showClear
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="mixer" className="p-col-12 p-md-3 p-xl-2">
                Mixer
              </label>
              <div className="p-col-7 p-md-5 p-xl-4">
                <Dropdown
                  id="mixer"
                  onChange={formik.handleChange}
                  value={formik.values.mixer}
                  disabled={isTrialTracker}
                  options={mixers}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable(urls.PRODUCT_MIXERS)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="applicationMethod"
                className="p-col-12 p-md-3 p-xl-2"
              >
                Application Method
              </label>
              <div className="p-col-7 p-md-5 p-xl-4">
                <Dropdown
                  id="applicationMethod"
                  onChange={formik.handleChange}
                  value={formik.values.applicationMethod}
                  disabled={isTrialTracker}
                  options={applicationMethods}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() =>
                    onOpenRefTable(urls.PRODUCT_APPLICATION_METHODS)
                  }
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="sdsNumber" className="p-col-12 p-md-3 p-xl-2">
                SDS Number
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="sdsNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sdsNumber}
                  disabled={isTrialTracker}
                  aria-describedby="sdsNumber-invalid"
                  className={formik.errors.sdsNumber ? 'p-invalid' : null}
                />
                {formik.touched.sdsNumber && formik.errors.sdsNumber && (
                  <small id="sdsNumber-invalid" className="p-error p-d-block">
                    {formik.errors.sdsNumber}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="epaRegistrationNumber"
                className="p-col-12 p-md-3 p-xl-2"
              >
                EPA Registration No.
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="epaRegistrationNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.epaRegistrationNumber}
                  disabled={isTrialTracker}
                  aria-describedby="epaRegistrationNumber-invalid"
                  className={
                    formik.errors.epaRegistrationNumber ? 'p-invalid' : null
                  }
                />
                {formik.touched.epaRegistrationNumber &&
                  formik.errors.epaRegistrationNumber && (
                    <small
                      id="epaRegistrationNumber-invalid"
                      className="p-error p-d-block"
                    >
                      {formik.errors.epaRegistrationNumber}
                    </small>
                  )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="sdsUrl" className="p-col-12 p-md-3 p-xl-2">
                SDS URL
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="sdsUrl"
                  type="text"
                  placeholder={'https://sitename.com/sds'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sdsUrl}
                  disabled={isTrialTracker}
                  aria-describedby="sdsUrl-invalid"
                  className={formik.errors.sdsUrl ? 'p-invalid' : null}
                />
                {formik.touched.sdsUrl && formik.errors.sdsUrl && (
                  <small id="sdsUrl-invalid" className="p-error p-d-block">
                    {formik.errors.sdsUrl}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="labelUrl" className="p-col-12 p-md-3 p-xl-2">
                Label URL
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="labelUrl"
                  type="text"
                  placeholder={'https://sitename.com/label'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.labelUrl}
                  disabled={isTrialTracker}
                  aria-describedby="labelUrl-invalid"
                  className={formik.errors.labelUrl ? 'p-invalid' : null}
                />
                {formik.touched.labelUrl && formik.errors.labelUrl && (
                  <small id="labelUrl-invalid" className="p-error p-d-block">
                    {formik.errors.labelUrl}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="equipment" className="p-col-12 p-md-3 p-xl-2">
                Equipment
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputText
                  id="equipment"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.equipment}
                  disabled={isTrialTracker}
                  aria-describedby="equipment-invalid"
                  className={formik.errors.equipment ? 'p-invalid' : null}
                />
                {formik.touched.equipment && formik.errors.equipment && (
                  <small id="equipment-invalid" className="p-error p-d-block">
                    {formik.errors.equipment}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="phone" className="p-col-12 p-md-3 p-xl-2">
                Phone Number
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputMask
                  id="phone"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  disabled={isTrialTracker}
                  aria-describedby="phone-invalid"
                  className={formik.errors.phone ? 'p-invalid' : null}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <small id="address2-invalid" className="p-error p-d-block">
                    {formik.errors.phone}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3 p-xl-2">
                Note
              </label>
              <div className="p-col-12 p-md-6 p-xl-5">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                  disabled={isTrialTracker}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="p-grid p-col-12 p-md-7 p-justify-end pad-r-0"
            style={{ display: showElements }}
          >
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              icon="pi pi-check"
              disabled={!formik.dirty || !formik.isValid}
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              disabled={!formik.dirty}
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </div>
      <Dialog
        className="confirmDialog"
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </div>
  );
};

export default ProductForm;
