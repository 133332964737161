import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import DatePicker, { registerLocale } from 'datepicker-special-week-numbers';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { urls, useRequest } from '../../../Common/ApiServices';
import { initialTrialFormSchema, trialFormSchema } from './trialFormValidation';
import { getDefaultDateFormat } from '../../../Common/utils';
import { setIsShared } from '../../../../reduxStore/isShared/actions';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import { useAuth0 } from '@auth0/auth0-react';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import TrialShare from './TrialShare/TrialShare';
import {
  locales,
  popUp,
  tier,
  titleText,
} from '../../../Common/globalConstants';
import { initialProductParams } from '../../../Products/constants';
import { isAuth } from '../../../../auth/auth-service';
import { getProfile } from '../../../../reduxStore/trial/actions';
import StatusesTable from '../../Statuses/StatusesTable';

import styles from './TrialForm.module.scss';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

const TrialForm = ({ trialInfo, setIsUpdated, trialId }) => {
  const [logFrequenciesInfo, setLogFrequenciesInfo] = useState([]);
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [displayStatusesTable, setDisplayStatusesTable] = useState(false);
  const [displayShareModal, setDisplayShareModal] = useState(false);
  const [shared, setShared] = useState(false);
  const [products, setProducts] = useState([]);

  const history = useHistory();

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const queryRole = {
    query: {
      role: 'PPT_TRIAL_MANAGER',
    },
  };

  const { error, sendRequest } = useRequest({});

  const subscription = useSelector((state) => state.subscription?.uuid);
  const logFrequencies = useSelector((state) => state.trial?.logFrequencies);
  const permissions = useSelector((state) => state.permissions?.permissions);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const errorRedux = useSelector((state) => state.trial?.error);
  const locations = useSelector((state) => state.taskOptions?.locations);
  const defaultFirstDay = useSelector(
    (state) => state.defaultLocation?.firstDay
  );
  const userCompany = useSelector((state) => state.profileInfo?.company);
  const employees = useSelector((state) => state.trial.employees);
  const cropTemplates = useSelector((state) => state.trial.cropTemplates);
  const statuses = useSelector((state) => state.trial.statuses);

  const employeeOptions =
    employees &&
    employees.map((employee) => ({
      id: employee.employee.id.toString(),
      name: employee.employee.firstName + ' ' + employee.employee.lastName,
    }));

  const cropTemplatesOptions =
    cropTemplates &&
    cropTemplates.map((template) => {
      return {
        id: template.id,
        name: template.name,
        source: template.source,
      };
    });

  const showPartnerLocations = shared ? 'block' : 'none';

  const showElements = shared || isTrialTracker ? 'none' : 'inline-flex';

  const titleMessage = useMemo(() => {
    if (subscription !== tier.essentials) return '';
    return permissions.includes('ppt:manage:company')
      ? titleText.denyAdmin
      : titleText.denyUser;
  }, [permissions, subscription]);

  const locationOptions =
    locations &&
    locations?.map((location) => {
      return { id: location.id, name: location.name };
    });

  const responseFailed = (errorMessage = errorCodes.DEFAULT_MESSAGE.text) => {
    if (error?.response?.data?.errors) {
      error?.response?.data?.errors?.map((err) =>
        formik.setFieldError(err.fieldName, errorCodes[err.code])
      );
    } else {
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,

          summary: popUp.summary.ERROR,

          detail: errorMessage,

          life: 5000,

          sticky: null,
        })
      );
    }
  };

  const uniqueProductByName = (arr) => {
    return [...new Map(arr.map((item) => [item.id, item])).values()];
  };

  const fetchProducts = async () => {
    const requestData = {
      url: urls.SEARCH_PRODUCTS,
      method: 'POST',
      data: initialProductParams,
    };
    const response = await sendRequest(requestData);

    if (response) {
      const products = response?.data?.results?.map((product) => {
        return {
          id: product.product.id,

          name: product.product.name,

          tradeName: product.product.tradeName,
        };
      });

      setProducts(products);
    }

    return response;
  };

  const formik = useFormik({
    initialValues: initialTrialFormSchema(trialInfo),
    enableReinitialize: true,
    validationSchema: trialFormSchema,
    onSubmit: async (values) => {
      const newTrialParams = {
        id: trialId,
        name: values.name,
        locationId: values.location.id,
        trialManagerId: values.trialManager.id,
        logFrequencyId: values.logFrequency?.id || null,
        statusId: values.status?.id || null,
        startDate:
          values.startDate && moment(values.startDate).format('YYYY-MM-DD'),
        closeDate:
          values.closeDate && moment(values.closeDate).format('YYYY-MM-DD'),
        description: values.description || null,
        contract: values.contract,
        note: values.note || null,
        cropTemplateId: values?.cropTemplate?.id,
        products: {
          id: {
            in: values?.products?.map((product) => product.id),
          },
        },
        versionNumber: trialInfo.version?.number || 0,
      };
      const requestData = {
        url: urls.UPDATE_TRIAL,
        method: 'POST',
        data: newTrialParams,
      };

      const response = await sendRequest(requestData);
      response && onTrialUpdate();
      return response;
    },
  });

  const selectedLocation =
    locations &&
    locations.find((location) => {
      return location.id === formik.values.location.id;
    });

  const firstDayOfWeek = selectedLocation
    ? selectedLocation?.firstDay
    : defaultFirstDay;

  const dateFormatting = selectedLocation
    ? selectedLocation?.dateFormat
    : getDefaultDateFormat();

  const getLogFrequencies = async () => {
    let frequencies = [];
    if (!logFrequencies) {
      const requestData = {
        url: urls.LOG_FREQUENCIES,
        method: 'GET',
        data: {},
      };

      const response = await sendRequest(requestData);
      if (response) {
        frequencies = response.data.sort((a, b) => (a.name < b.name ? -1 : 1));
      }
    } else {
      frequencies = logFrequencies;
    }
    setLogFrequenciesInfo(frequencies);
  };

  const updateRecentlyVisitedTrial = async () => {
    const requestData = {
      url: urls.UPDATE_RECENTLY_VISITED_TRIAL,
      method: 'POST',
      data: {
        trial: {
          id: {
            is: trialInfo?.id,
          },
        },
      },
    };

    return await sendRequest(requestData);
  };

  const errorCodes = {
    NOT_PROVIDED: 'This field should be provided.',
    NOT_UNIQUE: 'Trial Name should be unique.',
    INVALID_RANGE: 'Close Date should be after Start Date.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  };

  const accept = () => {
    formik.handleReset();
  };

  const confirm = (values, dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onTrialUpdate = () => {
    setIsUpdated(true);
    dispatch(
      setPopUp({
        severity: popUp.severities.SUCCESS,
        summary: popUp.summary.SUCCESSFUL,
        detail: 'Trial was updated.',
        life: 5000,
        sticky: null,
      })
    );
  };

  const onOpenRefTable = () => {
    setDisplayRefTable(true);
  };

  const onOpenStatusesTable = () => {
    setDisplayStatusesTable(true);
  };

  const CustomCalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      label={value}
      className={`${styles.calendarButton} p-button-text p-button-plain`}
      icon="pi pi-calendar"
      iconPos="right"
      onClick={onClick}
      ref={ref}
      disabled={shared || isTrialTracker}
      type="button"
    />
  ));

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    { label: trialInfo.name },
  ];

  const onModalOpen = () => {
    setDisplayShareModal(true);
  };

  const onTrialShared = (message) => {
    dispatch(
      setPopUp({
        severity: popUp.severities.SUCCESS,
        summary: popUp.summary.SUCCESSFUL,
        detail: `Trial ${
          message.trialName
        } was shared with ${message.employee.emails.join(', ')}`,
        life: 5000,
        sticky: null,
      })
    );
  };

  const actionData = {
    queryRole: queryRole,
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  useEffect(() => {
    if (!employees || !cropTemplates) {
      dispatch(getProfile(actionData));
    }
  }, [employees, cropTemplates]);

  useLayoutEffect(() => {
    return () => {
      dispatch(setIsShared(false));
    };
  }, []);

  useEffect(() => {
    getLogFrequencies();
    fetchProducts();
  }, [logFrequencies]);

  useEffect(async () => {
    if (trialInfo?.companyId && userCompany?.id) {
      const shared = userCompany?.id !== trialInfo.companyId;
      setShared(shared);
      dispatch(setIsShared(shared));
      updateRecentlyVisitedTrial();
    }
  }, [trialInfo, userCompany]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.errors) {
        error?.response?.data?.errors?.map((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      } else {
        let errorDetail = 'Something went wrong.';

        if (error.response.data.error?.code === 'REFERENCES_EXIST') {
          errorDetail =
            'Location field cannot be edited because log records exist.';
        } else if (error.response.data.error?.code === 'CONFLICT') {
          errorDetail =
            'This trial has been updated by another user. Please refresh the page and then save your updates.';
        }

        responseFailed(errorDetail);
      }
    }
  }, [error]);

  return (
    <div className={styles.trialInfo}>
      <TrialShare
        trialInfo={trialInfo}
        header="Share Trial Information with:"
        displayShareModal={displayShareModal}
        setDisplayShareModal={setDisplayShareModal}
        trialShared={onTrialShared}
      />
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={formik.dirty} />
      <div className={styles.trialForm}>
        <h3>Trial Information</h3>
        <Divider />
        <form onSubmit={formik.handleSubmit} className="trialForm">
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="name"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                Name*
              </label>
              <div className="p-col-12 p-md-7 p-xl-4">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  disabled={shared || isTrialTracker}
                  className={formik.errors.name && 'p-invalid'}
                />
                {formik.touched.name && formik.errors.name && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="location"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                Location*
              </label>
              <div className="p-col-12 p-md-7 p-xl-4">
                <Dropdown
                  id="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  aria-describedby="location-invalid"
                  className={`${
                    (errorRedux ||
                      (formik.errors.location && formik.touched.location)) &&
                    'p-invalid'
                  }`}
                  options={locationOptions}
                  disabled={shared || isTrialTracker}
                  placeholder={
                    shared || isTrialTracker ? formik.values.location.name : ''
                  }
                  optionLabel="name"
                />
                {formik.touched.location && formik.errors.location && (
                  <small id="location-invalid" className="p-error p-d-block">
                    {formik.errors.location}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="trialManager"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                Trial Manager*
              </label>
              <div className="p-col-12 p-md-7 p-xl-4">
                <Dropdown
                  id="trialManager"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'trialManager' } });
                  }}
                  value={formik.values.trialManager}
                  aria-describedby="trialManager-invalid"
                  className={`${
                    (errorRedux ||
                      (formik.errors.trialManager &&
                        formik.touched.trialManager)) &&
                    'p-invalid'
                  }`}
                  options={employeeOptions}
                  optionLabel="name"
                  disabled={shared || isTrialTracker}
                  placeholder={
                    shared || isTrialTracker
                      ? formik.values.trialManager.name
                      : ''
                  }
                />
                {formik.touched.trialManager && formik.errors.trialManager && (
                  <small
                    id="trialManager-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.trialManager}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="startDate" className="p-col-12 p-md-2 p-xl-2">
                Start Date
              </label>
              <div className="p-col-12 p-md-8 p-xl-4">
                <DatePicker
                  id="startDate"
                  onChange={(val) => {
                    formik.setFieldValue('startDate', val);
                  }}
                  selected={formik?.values?.startDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable={!shared && !isTrialTracker}
                  customInput={<CustomCalendarInput />}
                  disabled={shared || isTrialTracker}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="closeDate" className="p-col-12 p-md-2 p-xl-2">
                Close Date
              </label>
              <div className="p-col-12 p-md-8 p-xl-4">
                <DatePicker
                  className={formik.errors.closeDate && 'picker-error'}
                  id="closeDate"
                  onChange={(val) => {
                    formik.setFieldValue('closeDate', val);
                  }}
                  selected={formik.values.closeDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  aria-describedby="closeDate-invalid"
                  isClearable={!shared && !isTrialTracker}
                  disabled={shared || isTrialTracker}
                  customInput={<CustomCalendarInput />}
                />
                {formik.errors.closeDate && (
                  <small id="closeDate-invalid" className="p-error p-d-block">
                    {formik.errors.closeDate}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-2 p-xl-2">
                Description
              </label>
              <div className="p-col-12 p-md-7 p-xl-4">
                <InputTextarea
                  className={formik.errors.description && 'p-invalid'}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                  disabled={shared || isTrialTracker}
                />
                {formik.errors.description && (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <div className={`${styles.checkbox} p-field-checkbox p-offset-2`}>
                <Checkbox
                  id="contract"
                  onChange={formik.handleChange}
                  value={formik.values.contract}
                  checked={formik.values.contract}
                  disabled={shared || isTrialTracker}
                />
                <label htmlFor="contract">Contract</label>
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="logFrequency" className="p-col-12 p-md-2 p-xl-2">
                Log Frequency
              </label>
              <div className="p-col-9 p-md-6 p-xl-4">
                <Dropdown
                  id="logFrequency"
                  onChange={formik.handleChange}
                  value={formik.values.logFrequency}
                  options={logFrequenciesInfo || logFrequencies}
                  optionLabel="name"
                  showClear
                  disabled={shared || isTrialTracker}
                  placeholder={
                    shared || isTrialTracker
                      ? formik.values.logFrequency?.name
                      : ''
                  }
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable()}
                />
              </div>
            </div>

            <div className="p-field p-grid p-ai-start">
              <label htmlFor="status" className="p-col-12 p-md-2 p-xl-2">
                Status
              </label>

              <div className="p-col-9 p-md-6 p-xl-4">
                <Dropdown
                  id="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  options={statuses}
                  optionLabel="name"
                  disabled={shared || isTrialTracker}
                  placeholder={
                    shared || isTrialTracker ? formik.values.status?.name : ''
                  }
                  showClear
                />
              </div>

              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenStatusesTable()}
                />
              </div>
            </div>
            {!shared && (
              <div className="p-field p-grid p-ai-start">
                <label
                  htmlFor="cropTemplate"
                  className="p-col-12 p-md-2 p-xl-2"
                >
                  Crop Label Template
                </label>
                <div className="p-col-12 p-md-7 p-xl-4">
                  <Dropdown
                    id="cropTemplate"
                    onChange={formik.handleChange}
                    onBlur={() => {
                      formik.handleBlur({
                        target: { name: 'cropTemplate' },
                      });
                    }}
                    value={formik.values.cropTemplate}
                    aria-describedby="cropTemplate-invalid"
                    className={classNames(
                      (errorRedux ||
                        (formik.errors.cropTemplate &&
                          formik.touched.cropTemplate)) &&
                        'p-invalid'
                    )}
                    options={cropTemplatesOptions}
                    optionLabel="name"
                    showClear
                    disabled={shared || isTrialTracker}
                  />
                  {formik.touched.cropTemplate && formik.errors.cropTemplate && (
                    <small
                      id="cropTemplate-invalid"
                      className="p-error p-d-block"
                    >
                      {formik.errors.cropTemplate}
                    </small>
                  )}
                </div>
              </div>
            )}
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="products" className="p-col-12 p-md-2 p-xl-2">
                Products
              </label>
              <div className="p-col-12 p-md-7 p-xl-4">
                <MultiSelect
                  id="products"
                  value={
                    formik.values.products &&
                    uniqueProductByName(formik.values.products)
                  }
                  options={shared ? formik.values.products : products}
                  disabled={shared || isTrialTracker}
                  onChange={formik.handleChange}
                  optionLabel="tradeName"
                  maxSelectedLabels={3}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-2 p-xl-2">
                Note
              </label>
              <div className="p-col-12 p-md-7 p-xl-4">
                <InputTextarea
                  className={formik.errors.note && 'p-invalid'}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                  disabled={shared || isTrialTracker}
                />
                {formik.errors.note && (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'p-grid p-col-12 p-md-9 p-xl-6 p-justify-end pad-r-0',
              styles.buttonsGroup
            )}
            style={{ display: showElements }}
          >
            <Button
              className={styles.button}
              type="button"
              title={titleMessage}
              label="Share"
              icon="pi pi-share-alt"
              disabled={isTrialTracker || subscription === tier.essentials}
              onClick={() => onModalOpen()}
            />
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              disabled={!formik.dirty || !formik.isValid}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.values, formik.dirty)}
            />
          </div>
        </form>
        <div
          className="sharedTrialBlock"
          style={{ display: showPartnerLocations }}
        />
      </div>
      <Dialog
        className="confirmDialog"
        header="Log Frequencies"
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          FNote
          values={logFrequenciesInfo}
          margin="20px"
          referenceName="logfrequencies"
          editable={true}
        />
      </Dialog>

      <Dialog
        className="confirmDialog"
        header="Trial Statuses"
        visible={displayStatusesTable}
        onHide={() => setDisplayStatusesTable(false)}
      >
        <StatusesTable values={statuses} />
      </Dialog>
    </div>
  );
};

export default withRouter(TrialForm);
