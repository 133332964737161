const CROP = '/crop';
const TRIAL = '/trial';
const SETTINGS = '/settings';
const TOKEN = '/token';

export const ERROR_CODE = '404';
export const ERROR_TEXT = 'WE ARE SORRY, PAGE NOT FOUND!';
export const LINK_TEXT = 'Back To Homepage';

export default {
  HOME: '/',
  DASHBOARD: '/dashboard',
  SIGN_UP: '/signup',
  PRIVACY_POLICY: '/privacy-policy',
  USER_AGREEMENT: '/user-agreement',
  PRICING: '/pricing',
  TRIALS: '/trials',
  TRIAL: `${TRIAL}/:id`,
  TRIAL_PLANTS: `${TRIAL}/:id/plants`,
  TRIAL_SELECT_PLANTS: `${TRIAL}/:id/select-plants`,
  TRIAL_FIELDS: `${TRIAL}/:id/fields`,
  PLANT_GOALS_RESULTS: `${TRIAL}/:id/plant-goals-results`,
  PLANT_GOALS: `${TRIAL}/:id/plant-goals`,
  TRIAL_SELECT_FIELDS: `${TRIAL}/:id/select-fields`,
  TRIAL_LOGS: `${TRIAL}/:id/logs`,
  HISTORY: `${TRIAL}/:id/history`,
  REPORTS: `${TRIAL}/:id/reports`,
  CROPS: `${TRIAL}/:id/crops`,
  CROP: `${CROP}/:id`,
  CROP_LOGS: `${CROP}/:id/logs`,
  PLANTS: '/plants',
  LOCATIONS: '/locations',
  LOCATION: '/location/:id',
  SECTIONS: '/location/:id/sections',
  EMPLOYEES: '/employees',
  TASKS: '/tasks',
  PRODUCTS: '/products',
  PRODUCT: '/product/:id',
  APPLICATIONS: '/product/:id/applications',
  COMPANY_INFO: '/administration/company-info',
  REPLICATION: `${SETTINGS}/replication`,
  LABEL: `${SETTINGS}/label/:id`,
  LABELS: `${SETTINGS}/labels`,
  SUPPORT: '/support',
  CONTACT_US: '/contact-us',
  SHARED_TRIALS: `${TRIAL}/:id/shared-trials`,
  LOGIN: '/login',
  OUTDATED: `${TOKEN}/outdated`,
};
