export const initialBatchCropInfo = {
  trialId: '',
  section: '',
  startForm: '',
  potSize: '',
  startDate: null,
  plantDate: null,
  transplantDate: null,
  spaceDate1: null,
  spaceDate2: null,
  quantity: '',
  plantsPerPot: '',
  note: '',
};

export const modals = {
  CROP: 'CROP',
  PRINT: 'PRINT',
  BATCH: 'BATCH',
};
