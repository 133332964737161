const fileTypes = {
  IMAGE: 'IMAGE',
  PDF: 'PDF',
};

const initialPlantInfo = {
  name: '',
  code: '',
  program: null,
  class: null,
  series: null,
  breeder: null,
  marketStatus: null,
  patentNumber: '',
  tradeName: '',
  scientificName: '',
  color: '',
  active: { label: 'Yes', status: true },
  description: '',
  note: '',
  image: '',
};

const initialBatchInfo = {
  code: '',
  active: false,
  description: '',
  breeder: null,
  program: null,
  class: null,
  series: null,
  marketStatus: null,
  patentNumber: '',
  tradeName: '',
  scientificName: '',
  note: '',
};

const getPlantParams = (id) => {
  return {
    query: {
      plant: {
        id: {
          is: id,
        },
      },
    },
  };
};
const errorCodes = {
  NOT_PROVIDED: {
    code: 'NOT_PROVIDED',
    text: 'Missing values for required column(s): ',
  },
  NOT_UNIQUE: {
    code: 'NOT_UNIQUE',
    text: 'Values are not unique in column(s): ',
  },
  PLANT_COLUMN_NOT_PROVIDED: {
    code: 'PLANT_COLUMN_NOT_PROVIDED',
    text: 'Missing required column(s): ',
  },
  DEFAULT_MESSAGE: 'The file cannot be imported.',
};

const activeOptions = [
  { label: 'Yes', status: true },
  { label: 'No', status: false },
];

const initialPlantParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'name',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
};

export {
  fileTypes,
  initialPlantInfo,
  initialBatchInfo,
  getPlantParams,
  errorCodes,
  activeOptions,
  initialPlantParams,
};
