import React from 'react';

const zplFonts = [{ name: 'Arial' }];

const units = [{ name: 'in' }];

const types = {
  TEXT: 'TEXT',
  BARCODE: 'BARCODE',
  QR: 'QR',
  IMAGE: 'IMAGE',
};

const enumToCss = {
  measure: {
    INCH: 'in',
    PIXEL: 'px',
    POINT: 'pt',
  },
  family: {
    ARIAL: 'Arial',
    ROBOTO: 'Roboto',
  },
  weight: {
    BOLD: 'bolder',
    NORMAL: 'normal',
  },
};

const cssToEnum = {
  measure: {
    in: 'INCH',
    px: 'PIXEL',
    pt: 'POINT',
  },
  family: {
    Arial: 'ARIAL',
    Roboto: 'ROBOTO',
  },
  weight: {
    bolder: 'BOLD',
    normal: 'NORMAL',
  },
};

const multiplier = {
  INCH: 96,
  PIXEL: 1,
  POINT: 1.333,
};

const maxSize = {
  width: 900,
  height: 500,
};

const minSize = {
  width: 200,
  height: 20,
};

const defaultFontSize = 10;

const fieldOrigin = {
  SYSTEM: 'SYSTEM',
  CUSTOM: 'CUSTOM',
};
const defaultQRSize = 85;

const fieldTypes = [{ name: 'TEXT' }, { name: 'IMAGE' }];

export {
  zplFonts,
  defaultFontSize,
  units,
  types,
  enumToCss,
  cssToEnum,
  multiplier,
  minSize,
  maxSize,
  fieldTypes,
  fieldOrigin,
  defaultQRSize,
};
