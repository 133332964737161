import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColorPicker } from 'primereact/colorpicker';
import { confirmDialog } from 'primereact/confirmdialog';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import cloneDeep from 'lodash/cloneDeep';

import { setPopUp } from '../../../reduxStore/popUp/actions';
import { valueNameSchema } from './statusesTableValidation';
import { isAuth } from '../../../auth/auth-service';
import { getProfile } from '../../../reduxStore/trial/actions';
import { urls, useRequest } from '../../Common/ApiServices';
import { errorCodes } from '../constants';
import { popUp } from '../../Common/globalConstants';

import styles from './StatusesTable.module.scss';

const StatusesTable = ({ values }) => {
  const [statuses, setStatuses] = useState(null);
  const [initialStatuses, setInitialStatuses] = useState(null);
  const [displayStatusModal, setDisplayStatusModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maxLengthExceeded, setMaxLengthExceeded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const toast = useRef(null);
  const topToast = useRef(null);

  const dispatch = useDispatch();
  const { logout, getAccessTokenSilently } = useAuth0();

  const { error, sendRequest } = useRequest({});

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const deleteStatus = async (value) => {
    setLoading(true);
    const requestData = {
      url: `${urls.TRIAL_STATUSES}/${value.id}`,
      method: 'DELETE',
      data: {},
    };
    const response = await sendRequest(requestData);
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `${value.name} value was deleted..`,
        life: 5000,
      });

      dispatch(getProfile(actionData));
      setLoading(false);
    }

    return response;
  };

  const checkColorHex = (colorHex) => {
    return colorHex[0] === '#' ? colorHex : `#${colorHex}`;
  };

  const editStatus = async (data) => {
    setLoading(true);
    const requestData = {
      url: `${urls.TRIAL_STATUSES}/${data.id}`,
      method: 'PUT',
      data: { name: data.name, colorHex: checkColorHex(data.colorHex) },
    };
    const response = await sendRequest(requestData);
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `${selectedStatus?.name} value was updated.`,
        life: 5000,
      });
      setLoading(false);
      dispatch(getProfile(actionData));
      setEditing(false);
      setSelectedStatus(null);
    }

    return response;
  };

  const createStatus = async (value) => {
    setLoading(true);
    const requestData = {
      url: urls.TRIAL_STATUSES,
      method: 'POST',
      data: {
        name: value.name,
        colorHex: `#${value.colorHex}`,
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      onHide();
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `${value.name} value was created.`,
        life: 5000,
      });
      setLoading(false);
      dispatch(getProfile(actionData));
    }

    return response;
  };

  const onHide = () => {
    setDisplayStatusModal(false);
    formik.resetForm();
  };

  const onRowEditInit = (event) => {
    setSelectedStatus({
      id: event.data.id,
      name: event.data.name,
      colorHex: checkColorHex(event.data.colorHex),
    });
    setEditing(true);
  };

  const onRowEditSave = (val) => {
    editStatus(val.data);
    setEditing(false);
  };

  const onRowEditCancel = () => {
    setStatuses([...initialStatuses]);
    setEditing(false);
    setSelectedStatus(null);
  };

  const onEditorValueChange = (fieldKey, props, value) => {
    let updatedStatuses = [...props.value];
    updatedStatuses[props.rowIndex][props.field] =
      props.field === 'colorHex' ? checkColorHex(value) : value;
    setStatuses(updatedStatuses);
  };

  const inputTextEditor = (fieldKey, props, field) => {
    return (
      <>
        <InputText
          type="text"
          value={props.rowData[field]}
          style={{ width: '100%' }}
          className={inputStyle}
          maxLength={maxInputLength}
          onChange={(e) => onEditorValueChange(fieldKey, props, e.target.value)}
        />
        <small id="username2-help" className={spanStyle}>
          Maximum length exceed.
        </small>
      </>
    );
  };

  const inputColorEditor = (fieldKey, props, field) => {
    const colorHex = checkColorHex(props.rowData[field]);
    return (
      <div className="card flex justify-content-center">
        <ColorPicker
          value={colorHex}
          onChange={(e) => onEditorValueChange(fieldKey, props, e.target.value)}
        />
      </div>
    );
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          type="button"
          label="New Value"
          icon="pi pi-plus"
          disabled={editing}
          onClick={() => onNewValueAdd()}
        />
      </div>
    </div>
  );

  const maxInputLength = 32;
  const inputStyle = maxLengthExceeded ? 'p-invalid p-d-block' : '';
  const spanStyle = maxLengthExceeded ? 'p-error p-d-block' : 'p-d-none';

  const onNewValueAdd = () => {
    setDisplayStatusModal(true);
  };

  const removeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          type="button"
          className="p-button-rounded p-button-warning"
          style={{ visibility: editing ? 'hidden' : 'visible' }}
          onClick={() => confirmDeleteValue(rowData)}
        />
      </React.Fragment>
    );
  };

  const confirmDeleteValue = (value) => {
    setSelectedStatus(value);
    confirmDialog({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `Are you sure you want to delete ${value?.name} status?`,
      accept: () => deleteStatus(value),
    });
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const formik = useFormik({
    initialValues: { name: '', colorHex: '' },
    enableReinitialize: true,
    validationSchema: valueNameSchema,
    onSubmit: (value) => {
      createStatus(value);
    },
  });

  const statusBodyTemplate = (rowData) => {
    return (
      <div>
        <i
          className={`pi pi-circle-on ${styles.statusIcon}`}
          style={{ color: rowData.colorHex }}
        />
      </div>
    );
  };

  useEffect(() => {
    setStatuses(cloneDeep(values));
    setInitialStatuses(cloneDeep(values));
  }, [values]);

  useEffect(() => {
    if (error) {
      let message = errorCodes.DEFAULT_MESSAGE.text;
      if (
        error.response.data?.error?.code === errorCodes.TRIAL_IS_SHARED.code
      ) {
        message = `Unable to update the ${selectedStatus?.name} trial because it is used in trial.`;
      } else if (
        error.response.data?.error?.code === errorCodes.TRIAL_HAS_LOGS.code
      ) {
        setStatuses(cloneDeep(initialStatuses));
        message = `Unable to update the ${selectedStatus?.name} status because the trial contains logs.`;
      } else if (
        error.response.data?.error?.code ===
        errorCodes.TRIAL_REFERENCES_EXIST.code
      ) {
        message = `Unable to delete the ${selectedStatus?.name} status because it is being used by other trials.`;
      }
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: message,
          life: 5000,
          sticky: null,
        })
      );
      setDisplayStatusModal(false);
      setLoading(false);
      setEditing(false);
    }
  }, [error]);

  return (
    <div className={styles.statusesTable}>
      <Toast ref={toast} />
      <Dialog
        className={styles.formDataDialog}
        visible={displayStatusModal}
        onHide={() => confirm(false)}
      >
        <Toast ref={topToast} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                />
                {formik.touched.name && formik.errors.name && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Color*
              </label>
              <div
                className={classNames('p-col-12 p-md-9', styles.colorPicker)}
              >
                <ColorPicker
                  id="colorHex"
                  onChange={(e) => formik.setFieldValue('colorHex', e.value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.colorHex}
                />
                {formik.touched.colorHex && formik.errors.colorHex && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.colorHex}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(false)}
            />
          </div>
        </form>
      </Dialog>
      <DataTable
        className="p-datatable-sm statuses-table"
        loading={loading}
        value={statuses}
        header={header}
        scrollable
        editMode="row"
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
        onRowEditInit={onRowEditInit}
      >
        <Column
          field="name"
          columnKey="name"
          header="Name"
          editor={(props) => inputTextEditor('fields', props, 'name')}
        />
        <Column
          field="colorHex"
          columnKey="colorHex"
          header="Color"
          body={statusBodyTemplate}
          editor={(props) => inputColorEditor('fields', props, 'colorHex')}
        />
        <Column
          className={editing ? 'rowEditor-editButton-hidden ' : ''}
          rowEditor={true}
          header="Edit"
          headerStyle={{ width: '90px' }}
        />
        <Column
          field="remove"
          header={'Remove'}
          headerStyle={{ width: '70px' }}
          body={removeBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default StatusesTable;
