import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { updateAuthCookieExpiration } from './auth-cookie';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/dashboard`}
      audience={audience}
      onRedirectCallback={() => {
        updateAuthCookieExpiration();
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
