import React from 'react';
import LeftBar from '../../LeftBar/LeftBar';
import LocationMainMenu from '../Menu/LocationMainMenu';
import LocationList from '../LocationList/LocationList';

const LocationPage = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <LocationMainMenu />
      </LeftBar>
      <LocationList />
    </React.Fragment>
  );
};

export default LocationPage;
