import React from 'react';
import Menu from '../../../Common/Menu/Menu';

const ProductMenu = ({ productName }) => {
  const productId = localStorage.getItem('productId');
  const productLink = `/product/${productId}`;

  const links = [
    { link: productLink, value: 'Information' },
    { link: `${productLink}/applications`, value: 'Applications' },
  ];

  return <Menu items={links} name={productName} />;
};

export default ProductMenu;
