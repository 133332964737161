import React from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { initialModalSchema, modalSchema } from './emailModalFormValidation';
import styles from './emailModalFormodal.module.scss';

const EmailModalForm = ({
  setIsModalOpen,
  email,
  verifyCompanyEmail,
  emailVerified,
}) => {
  const formik = useFormik({
    initialValues: initialModalSchema({ email: email }),
    enableReinitialize: true,
    validationSchema: modalSchema,
    onSubmit: (values) => verifyCompanyEmail(values.email),
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <span className={styles.title}>Verify email</span>{' '}
            <Button
              icon="pi pi-times"
              type="button"
              className="p-button-rounded p-button-outlined"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <div className={styles.modalContent}>
            <InputText
              id="email"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              aria-describedby="email-invalid"
              className={
                formik.errors.email && formik.touched.email && 'p-invalid'
              }
              disabled={emailVerified && email}
            />
            {formik.touched.email && formik.errors.email && (
              <small id="email-invalid" className="p-error p-d-block">
                {formik.errors.email}
              </small>
            )}
            <Button
              label="Send Verification Email"
              type="submit"
              className={styles.submitButton}
              disabled={(emailVerified && !formik.dirty) || !formik.isValid}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailModalForm;
