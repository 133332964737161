import * as actionTypes from './actions';

const initialState = {
  categories: [],
  classifications: [],
  manufacturers: [],
  mixers: [],
  applicationMethods: [],
  measureUnits: [],
  mixerMeasureUnits: [],
  productFiltersReady: false,
};

export const productFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.PRODUCT_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        categories: action.categories,
        classifications: action.classifications,
        manufacturers: action.manufacturers,
        mixers: action.mixers,
        applicationMethods: action.applicationMethods,
        measureUnits: action.measureUnits,
        mixerMeasureUnits: action.mixerMeasureUnits,
        productsFiltersReady: true,
      };
    case actionTypes.PRODUCT_FILTERS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
