import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import merge from 'deepmerge';
import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { getProfileFieldGroups } from '../../../../reduxStore/profileFieldGroups/actions';
import { isAuth } from '../../../../auth/auth-service';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import FieldForm from '../FieldForm/FieldForm';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { fieldOrigin } from '../../../Labels/LabelsTemplates/LabelsEditor/constants';
import { urls, useRequest } from '../../../Common/ApiServices';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { popUp } from '../../../Common/globalConstants';
import styles from './FieldList.module.scss';

const FieldList = (props) => {
  const trialId = localStorage.getItem('trialId');
  const initialFieldParams = {
    query: {
      trial: {
        id: {
          is: trialId,
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'name',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldParams, setFieldParams] = useState(initialFieldParams);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [cancelSelectionDialog, setCancelSelectionDialog] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [deleteFieldDialog, setDeleteFieldDialog] = useState(false);
  const [fieldIsDeleted, setFieldIsDeleted] = useState(false);
  const [displayFieldModal, setDisplayFieldModal] = useState(false);
  const [fieldIsUpdated, setFieldIsUpdated] = useState(false);
  const [fieldIsCreated, setFieldIsCreated] = useState(false);

  const tableRef = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const history = useHistory();

  const groups = useSelector((state) => state.profileFieldGroups.groups);

  const { error, sendRequest } = useRequest({});
  const fetchFields = async () => {
    setLoading(true);
    const requestData = {
      url: urls.SEARCH_PROFILE_FIELDS,
      method: 'POST',
      data: fieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      const fields = await response.data.results.map((rawField) => ({
        id: rawField.profileField.id,
        label: rawField.profileField.label,
        name: rawField.profileField.name,
        group: rawField?.profileField?.group?.name,
        minValue: rawField.profileField.minValue,
        maxValue: rawField.profileField.maxValue,
        multiSelect: rawField.profileField.multiselect,
        type:
          rawField.profileField.type[0].toUpperCase() +
          rawField.profileField.type.slice(1).toLowerCase().replace('_', ' '),
        eventType: rawField.profileField.eventType,
        values: rawField.profileField.values
          ? rawField.profileField.values.map((value) => value.name).join(', ')
          : null,
        origin: rawField.profileField.origin,
      }));
      setFields(fields);
      setTotalRecords(response.data.page.total);
      setFieldIsUpdated(false);
      setFieldIsCreated(false);
      setFieldIsDeleted(false);
    }
    setLoading(false);
  };

  const toTrialFieldList = async () => {
    await setSelectedFields([]);
    history.push(`/trial/${trialId}/fields`);
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div className={styles.linkStyle} onClick={() => onFieldSelect(rowData)}>
        {rowData.name}
      </div>
    );
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onFieldSelect = (field) => {
    setSelectedField(field);
    setDisplayFieldModal(true);
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const onSave = async () => {
    const selectedFieldIds = selectedFields.map((field) => field.id);

    const requestData = {
      url: urls.ADD_TRIAL_FIELDS,
      method: 'POST',
      data: {
        trialId: trialId,
        profileFieldId: {
          in: selectedFieldIds,
        },
      },
    };

    const response = await sendRequest(requestData);

    if (response) {
      setSelectedFields([]);
      toTrialFieldList();
    }
  };

  const confirmDeleteField = (field) => {
    setFieldToDelete(field);
    setDeleteFieldDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.origin !== fieldOrigin.SYSTEM) {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteField(rowData)}
          />
        </React.Fragment>
      );
    }
  };

  const onCancel = () => {
    if (selectedFields.length > 0) {
      setCancelSelectionDialog(true);
    } else {
      toTrialFieldList();
    }
  };

  const hideCancelSelectionDialog = () => {
    setCancelSelectionDialog(false);
  };

  const onNewFieldCreate = () => {
    setSelectedField(null);
    setDisplayFieldModal(true);
  };

  const checkboxBodyTemplate = (isChecked) => {
    return <Checkbox disabled={true} checked={isChecked} />;
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || fields;
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          disabled={selectedFields.length === 0}
          label="Save"
          onClick={() => onSave()}
        />
        <Button
          className=" p-button-secondary p-button-raised"
          label="Cancel"
          onClick={() => onCancel()}
        />
        <Button
          className="p-button-raised"
          label="New Trial Field"
          icon="pi pi-plus"
          onClick={() => onNewFieldCreate()}
        />
      </div>
      <span className={classNames('p-input-icon-left', styles.tabSearch)}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage = 'No fields were found.';
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          totalRecords={totalRecords}
          className="tabPaginator"
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const cancelSelectionDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideCancelSelectionDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={toTrialFieldList}
      />
    </React.Fragment>
  );

  const hideDeleteFieldDialog = () => {
    setDeleteFieldDialog(false);
  };

  const deleteField = async () => {
    setDeleteFieldDialog(false);

    const requestData = {
      url: urls.DELETE_PROFILE_FIELDS,
      method: 'POST',
      data: {
        id: {
          in: [fieldToDelete.id],
        },
      },
    };

    const response = await sendRequest(requestData);

    if (response) {
      setFieldIsDeleted(true);
    }
  };

  const deleteFieldDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteFieldDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteField}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName'),
      command: () => {
        history.push(`/trial/${trialId}`);
      },
    },
    {
      label: 'Trial Fields',
      command: () => {
        history.push(`/trial/${trialId}/fields`);
      },
    },
    { label: 'Select Fields' },
  ];

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  useEffect(() => {
    if (sort) {
      setFieldParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    setFieldParams((oldParams) => {
      let params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    setFieldParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (globalSearch) {
        const searchParams = { query: { search: { is: globalSearch } } };
        params = merge(params, searchParams);
      } else if (!globalSearch && params.query.search) {
        delete params.query.search;
      }
      return params;
    });
  }, [globalSearch]);

  useEffect(() => {
    !loading && fetchFields();
  }, [fieldParams]);

  useEffect(() => {
    if (fieldIsDeleted) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Field ${fieldToDelete?.name} was deleted.`,
          life: 5000,
        })
      );
      fetchFields();
    } else if (fieldIsCreated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New profile field was created.',
          life: 5000,
        })
      );
      fetchFields();
    } else if (fieldIsUpdated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Profile field was updated.',
          life: 5000,
        })
      );
      fetchFields();
      setSelectedField(null);
    }
  }, [fieldIsUpdated, fieldIsCreated, fieldIsDeleted]);

  useEffect(() => {
    dispatch(getProfileFieldGroups(actionData));
  }, []);

  useEffect(() => {
    if (error && error.response.data.errors) {
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: 'Selected fields already exist in the trial',
          life: 5000,
        })
      );
    }
  }, [error]);

  return (
    <div className={`${styles.fieldList} list-generic`}>
      <FieldForm
        fieldId={selectedField?.id || null}
        groups={groups}
        programs={props.programs}
        classes={props.classes}
        series={props.series}
        breeders={props.breeders}
        displayFieldModal={displayFieldModal}
        setDisplayFieldModal={setDisplayFieldModal}
        setSelectedField={setSelectedField}
        setFieldIsUpdated={setFieldIsUpdated}
        setFieldIsCreated={setFieldIsCreated}
        fieldIsUpdated={fieldIsUpdated}
        fieldIsCreated={fieldIsCreated}
        isSystemField={selectedField?.origin === fieldOrigin.SYSTEM}
      />
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={selectedFields.length > 0} />
      <DataTable
        ref={tableRef}
        className={classNames(
          'table-generic p-datatable-sm',
          styles.customTableStyle
        )}
        value={fields}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={selectedFields}
        selectionMode="checkbox"
        onSelectionChange={(e) => setSelectedFields(e.value)}
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        scrollable
        frozenWidth="0px"
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '50px', height: '48px', padding: '0 7px' }}
          bodyStyle={{ height: '50px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="name"
          sortField="name"
          columnKey="name"
          header="Name"
          headerStyle={{ width: '270px' }}
          body={nameBodyTemplate}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="group"
          sortField="group"
          columnKey="group"
          header="Group"
          headerStyle={{ width: '270px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="label"
          sortField="label"
          columnKey="label"
          header="Display Name"
          headerStyle={{ width: '270px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="type"
          sortField="type"
          columnKey="type"
          header="Data Type"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="eventType"
          sortField="eventType"
          columnKey="eventType"
          header="Field Type"
          headerStyle={{ width: '150px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="values"
          sortField="values"
          columnKey="values"
          header="List Values"
          headerStyle={{ width: '200px' }}
        />
        <Column
          field="multiSelect"
          sortField="multiSelect"
          columnKey="multiSelect"
          header="Multi Select"
          headerStyle={{ width: '100px' }}
          body={(rowData) => checkboxBodyTemplate(rowData.multiSelect)}
        />
        <Column
          field="remove"
          header="Remove"
          headerStyle={{ width: '70px' }}
          body={actionBodyTemplate}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={cancelSelectionDialog}
        className="confirmDialog"
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={cancelSelectionDialogFooter}
        onHide={hideCancelSelectionDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          <span>Are you sure you want to cancel profile fields selection?</span>
        </div>
      </Dialog>
      <Dialog
        visible={deleteFieldDialog}
        className="confirmDialog"
        style={{ width: '450px' }}
        header="Delete Confirmation"
        modal
        footer={deleteFieldDialogFooter}
        onHide={hideDeleteFieldDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {fieldToDelete && (
            <span>
              Are you sure you want to delete <b>{fieldToDelete.name}</b> field?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default FieldList;
