import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import merge from 'deepmerge';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import ShowDetails from '../../Common/showDetails/showDetails';
import TaskForm from '../TaskForm/TaskForm';
import CropSelectionList from '../CropSelectionList/CropSelectionList';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import { useRequest, urls } from '../../Common/ApiServices';
import {
  getInitialTasksParams,
  getDeleteCropsParams,
  getSearchCropsParams,
  messageText,
} from '../constants';
import {
  errorCodes,
  popUp,
  showDetailsFields,
} from '../../Common/globalConstants';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import styles from './TaskList.module.scss';

const TaskList = ({
  loading,
  setTaskManagers,
  setTaskStatuses,
  setTaskTypes,
  setTrials,
  setView,
  setTaskParams,
  setSelectedCalendarTask,
  taskManagers,
  taskStatuses,
  taskSections,
  taskTypes,
  trials,
  tasks,
  products,
  taskParams,
  totalRecords,
  fetchTasks,
  initialLocation,
}) => {
  const [crops, setCrops] = useState([]);
  const [cropParams, setCropParams] = useState({});
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectedTask, setSelectedTask] = useState({});
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [sortTasks, setSortTasks] = useState({
    field: 'description',
    order: 1,
  });
  const [sortCrops, setSortCrops] = useState({
    field: 'section',
    order: 1,
  });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [displayCropSelectionModal, setDisplayCropSelectionModal] =
    useState(false);
  const [savedTasks, setSavedTasks] = useState([]);
  const [displayTaskModal, setDisplayTaskModal] = useState(false);
  const [taskIsCreated, setTaskIsCreated] = useState(false);
  const [taskIsUpdated, setTaskIsUpdated] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [deleteTaskDialog, setDeleteTaskDialog] = useState(false);
  const [cropToDelete, setCropToDelete] = useState(null);
  const [deleteCropDialog, setDeleteCropDialog] = useState(false);

  const tableRef = useRef(null);

  const cropTableRef = useRef(null);

  const dispatch = useDispatch();

  const { error, isLoading, sendRequest } = useRequest({});

  const defaultLocation = useSelector(
    (state) => state.taskOptions.defaultLocation
  );
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const getInitialSections = (id) => {
    const expandedTask = savedTasks.find((task) => task.id === id);
    const newSections = expandedTask.sections.map((section) => {
      return {
        allCrops: section.allCrops,
        breeder: section.breeder,
        crop: section.crop,
        plant: section.plant,
        section: section.section,
        skipped: section.skipped,
        trial: expandedTask.trial,
      };
    });
    setCrops(newSections);
  };

  const updateSelectedTasks = (data) => {
    const saved = [...savedTasks];
    saved.forEach((task) => {
      if (task.id === Object.keys(expandedRows)[0]) {
        task.sections = data;
      }
    });
    setSavedTasks(saved);
  };

  const fetchCrops = async () => {
    const requestData = {
      url: urls.SEARCH_TASK_SECTIONS_CROPS,
      method: 'POST',
      data: cropParams,
    };
    const response = await sendRequest(requestData);

    updateSelectedTasks(response.data.results);
    setCrops(response.data.results);

    return response;
  };

  const onTaskSelect = (task) => {
    setExpandedRows({});
    setSelectedTask(task);
    setDisplayTaskModal(true);
  };

  const descriptionBodyTemplate = (rowData) => {
    return (
      <button
        className={styles.linkStyle}
        onClick={() => onTaskSelect(rowData)}
      >
        {rowData.description}
      </button>
    );
  };

  const onSortTasks = ({ sortField, sortOrder }) => {
    setSortTasks({ field: sortField, order: sortOrder });
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || savedTasks;
  };

  const onSortCrops = ({ sortField, sortOrder }) => {
    setSortCrops({ field: sortField, order: sortOrder });
  };

  const cropsSortFunc = () => {
    return cropTableRef?.current?.props.value || crops;
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const onNewTaskCreate = () => {
    setSelectedTask({});
    setDisplayTaskModal(true);
  };

  const updateTaskStatus = async (completedTasksId) => {
    const requestData = {
      url: urls.UPDATE_TASK_STATUS,
      method: 'POST',
      data: {
        task: {
          id: {
            in: completedTasksId,
          },
          status: 'COMPLETED',
        },
      },
    };

    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Task status was changed successfully!',
          life: 5000,
          sticky: null,
        })
      );
      fetchTasks();
      setCheckedTasks([]);
    }
  };

  const onTaskComplete = () => {
    const completedIds = checkedTasks.map((task) => task.id);
    updateTaskStatus(completedIds);
  };

  const onRowExpand = async (event) => {
    getInitialSections(event.data.id);
  };

  const onRowCollapse = () => {
    setCrops([]);
  };

  const changeView = () => {
    setView({ list: false, calendar: true });
  };

  const hideDeleteCropDialog = () => {
    setCropToDelete(null);
    setDeleteCropDialog(false);
  };

  const prepareExpandedRows = (data) => {
    let result = {};
    const expanded = expandedRows ? expandedRows : {};
    const keys = Object.keys(expanded);
    for (let key in data) {
      if (!keys.includes(key)) {
        result[key] = data[key];
      }
    }
    setExpandedRows(result);
  };

  const deleteCrop = async () => {
    const expandedTaskId = Object.keys(expandedRows)[0];
    const requestData = {
      url: urls.DELETE_TASK_SECTIONS_CROPS,
      method: 'POST',
      data: getDeleteCropsParams(cropToDelete, expandedTaskId),
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: messageText.cropDeleted,
          life: 5000,
          sticky: null,
        })
      );
      setCropToDelete(null);
      setDeleteCropDialog(false);
      setCropParams(getSearchCropsParams(expandedTaskId));
    }

    return response;
  };

  const deleteCropDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCropDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteCrop}
      />
    </>
  );

  const confirmDeleteCrop = (e, crop) => {
    e.stopPropagation();
    setCropToDelete(crop);
    setDeleteCropDialog(true);
  };

  const removeCropBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={(e) => confirmDeleteCrop(e, [rowData])}
        />
      </React.Fragment>
    );
  };

  const removeCropColumn = !isTrialTracker && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={removeCropBodyTemplate}
    />
  );

  const rowExpansionTemplate = (data) => {
    const cropsWithTrial = crops.map((crop) => {
      crop.trial = data.trial;
      return crop;
    });
    return (
      <div style={{ padding: '1rem' }}>
        <h4>Sections and Crops</h4>
        <DataTable
          ref={cropTableRef}
          value={cropsWithTrial}
          header={innerHeader}
          sortField={sortCrops.field}
          sortOrder={sortCrops.order}
          onSort={onSortCrops}
        >
          <Column
            field="section.name"
            sortField="sectionName"
            header="Section"
            sortable
            sortFunction={cropsSortFunc}
          />
          <Column
            field="allCrops"
            header="All Crops"
            body={(rowData) => checkboxBodyTemplate(rowData.allCrops)}
          />
          {data.type === 'Chemical' && (
            <Column
              field="crop.skipped"
              sortField="skipped"
              header="Skipped"
              body={(rowData) => skippedBodyTemplate(rowData.skipped)}
            />
          )}

          <Column
            field="crop.number"
            sortField="cropNumber"
            header="Crop #"
            sortable
          />
          <Column
            field="plant.name"
            sortField="plantName"
            header="Plant Name"
            sortable
          />
          <Column
            field="crop.plantWeek"
            sortField="plantWeek"
            header="Plant Week"
            sortable
          />
          <Column
            field="breeder.name"
            sortField="breederName"
            header="Breeder"
            sortable
          />
          <Column field="trial" sortField="trialName" header="Trial" sortable />
          {removeCropColumn}
        </DataTable>
        <Dialog
          visible={deleteCropDialog}
          className="confirmDialog"
          header="Delete Confirmation"
          footer={deleteCropDialogFooter}
          onHide={hideDeleteCropDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-info-circle p-mr-3"
              style={{ fontSize: '2rem' }}
            />
            {cropToDelete && (
              <span>
                {cropToDelete[0]?.allCrops
                  ? 'Are you sure you want to delete all crops'
                  : 'Are you sure you want to delete crop'}{' '}
                <b>{cropToDelete[0]?.crop?.number}</b>?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    );
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="New Task"
          style={{ display: showElements }}
          icon="pi pi-plus"
          onClick={() => onNewTaskCreate()}
        />
        <Button
          className="p-button-raised"
          icon="pi pi-calendar"
          onClick={() => changeView()}
        />
        <Button
          className="p-button-raised"
          label="Complete Task"
          style={{ display: showElements }}
          disabled={!checkedTasks.length}
          onClick={() => onTaskComplete()}
        />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const innerHeader = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="Select Section/Crop"
          icon="pi pi-plus"
          style={{ display: showElements }}
          onClick={() => {
            setDisplayCropSelectionModal(true);
          }}
        />
      </div>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage = 'No tasks conform to filters.';
        if (isEqual(getInitialTasksParams(defaultLocation?.id), taskParams)) {
          emptyMessage = 'You don’t have tasks yet. Please create a task.';
        }
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const checkboxBodyTemplate = (allCrops) => {
    return <Checkbox disabled={true} checked={allCrops} />;
  };

  const skippedBodyTemplate = (allCrops) => {
    return allCrops ? (
      <i className={classNames('pi pi-check', styles.completed)} />
    ) : (
      ''
    );
  };

  const hideDeleteTaskDialog = () => {
    setTaskToDelete(null);
    setDeleteTaskDialog(false);
  };

  const deleteTask = async () => {
    const requestData = {
      url: urls.DELETE_TASKS,
      method: 'POST',
      data: {
        id: {
          in: [`${taskToDelete[0].id}`],
        },
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: messageText.taskDeleted,
          life: 5000,
          sticky: null,
        })
      );
      hideDeleteTaskDialog();
      fetchTasks();
    }

    return response;
  };

  const deleteTaskDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteTaskDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteTask}
      />
    </>
  );

  const confirmDeleteTask = (e, task) => {
    e.stopPropagation();
    setTaskToDelete(task);
    setDeleteTaskDialog(true);
  };

  const removeBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-warning"
        onClick={(e) => confirmDeleteTask(e, [rowData])}
      />
    );
  };

  const removeColumn = !isTrialTracker && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={removeBodyTemplate}
    />
  );

  const breadCrumbItems = [{ label: 'Tasks' }];

  useEffect(() => {
    setSavedTasks(tasks);
    setSelectedTask({});
  }, [tasks]);

  useEffect(() => {
    if (sortTasks) {
      setTaskParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sortTasks.field;
        params.navigation.sort[0].order =
          sortTasks.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sortTasks]);

  useEffect(() => {
    if (sortCrops && expandedRows) {
      const newParams = {
        query: {
          task: {
            id: {
              is: Object.keys(expandedRows)[0],
            },
          },
        },
        navigation: {
          sort: [
            {
              key: sortCrops.field,
              order: sortCrops.order === 1 ? 'ASC' : 'DESC',
            },
          ],
        },
      };
      setCropParams(newParams);
    }
  }, [sortCrops]);

  useEffect(() => {
    setTaskParams((oldParams) => {
      let params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    setTaskParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (globalSearch) {
        const searchParams = { query: { search: { is: globalSearch } } };
        params = merge(params, searchParams);
      } else if (!globalSearch && params.query.search) {
        delete params.query.search;
      }
      return params;
    });
  }, [globalSearch]);

  useEffect(() => {
    if (taskIsCreated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: messageText.taskCreated,
          life: 5000,
          sticky: null,
        })
      );
      fetchTasks();
      setTaskIsCreated(false);
    } else if (taskIsUpdated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Task ${selectedTask?.description} was updated.`,
          life: 5000,
          sticky: null,
        })
      );
      fetchTasks();
      setTaskIsUpdated(false);
    }
  }, [taskIsCreated, taskIsUpdated]);

  useEffect(() => {
    Object.keys(cropParams).length && fetchCrops();
  }, [cropParams]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.errors) {
        const text =
          error?.response?.data?.errors[0]?.code ===
            errorCodes.NOT_PROVIDED.code &&
          (error?.response?.data?.errors[0]?.fieldName === 'applicationId' ||
            error?.response?.data?.errors[0]?.fieldName === 'productId')
            ? messageText?.productNotProvided
            : errorCodes.DEFAULT_MESSAGE.text;

        dispatch(
          setPopUp({
            severity: popUp.severities.ERROR,
            summary: popUp.summary.ERROR,
            detail: text,
            life: 5000,
            sticky: null,
          })
        );
      }
      hideDeleteCropDialog();
      hideDeleteTaskDialog();
    }
  }, [error]);

  return (
    <div className={`${styles.taskList} list-generic`}>
      {displayTaskModal && (
        <TaskForm
          taskId={selectedTask?.id}
          displayTaskModal={displayTaskModal}
          taskIsCreated={taskIsCreated}
          trials={trials}
          products={products}
          taskTypes={taskTypes}
          taskManagers={taskManagers}
          taskStatuses={taskStatuses}
          taskSections={taskSections}
          setTrials={setTrials}
          setTaskTypes={setTaskTypes}
          setTaskManagers={setTaskManagers}
          setTaskStatuses={setTaskStatuses}
          setDisplayTaskModal={setDisplayTaskModal}
          setTaskIsCreated={setTaskIsCreated}
          setTaskIsUpdated={setTaskIsUpdated}
          setSelectedTask={setSelectedTask}
          setSelectedCalendarTask={setSelectedCalendarTask}
          initialLocation={initialLocation}
        />
      )}
      <CropSelectionList
        taskId={expandedRows}
        displayCropSelectionModal={displayCropSelectionModal}
        setDisplayCropSelectionModal={setDisplayCropSelectionModal}
        fetchCrops={fetchCrops}
        setCropParams={setCropParams}
      />
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        rowExpandMode="single"
        className={classNames(
          'table-generic p-datatable-sm logs',
          styles.customTableStyle
        )}
        value={savedTasks}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={checkedTasks}
        selectionMode="checkbox"
        cellSelection
        onSelectionChange={(e) => setCheckedTasks(e.value)}
        dataKey="id"
        emptyMessage=""
        expandedRows={expandedRows}
        onRowToggle={(e) => {
          prepareExpandedRows(e.data);
        }}
        expandableRows="true"
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        sortField={sortTasks.field}
        sortOrder={sortTasks.order}
        onSort={onSortTasks}
        loading={isLoading || loading}
        reorderableColumns
        scrollable
      >
        <Column
          selectionMode="multiple"
          headerStyle={{
            width: '40px',
            height: '48px',
            padding: '0 7px',
          }}
        />
        <Column expander style={{ width: '40px' }} />
        <Column
          field="startDate"
          sortField="startDate"
          reorderable
          columnKey="startDate"
          header="Start Date"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="startWeekSort"
          sortField="startDate"
          reorderable
          columnKey="startWeek"
          header="Start Week"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="description"
          reorderable
          body={descriptionBodyTemplate}
          columnKey="description"
          header="Description"
          headerStyle={{ width: '220px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="type"
          reorderable
          columnKey="type"
          header="Type"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="status"
          sortField="status"
          reorderable
          header="Status"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="taskManager"
          reorderable
          sortField="managerFirstName"
          columnKey="taskManager"
          header="Task Manager"
          headerStyle={{ width: '170px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="product"
          reorderable
          sortField="productName"
          columnKey="product"
          header="Product"
          headerStyle={{ width: '170px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="applicationCode"
          reorderable
          sortField="applicationCode"
          columnKey="applicationCode"
          header="Application Code"
          headerStyle={{ width: '170px' }}
        />
        <Column
          field="note"
          reorderable
          sortField="note"
          columnKey="note"
          header="Note"
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          headerStyle={{ width: '170px' }}
          body={(rowData) => ShowDetails(rowData[showDetailsFields.NOTE])}
        />
        <Column
          field="trial"
          reorderable
          sortField="trialName"
          columnKey="trial"
          header="Trial"
          headerStyle={{ width: '170px' }}
          sortable
          sortFunction={sortFunc}
        />
        {removeColumn}
      </DataTable>
      {footer()}
      <Dialog
        className="confirmDialog"
        visible={deleteTaskDialog}
        header="Delete Confirmation"
        footer={deleteTaskDialogFooter}
        onHide={hideDeleteTaskDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {taskToDelete && (
            <span>
              Are you sure you want to delete task{' '}
              <b>{taskToDelete[0].description}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default withRouter(TaskList);
