import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import TaskForm from '../Tasks/TaskForm/TaskForm';
import { firstDay, locales } from './constants';
import { popUp } from '../Common/globalConstants';
import { setPopUp } from '../../reduxStore/popUp/actions';

const FullCalendarPage = ({
  dateFormat,
  firstDayOfWeek,
  tasks,
  setView,
  trials,
  taskTypes,
  taskManagers,
  taskStatuses,
  fetchTasks,
  products,
  selectedCalendarTask,
  setSelectedCalendarTask,
  initialLocation,
}) => {
  const [events, setEvents] = useState([]);
  const [displayTaskModal, setDisplayTaskModal] = useState(false);
  const [taskIsUpdated, setTaskIsUpdated] = useState(false);

  const dispatch = useDispatch();

  const mapDate = (start, end) => {
    if (
      start.substring(start.length - 10) === 'T00:00:00Z' &&
      end.substring(end.length - 10) === 'T23:59:59Z'
    ) {
      const s = moment(start).format('YYYY-MM-DD');
      const e = moment(end).format('YYYY-MM-DD');
      return { start: s, end: e };
    } else {
      const s = moment(start).format('YYYY-MM-DD HH:mm');
      const e = moment(end).format('YYYY-MM-DD HH:mm');
      return { start: s, end: e };
    }
  };

  const mapTasksToCalendar = () => {
    const mappedTasks = tasks?.map((task) => {
      return {
        id: task.id,
        title: task.description,
        start: mapDate(task.startCalendarDate, task.endCalendarDate).start,
        end: mapDate(task.startCalendarDate, task.endCalendarDate).end,
      };
    });
    setEvents(mappedTasks);
  };

  const changeView = () => {
    setView({ list: true, calendar: false });
  };

  const onSelect = () => {
    setDisplayTaskModal(true);
  };

  const onTaskSelect = (event) => {
    setDisplayTaskModal(true);
    setSelectedCalendarTask(event.event._def.publicId);
  };

  const myCustomButton = {
    text: '',
    icon: 'pi pi-list',
    click: () => changeView(),
  };
  const getToday = () => {
    const day = new Date();
    return moment(day).format('YYYY-MM-DD HH:mm');
  };

  const breadCrumbItems = [{ label: 'Tasks' }];

  useEffect(() => {
    const taskName = events.filter(
      (event) => event.id === selectedCalendarTask
    )[0]?.title;

    if (taskIsUpdated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Task ${taskName} was updated.`,
          life: 5000,
          sticky: null,
        })
      );
      fetchTasks();
      setTaskIsUpdated(false);
    }
  }, [taskIsUpdated]);

  useEffect(() => {
    tasks && mapTasksToCalendar();
  }, [tasks]);

  return (
    <div className="list-generic">
      <BreadCrumb items={breadCrumbItems} />
      <TaskForm
        displayTaskModal={displayTaskModal}
        setDisplayTaskModal={setDisplayTaskModal}
        setSelectedCalendarTask={setSelectedCalendarTask}
        taskId={selectedCalendarTask}
        trials={trials}
        products={products}
        taskManagers={taskManagers}
        taskTypes={taskTypes}
        taskStatuses={taskStatuses}
        setTaskIsUpdated={setTaskIsUpdated}
        initialLocation={initialLocation}
      />
      <FullCalendar
        events={events}
        initialDate={getToday()}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin]}
        customButtons={{ myCustomButton }}
        headerToolbar={{
          left: 'prev,next today myCustomButton',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        editable
        selectable
        selectMirror
        dayMaxEvents
        weekNumbers
        contentHeight="650px"
        selectAllow={onSelect}
        eventClick={(e) => onTaskSelect(e)}
        firstDay={firstDay[firstDayOfWeek]}
        locales={allLocales}
        locale={locales[dateFormat]}
      />
    </div>
  );
};

export default FullCalendarPage;
