import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadCrumb as PrimeBreadCrumb } from 'primereact/breadcrumb';
import styles from './BreadCrumb.module.scss';

const BreadCrumb = (props) => {
  const history = useHistory();
  const home = {
    icon: 'pi pi-home',
    command: () => {
      history.push('/dashboard');
    },
  };

  return (
    <div className={styles.breadCrumb}>
      <PrimeBreadCrumb model={props.items} home={home} />
    </div>
  );
};

export default BreadCrumb;
