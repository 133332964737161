import React from 'react';
import styles from './Outdated.module.scss';

const OutdatedPage = () => {
  return (
    <div className={styles.outdated}>
      <div className={styles.card}>
        <div className={styles.envelope} />
        <h2>Authentication Error</h2>
        <span>Access expired</span>
      </div>
      <div className={styles.hint}>
        Need more help? Please contact the administrator.
      </div>
    </div>
  );
};

export default OutdatedPage;
