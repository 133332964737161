import { all, put } from 'redux-saga/effects';
import * as actions from '../reportFilters/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getReportFiltersSaga(action) {
  yield put(actions.reportFiltersRequest());
  const actionData = action.actionData;
  const parametersData = {
    url: urls.PARAMETERS_PLANT_COMPARISON,
    method: 'POST',
    data: {
      trial: {
        id: {
          is: action.trialId,
        },
      },
    },
  };

  try {
    let [parameters] = yield all([makeRequest(parametersData, actionData)]);

    let weeks = parameters.data.logWeeks.map((week) => ({
      id: week,
      name: week,
    }));
    yield put(
      actions.reportFiltersSuccess(
        parameters.data.plants,
        parameters.data.trialFields,
        weeks
      )
    );
  } catch (error) {
    yield put(actions.reportFiltersFail(error.response.status));
  }
}
