import { urls } from '../Common/ApiServices';

const initialProductParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'tradeName',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
};

const getInitialApplicationsParams = (id) => {
  return {
    query: {
      product: {
        id: {
          is: id,
        },
      },
    },
  };
};

const initialProductInfo = {
  code: '',
  name: '',
  tradeName: '',
  category: null,
  classification: null,
  manufacturer: null,
  note: '',
};
const initialApplicationInfo = {
  code: '',
  quantity: '',
  mixerQuantity: '',
  note: '',
};

const getDeleteProductParams = (id) => {
  return {
    id: {
      in: [id],
    },
  };
};

const getDeleteApplicationParams = (id) => {
  return {
    id: {
      in: [id],
    },
  };
};

const getProductParams = (productId) => ({
  query: {
    product: {
      id: {
        is: productId,
      },
    },
  },
});

const filtersNames = {
  category: 'category',
  classification: 'classification',
  manufacturer: 'manufacturer',
};

const errorFields = {
  code: 'productCode',
  name: 'productName',
  tradeName: 'tradeName',
};

const errorMessages = {
  code: 'Product code should be unique',
  name: 'Product name should be unique',
  tradeName: 'Trade Name should be unique',
};

const errorOptions = {
  INVALID_RANGE: {
    mixerQuantity: 'Mixer quantity must be a positive number ',
    quantity: 'Quantity must be a positive number',
  },
  NOT_PROVIDED: {
    mixerQuantity: 'Mixer quantity is required ',
    quantity: 'Quantity is required',
  },
};

const applicationsErrorFields = {
  code: 'code',
};

const applicationsErrorMessages = {
  code: 'Application code should be unique',
};

const productFields = {
  CODE: 'code',
  TRADE_NAME: 'tradeName',
  CATEGORY_NAME: 'categoryName',
};

const refTableHeaders = {
  [urls.PRODUCT_CATEGORIES]: 'Categories',
  [urls.PRODUCT_CLASSIFICATIONS]: 'Classifications',
  [urls.PRODUCT_MANUFACTURERS]: 'Manufacturers',
  [urls.PRODUCT_MIXERS]: 'Mixers',
  [urls.PRODUCT_APPLICATION_METHODS]: 'Application Methods',
};

export {
  initialProductParams,
  getInitialApplicationsParams,
  initialProductInfo,
  initialApplicationInfo,
  getDeleteProductParams,
  getProductParams,
  getDeleteApplicationParams,
  filtersNames,
  errorFields,
  errorMessages,
  applicationsErrorFields,
  applicationsErrorMessages,
  errorOptions,
  productFields,
  refTableHeaders,
};
