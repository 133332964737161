import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import { urls, useRequest } from '../../../Common/ApiServices';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { popUp } from '../../../Common/globalConstants';
import styles from './TrialFieldList.module.scss';

const TrialFieldList = (props) => {
  const { id } = useParams();
  const initialFieldParams = {
    query: {
      trial: {
        id: {
          in: [id],
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'order',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [fields, setFields] = useState([]);
  const [fieldParams, setFieldParams] = useState(initialFieldParams);
  const [initialFields, setInitialFields] = useState([]);
  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null);
  const [sort, setSort] = useState({ field: 'order', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [deleteFieldDialog, setDeleteFieldDialog] = useState(false);
  const [editing, setEditing] = useState(false);
  const [fieldIsEdited, setFieldIsEdited] = useState(false);
  const [fieldIsDeleted, setFieldIsDeleted] = useState(false);

  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const history = useHistory();

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const { sendRequest } = useRequest({});

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const fetchFields = async () => {
    setLoading(true);
    const requestData = {
      url: urls.SEARCH_TRIAL_FIELDS,
      method: 'POST',
      data: fieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      const initialFields = response.data.results.map((rawField) => ({
        id: rawField.trialField.id,
        name: rawField.trialField.name,
        order: rawField.trialField.order,
        group: rawField?.trialField?.group?.name,
        required: rawField.trialField.required,
        minValue: rawField.trialField.minValue,
        maxValue: rawField.trialField.maxValue,
        multiselect: rawField.trialField.multiselect,
        imageCount: rawField.trialField.imageCount || '',
        label: rawField.trialField.label,
        type:
          rawField.trialField.type[0].toUpperCase() +
          rawField.trialField.type.slice(1).toLowerCase().replace('_', ' '),
        values: rawField.trialField.values
          ? rawField.trialField.values.map((value) => value.name).join(', ')
          : null,
        versionNumber: rawField.trialField.version?.number || 0,
        eventType: rawField.trialField.eventType,
      }));

      setInitialFields(initialFields);
      const copiedFields = cloneDeep(initialFields);
      setFields(copiedFields);
      setTotalRecords(response.data.page.total);
      setFieldIsDeleted(false);
      setFieldToEdit(null);
      setFieldIsEdited(false);
    }
    setLoading(false);
  };

  const patchField = async () => {
    const {
      id,
      label,
      order,
      required,
      imageCount,
      minValue,
      maxValue,
      versionNumber,
    } = fieldToEdit;
    const newFieldParams = {
      id: id,
      label: label,
      required: required,
      minValue: minValue === undefined ? '' : +minValue,
      maxValue: maxValue === undefined ? '' : +maxValue,
      imageCount: imageCount,
      order: order,
      versionNumber: versionNumber,
    };

    const requestData = {
      url: urls.PATCH_TRIAL_FIELD,
      method: 'POST',
      data: newFieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `${fieldToEdit.label} field was updated.`,
          life: 5000,
          sticky: null,
        })
      );
    }
    fetchFields();
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onSelectFields = () => {
    const selectFieldPage = 'select-fields';
    history.push(`/trial/${id}/${selectFieldPage}`);
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || fields;
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          style={{ display: showElements }}
          label="Select Trial Fields"
          icon="pi pi-plus"
          disabled={editing}
          onClick={() => onSelectFields()}
        />
      </div>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage =
          'You don’t have profile fields yet. Please select profile fields for the trial.';
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const checkboxBodyTemplate = (isDisabled, isChecked) => {
    return <Checkbox disabled={isDisabled} checked={isChecked} />;
  };

  const confirmDeleteField = (field) => {
    setFieldToDelete(field);
    setDeleteFieldDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return isTrialTracker ? null : (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          style={{ visibility: editing ? 'hidden' : 'visible' }}
          onClick={() => confirmDeleteField(rowData)}
        />
      </React.Fragment>
    );
  };

  const hideDeleteFieldDialog = () => {
    setDeleteFieldDialog(false);
  };

  const deleteTrialField = async () => {
    setDeleteFieldDialog(false);
    const fieldParams = {
      trialFieldId: {
        in: [fieldToDelete.id],
      },
    };

    const requestData = {
      url: urls.DELETE_TRIAL_FIELDS,
      method: 'POST',
      data: fieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `${fieldToDelete.label} field was deleted from the trial.`,
          life: 5000,
          sticky: null,
        })
      );
      setFieldIsDeleted(true);
    }
  };

  const onRowEditInit = (event) => {
    setFieldToEdit(event.data);
    setEditing(true);
  };

  const onRowEditSave = (event) => {
    patchField(event.data);
    setFieldIsEdited(true);
    setEditing(false);
    setFieldToEdit(null);
  };

  const onRowEditCancel = () => {
    setFieldIsEdited(false);
    const newFields = JSON.parse(JSON.stringify(initialFields));
    setFields([...newFields]);
    setEditing(false);
  };

  const onEditorValueChange = (fieldKey, props, value) => {
    let updatedFields = [...props.value];
    updatedFields[props.rowIndex][props.field] = value;
    setFields(updatedFields);
  };

  const inputTextEditor = (fieldKey, props, field) => {
    if (
      (!['Integer', 'Number'].includes(props.rowData.type) &&
        ['minValue', 'maxValue'].includes(field)) ||
      (props.rowData.type !== 'Image' && field === 'imageCount')
    ) {
      return null;
    }
    let defaultWidth = '66px';
    if (field === 'label') defaultWidth = '230px';
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        style={{ width: defaultWidth }}
        onChange={(e) => onEditorValueChange(fieldKey, props, e.target.value)}
      />
    );
  };

  const checkboxEditor = (fieldKey, props) => {
    return (
      <div className={styles.checkboxEditor}>
        <Checkbox
          tooltip={
            props.rowData.eventType === 'EVENT'
              ? 'Disabled for field type "Event"'
              : ''
          }
          disabled={props.rowData.eventType === 'EVENT'}
          checked={props.rowData.required}
          onChange={(e) => onEditorValueChange(fieldKey, props, e.checked)}
        />
      </div>
    );
  };

  const deleteFieldsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteFieldDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteTrialField}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName') || props.location.state,
      command: () => {
        history.push(`/trial/${id}`);
      },
    },
    { label: 'Trial Fields' },
  ];

  useEffect(() => {
    if (sort) {
      setFieldParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    setFieldParams((oldParams) => {
      let params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    !loading && fetchFields();
  }, [fieldParams]);

  useEffect(() => {
    if (fieldIsDeleted) {
      fetchFields();
      setEditing(false);
    }
  }, [fieldIsDeleted]);

  useEffect(() => {
    if (fieldToEdit) {
      patchField();
    }
  }, [fieldIsEdited]);

  return (
    <div className={`${styles.trialFieldList} list-generic`}>
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className="table-generic p-datatable-sm"
        reorderableColumns
        value={fields}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        scrollable
        emptyMessage=""
        editMode="row"
        frozenWidth="0px"
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
        onRowEditInit={onRowEditInit}
      >
        <Column
          className="p-text-nowrap p-text-truncate"
          field="label"
          editor={(props) => inputTextEditor('fields', props, 'label')}
          columnKey="label"
          header="Display Name"
          headerStyle={{ width: '250px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable={!editing}
          sortFunction={sortFunc}
        />
        <Column
          field="group"
          columnKey="group"
          header="Group"
          headerStyle={{ width: '120px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable={!editing}
          sortFunction={sortFunc}
        />
        <Column
          field="type"
          columnKey="type"
          header="Data Type"
          headerStyle={{ width: '120px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable={!editing}
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="values"
          sortField="values"
          columnKey="values"
          header="List Values"
          headerStyle={{ width: '120px' }}
        />
        <Column
          field="multiselect"
          columnKey="multiselect"
          header="Multi Select"
          body={(rowData) => checkboxBodyTemplate(true, rowData.multiselect)}
          headerStyle={{ width: '100px' }}
        />
        <Column
          field="required"
          columnKey="required"
          header="Required"
          body={(rowData) => checkboxBodyTemplate(true, rowData.required)}
          editor={(props) => checkboxEditor('fields', props, 'required')}
          headerStyle={{ width: '80px' }}
        />
        <Column
          field="minValue"
          columnKey="minValue"
          header="Min Value"
          editor={(props) => inputTextEditor('fields', props, 'minValue')}
          headerStyle={{ width: '90px' }}
        />
        <Column
          field="maxValue"
          columnKey="maxValue"
          header="Max Value"
          editor={(props) => inputTextEditor('fields', props, 'maxValue')}
          headerStyle={{ width: '90px' }}
        />
        <Column
          field="imageCount"
          columnKey="imageCount"
          header="Photo Qty"
          editor={(props) => inputTextEditor('fields', props, 'imageCount')}
          headerStyle={{ width: '90px' }}
        />
        <Column
          field="order"
          columnKey="order"
          header="Order"
          editor={(props) => inputTextEditor('fields', props, 'order')}
          headerStyle={{ width: '80px' }}
          sortable={!editing}
          sortFunction={sortFunc}
        />
        <Column
          className={editing ? 'rowEditor-editButton-hidden ' : ''}
          header="Edit"
          headerStyle={{ width: '80px' }}
          rowEditor={!isTrialTracker}
        />
        <Column
          field="remove"
          header="Remove"
          body={actionBodyTemplate}
          headerStyle={{ width: '80px' }}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={deleteFieldDialog}
        className="confirmDialog"
        style={{ width: '450px' }}
        header="Delete Confirmation"
        modal
        footer={deleteFieldsDialogFooter}
        onHide={hideDeleteFieldDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {fieldToDelete && (
            <span>
              Are you sure you want to delete <b>{fieldToDelete.label}</b> field
              from the trial?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default withRouter(TrialFieldList);
