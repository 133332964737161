import React, { useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import classNames from 'classnames';
import BackDrop from '../BackDrop/BackDrop';
import styles from './ZoomGallery.module.scss';

const ZoomGallery = ({ close, images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className={styles.zoomGallery}>
      <BackDrop close={close} />
      <div className={styles.galleryContainer}>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: '',
              isFluidWidth: true,
              src: selectedImage.img,
            },
            largeImage: {
              src: selectedImage.img,
              width: 1800,
              height: 1800,
            },
            isHintEnabled: true,
            enlargedImagePosition: 'over',
            enlargedImageContainerDimensions: {
              width: '600px',
              height: '600px',
            },
          }}
        />
        <div className={styles.imageMenu}>
          {images.map((image) => {
            return (
              <div
                className={classNames(
                  styles.imageItem,
                  image.imgId === selectedImage.imgId && styles.active
                )}
                key={image.imgId}
                onClick={() => setSelectedImage(image)}
              >
                <img src={image.imgTmbn} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ZoomGallery;
