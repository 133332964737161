import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useAuth0 } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import {
  initialLocationFormSchema,
  locationFormSchema,
} from './locationFormValidation';
import { isAuth } from '../../../auth/auth-service';
import { getTaskOptions } from '../../../reduxStore/taskOptions/actions';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import PromptIfDirty from '../../Common/PromptIfDirty';
import countries from 'iso3166-2-db/i18n/dispute/UN/en';
import { isEmpty } from 'lodash';
import { getFilteredCountries } from '../../Common/utils';
import { firstDaysOfWeek } from '../constants';
import { urls, useRequest } from '../../Common/ApiServices';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import { popUp } from '../../Common/globalConstants';
import styles from './LocationForm.module.scss';

const LocationForm = ({ setLocationName }) => {
  const { id } = useParams();
  const locationParams = {
    query: {
      location: {
        id: {
          is: id,
        },
      },
    },
  };

  const initialLocationInfo = {
    name: '',
    type: null,
    firstDayOfWeek: null,
    address: '',
    address2: '',
    city: '',
    state: null,
    postalCode: '',
    country: null,
    primaryPhone: '',
    description: '',
  };

  const [locationInfo, setLocationInfo] = useState(initialLocationInfo);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState({});

  localStorage.setItem('locationName', locationInfo.name);
  localStorage.setItem('locationId', locationInfo.id);

  const { logout, getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  useEffect(() => {
    !isEmpty(filteredCountries) && getLocationInfo();
  }, [filteredCountries]);

  useEffect(() => {
    isUpdated && getLocationInfo();
  }, [isUpdated]);

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = filteredCountries[selectedCountry.code].regions
        .map((region) => ({ name: region.name, code: region.iso }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      setStates(countryStates);
      formik.values.state = countryStates[0];
    }
  }, [selectedCountry]);

  useEffect(() => {
    setFilteredCountries(getFilteredCountries(countries));
  }, []);

  const getLocationInfo = async () => {
    const requestData = {
      url: urls.EXTRACT_LOCATION,
      method: 'POST',
      data: locationParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      let dataLocation = response.data.location;
      const countryCode = dataLocation.countryId;
      const countryName = filteredCountries[countryCode].name;
      dataLocation.country = { name: countryName, code: countryCode };
      const stateCode = dataLocation.stateId;
      const states = filteredCountries[countryCode].regions.filter(
        (region) => region.iso === stateCode
      );
      const stateName = states && states[0]?.name;
      dataLocation.state = { name: stateName, code: stateCode };
      dataLocation.type = { name: dataLocation.type };

      setIsUpdated(false);
      setLocationInfo(dataLocation);
      setLocationName(dataLocation.name);
      setSelectedCountry(dataLocation.country);
    }
  };

  const countryList = Object.entries(filteredCountries)
    .map((country) => ({ code: country[0], name: country[1].name }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const locationTypes = [{ name: 'GROWER' }, { name: 'BREEDER' }];

  const errorCodes = {
    NOT_UNIQUE: 'Location Name should be unique.',
    NOT_PROVIDED: 'This field should be provided.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
    INVALID_FIELD_FORMAT: 'Invalid field format',
  };

  const accept = () => {
    formik.handleReset();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const formik = useFormik({
    initialValues: initialLocationFormSchema(locationInfo),
    enableReinitialize: true,
    validationSchema: locationFormSchema,
    onSubmit: async (values) => {
      const newLocationParams = {
        id: id,
        name: values.name,
        type: values.type.name,
        address: values.address,
        address2: values.address2 || '',
        city: values.city,
        countryId: values.country.code,
        stateId: values.state.code,
        postalCode: values.postalCode,
        primaryPhone: values.primaryPhone || null,
        firstDayOfWeek: values.firstDay.day,
        description: values.description || '',
        versionNumber: locationInfo.version?.number || 0,
      };
      const requestData = {
        url: urls.UPDATE_LOCATION,
        method: 'POST',
        data: newLocationParams,
      };

      const response = await sendRequest(requestData);

      if (response) {
        setIsUpdated(true);
        dispatch(getTaskOptions(actionData));
        dispatch(
          setPopUp({
            severity: popUp.severities.SUCCESS,
            summary: popUp.summary.SUCCESSFUL,
            detail: 'Location was updated.',
            life: 5000,
            sticky: null,
          })
        );
      }
    },
  });

  const onCountryChange = (event) => {
    formik.setFieldValue('country', event.value);
    setSelectedCountry(event.value);
  };

  const breadCrumbItems = [
    {
      label: 'Locations',
      command: () => {
        history.push('/locations');
      },
    },
    { label: locationInfo.name },
  ];

  useEffect(() => {
    if (error && error.response.data.errors) {
      error.response.data.errors.forEach((err) =>
        formik.setFieldError(err.fieldName, errorCodes[err.code])
      );
    }
  }, [error]);

  return (
    <div className={styles.locationInfo}>
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={formik.dirty} />
      <div className={styles.locationForm}>
        <h3>Location Information</h3>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="name"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Name*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                />
                {formik.touched.name && formik.errors.name && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="address"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Address Line 1*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  aria-describedby="address-invalid"
                  className={formik.errors.address ? 'p-invalid' : null}
                />
                {formik.touched.address && formik.errors.address && (
                  <small id="address-invalid" className="p-error p-d-block">
                    {formik.errors.address}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="address2" className="p-col-12 p-md-3 p-xl-2">
                Address Line 2
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="address2"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address2}
                  aria-describedby="address2-invalid"
                  className={formik.errors.address2 ? 'p-invalid' : null}
                />
                {formik.touched.address2 && formik.errors.address2 && (
                  <small id="address2-invalid" className="p-error p-d-block">
                    {formik.errors.address2}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="city"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                City*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  aria-describedby="city-invalid"
                  className={formik.errors.city ? 'p-invalid' : null}
                />
                {formik.touched.city && formik.errors.city && (
                  <small id="city-invalid" className="p-error p-d-block">
                    {formik.errors.city}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="country"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Country*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="country"
                  onChange={onCountryChange}
                  value={formik.values.country}
                  options={countryList}
                  optionLabel="name"
                  filter
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="state"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                State/Province*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  options={states}
                  optionLabel="name"
                  filter
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="postalCode"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Postal Code*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="postalCode"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                  aria-describedby="postalCode-invalid"
                  className={formik.errors.postalCode ? 'p-invalid' : null}
                />
                {formik.touched.postalCode && formik.errors.postalCode && (
                  <small id="postalCode-invalid" className="p-error p-d-block">
                    {formik.errors.postalCode}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="type"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Location Type*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  options={locationTypes}
                  optionLabel="name"
                />
                {formik.touched.type && formik.errors.type && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik.errors.type}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="primaryPhone" className="p-col-12 p-md-3 p-xl-2">
                Primary Phone
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputMask
                  className={formik.errors.primaryPhone ? 'p-invalid' : null}
                  id="primaryPhone"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                  value={formik.values.primaryPhone}
                  onChange={formik.handleChange}
                  aria-describedby="primaryPhone-invalid"
                />
                {formik.errors.primaryPhone ? (
                  <small
                    id="primaryPhone-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.primaryPhone}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="firstDay"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                First Day of Week*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="firstDay"
                  onChange={formik.handleChange}
                  value={formik.values.firstDay}
                  options={firstDaysOfWeek}
                  optionLabel="name"
                />
                {formik.touched.firstDay && formik.errors.firstDay && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik.errors.firstDay}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3 p-xl-2">
                Description
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputTextarea
                  className={formik.errors.description ? 'p-invalid' : null}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-lg-10 p-xl-7 p-justify-end pad-r-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                !formik.dirty ||
                  formik.errors.city ||
                  formik.errors.state ||
                  formik.errors.postalCode ||
                  formik.errors.country ||
                  formik.errors.type ||
                  formik.errors.primaryPhone ||
                  formik.errors.description ||
                  formik.errors.address ||
                  formik.errors.address2 ||
                  (formik.values.name === locationInfo.name &&
                    formik.values.type.name === locationInfo.type.name &&
                    formik.values.address === locationInfo.address &&
                    formik.values.address2 === locationInfo.address2 &&
                    formik.values.city === locationInfo.city &&
                    formik.values.state.name === locationInfo.state.name &&
                    formik.values.postalCode === locationInfo.postalCode &&
                    formik.values.firstDay.name ===
                      locationInfo.firstDayOfWeek.day &&
                    formik.values.country.name === locationInfo.country.name &&
                    formik.values.primaryPhone?.replace(/\D/g, '') ===
                      locationInfo.primaryPhone?.replace(/\D/g, '') &&
                    formik.values.description === locationInfo.description)
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              disabled={Boolean(
                !formik.dirty ||
                  formik.errors.city ||
                  formik.errors.state ||
                  formik.errors.postalCode ||
                  formik.errors.country ||
                  formik.errors.type ||
                  formik.errors.primaryPhone ||
                  formik.errors.description ||
                  formik.errors.address ||
                  formik.errors.address2 ||
                  (formik.values.name === locationInfo.name &&
                    formik.values.type.name === locationInfo.type.name &&
                    formik.values.address === locationInfo.address &&
                    formik.values.address2 === locationInfo.address2 &&
                    formik.values.city === locationInfo.city &&
                    formik.values.state.name === locationInfo.state.name &&
                    formik.values.postalCode === locationInfo.postalCode &&
                    formik.values.firstDay.name ===
                      locationInfo.firstDayOfWeek.day &&
                    formik.values.country.name === locationInfo.country.name &&
                    formik.values.primaryPhone?.replace(/\D/g, '') ===
                      locationInfo.primaryPhone?.replace(/\D/g, '') &&
                    formik.values.description === locationInfo.description)
              )}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(LocationForm);
