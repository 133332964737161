import React from 'react';
import { Button } from 'primereact/button';
import styles from './FilterControls.module.scss';

const FilterControls = (props) => {
  return (
    <div className={styles.filterControls}>
      <div className={styles.labelText}>Filters</div>
      <div className={styles.filterButtons}>
        <Button
          label="Apply"
          className="p-button-raised"
          onClick={props.applyFilters}
        />
        <Button
          label="Clear All"
          className="p-button-secondary p-button-raised"
          onClick={props.resetFilters}
        />
      </div>
    </div>
  );
};

export default FilterControls;
