import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { links } from '../../Common/globalConstants';
import { types } from '../constants';
import styles from './DashboardItem.module.scss';

function DashboardItem({
  id,
  firstName,
  lastName,
  name,
  startDate,
  region,
  description,
  dueDate,
  panelType,
  onTaskSelect,
}) {
  if (panelType === types.TASKS) {
    return (
      <div
        id={id}
        className={classNames(styles.dashboardItem)}
        onClick={(e) => onTaskSelect(e, id)}
      >
        <div className={styles.taskWrapper}>
          <div className={styles.taskTitle}>
            <div className={styles.taskLink}>{description}</div>
            <div
              className={classNames('p-component', styles.taskManager)}
            >{`${firstName} ${lastName}`}</div>
          </div>
          <div className={styles.taskContent}>
            <div className="p-component">{dueDate}</div>
          </div>
        </div>
      </div>
    );
  } else if (panelType === types.LOCATIONS) {
    return (
      <div className={classNames(styles.dashboardItem)}>
        <Link to={`${links.LOCATION}/${id}`}>
          <div className={styles.itemContent}>
            <div className={`p-component ${styles.name}`}>{name}</div>
            <div className={`p-component ${styles.region}`}>{region}</div>
          </div>
        </Link>
      </div>
    );
  } else if (panelType === types.TRIALS) {
    return (
      <div className={classNames(styles.dashboardItem)}>
        <Link to={{ pathname: `${links.TRIAL}/${id}`, state: name }}>
          <div className={styles.itemContent}>
            <div className={`p-component ${styles.name}`}>{name}</div>
            <div className={`p-component ${styles.region}`}>{startDate}</div>
          </div>
        </Link>
      </div>
    );
  }
}

export default DashboardItem;
