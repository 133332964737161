import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { urls, useRequest } from '../Common/ApiServices';
import { links, popUp } from '../Common/globalConstants';
import { types } from './constants';
import { getFormattedDate } from '../Common/utils';
import DashboardItem from './DashboardItem/DashboardItem';
import TaskForm from '../Tasks/TaskForm/TaskForm';
import { getTopTasksParams, initialLocationParams } from './constants';
import { dateFormat, userRoles } from '../Common/globalConstants';
import { setPopUp } from '../../reduxStore/popUp/actions';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const defaultLocation = useSelector(
    (state) => state.taskOptions.defaultLocation
  );

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recentlyVisited, setRecentlyVisited] = useState([]);
  const [topTasks, setTopTasks] = useState([]);
  const [displayTaskModal, setDisplayTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskIsCreated, setTaskIsCreated] = useState(false);
  const [taskIsUpdated, setTaskIsUpdated] = useState(false);

  const dispatch = useDispatch();

  const { sendRequest } = useRequest({});

  const options = useSelector((state) => state.taskOptions.locations);
  const permissions = useSelector((state) => state.permissions.permissions);
  const subscription = useSelector((state) => state.subscription);
  const trials = useSelector((state) => state.taskOptions.trials);
  const taskTypes = useSelector((state) => state.taskOptions.types);
  const products = useSelector((state) => state.taskOptions.products);
  const taskStatuses = useSelector((state) => state.taskOptions.statuses);
  const taskManagers = useSelector((state) => state.taskOptions.employees);

  const isAdmin = permissions.includes(userRoles.COMPANY_MANAGE);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const isActiveSubscription = useSelector(
    (state) => state.isActiveSubscription.isActive
  );

  const onLocationChange = (e) => {
    setSelectedLocation(e.value);
  };

  const onNewTaskCreate = () => {
    setDisplayTaskModal(true);
  };

  const onTaskSelect = (e, id) => {
    e.preventDefault();
    const selectedTask = topTasks.find((task) => {
      return task.task.id === id;
    });
    setSelectedTask(selectedTask.task);
    setDisplayTaskModal(true);
  };

  const showCalendar = { calendar: true, list: false };
  const onlyThisWeek = { showThisWeek: true };

  const getRecentlyVisitedTrials = async () => {
    const requestData = {
      url: urls.RECENTLY_VISITED_TRIALS,
      method: 'POST',
      data: {},
    };
    setLoading(true);
    const response = await sendRequest(requestData);

    const trials = response.data.trials.map((trial) => {
      return {
        ...trial,
        formattedStartDate: getFormattedDate(
          trial.startDate,
          dateFormat[trial.dateFormat.datePattern]
        ),
      };
    });
    setRecentlyVisited(trials);
    setLoading(false);

    return response;
  };

  const getTopTasks = async (id) => {
    const requestData = {
      url: urls.SEARCH_DASHBOARD_TASKS,
      method: 'POST',
      data: getTopTasksParams(id),
    };
    const response = await sendRequest(requestData);
    if (response) {
      const tasks = response.data.results.map((task) => {
        return {
          ...task,
          formattedStartDate: getFormattedDate(
            task.task.startDate,
            dateFormat[task.task.dateFormat.datePattern]
          ),
          formattedEndDate: getFormattedDate(
            task.task.endDate,
            dateFormat[task.task.dateFormat.datePattern]
          ),
        };
      });
      setTopTasks(tasks);
    }
    setLoading(false);

    return response;
  };

  const getLocations = async () => {
    const requestData = {
      url: urls.BROWSE_LOCATIONS,
      method: 'POST',
      data: initialLocationParams,
    };

    const response = await sendRequest(requestData);

    setLocations(response.data.results);
    setLoading(false);

    return response;
  };

  const taskHeader = (
    <>
      <div className={styles.taskHeader}>
        <div className={styles.headerTop}>
          <Link
            to={{ pathname: links.TASKS, state: onlyThisWeek }}
            className={classNames(
              styles.leftHeader,
              !isActiveSubscription && styles.noClickableCalendar
            )}
          >
            <div>Tasks</div>
          </Link>
          <div className={styles.rightHeader}>
            <Button
              className={classNames(
                'p-button-raised',
                isTrialTracker && styles.hideElement
              )}
              label="New Task"
              icon="pi pi-plus"
              onClick={() => onNewTaskCreate()}
              disabled={!isActiveSubscription}
            />
          </div>
        </div>
        <div className={styles.headerBottom}>
          <div
            className={classNames(
              styles.dropdownWrapper,
              loading && styles.loading
            )}
          >
            <Dropdown
              value={selectedLocation}
              options={options || []}
              onChange={onLocationChange}
              optionLabel="name"
              placeholder="Select Location"
              disabled={!isActiveSubscription}
            />
          </div>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (!isEmpty(subscription)) {
      isActiveSubscription && getRecentlyVisitedTrials();
      isActiveSubscription &&
        permissions.includes(userRoles.PPT_MANAGE_COMPANY) &&
        getLocations();
    }
  }, [subscription]);

  useEffect(() => {
    isActiveSubscription &&
      selectedLocation?.id &&
      getTopTasks(selectedLocation.id);
  }, [selectedLocation]);

  useEffect(() => {
    if (taskIsCreated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New task was created.',
          life: 5000,
          sticky: null,
        })
      );
      getTopTasks(selectedLocation.id);
      setTaskIsCreated(false);
    } else if (taskIsUpdated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Task ${selectedTask.description} was updated.`,
          life: 5000,
          sticky: null,
        })
      );
      getTopTasks(selectedLocation.id);
      setTaskIsUpdated(false);
    }
  }, [taskIsCreated, taskIsUpdated]);

  useEffect(() => {
    if (defaultLocation.id) {
      setSelectedLocation(defaultLocation);
    }
  }, [defaultLocation]);

  return (
    <div className={styles.dashboard}>
      {displayTaskModal && (
        <TaskForm
          taskId={selectedTask?.id || null}
          displayTaskModal={displayTaskModal}
          taskIsCreated={taskIsCreated}
          trials={trials}
          products={products}
          taskTypes={taskTypes}
          taskManagers={taskManagers}
          taskStatuses={taskStatuses}
          setDisplayTaskModal={setDisplayTaskModal}
          setTaskIsCreated={setTaskIsCreated}
          setTaskIsUpdated={setTaskIsUpdated}
          setSelectedTask={setSelectedTask}
          initialLocation={selectedLocation}
        />
      )}
      <div className={styles.dashboardContent}>
        {loading && isActiveSubscription && (
          <div className="p-datatable-loading-overlay p-component-overlay">
            <i
              className={classNames(
                'p-datatable-loading-icon pi-spin pi pi-spinner',
                styles.spinnerStyle
              )}
            />
          </div>
        )}
        <div className={styles.left}>
          {permissions.includes(userRoles.PPT_MANAGE_COMPANY) && (
            <Panel header="Locations" className={styles.panel}>
              {locations.length ? (
                <>
                  <div className={styles.caption}>
                    <span>Name</span>
                    <span>Region</span>
                  </div>
                  {locations.map((item) => {
                    return (
                      <DashboardItem
                        key={item.location.id}
                        id={item.location.id}
                        panelType={types.LOCATIONS}
                        name={item.location.name}
                        region={`${item.location.city}, ${item.location.stateId} ${item.location.countryId}`}
                      />
                    );
                  })}
                  <div
                    className={classNames(
                      styles.panelFooter,
                      !isActiveSubscription && styles.noClickable
                    )}
                  >
                    <Link to={links.LOCATIONS}>More</Link>
                  </div>
                </>
              ) : (
                <div className={styles.alert}>
                  You don’t have locations yet. Please create a location.
                </div>
              )}
            </Panel>
          )}
          <Panel header="Trials" className={styles.panel}>
            {recentlyVisited.length ? (
              <>
                <div className={styles.caption}>
                  <span>Name</span>
                  <span>Start Date</span>
                </div>
                {recentlyVisited.map((trial) => {
                  return (
                    <DashboardItem
                      key={trial.id}
                      id={trial.id}
                      panelType={types.TRIALS}
                      isAdmin={isAdmin}
                      name={trial.name}
                      startDate={trial.formattedStartDate}
                    />
                  );
                })}
                <div
                  className={classNames(
                    styles.panelFooter,
                    !isActiveSubscription && styles.noClickable
                  )}
                >
                  <Link to={links.TRIALS}>More</Link>
                </div>
              </>
            ) : (
              <div className={styles.alert}>
                You don’t have recently visited trials yet.
              </div>
            )}
          </Panel>
        </div>
        <div className={styles.right}>
          <Panel
            className={classNames(
              styles.panel,
              styles.calendarPanel,
              !isActiveSubscription && styles.noClickableCalendar
            )}
            icon="pi pi-check"
            iconPos="left"
          >
            <Link to={{ pathname: links.TASKS, state: { showCalendar } }}>
              <div className={styles.calendarTitleBlock}>
                <span
                  className={classNames(
                    'pi pi-calendar',
                    styles.calendarIcons,
                    !isActiveSubscription && styles.opacityIcons
                  )}
                />
                <span className={styles.calendarTitle}>Calendar</span>
              </div>
              <span
                className={classNames(
                  'pi pi-caret-right',
                  styles.calendarIcons,
                  !isActiveSubscription && styles.opacityIcons
                )}
              />
            </Link>
          </Panel>
          <Panel
            headerTemplate={taskHeader}
            className={classNames(styles.panel)}
          >
            {topTasks?.length ? (
              <>
                <div className={styles.caption}>
                  <span>Description</span>
                  <span>End Date</span>
                </div>
                {topTasks.map((task) => {
                  return (
                    <DashboardItem
                      key={task.task.id}
                      id={task.task.id}
                      panelType={types.TASKS}
                      description={task.task.description}
                      firstName={
                        task.taskManager?.firstName
                          ? task.taskManager?.firstName
                          : ''
                      }
                      lastName={
                        task.taskManager?.lastName
                          ? task.taskManager?.lastName
                          : ''
                      }
                      dueDate={task.formattedEndDate}
                      isAdmin={isAdmin}
                      onTaskSelect={onTaskSelect}
                    />
                  );
                })}
              </>
            ) : (
              <div className={styles.alert}>
                You don’t have tasks yet. Please create a task.
              </div>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
