import * as actionTypes from './actions';

const initialState = {
  permissions: '',
};

export const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.join(', '),
      };
    default:
      return state;
  }
};
