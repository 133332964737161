import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { isAuth } from '../../auth/auth-service';
import classNames from 'classnames';
import { Button } from 'primereact/button';
//ToDo: uncomment after conference
// import { useLottie } from 'lottie-react';
// import TrialComposition from './TrialsComposition.json';
import BlockedUserDialog from './BlockedUserDialog/BlockedUserDialog';
import { links } from '../Common/globalConstants';
import styles from './LandingPage.module.scss';

const LandingPage = ({ blockedUser = false }) => {
  const [showDialog, setShowDialog] = useState(blockedUser);

  const history = useHistory();
  //ToDo: uncomment after conference
  // const options = {
  //   animationData: TrialComposition,
  //   loop: true,
  // };
  const { loginWithRedirect } = useAuth0();
  //ToDo: uncomment after conference
  // const { View } = useLottie(options);
  return (
    <main className={styles.landingPage}>
      {blockedUser && (
        <BlockedUserDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
        />
      )}
      <section className={classNames(styles.ultimate)}>
        {/*{View}*/}
        <h1 className={styles.ultimateMobileHeader}>
          The <span className={styles.golden}>Ultimate</span>
          <br /> Plant Performance Tracker
        </h1>
        <div className={styles.ultimateWrapper}>
          <div className={styles.content}>
            <h1 className={styles.ultimateHeader}>
              The <span className={styles.golden}>Ultimate</span> Plant
              Performance Tracker
            </h1>
            <p className={classNames(styles.plainText, styles.ultimateText)}>
              Plant Partner Trials is the easy track and share solution for
              managing your plant trials.
            </p>
            <div className={styles.buttonsGroup}>
              <>
                <Button
                  className={classNames(styles.demo, styles.greenButton)}
                  label="Request a Demo"
                  onClick={() => history.push(links.CONTACT_US)}
                />
                {!isAuth() && (
                  <Button
                    className={styles.freeTrial}
                    label="Start a Free Trial"
                    onClick={() => history.push(links.SIGNUP)}
                  />
                )}
              </>
            </div>
          </div>
        </div>
        <div className={styles.mobileContent}>
          <h4>
            Plant Partner Trials is the easy track and share solution for
            managing your plant trials.
          </h4>
          <div className={styles.mobileButtonsGroup}>
            <Button
              className={classNames(styles.demo, styles.greenButton)}
              label="Request a Demo"
              onClick={() => history.push(links.CONTACT_US)}
            />
            {!isAuth() && (
              <Button
                className={styles.freeTrial}
                label="Start a Free Trial"
                onClick={() => history.push(links.SIGNUP)}
              />
            )}
          </div>
        </div>
      </section>
      <section className={classNames(styles.benefits, styles.sectionPadding)}>
        <h4>Trial Tracking Designed for Growers</h4>
        <div className={classNames(styles.benefitsInfo, styles.plainText)}>
          When including a new variety into your program mix, trying out a new
          chemical or growing method or managing formal trials for breeders,
          tracking plant performance is essential. Plant Partner Trials provides
          an easy, efficient way to track, view and share all the data and
          images needed to evaluate performance. Capture all the data and images
          you need with the mobile app and review with your trial partners
          online in real time.
        </div>
        <div className={styles.grid}>
          <div className={styles.gridTr}>
            <div className={styles.gridTd}>
              <div className={classNames(styles.item, styles.plainText)}>
                Different trials need different data. Plant Partner Trials can
                be customized to match the requirements for each trial.
              </div>
            </div>
            <div className={styles.gridTd}>
              <div className={classNames(styles.item, styles.plainText)}>
                Plant Partner Trials provides all the tools you need to manage
                your trials. It&apos;s easy to track plants, growing
                instructions, crops, locations, tasks, chemicals and
                performance.
              </div>
            </div>
          </div>
          <div className={styles.gridTr}>
            <div className={styles.gridTd}>
              <div className={classNames(styles.item, styles.plainText)}>
                With Plant Partner Trials, you can track all your trials in a
                single application. Everything is centrally located for quick
                turnaround when sharing results with your breeder partners.
              </div>
            </div>
            <div className={styles.gridTd}>
              <div className={classNames(styles.item, styles.plainText)}>
                Visualize performance with images, charts and graphs to
                understand correlations between growing methods and performance.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classNames(styles.rings, styles.sectionPadding)}>
        <h4>Easy, Accurate Plant Trial Tracking Every Time</h4>
        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <div className={classNames(styles.setup, styles.square)}>
              <div
                className={classNames(styles.setupHeader, styles.ringHeader)}
              >
                Setup Trials
              </div>
            </div>
            <p className={styles.plainText}>
              It&apos;s easy to import your plant catalog and setup your
              chemicals. Configure your trials to track the exact data and
              images you need.
            </p>
          </div>
          <div className={styles.card}>
            <div className={classNames(styles.crops, styles.square)}>
              <div
                className={classNames(styles.cropsHeader, styles.ringHeader)}
              >
                Plant Crops
              </div>
            </div>
            <p className={styles.plainText}>
              Enter your crops and print crop labels for easy scanning. Schedule
              and track maintenance tasks and chemical applications.
            </p>
          </div>
          <div className={styles.card}>
            <div className={classNames(styles.mobileApp, styles.square)}>
              <div
                className={classNames(
                  styles.mobileAppHeader,
                  styles.ringHeader
                )}
              >
                Mobile Tracking
              </div>
            </div>
            <p className={styles.plainText}>
              The easy mobile app lets trackers scan crop labels and enter
              required data and images accurately every time.
            </p>
          </div>
          <div className={styles.card}>
            <div className={classNames(styles.visualisation, styles.square)}>
              <div
                className={classNames(styles.visualHeader, styles.ringHeader)}
              >
                Visualize results
              </div>
            </div>
            <p className={styles.plainText}>
              Easily view weekly data and images on line and visualize
              performance charts.
            </p>
          </div>
          <div className={styles.card}>
            <div className={classNames(styles.share, styles.square)}>
              <div
                className={classNames(styles.shareHeader, styles.ringHeader)}
              >
                Share
              </div>
            </div>
            <p className={styles.plainText}>
              Share the results with your growing team and breeding partners in
              real time throughout the season.
            </p>
          </div>
        </div>
      </section>
      <section className={classNames(styles.mobile, styles.sectionPadding)}>
        <h4>Integrated Mobile App</h4>
        <div className={styles.mobileWrapper}>
          <div className={styles.mobileOuter}>
            <div className={styles.mobileInner} />
          </div>
          <div className={styles.mobileInfo}>
            <p className={classNames(styles.mobileText, styles.plainText)}>
              One of the challenges to effective trial tracking is making sure
              the data and images you need are captured accurately and
              consistently. The integrated Plant Partner Trials mobile app
              provides a process that is quick and easy for employees to learn
              and use consistently every time.
            </p>
            <ul className={styles.mobileList}>
              <li className={classNames(styles.plainText, styles.listItem)}>
                Every crop gets a barcoded crop label.
              </li>
              <li className={classNames(styles.plainText, styles.listItem)}>
                The mobile app is quick to learn. Trial trackers scan the crop
                code and the app walks them through recording the exact data and
                images required for that specific trial.
              </li>
              <li className={classNames(styles.plainText, styles.listItem)}>
                All data and images are saved online immediately as the tracker
                records for each crop. The mobile app requires data and images
                to be complete before saving.
              </li>
              <li className={classNames(styles.plainText, styles.listItem)}>
                The mobile app can be used off line for remote areas.
              </li>
              <li className={classNames(styles.plainText, styles.listItem)}>
                The mobile app displays crop histories and chemical application
                schedules as well as maintenance tasks and status.
              </li>
            </ul>
          </div>
        </div>
        <ul className={styles.mobileListSmall}>
          <li className={classNames(styles.plainText, styles.listItem)}>
            Every crop gets a barcoded crop label.
          </li>
          <li className={classNames(styles.plainText, styles.listItem)}>
            The mobile app is quick to learn. Trial trackers scan the crop code
            and the app walks them through recording the exact data and images
            required for that specific trial.
          </li>
          <li className={classNames(styles.plainText, styles.listItem)}>
            All data and images are saved online immediately as the tracker
            records for each crop. The mobile app requires data and images to be
            complete before saving.
          </li>
          <li className={classNames(styles.plainText, styles.listItem)}>
            The mobile app can be used off line for remote areas.
          </li>
          <li className={classNames(styles.plainText, styles.listItem)}>
            The mobile app displays crop histories and chemical application
            schedules as well as maintenance tasks and status.
          </li>
        </ul>
      </section>
      <section className={classNames(styles.visualize, styles.sectionPadding)}>
        <h4>Visualize and Share Results in Real Time</h4>
        <div className={styles.visualizeWrapper}>
          <div className={styles.visualizeInfo}>
            <p className={styles.plainText}>
              With Plant Partner Trials you have immediate access to a variety
              of data views. It&apos;s easy to identify trends and outliers
              throughout the season. Crop histories are easily accessed on the
              mobile app.
            </p>
            <p className={styles.plainText}>
              Invite your breeder partners to view their trial data and photos
              as you progress and provide instant feedback.
            </p>
          </div>
          <div className={styles.visualizeLogo} />
        </div>
      </section>
      <section className={classNames(styles.community, styles.sectionPadding)}>
        <h4>The Plant Partner Growing Community</h4>
        <div className={styles.communityWrapper}>
          <div className={styles.communityOuter}>
            <div className={styles.communityInner} />
          </div>
          <div className={styles.communityInfo}>
            <p className={styles.plainText}>
              Plant Partner Trials is one of many software solutions for the
              industry powered by Starcom. With forty years of experience
              developing ERP systems and related technology for growers, Plant
              Partner solutions are the right fit for your growing business.
            </p>
            <p className={styles.plainText}>
              Whether you are a manufacturer, breeder or grower, Plant Partner
              Trials provides the essential tools and support to collaborate for
              optimum plant performance.
            </p>
            <Button
              className={styles.greenButton}
              label="Contact Us"
              onClick={() => history.push(links.CONTACT_US)}
            />
          </div>
        </div>
      </section>
      <section className={styles.footer}>
        <div className={styles.innerFooter}>
          <div className={styles.top}>
            {!isAuth() && (
              <div className={styles.onlyDesktop}>
                <h4>Get started with a free trial account.</h4>
              </div>
            )}
            {!isAuth() && (
              <div>
                <Button
                  className={styles.freeTrial}
                  label="Start a Free Trial"
                  onClick={() => history.push(links.SIGNUP)}
                />
              </div>
            )}

            <div className={styles.googlePay}>
              <a href="https://play.google.com/store/apps/details?id=com.starcomsoft.trials" />
            </div>
          </div>
          <div className={styles.middle}>
            <div className={styles.footerLogo}>
              <div className={styles.logotype} />
              <div className={styles.links}>
                <Button onClick={() => history.push(links.CONTACT_US)}>
                  Contact Us
                </Button>
                {!isAuth() && (
                  <Button onClick={() => loginWithRedirect({})}>Log In</Button>
                )}
              </div>
            </div>
            <div className={styles.footerInfo}>
              <p>Starcom Computer Corp.</p>
              <p>19125 North Creek Parkway</p>
              <p>Bothell, WA 98011</p>
              <p>425.486.6464</p>
              <a href="https://starcomsoft.com/" className={styles.starcomsoft}>
                starcomsoft.com
              </a>
            </div>
          </div>
          <div className={styles.bottom}>
            <div>
              Copyright {new Date().getFullYear()} Starcom Computer Corp.
              <span className={styles.verticalLine} />
              <Link to={links.PRIVACY_POLICY} target="_blank">
                Privacy Policy
              </Link>
              <span className={styles.verticalLine} />
              <Link to={links.USER_AGREEMENT} target="_blank">
                User Agreement
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.mobileFooter}>
          <div className={styles.mobileTop}>
            <div className={styles.mobileLeft}>
              <div className={styles.footerLogo}>
                <div className={styles.logotype} />
                <div className={styles.links}>
                  <Button onClick={() => history.push(links.CONTACT_US)}>
                    Contact Us
                  </Button>
                  {!isAuth() && (
                    <Button onClick={() => loginWithRedirect({})}>
                      Log In
                    </Button>
                  )}
                </div>
                <div className={styles.googlePay}>
                  <a href="https://play.google.com/store/apps/details?id=com.starcomsoft.trials" />
                </div>
              </div>
            </div>
            <div className={styles.mobileRight}>
              {!isAuth() && (
                <div className={styles.buttonWrapper}>
                  <Button
                    className={styles.freeTrial}
                    label="Start a Free Trial"
                    onClick={() => history.push(links.SIGNUP)}
                  />
                </div>
              )}
              <div className={styles.footerInfo}>
                <p>Starcom Computer Corp.</p>
                <p>19125 North Creek Parkway</p>
                <p>Bothell, WA 98011</p>
                <p>425.486.6464</p>
                <a
                  href="https://starcomsoft.com/"
                  className={styles.starcomsoft}
                >
                  starcomsoft.com
                </a>
              </div>
            </div>
          </div>
          <div className={styles.mobileBottom}>
            <div>
              Copyright {new Date().getFullYear()} Starcom Computer Corp.
              <span className={styles.verticalLine} />
              <Link to={links.PRIVACY_POLICY} target="_blank">
                Privacy Policy
              </Link>
              <span className={styles.verticalLine} />
              <Link to={links.USER_AGREEMENT} target="_blank">
                User Agreement
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LandingPage;
