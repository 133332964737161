import { dialogText } from '../Common/globalConstants';

const getMessage = (isCompanyAdmin, limitExceeded, status = ' ') => {
  if (isCompanyAdmin) {
    return limitExceeded ? dialogText.limitAdmin : dialogText.denyAdmin(status);
  }
  return limitExceeded ? dialogText.limitUser : dialogText.denyUser;
};

export { getMessage };
