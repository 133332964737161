import React from 'react';
import LeftBar from '../../LeftBar/LeftBar';
import EmployeeMainMenu from '../Menu/EmployeeMainMenu';
import EmployeeList from '../EmployeeList/EmployeeList';

const EmployeePage = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <EmployeeMainMenu />
      </LeftBar>
      <EmployeeList />
    </React.Fragment>
  );
};

export default EmployeePage;
