export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const PROFILE_INFO_REQUEST = 'PROFILE_INFO_REQUEST';
export const PROFILE_INFO_SUCCESS = 'PROFILE_INFO_SUCCESS';
export const PROFILE_INFO_FAIL = 'PROFILE_INFO_FAIL';

export const getProfileInfo = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_PROFILE_INFO,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const profileInfoRequest = () => ({ type: PROFILE_INFO_REQUEST });

export const profileInfoSuccess = (profileInfo) => {
  return {
    type: PROFILE_INFO_SUCCESS,
    profileInfo: profileInfo,
  };
};

export const profileInfoFail = (error) => ({
  type: PROFILE_INFO_FAIL,
  error: error,
});
