export const GET_TASK_OPTIONS = 'GET_TASK_OPTIONS';
export const TASK_OPTIONS_REQUEST = 'TASK_OPTIONS_REQUEST';
export const TASK_OPTIONS_SUCCESS = 'TASK_OPTIONS_SUCCESS';
export const TASK_OPTIONS_FAIL = 'TASK_OPTIONS_FAIL';

export const getTaskOptions = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_TASK_OPTIONS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const taskOptionsRequest = () => ({ type: TASK_OPTIONS_REQUEST });

export const taskOptionsSuccess = (
  locations,
  trials,
  types,
  products,
  statuses,
  employees
) => ({
  type: TASK_OPTIONS_SUCCESS,
  locations: locations,
  trials: trials,
  types: types,
  products: products,
  statuses: statuses,
  employees: employees,
});

export const taskOptionsFail = (error) => ({
  type: TASK_OPTIONS_FAIL,
  error: error,
});
