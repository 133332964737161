import React, { useState, useCallback } from 'react';
import FilterControls from '../../../Filters/FilterControls';
import MultiSelectFilter from '../../../Filters/MultiSelectFilter';
import MenuDivider from '../../../Common/Menu/MenuDivider';
import { activeOptions } from '../constants';
import DropdownFilter from '../../../Filters/DropdownFilter';
const PlantFilters = ({
  applyNewFilters,
  programs,
  classes,
  series,
  breeders,
}) => {
  const [selectedPrograms, setSelectedPrograms] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [selectedBreeders, setSelectedBreeders] = useState(null);
  const [selectedActive, setSelectedActive] = useState(null);

  const onResetFilters = useCallback(() => {
    setSelectedPrograms(null);
    setSelectedClasses(null);
    setSelectedSeries(null);
    setSelectedBreeders(null);
    setSelectedActive(null);
    applyNewFilters(null, null, null, null, null, null);
  }, [applyNewFilters]);

  const onApplyFilters = useCallback(() => {
    applyNewFilters(
      selectedPrograms,
      selectedClasses,
      selectedSeries,
      selectedBreeders,
      selectedActive
    );
  }, [
    applyNewFilters,
    selectedActive,
    selectedBreeders,
    selectedClasses,
    selectedPrograms,
    selectedSeries,
  ]);

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <div className="filtersInputs">
        <MultiSelectFilter
          label="Program"
          items={programs}
          selectedItems={selectedPrograms}
          setSelectedItems={setSelectedPrograms}
          filter
          filterName="Select Programs"
        />
        <MultiSelectFilter
          label="Class"
          items={classes}
          selectedItems={selectedClasses}
          setSelectedItems={setSelectedClasses}
          filter
          filterName="Select Classes"
        />
        <MultiSelectFilter
          label="Series"
          items={series}
          selectedItems={selectedSeries}
          setSelectedItems={setSelectedSeries}
          filter
          filterName="Select Series"
        />
        <MultiSelectFilter
          label="Breeders"
          items={breeders}
          selectedItems={selectedBreeders}
          setSelectedItems={setSelectedBreeders}
          filter
          filterName="Select Breeders"
        />
        <DropdownFilter
          label="Active"
          items={activeOptions}
          selectedItem={selectedActive}
          setSelectedItem={setSelectedActive}
          optionLabel="label"
          filterName="Select Active"
        />
      </div>
    </div>
  );
};

export default PlantFilters;
