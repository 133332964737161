import { differenceBy } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  cssToEnum,
  enumToCss,
  fieldOrigin,
  multiplier,
  types,
} from './constants';

const getInitialLabel = (fullTemplate, currentName) => {
  return {
    id: fullTemplate?.id,
    template: fullTemplate?.name,
    name: currentName ? currentName : fullTemplate?.name,
    description: fullTemplate?.description,
    measureUnit: enumToCss.measure[fullTemplate?.style?.measureUnit],
    width: fullTemplate?.style?.width,
    height: fullTemplate?.style?.height,
    leftMargin: fullTemplate?.style?.marginLeft,
    topMargin: fullTemplate?.style?.marginTop,
    rightMargin: fullTemplate?.style?.marginRight,
    bottomMargin: fullTemplate?.style?.marginBottom,
    fields: fullTemplate?.fields,
  };
};

const createField = ({
  fieldName,
  fieldType,
  prettyName,
  measureUnit,
  fontSize,
  fontFamily,
  fontBold,
  barcodeHeight,
  showPrettyName,
  image,
}) => {
  const res = {
    name: fieldName,
    type: fieldType,
    prettyName: prettyName,
    showPrettyName: showPrettyName,
    style: {
      font: {
        dimension: {
          size: fontSize,
          measureUnit: measureUnit || 'POINT',
        },
        family: cssToEnum.family[fontFamily?.name],
        weight: fontBold ? 'BOLD' : 'NORMAL',
      },
      axisXY: {
        x: 0,
        y: 0,
      },
    },
  };
  if (fieldType === types.BARCODE) {
    res.style.dimension = {
      height: barcodeHeight,
      width: null,
      measureUnit: 'POINT',
    };
    res.showPrettyName = true;
  }
  if (fieldType === types.IMAGE || fieldType === types.QR) {
    res.style = {
      axisXY: {
        x: 0,
        y: 0,
      },
      dimension: {
        height: image.height,
        width: image.width,
        measureUnit: image.measureUnit,
      },
    };
    res.link = image.link;
  }
  return res;
};

const checkNameAndDescription = (templateName, templateDescription, formik) => {
  return (
    (templateName === formik.values.name &&
      templateDescription === formik.values.description) ||
    formik.errors.name ||
    formik.errors.description
  );
};

const getFieldTypeOptions = (values, fullTemplate = [], customFields = []) => {
  const selectedOptions = values.fields;
  const availableOptions = fullTemplate?.availableFields;
  const res = availableOptions && [...customFields, ...availableOptions];

  const difference = differenceBy(res, selectedOptions, 'name');
  return res?.map((op) => {
    if (difference.find((el) => el.name === op.name)) {
      return { ...op, disabled: false };
    }
    return { ...op, disabled: true };
  });
};

const getTemplateData = (
  templateInfo,
  availableOptions,
  fullTemplate,
  sourceId,
  draftTemplateId
) => {
  const customOptions = availableOptions?.filter(
    (field) => field.origin !== fieldOrigin.SYSTEM
  );

  const mappedCustomOptions = customOptions?.map((field) => {
    return {
      name: field.name,
      type: field.type,
      prettyName: field.prettyName,
      showPrettyName: field.showPrettyName,
      exampleValue: field.exampleValue,
      val: field.val,
    };
  });

  const res = {
    name: templateInfo.name,
    description: templateInfo.description,
    sourceId: sourceId,
    id: draftTemplateId,
    style: {
      width: templateInfo.width,
      height: templateInfo.height,
      marginLeft: templateInfo.leftMargin,
      marginRight: templateInfo.rightMargin,
      marginTop: templateInfo.topMargin,
      marginBottom: templateInfo.bottomMargin,
      measureUnit: cssToEnum.measure[templateInfo.measureUnit.name],
    },
    fields: templateInfo.fields?.map((field) => {
      if (field.type === 'IMAGE') {
        return {
          type: field.type,
          name: field.name,
          prettyName: field.prettyName,
          showPrettyName: field.showPrettyName,
          style: {
            axisXY: { ...field.style.axisXY },
            dimension: { ...field.style.dimension },
          },
        };
      }
      return {
        type: field.type,
        name: field.name,
        prettyName: field.prettyName,
        showPrettyName: field.showPrettyName,
        style: { ...field.style },
      };
    }),
  };

  res.availableFields = mappedCustomOptions;

  return res;
};

const confirmDiscard = (values, dirty, accept) => {
  if (dirty) {
    confirmDialog({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to discard the changes?',
      accept,
    });
  } else {
    accept();
  }
};

const checkPrettyNameValidity = (val) => {
  const regExp = /^[0-9a-zA-Z:&#\-',. ]+$/;
  return val !== '' && !regExp.test(val);
};

const getFieldFontSize = (field) => {
  if (field.type === 'QR' || field.type === 'IMAGE') {
    return 0;
  } else {
    return (
      field?.style.font?.dimension.size *
      multiplier[field?.style.font?.dimension?.measureUnit]
    );
  }
};

export {
  getInitialLabel,
  createField,
  checkNameAndDescription,
  getFieldTypeOptions,
  getTemplateData,
  confirmDiscard,
  checkPrettyNameValidity,
  getFieldFontSize,
};
