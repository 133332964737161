import * as yup from 'yup';

export const initialLabelFormSchema = (templateInfo, showSystemTemplates) => {
  return {
    name: !showSystemTemplates ? templateInfo?.name : '',
    description: !showSystemTemplates ? templateInfo?.description : '',
    measureUnit: templateInfo?.measureUnit
      ? { name: templateInfo?.measureUnit }
      : { name: 'in' },
    width: templateInfo?.width ? templateInfo?.width : 3,
    height: templateInfo?.height ? templateInfo?.height : 1,
    leftMargin: templateInfo?.leftMargin || '',
    topMargin: templateInfo?.topMargin || '',
    rightMargin: templateInfo?.rightMargin || '',
    bottomMargin: templateInfo?.bottomMargin || '',
    fields: templateInfo.fields,
  };
};

const lengthError = 'Maximum length exceeded.';

export const labelFormSchema = yup.object().shape({
  template: yup.object(),
  name: yup.string().required('Name should be provided').max(100, lengthError),
  description: yup
    .string()
    .required('Description should be provided')
    .max(100, lengthError),
  measureUnit: yup.object(),
  fontFamily: yup.object(),
  width: yup.number().max(9, 'Max value 9.').min(2, 'Min value 2.'),
  height: yup.number().max(5, 'Max value 5.').min(0.2, 'Min value 0.2.'),
  leftMargin: yup.number().max(1, 'Max value 1.').min(0.01, 'Min value 0.01.'),
  topMargin: yup.number().max(1, 'Max value 1.').min(0.01, 'Min value 0.01.'),
  rightMargin: yup.number().max(1, 'Max value 1.').min(0.01, 'Min value 0.01.'),
  bottomMargin: yup
    .number()
    .max(1, 'Max value 1.')
    .min(0.01, 'Min value 0.01.'),
  fontSize: yup.object(),
});
