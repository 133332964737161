export const modalType = {
  ONE: 'ONE',
  BATCH: 'BATCH',
  NONE: '',
};

export const initialPlantGoalsInfo = {
  plantName: [],
  field: '',
  daysAfterPlanting: '',
  goal: '',
};

export const getInitialPlantGoalsParams = (id) => ({
  query: { trial: { id: { is: id } } },

  navigation: {
    sort: [{ key: 'plantName', order: 'ASC' }],
    page: { from: 0, size: 20 },
  },
});
