import * as yup from 'yup';

export const initialPlantFormSchema = (plantInfo) => {
  return {
    name: plantInfo.name,
    code: plantInfo.code,
    active: plantInfo.active,
    program: plantInfo.program || null,
    class: plantInfo.class || null,
    series: plantInfo.series || null,
    breeder: plantInfo.breeder || null,
    marketStatus: plantInfo.marketStatus || null,
    hardinessZone: plantInfo.hardinessZone || null,
    patentNumber: plantInfo.patentNumber || '',
    tradeName: plantInfo.tradeName || '',
    scientificName: plantInfo.scientificName || '',
    color: plantInfo.color || null,
    description: plantInfo.description || '',
    note: plantInfo.note || '',
    imageId: plantInfo.image || '',
    pdfFileLink: plantInfo.pdfFileLink || null,
  };
};

const lengthError = 'Maximum length exceeded.';

export const plantFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Plant Name should be provided.')
    .max(50, lengthError),
  code: yup
    .string()
    .required('Plant Code should be provided.')
    .max(8, lengthError),
  description: yup.string().max(256, lengthError),
  patentNumber: yup.string().max(32, lengthError),
  tradeName: yup.string().max(50, lengthError),
  scientificName: yup.string().max(50, lengthError),
  note: yup.string().max(1024, lengthError),
});
