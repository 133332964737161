import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import {
  initialSectionFormSchema,
  sectionFormSchema,
} from './sectionFormValidation';
import urls from '../../../Common/ApiServices/urls';
import { useRequest } from '../../../Common/ApiServices';
import styles from './SectionForm.module.scss';

const SectionForm = ({
  sectionId,
  sectionTypes,
  zones,
  setSectionIsUpdated,
  setSectionIsCreated,
  setDisplaySectionModal,
  setSelectedSection,
  displaySectionModal,
  sectionIsUpdated,
  sectionIsCreated,
}) => {
  const locationId = localStorage.getItem('locationId');
  const sectionParams = {
    query: {
      section: {
        id: {
          is: sectionId,
        },
      },
    },
  };

  const initialSectionInfo = {
    name: '',
    sectionType: null,
    zone: null,
    description: '',
  };

  const [sectionInfo, setSectionInfo] = useState(initialSectionInfo);
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [touchedTypeField, setTouchedTypeField] = useState(false);

  const sectionNameRef = useRef('');

  const { error, sendRequest } = useRequest({});

  const errorCodes = {
    NOT_PROVIDED: 'Name should be provided.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
    NOT_UNIQUE: 'Name should be unique.',
  };

  const refTableValues = {
    sectiontypes: sectionTypes,
    zones: zones,
  };

  const getSectionInfo = async () => {
    const requestData = {
      url: urls.EXTRACT_SECTION,
      method: 'POST',
      data: sectionParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setSectionIsUpdated(false);
      setSectionInfo(response.data.section);
    }
  };

  const createSection = async (sectionParams) => {
    const requestData = {
      url: urls.CREATE_SECTION,
      method: 'POST',
      data: sectionParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setSectionIsCreated(true);
    }
  };

  const updateSection = async (sectionParams) => {
    const requestData = {
      url: urls.UPDATE_SECTION,
      method: 'POST',
      data: sectionParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setSectionIsUpdated(true);
    }
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setDisplaySectionModal(false);
    formik.resetForm();
    setSectionInfo(initialSectionInfo);
    setSelectedSection(null);
  };

  const formik = useFormik({
    initialValues: initialSectionFormSchema(sectionInfo, sectionTypes),
    enableReinitialize: true,
    validationSchema: sectionFormSchema,
    onSubmit: (values) => {
      let newSectionParams = {
        locationId: locationId,
        name: values.name,
        sectionTypeId: values.type?.id || null,
        zoneId: values.zone?.id || null,
        description: values.description || null,
      };
      if (sectionId) {
        newSectionParams = {
          ...newSectionParams,
          ...{
            id: sectionId,
            versionNumber: sectionInfo.version?.number || 0,
          },
        };
        updateSection(newSectionParams);
      } else {
        createSection(newSectionParams);
      }
    },
  });

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  useEffect(() => {
    if (sectionId) {
      getSectionInfo();
    }
  }, [sectionId]);

  useEffect(() => {
    if (!error && (sectionIsCreated || sectionIsUpdated)) {
      onHide();
    }
  }, [error, sectionIsUpdated, sectionIsCreated]);

  useEffect(() => {
    if (displayRefTable === true) {
      sectionNameRef.current = formik.values.name;
    }
    sectionInfo.name = sectionNameRef.current;
  }, [displayRefTable]);

  useEffect(() => {
    if (error) {
      setSectionIsCreated(false);
      setSectionIsUpdated(false);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      }
    }
  }, [error]);

  return (
    <Fragment>
      <Dialog
        className={styles.sectionFormDialog}
        header="Section Information"
        visible={displaySectionModal}
        onHide={() => confirm(formik.dirty)}
      >
        <PromptIfDirty dirty={formik.dirty} />
        <Tooltip
          target=".custom-choose-btn"
          content="Choose"
          position="bottom"
        />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                ) : (
                  <small>Section Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="type" className="p-col-12 p-md-3 p-text-bold">
                Type*
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="type"
                  name="type"
                  onChange={formik.handleChange}
                  onBlur={() => setTouchedTypeField(true)}
                  value={formik.values.type}
                  options={sectionTypes}
                  optionLabel="name"
                  filter
                  aria-describedby="name-invalid"
                  className={
                    sectionTypes?.length === 0 && touchedTypeField
                      ? 'p-invalid'
                      : null
                  }
                />
                {sectionTypes?.length === 0 && touchedTypeField ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    Section Type should be provided.
                  </small>
                ) : (
                  <small>Section Type is required.</small>
                )}
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable('sectiontypes')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="zone" className="p-col-12 p-md-3">
                Zone
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="zone"
                  onChange={formik.handleChange}
                  value={formik.values.zone}
                  options={zones}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable('zones')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.description ? 'p-invalid' : null}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                (!formik.dirty && !sectionNameRef) ||
                  !formik.values.name ||
                  formik.errors.description
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header={
          currentRefTableName === 'zones' ? 'Section Zones' : 'Section Types'
        }
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </Fragment>
  );
};

export default SectionForm;
