import React from 'react';
import { useSelector } from 'react-redux';
import ContactUsForm from './ContactUsForm/ContactUsForm';

const ContactUsPage = () => {
  const profileData = useSelector((state) => state?.profileInfo?.employee);

  return <ContactUsForm profileData={profileData} />;
};

export default ContactUsPage;
