import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { units } from '../constants';
import styles from './LabelsEditorMenu.module.scss';

const LabelsEditorMenu = ({ formik, isSystem }) => {
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  return (
    <div className={styles.editorMenu}>
      <div className={styles.menuItem}>
        <label htmlFor="measureUnit" className={styles.menuLabel}>
          Units
        </label>
        <Dropdown
          id="measureUnit"
          value={formik.values.measureUnit}
          options={units}
          optionLabel="name"
          disabled
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </div>
      <div className={styles.menuItem}>
        <label htmlFor="width" className={styles.menuLabel}>
          Width
        </label>
        <InputText
          id="width"
          type="number"
          min="2"
          max="9"
          step="0.01"
          value={formik.values.width ? formik.values.width : 2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSystem || isTrialTracker}
          aria-describedby="width-invalid"
          className={classNames(
            formik.errors.width && formik.touched.width && 'p-invalid',
            styles.menuInput
          )}
        />
        {formik.errors.width && formik.touched.width && (
          <small
            id="width-invalid"
            className={classNames('p-error p-d-block', styles.errorMessage)}
          >
            {formik.errors.width}
          </small>
        )}
      </div>
      <div className={styles.menuItem}>
        <label htmlFor="height" className={styles.menuLabel}>
          Height
        </label>
        <InputText
          id="height"
          type="number"
          min="0.2"
          max="5"
          step="0.01"
          value={formik.values.height}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          aria-describedby="height-invalid"
          disabled={isSystem || isTrialTracker}
          className={classNames(
            formik.errors.height && formik.touched.height && 'p-invalid',
            styles.menuInput
          )}
        />
        {formik.errors.height && formik.touched.height && (
          <small
            id="height-invalid"
            className={classNames('p-error p-d-block', styles.errorMessage)}
          >
            {formik.errors.height}
          </small>
        )}
      </div>
      <div className={styles.menuItem}>
        <label htmlFor="leftMargin" className={styles.menuLabel}>
          Left Margin
        </label>
        <InputText
          id="leftMargin"
          type="number"
          min="0.01"
          max="1"
          step="0.01"
          value={formik.values.leftMargin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          aria-describedby="leftMargin-invalid"
          disabled={isSystem || isTrialTracker}
          className={classNames(
            formik.errors.leftMargin &&
              formik.touched.leftMargin &&
              'p-invalid',
            styles.menuInput
          )}
        />
        {formik.errors.leftMargin && formik.touched.leftMargin && (
          <small
            id="leftMargin-invalid"
            className={classNames('p-error p-d-block', styles.errorMessage)}
          >
            {formik.errors.leftMargin}
          </small>
        )}
      </div>
      <div className={styles.menuItem}>
        <label htmlFor="rightMargin" className={styles.menuLabel}>
          Right Margin
        </label>
        <InputText
          id="rightMargin"
          type="number"
          min="0.01"
          max="1"
          step="0.01"
          value={formik.values.rightMargin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          aria-describedby="rightMargin-invalid"
          disabled={isSystem || isTrialTracker}
          className={classNames(
            formik.errors.rightMargin &&
              formik.touched.rightMargin &&
              'p-invalid',
            styles.menuInput
          )}
        />
        {formik.errors.rightMargin && formik.touched.rightMargin && (
          <small
            id="rightMargin-invalid"
            className={classNames('p-error p-d-block', styles.errorMessage)}
          >
            {formik.errors.rightMargin}
          </small>
        )}
      </div>
      <div className={styles.menuItem}>
        <label htmlFor="topMargin" className={styles.menuLabel}>
          Top Margin
        </label>
        <InputText
          id="topMargin"
          type="number"
          min="0.01"
          max="1"
          step="0.01"
          value={formik.values.topMargin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          aria-describedby="rightMargin-invalid"
          disabled={isSystem || isTrialTracker}
          className={classNames(
            formik.errors.topMargin && formik.touched.topMargin && 'p-invalid',
            styles.menuInput
          )}
        />
        {formik.errors.topMargin && formik.touched.topMargin && (
          <small
            id="topMargin-invalid"
            className={classNames('p-error p-d-block', styles.errorMessage)}
          >
            {formik.errors.topMargin}
          </small>
        )}
      </div>
      <div className={styles.menuItem}>
        <label htmlFor="bottomMargin" className={styles.menuLabel}>
          Bottom Margin
        </label>
        <InputText
          id="bottomMargin"
          type="number"
          min="0.01"
          max="1"
          step="0.01"
          value={formik.values.bottomMargin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          aria-describedby="bottomMargin-invalid"
          disabled={isSystem || isTrialTracker}
          className={classNames(
            formik.errors.bottomMargin &&
              formik.touched.bottomMargin &&
              'p-invalid',
            styles.menuInput
          )}
        />
        {formik.errors.bottomMargin && formik.touched.bottomMargin && (
          <small
            id="bottomMargin-invalid"
            className={classNames('p-error p-d-block', styles.errorMessage)}
          >
            {formik.errors.bottomMargin}
          </small>
        )}
      </div>
    </div>
  );
};

export default LabelsEditorMenu;
