export const GET_HISTORY_OPTIONS = 'GET_HISTORY_OPTIONS';
export const HISTORY_OPTIONS_REQUEST = 'TASK_HISTORY_REQUEST';
export const HISTORY_OPTIONS_SUCCESS = 'TASK_HISTORY_SUCCESS';
export const HISTORY_OPTIONS_FAIL = 'TASK_HISTORY_FAIL';

export const getHistoryOptions = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_HISTORY_OPTIONS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const historyOptionsRequest = () => ({ type: HISTORY_OPTIONS_REQUEST });

export const historyOptionsSuccess = (products, plants, sections) => ({
  type: HISTORY_OPTIONS_SUCCESS,
  products: products,
  plants: plants,
  sections: sections,
});

export const historyOptionsFail = (error) => ({
  type: HISTORY_OPTIONS_FAIL,
  error: error,
});
