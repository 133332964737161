export const GET_REPORT_FILTERS = 'GET_REPORT_FILTERS';
export const REPORT_FILTERS_REQUEST = 'REPORT_FILTERS_REQUEST';
export const REPORT_FILTERS_SUCCESS = 'REPORT_FILTERS_SUCCESS';
export const REPORT_FILTERS_FAIL = 'REPORT_FILTERS_FAIL';

export const getReportFilters = ({
  id,
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => {
  return {
    type: GET_REPORT_FILTERS,
    trialId: id,
    actionData: {
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuthenticated,
      getAccessTokenSilently: getAccessTokenSilently,
    },
  };
};

export const reportFiltersRequest = () => ({ type: REPORT_FILTERS_REQUEST });

export const reportFiltersSuccess = (plants, trialFields, logWeeks) => ({
  type: REPORT_FILTERS_SUCCESS,
  plants: plants,
  trialFields: trialFields,
  logWeeks: logWeeks,
});

export const reportFiltersFail = (error) => ({
  type: REPORT_FILTERS_FAIL,
  error: error,
});
