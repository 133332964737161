import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import printJS from 'print-js';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { urls, useRequest } from '../../../Common/ApiServices';
import Spinner from '../../../Common/Spinner/Spinner';
import { modals } from '../constants';
import styles from './PrintCropLabelsForm.module.scss';

const PrintCropLabelsForm = ({
  displayModal,
  setDisplayModal,
  selectedCrops,
  trialId,
  isTrialTracker,
}) => {
  const [template, setTemplate] = useState(null);
  const [isNotAssigned, setIsNotAssigned] = useState(false);

  const { error, isLoading, sendRequest } = useRequest({});

  const extractCropTemplate = async (trialId) => {
    const extractCropParams = {
      trial: {
        id: {
          is: trialId,
        },
      },
    };

    const requestData = {
      url: urls.EXTRACT_CROP_TEMPLATE,
      method: 'POST',
      data: extractCropParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setTemplate(response.data.template);
    }

    return response;
  };

  const getPdf = async () => {
    const cropsIds = selectedCrops.map((crop) => crop.id);
    const requestData = {
      url: urls.GENERATE_CROP_TEMPLATES_PDF,
      method: 'POST',
      data: {
        template: {
          id: {
            is: template.id,
          },
        },
        crop: {
          id: {
            in: cropsIds,
          },
        },
      },
    };
    const response = await sendRequest(requestData);
    return response && response.data.url;
  };

  const printTemplate = async () => {
    const pdfUrl = await getPdf();

    if (pdfUrl.length) {
      printJS({ printable: pdfUrl, type: 'pdf', base64: false });
    }
  };

  useEffect(async () => {
    const trialId = JSON.parse(localStorage.getItem('trialId'));
    trialId && (await extractCropTemplate(trialId));
  }, []);

  useEffect(() => {
    if (error) {
      error?.response?.data?.error?.code === 'NO_TEMPLATE' &&
        setIsNotAssigned(true);
    }
  }, [error]);

  return (
    <Dialog
      className={styles.printCropDialog}
      header="Print Label Preview"
      visible={displayModal === modals.PRINT}
      onHide={() => setDisplayModal('')}
    >
      <Divider />
      {isLoading && <Spinner />}
      <div
        className={classNames(
          styles.noTemplate,
          !template && isNotAssigned ? styles.show : styles.hide
        )}
      >
        {isTrialTracker ? (
          <p className={styles.noTemplateMessage}>
            Label Template isn’t set. Please contact the manager to choose the
            template.
          </p>
        ) : (
          <Link to={`/trial/${trialId}`} className={styles.noTemplateMessage}>
            Label Template isn’t set. Please choose the template.
          </Link>
        )}
      </div>
      <div
        className={classNames(
          styles.contentWrapper,
          !template ? styles.hide : styles.show
        )}
      >
        <div
          className={classNames(
            styles.templateName,
            'p-field p-grid p-ai-start p-bold'
          )}
        >
          <label htmlFor="template" className="p-col-12 p-md-3 p-text-bold">
            Label Template*
          </label>
          <div className="p-col-12 p-md-9">{template?.name}</div>
        </div>
        <div
          className={classNames(
            styles.preview,
            template ? styles.imageBorder : ''
          )}
        >
          <img src={template?.previewLink} alt="" />
        </div>
      </div>
      <div className={styles.buttonsBlock}>
        <Button
          className={`p-button-primary`}
          label="Print Template"
          type="button"
          icon="pi pi-print"
          disabled={!template}
          onClick={() => printTemplate()}
        />
        <Button
          className={`p-button-secondary`}
          label="Cancel"
          type="button"
          icon="pi pi-times"
          onClick={() => setDisplayModal('')}
        />
      </div>
    </Dialog>
  );
};

export default PrintCropLabelsForm;
