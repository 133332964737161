import React from 'react';
import { InputText } from 'primereact/inputtext';

const ShowDetails = (value) => {
  return (
    <InputText
      type="text"
      className="p-text-nowrap p-text-truncate hintDetails"
      readOnly
      value={value ? value : ''}
      tooltip={value}
      tooltipOptions={{ position: 'bottom' }}
    />
  );
};

export default ShowDetails;
