import React from 'react';
import Menu from '../../../Common/Menu/Menu';

const CropMenu = () => {
  const cropId = localStorage.getItem('cropId');
  const cropLink = `/crop/${cropId}`;

  const items = [
    { link: cropLink, value: 'Information' },
    { link: `${cropLink}/logs`, value: 'Logs' },
  ];

  return <Menu items={items} />;
};

export default CropMenu;
