import React from 'react';
import { useSelector } from 'react-redux';
import { checkLimitExceeded } from '../../Common/utils';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { getMessage } from '../utils';
import styles from './NavDialog.module.scss';

const NavDialog = ({
  userName,
  visible,
  setShowDialog,
  isCompanyAdmin,
  toCompanyInfoPage,
}) => {
  const dispatch = useDispatch();

  const limitExceeded = useSelector((state) => state.limitExceeded);
  const status = useSelector((state) => state.subscription.status);

  const dialogMessage = getMessage(isCompanyAdmin, limitExceeded, status);

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => {
            setShowDialog(false), checkLimitExceeded(dispatch, false);
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <div>
      {isCompanyAdmin ? (
        <Dialog
          header={`Dear ${userName}`}
          visible={visible}
          modal={true}
          footer={renderFooter()}
          className={styles.navDialog}
          onHide={() => {
            setShowDialog(false), checkLimitExceeded(dispatch, false);
          }}
        >
          {dialogMessage[0]}
          {
            <Button
              label="Billing & Subscriptions"
              className={classNames('p-button-text', styles.buttonStyle)}
              onClick={toCompanyInfoPage}
            />
          }
          {dialogMessage[1]}
          {<a href={`mailto:${supportEmail}`}>{supportEmail}</a>}
        </Dialog>
      ) : (
        <Dialog
          header={`Dear ${userName}`}
          visible={visible}
          modal={true}
          footer={renderFooter()}
          className={styles.navDialog}
          onHide={() => setShowDialog(false)}
        >
          {dialogMessage}
        </Dialog>
      )}
    </div>
  );
};

export default NavDialog;
