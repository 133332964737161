const statuses = {
  TRIALING: 'TRIALING',
  CANCELED: 'CANCELED',
  OUTDATED: 'OUTDATED',
  CONFIRMED: 'CONFIRMED',
  CONFLICT: 'CONFLICT',
};

const messages = {
  DEFAULT: 'Something went wrong.',
  OUTDATED:
    'Verification link you tried to use is no longer valid. ' +
    'Please initiate the verification process again to receive a new verification link.',
  CONFIRMED: 'Your email has been successfully verified!',
  TO_COMPLETE: 'To complete verification, please check your email inbox!',
  TO_CONFIRM: 'Are you sure you want to discard the changes?',
  CONFLICT:
    'This company has been updated by another user. Please refresh the page and then save your updates.',
  UPDATED: 'Company was updated.',
};

const errorCodes = {
  NOT_UNIQUE: 'This field should be unique.',
  NOT_PROVIDED: 'This field should be provided.',
  MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  INVALID_FIELD_FORMAT: 'Invalid field format',
};

const isVerifyModal = 'isVerifyModal';

export { statuses, messages, errorCodes, isVerifyModal };
