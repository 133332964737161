import { all, put } from 'redux-saga/effects';
import * as actions from '../crop/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getCropOptionsSaga(action) {
  yield put(actions.cropOptionsRequest());

  const actionData = action.actionData;

  const plantData = {
    url: urls.SEARCH_TRIAL_PLANTS,
    method: 'POST',
    data: {
      query: {
        trial: {
          id: {
            is: action.trialId,
          },
        },
      },
      navigation: {
        sort: [
          {
            key: 'name',
            order: 'ASC',
          },
        ],
        page: {
          from: 0,
          size: 1000,
        },
      },
    },
  };

  const trialData = {
    url: urls.EXTRACT_TRIAL,
    method: 'POST',
    data: {
      query: {
        trial: {
          id: {
            is: action.trialId,
          },
        },
      },
    },
  };

  const startFormData = {
    url: urls.START_FORMS,
    method: 'GET',
    data: {},
  };

  const potSizeData = {
    url: urls.POT_SIZES,
    method: 'GET',
    data: {},
  };

  try {
    const trial = yield makeRequest(trialData, actionData);
    const trialLocation = trial.data.location.id;
    const sectionData = {
      url: urls.SEARCH_SECTIONS,
      method: 'POST',
      data: {
        query: {
          location: {
            id: {
              in: [trialLocation],
            },
          },
        },
      },
    };
    let [plants, sections, startForms, potSizes] = yield all([
      makeRequest(plantData, actionData),
      makeRequest(sectionData, actionData),
      makeRequest(startFormData, actionData),
      makeRequest(potSizeData, actionData),
    ]);
    startForms = startForms.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    potSizes = potSizes.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    yield put(
      actions.cropOptionsSuccess(
        plants.data.results,
        sections.data.results,
        startForms,
        potSizes,
        trial.data.location
      )
    );
  } catch (error) {
    yield put(actions.cropOptionsFail(error.response));
  }
}
