export const GET_PROFILE_FIELD_GROUPS = 'PROFILE_FIELD_GROUPS';
export const PROFILE_FIELD_GROUPS_REQUEST = 'PROFILE_FIELD_GROUPS_REQUEST';
export const PROFILE_FIELD_GROUPS_SUCCESS = 'PROFILE_FIELD_GROUPS_SUCCESS';
export const PROFILE_FIELD_GROUPS_FAIL = 'PROFILE_FIELD_GROUPS_FAIL';

export const getProfileFieldGroups = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_PROFILE_FIELD_GROUPS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const profileFieldGroupsRequest = () => ({
  type: PROFILE_FIELD_GROUPS_REQUEST,
});

export const profileFieldGroupsSuccess = (groups) => ({
  type: PROFILE_FIELD_GROUPS_SUCCESS,
  groups: groups,
});

export const profileFieldGroupsFail = (error) => ({
  type: PROFILE_FIELD_GROUPS_FAIL,
  error: error,
});
