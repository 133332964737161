import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import LabelTemplateEditor from '../LabelsEditor/LabelTemplateEditor';
import { urls, useRequest } from '../../../Common/ApiServices';
import { getTemplateData } from '../LabelsEditor/utils';
import { popUp } from '../../../Common/globalConstants';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import styles from './NewLabelTemplate.module.scss';

function NewLabelTemplate({
  displayLabelModal,
  setDisplayLabelModal,
  labelOptions,
  fetchLabels,
}) {
  const [originTemplate, setOriginTemplate] = useState({});
  const [fullTemplate, setFullTemplate] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [draftTemplateId, setDraftTemplateId] = useState(null);
  const [customFields, setCustomFields] = useState([]);

  const dispatch = useDispatch();

  const { sendRequest } = useRequest({});

  const createDraftTemplate = useCallback(async () => {
    const requestData = {
      url: urls.CREATE_DRAFT_CROP_TEMPLATE,
      method: 'POST',
      data: {},
    };
    const response = await sendRequest(requestData);
    response && setDraftTemplateId(response.data.id);
    return response;
  }, [sendRequest]);

  const createTemplate = async (templateInfo, availableOptions) => {
    const requestData = {
      url: urls.UPDATE_CROP_TEMPLATE,
      method: 'POST',
      data: getTemplateData(
        templateInfo,
        availableOptions,
        fullTemplate,
        originTemplate.id,
        draftTemplateId
      ),
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New template was created.',
          life: 5000,
          sticky: null,
        })
      );
      fetchLabels();
    }
    return response;
  };

  const extractCropTemplate = async (id) => {
    const extractCropParams = {
      template: {
        id: {
          is: id,
        },
      },
    };
    const requestData = {
      url: urls.EXTRACT_CROP_TEMPLATE,
      method: 'POST',
      data: extractCropParams,
    };
    const response = await sendRequest(requestData);

    const result = response.data.template;
    setFullTemplate(result);

    return response;
  };

  const confirm = () => {
    const accept = () => {
      setDisplayLabelModal(false);
    };
    if (isDirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  useEffect(() => {
    labelOptions && setOriginTemplate(labelOptions[0]);
  }, [labelOptions]);

  useEffect(() => {
    originTemplate?.id && extractCropTemplate(originTemplate.id);
  }, [originTemplate]);

  return (
    <div className={styles.newLabel}>
      <Dialog
        className={styles.dialog}
        header="Label Information"
        visible={displayLabelModal}
        onHide={() => confirm()}
      >
        <LabelTemplateEditor
          showSystemTemplates={true}
          labelOptions={labelOptions}
          originTemplate={originTemplate}
          setCustomFields={setCustomFields}
          customFields={customFields}
          setOriginTemplate={setOriginTemplate}
          setDisplayLabelModal={setDisplayLabelModal}
          fullTemplate={fullTemplate}
          setFullTemplate={setFullTemplate}
          updateTemplate={createTemplate}
          setIsDirty={setIsDirty}
          createDraftTemplate={createDraftTemplate}
          currentTemplateId={draftTemplateId}
        />
      </Dialog>
    </div>
  );
}

export default NewLabelTemplate;
