import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import Footer from '../../../Common/Footer/Footer';
import ShowDetails from '../../../Common/showDetails/showDetails';
import { getInitialHistoryParams } from '../constants';
import { links, showDetailsFields } from '../../../Common/globalConstants';
import styles from './History.module.scss';

const HistoryList = ({
  historyItems,
  isLoading,
  totalRecords,
  trialId,
  historyParams,
  setHistoryParams,
}) => {
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [sort, setSort] = useState({ field: 'cropNumber', order: 1 });

  const { id } = useParams();
  const history = useHistory();
  const tableRef = useRef(null);

  const trialName = localStorage.getItem('trialName');

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push(links.TRIALS);
      },
    },
    {
      label: trialName,
      command: () => {
        history.push(`${links.TRIAL}/${id}`);
      },
    },
    { label: 'Application History' },
  ];
  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const sortFunc = () => tableRef?.current?.props.value || historyItems;
  const onSort = ({ sortField, sortOrder }) =>
    setSort({ field: sortField, order: sortOrder });

  useEffect(() => {
    setHistoryParams((oldParams) => {
      const params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    if (sort) {
      setHistoryParams((oldParams) => {
        const params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  return (
    <div className={`${styles.historyList} list-generic`}>
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className={'table-generic p-datatable-sm history-list'}
        value={historyItems}
        resizableColumns
        columnResizeMode="expand"
        dataKey="id"
        loading={isLoading}
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        reorderableColumns
        scrollable
        emptyMessage=""
      >
        <Column
          field="plant.name"
          header="Plant Name"
          reorderable
          bodyStyle={{ height: '50px' }}
          headerStyle={{ width: '160px', height: '48px' }}
        />
        <Column
          field="crop.cropNumber"
          sortField={'cropNumber'}
          header="Crop Number"
          reorderable
          sortable
          sortFunction={sortFunc}
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="crop.plantWeek"
          header="Plant Week"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="section.name"
          header="Section"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="product.tradeName"
          header="Product Trade Name"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="appDate"
          header="Application Date"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="appTime"
          header="Application Time"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="application.code"
          header="Application Code"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          className="p-dt-tooltip"
          field="task.note"
          header="Task Note"
          reorderable
          headerStyle={{ width: '150px', height: '48px' }}
          body={(rowData) => ShowDetails(rowData.task[showDetailsFields.NOTE])}
        />
      </DataTable>
      <Footer
        totalRecords={totalRecords}
        initParams={getInitialHistoryParams(trialId)}
        params={historyParams}
        pagination={pagination}
        type="history"
        singular={true}
        onPageSelect={onPageSelect}
      />
    </div>
  );
};

export default HistoryList;
