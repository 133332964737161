export const GET_CROP_OPTIONS = 'GET_CROP_OPTIONS';
export const CROP_OPTIONS_REQUEST = 'CROP_OPTIONS_REQUEST';
export const CROP_OPTIONS_SUCCESS = 'CROP_OPTIONS_SUCCESS';
export const CROP_OPTIONS_FAIL = 'CROP_OPTIONS_FAIL';

export const getCropOptions = ({
  id,
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_CROP_OPTIONS,
  trialId: id,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const cropOptionsRequest = () => ({ type: CROP_OPTIONS_REQUEST });

export const cropOptionsSuccess = (
  plants,
  sections,
  startForms,
  potSizes,
  location
) => ({
  type: CROP_OPTIONS_SUCCESS,
  plants: plants,
  sections: sections,
  startForms: startForms,
  potSizes: potSizes,
  location: location,
});

export const cropOptionsFail = (error) => ({
  type: CROP_OPTIONS_FAIL,
  error: error,
});
