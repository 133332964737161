import React from 'react';
import MenuDivider from './MenuDivider';
import { NavLink } from 'react-router-dom';
import styles from './Menu.module.scss';

const Menu = (props) => {
  return (
    <div className={styles.menu}>
      <ul className={styles.menuItems} role="menu">
        {props.items.map(({ link, value, onClick }, index) => (
          <div key={index}>
            <li key={index}>
              <NavLink
                exact
                to={{
                  pathname: link,
                  state: props.name || '',
                }}
                activeClassName={styles.active}
                onClick={onClick}
              >
                {value}
              </NavLink>
            </li>
            <MenuDivider />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
