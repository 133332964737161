import React from 'react';
import { useSelector } from 'react-redux';
import { useRequest, urls } from '../../Common/ApiServices';
import { statuses } from '../constants';
import Menu from '../../Common/Menu/Menu';

const AdminMenu = () => {
  const { sendRequest } = useRequest({});

  const subscriptionStatus = useSelector((state) => state.subscription.status);

  const toCustomerPortal = async (event) => {
    event.preventDefault();

    const requestData = {
      url: urls.CREATE_SUBSCRIPTION_PORTAL,
      method: 'POST',
      data: {},
    };

    const response = await sendRequest(requestData);
    response && window.location.assign(response.data.url);
    return response;
  };

  const items =
    !subscriptionStatus || subscriptionStatus === statuses.CANCELED
      ? [
          {
            link: '/administration/company-info',
            value: 'Company Information',
          },
          { link: '/pricing', value: 'Billing Details' },
        ]
      : [
          {
            link: '/administration/company-info',
            value: 'Company Information',
          },
          {
            link: '/',
            value: 'Billing Details',
            onClick: (event) => toCustomerPortal(event),
          },
        ];

  return (
    <>
      <Menu items={items} />
    </>
  );
};

export default AdminMenu;
