import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminMenu from '../AdminMenu/AdminMenu';
import CompanyForm from './CompanyForm';
import LeftBar from '../../LeftBar/LeftBar';
import { useLocation } from 'react-router-dom';
import { isVerifyModal } from '../constants';
import { popUp } from '../../Common/globalConstants';
import { setPopUp } from '../../../reduxStore/popUp/actions';

const CompanyInfo = () => {
  const [messageIsActive, setMessageIsActive] = useState(false);

  const isActiveSubscription = useSelector(
    (state) => state.isActiveSubscription.isActive
  );
  const { state } = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    return () => {
      setMessageIsActive(false);
    };
  }, []);

  useEffect(() => {
    !isActiveSubscription && !messageIsActive && setMessageIsActive(true);
  }, [isActiveSubscription]);

  useEffect(() => {
    messageIsActive &&
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail:
            'No active subscriptions. Please, click "Billing Details" to proceed.',
          life: 5000,
          sticky: true,
        })
      );
  }, [messageIsActive]);

  return (
    <>
      <LeftBar>
        <AdminMenu />
      </LeftBar>
      <CompanyForm isVerifyModal={state === isVerifyModal} />
    </>
  );
};

export default CompanyInfo;
