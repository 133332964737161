const types = {
  APPLICATION: 'application',
};

const getNoFiltersMessage = (type, singular) => {
  return singular
    ? `No ${type} conform to filters.`
    : `No ${type}(s) conform to filters.`;
};

const getNoEntriesMessage = (type, singular) => {
  return singular
    ? `You don’t have ${type} yet. Please create a ${type}.`
    : `You don’t have ${type}s yet. Please create a ${type}.`;
};
export { types, getNoFiltersMessage, getNoEntriesMessage };
