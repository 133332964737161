import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getProfile } from '../../../reduxStore/trial/actions';
import { useAuth0 } from '@auth0/auth0-react';
import TrialFilters from '../Filters/TrialFilters';
import TrialList from '../List/TrialList';
import LeftBar from '../../LeftBar/LeftBar';
import { isAuth } from '../../../auth/auth-service';

const TrialPage = (props) => {
  const [filters, setFilters] = useState(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const queryRole = {
    query: {
      role: 'PPT_TRIAL_MANAGER',
    },
  };

  const actionData = {
    queryRole: queryRole,
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  useEffect(() => {
    props.getProfile(actionData);
  }, []);

  const onApplyNewFilters = (statuses, locations, startDate) => {
    setFilters({
      statuses: statuses && statuses.length > 0 ? statuses : null,
      locations: locations && locations.length > 0 ? locations : null,
      startDate: startDate,
    });
  };

  return (
    <React.Fragment>
      <LeftBar>
        <TrialFilters
          locations={props.locations}
          statuses={props.statuses}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      <TrialList newFilters={filters} locations={props.locations} />
    </React.Fragment>
  );
};

// ToDo: Should be replaced with hooks
const mapStateToProps = (state) => {
  return {
    loading: state.trial.loading,
    locations: state.trial.locations,
    statuses: state.trial.statuses,
    profileReady: state.trial.profileReady,
  };
};

// ToDo: Should be replaced with hooks
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (actionData) => dispatch(getProfile(actionData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialPage);
