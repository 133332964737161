import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import NewLabelTemplate from './NewLabelTemplate/NewLabelTemplate';
import { urls, useRequest } from '../../Common/ApiServices';
import PromptIfDirty from '../../Common/PromptIfDirty';
import ShowDetails from '../../Common/showDetails/showDetails';
import { templateParams } from '../constants';
import {
  links,
  popUp,
  showDetailsFields,
  userRoles,
} from '../../Common/globalConstants';
import { fieldOrigin } from './LabelsEditor/constants';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import styles from './LabelsTemplateList.module.scss';

function LabelTemplateList(props) {
  const [labels, setLabels] = useState([]);
  const [initialLabels, setInitialLabels] = useState([]);
  const [editing, setEditing] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const [labelToEdit, setLabelToEdit] = useState(null);
  const [deleteLabelDialog, setDeleteLabelDialog] = useState(false);
  const [displayLabelModal, setDisplayLabelModal] = useState(false);
  const [labelIsCreated, setLabelIsCreated] = useState(false);

  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const permissions = useSelector((state) => state.permissions.permissions);

  const { isLoading, sendRequest } = useRequest({});

  const history = useHistory();
  const dispatch = useDispatch();

  const breadCrumbItems = permissions?.includes(userRoles.PPT_MANAGE_COMPANY)
    ? [
        {
          label: 'Settings',
          command: () => {
            history.push(links.REPLICATION);
          },
        },
        { label: 'Labels' },
      ]
    : [
        {
          label: 'Settings',
          command: () => {
            history.push(links.LABELS);
          },
        },
        { label: 'Labels' },
      ];

  const header = (
    <div>
      <Button
        className={classNames(
          'p-button-raised',
          isTrialTracker && styles.hideElement
        )}
        label="New Label Template"
        icon="pi pi-plus"
        onClick={() => onNewLabelCreate()}
      />
    </div>
  );

  const fetchLabels = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_CROP_TEMPLATES,
      method: 'POST',
      data: templateParams,
    };
    const response = await sendRequest(requestData);

    const result = response.data.templates;
    setLabels(cloneDeep(result));
    setInitialLabels(cloneDeep(result));

    return response;
  }, [sendRequest]);

  const updateLabel = async (data) => {
    const newData = {
      description: data.description,
      name: data.name,
      order: data.order,
      id: data.id,
    };

    const requestData = {
      url: urls.UPDATE_CROP_TEMPLATE,
      method: 'POST',
      data: newData,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setLabels(response.data.templates);
      await fetchLabels();
    }
    return response;
  };

  const deleteLabel = async () => {
    const requestData = {
      url: urls.DELETE_CROP_TEMPLATES,
      method: 'POST',
      data: {
        id: {
          in: [labelToDelete.id],
        },
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Template ${labelToDelete.name} was deleted.`,
          life: 5000,
          sticky: null,
        })
      );
      await fetchLabels();
    }
    setDeleteLabelDialog(false);
    return response;
  };

  const onNewLabelCreate = () => {
    setDisplayLabelModal(true);
  };

  const hideDeleteLabelDialog = () => {
    setDeleteLabelDialog(false);
  };

  const deleteLabelDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteLabelDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteLabel}
      />
    </React.Fragment>
  );

  const confirmDeletePlant = (label) => {
    setLabelToDelete(label);
    setDeleteLabelDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return props.isTrialTracker ? null : (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          disabled={editing || isTrialTracker}
          onClick={() => confirmDeletePlant(rowData)}
        />
      </React.Fragment>
    );
  };

  const onRowEditInit = (e) => {
    setLabelToEdit(e.data);
    setEditing(true);
  };

  const onRowEditSave = (event) => {
    updateLabel(event.data);
    dispatch(
      setPopUp({
        severity: popUp.severities.SUCCESS,
        summary: popUp.summary.SUCCESSFUL,
        detail: `Template ${labelToEdit?.name} was updated.`,
        life: 5000,
        sticky: null,
      })
    );
    setEditing(false);
    setLabelToEdit(null);
  };

  const onRowEditCancel = () => {
    setEditing(false);
    setLabels(initialLabels);
  };

  const onEditorValueChange = (props, value) => {
    let updatedLabels = [...props.value];
    updatedLabels[props.rowIndex][props.field] = value;
    setLabels(updatedLabels);
  };

  const inputTextEditor = (props) => {
    let defaultWidth = '200px';
    let placeholder = '';
    return (
      <InputText
        type="text"
        value={props.rowData[props.field]}
        style={{ width: defaultWidth }}
        onChange={(e) => onEditorValueChange(props, e.target.value)}
        placeholder={placeholder}
      />
    );
  };

  const onLabelSelect = (event) => {
    history.push({
      pathname: `${links.LABEL}/${event.id}`,
      state: event,
    });
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div className={styles.linkStyle} onClick={() => onLabelSelect(rowData)}>
        {rowData.name}
      </div>
    );
  };

  const sourceBodyTemplate = (rowData) => (
    <>{rowData.copyFrom ? rowData.copyFrom.name : ''}</>
  );

  const rowClass = (data) => {
    return { systemStyle: data.source === fieldOrigin.SYSTEM };
  };

  const removeColumn = !isTrialTracker && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={actionBodyTemplate}
    />
  );

  const editColumn = !isTrialTracker && (
    <Column
      className={classNames(editing ? 'rowEditor-editButton-hidden ' : '')}
      rowEditor={true}
      header="Edit"
      headerStyle={{ width: '80px', height: '48px' }}
    />
  );

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  return (
    <div className={classNames(styles.labelTemplates, 'list-generic')}>
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={editing} />
      {displayLabelModal && (
        <NewLabelTemplate
          labelIsCreated={labelIsCreated}
          displayLabelModal={displayLabelModal}
          setLabelIsCreated={setLabelIsCreated}
          setDisplayLabelModal={setDisplayLabelModal}
          labelOptions={labels?.map((label) => ({
            name: label.name,
            id: label.id,
          }))}
          fetchLabels={fetchLabels}
        />
      )}
      <DataTable
        className={classNames('table-generic p-datatable-sm logs')}
        value={labels}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        loading={isLoading}
        reorderableColumns
        rowClassName={rowClass}
        scrollable
        emptyMessage=""
        editMode="row"
        onRowEditInit={onRowEditInit}
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
      >
        <Column
          field="name"
          sortField="name"
          body={(rowData) => nameBodyTemplate(rowData)}
          columnKey="name"
          header="Name"
          headerStyle={{ height: '48px' }}
          bodyStyle={{ height: '50px' }}
          editor={(props) => inputTextEditor(props)}
        />
        <Column
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          field="description"
          sortField="description"
          columnKey="description"
          header="Description"
          body={(rowData) =>
            ShowDetails(rowData[showDetailsFields.DESCRIPTION])
          }
          editor={(props) => inputTextEditor(props)}
        />
        <Column
          field="source"
          sortField="source"
          columnKey="source"
          header="Source"
          body={(rowData) => sourceBodyTemplate(rowData)}
        />
        {editColumn}
        {removeColumn}
      </DataTable>
      <Dialog
        visible={deleteLabelDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        modal
        footer={deleteLabelDialogFooter}
        onHide={hideDeleteLabelDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {labelToDelete && (
            <span>
              Are you sure you want to delete <b>{labelToDelete.name}</b> from
              the label templates?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default LabelTemplateList;
