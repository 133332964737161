import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { uploadImage } from '../../../Common/utils';
import { urls, useRequest } from '../../../Common/ApiServices';
import { errorCodes } from '../../../Common/globalConstants';
import FileUploader from './FileUploader/FileUploader';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import { initialPlantFormSchema, plantFormSchema } from './plantFormValidation';
import {
  fileTypes,
  initialPlantInfo,
  getPlantParams,
  activeOptions,
} from '../constants';
import styles from './PlantForm.module.scss';

const PlantForm = ({
  plantId,
  programs,
  classes,
  series,
  colors,
  breeders,
  marketStatuses,
  hardinessZones,
  displayPlantModal,
  setDisplayPlantModal,
  setSelectedPlant,
  setPlantIsUpdated,
  setPlantIsCreated,
  plantIsUpdated,
  plantIsCreated,
  setNewPlantId,
}) => {
  const [plantInfo, setPlantInfo] = useState(initialPlantInfo);
  const [isError, setIsError] = useState(false);
  const [submitAndNew, setSubmitAndNew] = useState(false);
  const [createdImage, setCreatedImage] = useState(null);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [pdfToDelete, setPdfToDelete] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const [validationPdfFail, setValidationPdfFail] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [allHardinessZones, setAllHardinessZones] = useState(hardinessZones);

  const fileUploadRef = useRef(null);
  const pdfUploadRef = useRef(null);
  const { error, sendRequest } = useRequest({});

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = useMemo(
    () => (isTrialTracker ? 'none' : 'inline-flex'),
    [isTrialTracker]
  );

  const refTableValues = {
    breeders: breeders,
    classes: classes,
    series: series,
    programs: programs,
    colors: colors,
    'market-statuses': marketStatuses,
  };

  const onRemovePdf = () => {
    const plant = { ...plantInfo };
    delete plant.pdfFile;
    setPlantInfo(plant);
    setPdfToDelete(true);
    formik.setFieldValue('pdfFileLink', null);
  };

  const getPlantInfo = async () => {
    const requestData = {
      url: urls.EXTRACT_PLANT,
      method: 'POST',
      data: getPlantParams(plantId),
    };

    const response = await sendRequest(requestData);

    let dataPlant = response.data.plant;
    dataPlant.program = response.data.program;
    dataPlant.class = response.data.class;
    dataPlant.series = response.data.series;
    dataPlant.breeder = response.data.breeder;
    dataPlant.marketStatus = response.data.marketStatus;
    dataPlant.active = response.data.plant.active
      ? { label: 'Yes', status: true }
      : { label: 'No', status: false };
    setPlantIsUpdated(false);
    setPlantInfo(dataPlant);
    getAllZones(dataPlant.hardinessZone, hardinessZones);

    return response;
  };

  const createPlantImage = async () => {
    const imageType = selectedImage.type.slice(6);

    const requestData = {
      url: urls.CREATE_PLANT_IMAGE,
      method: 'POST',
      data: { image: { type: imageType } },
    };

    const response = await sendRequest(requestData);
    response && setCreatedImage(response.data.image);
    return response;
  };

  const createPlant = async (plantParams) => {
    const keys = Object.keys(plantParams);
    createdImage &&
      (await uploadImage(
        setUploading,
        selectedImage.objectURL,
        createdImage.uploadUrl,
        setIsError
      ));
    const bodyFormData = new FormData();
    keys.forEach((key) => {
      plantParams[key] !== null && bodyFormData.append(key, plantParams[key]);
    });
    const requestData = {
      url: urls.CREATE_PLANT,
      method: 'POST',
      data: bodyFormData,
      headers: [{ 'Content-Type': 'multipart/form-data' }],
    };
    const response = await sendRequest(requestData);
    if (response) {
      setIsError(false);
      setNewPlantId(response.data.id);
      setPlantIsCreated(true);
    }
    setSubmitAndNew(false);
    return response;
  };

  const updatePlant = async (plantParams) => {
    const keys = Object.keys(plantParams);
    createdImage &&
      (await uploadImage(
        setUploading,
        selectedImage.objectURL,
        createdImage.uploadUrl,
        setIsError
      ));
    const bodyFormData = new FormData();
    keys.forEach((key) => {
      plantParams[key] !== null && bodyFormData.append(key, plantParams[key]);
    });
    pdfToDelete && bodyFormData.append('pdfFile', new Blob(), '');

    const requestData = {
      url: urls.UPDATE_PLANT,
      method: 'POST',
      data: bodyFormData,
      headers: [{ 'Content-Type': 'multipart/form-data' }],
    };

    const response = await sendRequest(requestData);
    if (response) {
      setIsError(false);
      setPlantIsUpdated(true);
    }
    return response;
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty || selectedImage || isImageDeleted || selectedPdf) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setDisplayPlantModal(submitAndNew);
    formik.resetForm();
    setPlantInfo(initialPlantInfo);
    setSelectedImage(null);
    setCreatedImage(null);
    setIsImageDeleted(false);
    setSelectedPdf(null);
    setSelectedPlant(null);
    fileUploadRef.current?.clear();
    pdfUploadRef.current?.clear();
    setPdfToDelete(false);
  };

  const onTemplateSelect = (e) => {
    setSelectedImage(e.files[0]);
  };
  const onPdfTemplateSelect = (e) => {
    setSelectedPdf(e.files[0]);
  };

  const onTemplateRemove = () => {
    setSelectedImage(null);
    setCreatedImage(null);
    fileUploadRef.current.clear();
  };

  const onPdfTemplateRemove = () => {
    setSelectedPdf(null);
    pdfUploadRef.current.clear();
  };

  const onImageRemove = () => {
    setIsImageDeleted(true);
    setSelectedImage(null);
    setCreatedImage(null);
  };

  const onValidationFail = () => {
    setValidationFail(true);
  };

  const onValidationPdfFail = () => {
    setValidationPdfFail(true);
  };

  const formik = useFormik({
    initialValues: initialPlantFormSchema(plantInfo),
    enableReinitialize: true,
    validationSchema: plantFormSchema,
    onSubmit: (values) => {
      const currentImage =
        !isImageDeleted && plantInfo.image ? plantInfo.image?.id : null;
      let newPlantParams = {
        name: values.name,
        code: values.code,
        programId: values.program?.id || null,
        classId: values.class?.id || null,
        seriesId: values.series?.id || null,
        colorId: values.color?.id || null,
        breederId: values.breeder?.id || null,
        marketStatusId: values.marketStatus?.id || null,
        hardinessZoneId: values.hardinessZone?.id || null,
        patentNumber: values.patentNumber || null,
        tradeName: values.tradeName || null,
        scientificName: values.scientificName || null,
        active: values.active.status ? 'true' : 'false',
        description: values.description || null,
        note: values.note || null,
        imageId: currentImage || createdImage?.id || null,
        pdfFile: selectedPdf || null,
      };
      if (plantId) {
        newPlantParams = {
          ...newPlantParams,
          ...{
            id: plantId,
            versionNumber: plantInfo.version?.number || 0,
          },
        };
        updatePlant(newPlantParams);
      } else {
        createPlant(newPlantParams);
      }
    },
  });

  const onSubmitAndNew = () => {
    setSubmitAndNew(true);
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  const submitAndNewButton = !plantId && (
    <Button
      className={styles.button}
      label="Save and new"
      type="submit"
      onClick={onSubmitAndNew}
      disabled={Boolean(
        !formik.dirty ||
          uploading ||
          !formik.values.name ||
          !formik.values.code ||
          formik.errors.description ||
          formik.errors.scientificName ||
          formik.errors.tradeName ||
          formik.errors.note
      )}
      icon="pi pi-check"
      autoFocus
    />
  );

  const refTableHeader = () => {
    if (currentRefTableName === 'market-statuses') {
      return 'Market Status';
    }
    return (
      currentRefTableName[0]?.toUpperCase() + currentRefTableName?.slice(1)
    );
  };

  const errorHandler = (error) => {
    let message = errorCodes.DEFAULT_MESSAGE.text;
    if (error.response?.data?.errors) {
      error.response.data.errors.map(
        (err) => (
          formik.setFieldError(err.fieldName, errorCodes[err.code]),
          err.code === errorCodes.NOT_VALID_IMAGE_TYPE &&
            ((message = errorCodes.NOT_VALID_IMAGE_TYPE.text),
            onTemplateRemove())
        )
      );
      setIsError(true);
      setPlantIsCreated(false);
    }
  };

  const getAllZones = (value, options) => {
    const allOptions = options ? [...options] : [];
    let copyValue = value && { ...value };
    if (copyValue) {
      const res = _.find(options, function (val) {
        return _.isEqual(copyValue, val);
      });
      !res && allOptions.push(copyValue);
    }
    setAllHardinessZones(allOptions);
  };

  useEffect(() => {
    if (plantId) {
      getPlantInfo();
    }
  }, [plantId]);

  useEffect(() => {
    if (!isError && (plantIsCreated || plantIsUpdated)) {
      onHide();
    }
  }, [isError, plantIsUpdated, plantIsCreated]);

  useEffect(() => {
    if (selectedImage) {
      createPlantImage();
      setValidationFail(false);
    } else if (selectedPdf) {
      setValidationPdfFail(false);
    }
  }, [selectedImage, selectedPdf]);

  useEffect(() => {
    if (validationFail) {
      setCreatedImage(null);
      setSelectedImage(null);
    } else if (validationPdfFail) {
      setSelectedPdf(null);
    }
  }, [validationFail, validationPdfFail]);

  useEffect(() => {
    error && errorHandler(error);
  }, [error]);

  return (
    <>
      <Dialog
        className={styles.plantFormDialog}
        header="Plant Information"
        visible={displayPlantModal}
        onHide={() => confirm(formik.dirty)}
      >
        <PromptIfDirty dirty={formik.dirty} />
        <Tooltip
          target=".custom-choose-btn"
          content="Choose"
          position="bottom"
        />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                  disabled={isTrialTracker}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                ) : (
                  <small>Plant Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="code" className="p-col-12 p-md-3 p-text-bold">
                Code*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="code"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  aria-describedby="code-invalid"
                  className={formik.errors.code ? 'p-invalid' : null}
                  disabled={isTrialTracker}
                />
                {formik.touched.code && formik.errors.code ? (
                  <small id="code-invalid" className="p-error p-d-block">
                    {formik.errors.code}
                  </small>
                ) : (
                  <small>Plant Code is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.description ? 'p-invalid' : null}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                  disabled={isTrialTracker}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="breeder" className="p-col-12 p-md-3">
                Breeder
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="breeder"
                  onChange={formik.handleChange}
                  value={formik.values.breeder}
                  options={breeders}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('breeders')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="program" className="p-col-12 p-md-3">
                Program
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="program"
                  onChange={formik.handleChange}
                  value={formik.values.program}
                  options={programs}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('programs')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="class" className="p-col-12 p-md-3">
                Class
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="class"
                  onChange={formik.handleChange}
                  value={formik.values.class}
                  options={classes}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('classes')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="series" className="p-col-12 p-md-3">
                Series
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="series"
                  onChange={formik.handleChange}
                  value={formik.values.series}
                  options={series}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('series')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="series" className="p-col-12 p-md-3">
                Color
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="color"
                  onChange={formik.handleChange}
                  value={formik.values.color}
                  options={colors}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('colors')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="marketStatus" className="p-col-12 p-md-3">
                Market Status
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="marketStatus"
                  onChange={formik.handleChange}
                  value={formik.values.marketStatus}
                  options={marketStatuses}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('market-statuses')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="hardinessZone" className="p-col-12 p-md-3">
                Hardiness Zone
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="hardinessZone"
                  onChange={formik.handleChange}
                  value={formik.values.hardinessZone}
                  options={allHardinessZones || hardinessZones}
                  optionLabel="zone"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="active" className="p-col-12 p-md-3">
                Active
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="active"
                  onChange={formik.handleChange}
                  optionLabel="label"
                  value={formik.values.active}
                  className="p-dropdown"
                  options={activeOptions}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="patentNumber" className="p-col-12 p-md-3">
                Patent Number
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="patentNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.patentNumber}
                  aria-describedby="patentNumber-invalid"
                  className={formik.errors.patentNumber ? 'p-invalid' : null}
                  disabled={isTrialTracker}
                />
                {formik.touched.tradeName && formik.errors.patentNumber && (
                  <small
                    id="patentNumber-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.patentNumber}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="tradeName" className="p-col-12 p-md-3">
                Trade Name
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="tradeName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tradeName}
                  aria-describedby="tradeName-invalid"
                  className={formik.errors.tradeName ? 'p-invalid' : null}
                  disabled={isTrialTracker}
                />
                {formik.touched.tradeName && formik.errors.tradeName && (
                  <small id="tradeName-invalid" className="p-error p-d-block">
                    {formik.errors.tradeName}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="scientificName" className="p-col-12 p-md-3">
                Scientific Name
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="scientificName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.scientificName}
                  aria-describedby="scientificName-invalid"
                  className={formik.errors.scientificName ? 'p-invalid' : null}
                  disabled={isTrialTracker}
                />
                {formik.touched.scientificName && formik.errors.scientificName && (
                  <small
                    id="scientificName-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.scientificName}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                  disabled={isTrialTracker}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <FileUploader
            fileType={fileTypes.PDF}
            fileRef={pdfUploadRef}
            name="plantPdf"
            accept=".pdf"
            maxFileSize={1000000}
            onSelect={onPdfTemplateSelect}
            uploading={uploading}
            plantInfo={plantInfo}
            selectedPdf={selectedPdf}
            isTrialTracker={isTrialTracker}
            onValidationFail={onValidationPdfFail}
            onTemplateRemove={onPdfTemplateRemove}
            onRemove={onRemovePdf}
            showElements={showElements}
            disabled={Boolean(formik.values.pdfFileLink || isTrialTracker)}
          />
          <FileUploader
            fileType={fileTypes.IMAGE}
            fileRef={fileUploadRef}
            name="plantImage"
            accept=".jpeg,.jpg,.png"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onValidationFail={onValidationFail}
            uploading={uploading}
            plantInfo={plantInfo}
            isTrialTracker={isTrialTracker}
            isImageDeleted={isImageDeleted}
            selectedImage={selectedImage}
            onTemplateRemove={onTemplateRemove}
            onRemove={onImageRemove}
            showElements={showElements}
            disabled={Boolean(
              selectedImage ||
                (plantInfo.image && !isImageDeleted) ||
                isTrialTracker
            )}
          />
          <div
            className={classNames(
              'p-grid p-col-12 p-justify-end margin-l-0',
              styles.noTopPadding,
              styles.noRightPadding
            )}
          >
            {submitAndNewButton}
            <Button
              className={styles.button}
              style={{ display: showElements }}
              label="Save"
              type="submit"
              disabled={Boolean(
                (!formik.dirty &&
                  !selectedImage &&
                  !isImageDeleted &&
                  !selectedPdf &&
                  !pdfToDelete) ||
                  !formik.values.name ||
                  !formik.values.code ||
                  formik.errors.description ||
                  formik.errors.scientificName ||
                  formik.errors.tradeName ||
                  formik.errors.name ||
                  formik.errors.note
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              style={{ display: showElements }}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              disabled={Boolean(uploading)}
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </>
  );
};

export default PlantForm;
