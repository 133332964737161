import * as actionTypes from './actions';

const initialState = {
  loading: false,
  company: null,
  dateFormat: null,
  employee: null,
  firstDayOfWeek: null,
};

export const profileInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.PROFILE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        company: action.profileInfo.company,
        dateFormat: action.profileInfo.dateFormat,
        employee: action.profileInfo.employee,
        firstDayOfWeek: action.profileInfo.firstDayOfWeek,
      };
    case actionTypes.PROFILE_INFO_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
