import * as yup from 'yup';
import { log10 } from 'chart.js/helpers';

export const initialSettingsSchema = (settingsInfo) => {
  return {
    accountId: settingsInfo?.accountId || '',
    bucket: settingsInfo?.bucket || '',
  };
};

const bucketValidNameMessage =
  "Can't be formatted as an IP address. Can't begin with the prefix xn-- and end with the suffix -s3alias." +
  ' Must begin and end with a letter or number. ' +
  ' Contains only lowercase letters, numbers, dots and hyphens';
const bucketLengthMessage =
  'Must be between 3 (min) and 63 (max) characters long';

export const settingsSchema = yup.object().shape({
  accountId: yup
    .string()
    .required('Account Id is a required field.')
    .test(
      'len',
      'Must be exactly 12 characters.',
      (val) => !val || (val && val.toString().length === 12)
    )
    .matches(/^[0-9-]*$/, 'Account Id can only contain numbers.'),
  bucket: yup
    .string()
    .required('Bucket Name is a required field')
    .min(3, bucketLengthMessage)
    .max(63, bucketLengthMessage)
    .matches(/^(?!xn--).*/, bucketValidNameMessage)
    .matches(/^(?!.*\-s3alias$)[/\w\.-]+$/, bucketValidNameMessage)
    .matches(/^(?!.*\-$)[/\w\.-]+$/, bucketValidNameMessage)
    .matches(
      /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/,
      bucketValidNameMessage
    ),
});
