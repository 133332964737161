import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { CSSTransition } from 'react-transition-group';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import countries from 'iso3166-2-db/i18n/dispute/UN/en';
import { isEmpty } from 'lodash';
import {
  companyFormSchema,
  initialCompanyFormSchema,
} from './companyFormValidation';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import { checkLimitExceeded, getFilteredCountries } from '../../Common/utils';
import { urls, useRequest } from '../../Common/ApiServices';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import PromptIfDirty from '../../Common/PromptIfDirty';
import EmailModalForm from '../EmailModalForm/EmailModalForm';
import { firstDaysOfWeek } from '../../Locations/constants';
import { errorCodes, messages, statuses } from '../constants';
import { popUp } from '../../Common/globalConstants';
import styles from './CompanyForm.module.scss';

const CompanyForm = ({ isVerifyModal = false }) => {
  const initialCompanyInfo = {
    name: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: {},
    postalCode: '',
    country: {},
    phoneNumber: '',
    webAddress: '',
  };

  const [companyInfo, setCompanyInfo] = useState(initialCompanyInfo);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { error, sendRequest } = useRequest({});

  const isActiveSubscription = useSelector(
    (state) => state.isActiveSubscription.isActive
  );

  const dispatch = useDispatch();

  const getStatus = useCallback(() => {
    const status = location.search.substring(1).split('=')[1];
    if (status === statuses.OUTDATED) {
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: messages.OUTDATED,
          life: 5000,
        })
      );
    } else if (status === statuses.CONFIRMED) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: messages.CONFIRMED,
          life: 5000,
        })
      );
    }
  }, []);

  const verifyCompanyEmail = useCallback(async (email) => {
    const requestData = {
      url: urls.SEND_COMPANY_EMAIL_VERIFICATION,
      method: 'POST',
      data: { email: email },
    };

    const response = await sendRequest(requestData);

    if (response) {
      setIsModalOpen(false);
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: messages.TO_COMPLETE,
          life: 5000,
        })
      );
    }
  }, []);

  const getCompanyInfo = useCallback(async () => {
    const requestData = {
      url: urls.EXTRACT_COMPANY,
      method: 'POST',
      data: {},
    };

    const response = await sendRequest(requestData);

    if (response) {
      const countryCode = response.data.address.countryId;
      const countryName = filteredCountries[countryCode].name;
      const stateCode = response.data.address.stateId || '';
      const states = filteredCountries[countryCode].regions.filter(
        (region) => region.iso === stateCode
      );

      const stateName = states.length && states[0].name;

      const dataCompany = {
        id: response.data.id,
        name: response.data.name,
        email: response.data.email || '',
        emailVerified: response.data.emailVerified,
        webAddress: response.data.webAddress,
        phoneNumber: response.data.phoneNumber,
        address: response.data.address.address || '',
        address2: response.data.address?.address2 || '',
        postalCode: response.data.address?.postalCode || '',
        city: response.data.address?.city || '',
        country: { name: countryName, code: countryCode },
        state: { name: stateName, code: stateCode },
        firstDayOfWeek: response.data.firstDayOfWeek,
      };

      setIsUpdated(false);
      setCompanyInfo(dataCompany);
      setSelectedCountry(dataCompany.country);
    }
  }, [filteredCountries]);

  const countryList = Object.entries(filteredCountries)
    .map((country) => ({ code: country[0], name: country[1].name }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const accept = useCallback(() => {
    formik.handleReset();
  }, []);

  const confirm = useCallback(
    (dirty) => {
      if (dirty) {
        confirmDialog({
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          message: messages.TO_CONFIRM,
          accept,
        });
      } else {
        accept();
      }
    },
    [accept]
  );

  const formik = useFormik({
    initialValues: initialCompanyFormSchema(companyInfo),
    enableReinitialize: true,
    validationSchema: companyFormSchema,
    onSubmit: async (values) => {
      const newCompanyParams = {
        name: values?.name,
        email: values.email,
        phoneNumber: values?.phoneNumber || null,
        webAddress: values?.webAddress || null,
        firstDayOfWeek: values?.firstDay.day || null,
        address: {
          address: values?.address || '',
          address2: values?.address2 || '',
          city: values?.city || '',
          countryId: values?.country?.code || '',
          stateId: values?.state?.code || '',
          postalCode: values?.postalCode || '',
        },
      };

      const requestData = {
        url: urls.UPDATE_COMPANY,
        method: 'POST',
        data: newCompanyParams,
      };

      const response = await sendRequest(requestData);
      if (response) {
        getCompanyInfo();
        dispatch(
          setPopUp({
            severity: popUp.severities.SUCCESS,
            summary: popUp.summary.SUCCESSFUL,
            detail: messages.UPDATED,
            life: 5000,
          })
        );
      }
    },
  });

  const onCountryChange = useCallback((event) => {
    formik.setFieldValue('country', event.value);
    setSelectedCountry(event.value);
  }, []);

  const breadCrumbItems = [{ label: 'Company information' }];

  useEffect(() => {
    checkLimitExceeded(dispatch, false);
    getStatus();
  }, []);

  useEffect(() => {
    !isEmpty(filteredCountries) && getCompanyInfo();
  }, [filteredCountries]);

  useEffect(() => {
    isUpdated && getCompanyInfo();
  }, [isUpdated]);

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = filteredCountries[selectedCountry.code]?.regions
        .map((region) => ({ name: region.name, code: region.iso }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      setStates(countryStates);
      formik.values.state = countryStates[0] || '';
    }
  }, [selectedCountry]);

  useEffect(() => {
    setFilteredCountries(getFilteredCountries(countries));
    isVerifyModal && setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (error) {
      if (error.response.data.errors) {
        error.response.data.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      }
    }
  }, [error]);

  return (
    <div className={styles.companyInfo}>
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={formik.dirty} />
      <div className={styles.companyForm}>
        <h3>Company Information</h3>
        <Divider />
        <CSSTransition
          classNames="fade"
          in={isModalOpen}
          timeout={300}
          unmountOnExit
        >
          <EmailModalForm
            email={companyInfo.email}
            setIsModalOpen={setIsModalOpen}
            verifyCompanyEmail={verifyCompanyEmail}
            emailVerified={companyInfo.emailVerified}
          />
        </CSSTransition>

        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="name"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                Name*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  disabled={!isActiveSubscription}
                  className={
                    formik.errors.name && formik.touched.name && 'p-invalid'
                  }
                />
                {formik.touched.name && formik.errors.name && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="email" className="p-col-12 p-md-2 p-text-bold">
                Email*
              </label>
              <div className="p-col-9 p-md-7 p-lg-4 p-xl-4">
                <InputText
                  id="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  aria-describedby="email-invalid"
                  disabled={!isActiveSubscription}
                  className={
                    formik.errors.email && formik.touched.email && 'p-invalid'
                  }
                />
                {formik.touched.email && formik.errors.email && (
                  <small id="email-invalid" className="p-error p-d-block">
                    {formik.errors.email}
                  </small>
                )}
              </div>
              <div className="p-col-1 p-md-1">
                <Button
                  type="button"
                  label={companyInfo.emailVerified ? ' Verified' : 'Verify'}
                  className={styles.verifyButton}
                  onClick={() => setIsModalOpen(true)}
                  disabled={companyInfo.emailVerified}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="address"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                Address Line 1*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  disabled={!isActiveSubscription}
                  aria-describedby="address-invalid"
                  className={
                    formik.errors.address &&
                    formik.touched.address &&
                    'p-invalid'
                  }
                />
                {formik.touched.address && formik.errors.address && (
                  <small id="address-invalid" className="p-error p-d-block">
                    {formik.errors.address}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="address2" className="p-col-12 p-md-2 p-xl-2">
                Address Line 2
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="address2"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address2}
                  disabled={!isActiveSubscription}
                  aria-describedby="address2-invalid"
                  className={
                    formik.errors.address2 &&
                    formik.touched.address2 &&
                    'p-invalid'
                  }
                />
                {formik.touched.address2 && formik.errors.address2 && (
                  <small id="address2-invalid" className="p-error p-d-block">
                    {formik.errors.address2}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="city"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                City*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  disabled={!isActiveSubscription}
                  aria-describedby="city-invalid"
                  className={
                    formik.errors.city && formik.touched.city && 'p-invalid'
                  }
                />
                {formik.touched.city && formik.errors.city && (
                  <small id="city-invalid" className="p-error p-d-block">
                    {formik.errors.city}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="country" className="p-col-12 p-md-2 p-text-bold">
                Country*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="country"
                  onChange={onCountryChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'country' } });
                  }}
                  aria-describedby="type-invalid"
                  className={`${
                    formik.errors.country &&
                    formik.touched.country &&
                    'p-invalid'
                  }`}
                  value={formik.values.country}
                  disabled={!isActiveSubscription}
                  options={countryList}
                  optionLabel="name"
                  filter
                />
                {formik.touched.state && formik.errors.state && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik?.errors?.country?.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="state" className="p-col-12 p-md-2 p-text-bold">
                State/Province*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="state"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'state' } });
                  }}
                  value={formik.values.state}
                  aria-describedby="state-invalid"
                  className={`${
                    formik.errors.state && formik.touched.state && 'p-invalid'
                  }`}
                  options={states}
                  disabled={!isActiveSubscription}
                  optionLabel="name"
                  filter
                />
                {formik.touched.state && formik.errors.state && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik?.errors?.state?.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="postalCode"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                Postal Code*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="postalCode"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                  disabled={!isActiveSubscription}
                  aria-describedby="postalCode-invalid"
                  className={
                    formik.errors.postalCode &&
                    formik.touched.postalCode &&
                    'p-invalid'
                  }
                />
                {formik.touched.postalCode && formik.errors.postalCode && (
                  <small id="postalCode-invalid" className="p-error p-d-block">
                    {formik.errors.postalCode}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="firstDay"
                className="p-col-12 p-md-2 p-xl-2 p-text-bold"
              >
                First Day of Week*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="firstDay"
                  onChange={formik.handleChange}
                  value={formik.values.firstDay}
                  options={firstDaysOfWeek}
                  optionLabel="name"
                />
                {formik.touched.firstDay && formik.errors.firstDay && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik.errors.firstDay}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="phoneNumber" className="p-col-12 p-md-2 p-xl-2">
                Phone Number
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputMask
                  className={
                    formik.errors.phoneNumber &&
                    formik.touched.phoneNumber &&
                    'p-invalid'
                  }
                  id="phoneNumber"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                  value={formik.values.phoneNumber}
                  disabled={!isActiveSubscription}
                  onChange={formik.handleChange}
                  aria-describedby="phoneNumber-invalid"
                />
                {formik.errors.phoneNumber ? (
                  <small id="phoneNumber-invalid" className="p-error p-d-block">
                    {formik.errors.phoneNumber}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="webAddress" className="p-col-12 p-md-2 p-xl-2">
                Web Address
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="webAddress"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.webAddress}
                  disabled={!isActiveSubscription}
                  aria-describedby="webAddress-invalid"
                  className={formik.errors.webAddress && 'p-invalid'}
                />
                {formik.errors.webAddress && (
                  <small id="webAddress-invalid" className="p-error p-d-block">
                    {formik.errors.webAddress}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-lg-10 p-xl-7 p-justify-end pad-r-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                !formik.dirty ||
                  !formik?.values?.name ||
                  !formik?.values?.email ||
                  !formik?.values?.address ||
                  !formik?.values?.state?.name ||
                  formik?.errors?.name ||
                  formik?.errors?.city ||
                  formik?.errors?.state ||
                  formik?.errors?.postalCode ||
                  formik?.errors?.firstDay ||
                  formik?.errors?.country ||
                  formik?.errors?.phoneNumber ||
                  formik?.errors?.address ||
                  formik?.errors?.address2 ||
                  formik?.values?.phoneNumber
                    .replace('-', '')
                    .replace('(', '')
                    .replace(')', '')
                    .replace(' ', '') === companyInfo.phoneNumber
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              disabled={!formik.dirty}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyForm;
