import React from 'react';
import isEqual from 'lodash/isEqual';
import { Paginator } from 'primereact/paginator';
import { types, getNoFiltersMessage, getNoEntriesMessage } from './constants';

const Footer = ({
  totalRecords,
  initParams,
  params,
  type,
  singular,
  pagination,
  onPageSelect,
}) => {
  if (!totalRecords) {
    const emptyMessage = isEqual(initParams, params)
      ? getNoEntriesMessage(type, singular)
      : getNoFiltersMessage(type, singular);

    return (
      <div className="generic-list-message">
        <h3 className="p-text-center">{emptyMessage}</h3>
      </div>
    );
  } else {
    return (
      type !== types.APPLICATION && (
        <Paginator
          rows={pagination.rows}
          totalRecords={totalRecords}
          className="tabPaginator"
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      )
    );
  }
};

export default Footer;
