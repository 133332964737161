export const GET_SECTION_OPTIONS = 'GET_SECTION_OPTIONS';
export const SECTION_OPTIONS_REQUEST = 'SECTION_OPTIONS_REQUEST';
export const SECTION_OPTIONS_SUCCESS = 'SECTION_OPTIONS_SUCCESS';
export const SECTION_OPTIONS_FAIL = 'SECTION_OPTIONS_FAIL';

export const getSectionOptions = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_SECTION_OPTIONS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const sectionOptionsRequest = () => ({ type: SECTION_OPTIONS_REQUEST });

export const sectionOptionsSuccess = (sectionTypes, zones) => ({
  type: SECTION_OPTIONS_SUCCESS,
  sectionTypes: sectionTypes,
  zones: zones,
});

export const sectionOptionsFail = (error) => ({
  type: SECTION_OPTIONS_FAIL,
  error: error,
});
