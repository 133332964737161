import * as yup from 'yup';

export const initialProductFormSchema = (productInfo) => {
  return {
    productCode: productInfo.code,
    tradeName: productInfo.tradeName,
    productName: productInfo.name,
    category: productInfo.category || undefined,
    classification: productInfo.classification || undefined,
    manufacturer: productInfo.manufacturer || undefined,
    note: productInfo.note || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const productFormSchema = yup.object().shape({
  productCode: yup
    .string()
    .required('Product code should be provided.')
    .max(32, lengthError),
  tradeName: yup
    .string()
    .required('Trade name should be provided.')
    .max(32, lengthError),
  productName: yup.string().max(32, lengthError),
  category: yup.object(),
  classification: yup.object(),
  manufacturer: yup.object(),
  note: yup.string().max(1024, lengthError),
});
