import * as actionTypes from './actions';

const initialState = {
  loading: false,
  sectionTypes: null,
  zones: null,
};

export const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SECTION_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.SECTION_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sectionTypes: action.sectionTypes,
        zones: action.zones,
      };
    case actionTypes.SECTION_OPTIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
