import * as actionTypes from './actions';

const initialState = {
  trialName: null,
  trialId: null,
  locations: null,
  logFrequencies: null,
  employees: null,
  cropTemplates: null,
  statuses: null,
  profileReady: false,
};

export const trialReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRIAL_INFO:
      return {
        ...state,
        trialName: action.name,
        trialId: action.id,
      };
    case actionTypes.PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: action.locations,
        logFrequencies: action.logFrequencies,
        employees: action.employees,
        cropTemplates: action.cropTemplates,
        statuses: action.statuses,
        profileReady: true,
      };
    case actionTypes.PROFILE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
