import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { fileTypes } from '../../constants';
import styles from './FileUploader.module.scss';

const FileUploader = ({
  fileType,
  fileRef,
  onSelect,
  onValidationFail,
  onTemplateRemove,
  uploading,
  plantInfo,
  isImageDeleted,
  isTrialTracker,
  selectedImage,
  selectedPdf,
  disabled,
  onRemove,
  name,
  accept,
  showElements,
}) => {
  const headerTemplate = (options) => {
    const { className, chooseButton } = options;
    const progressBar = uploading && selectedImage && (
      <div className="p-d-flex p-jc-end">
        <h4>Uploading image...</h4>
        <ProgressBar
          className={classNames('p-ml-5 p-as-center', styles.progressBar)}
          mode="indeterminate"
        />
      </div>
    );
    return (
      <div
        className={classNames(className, 'p-jc-between', styles.uploaderHeader)}
      >
        {chooseButton}
        {progressBar}
      </div>
    );
  };

  const pdfHeaderTemplate = (options) => {
    const { className, chooseButton } = options;
    const progressBar = uploading && selectedPdf && (
      <div className="p-d-flex p-jc-end">
        <h4>Uploading file...</h4>
        <ProgressBar
          className={classNames('p-ml-5 p-as-center', styles.progressBar)}
          mode="indeterminate"
        />
      </div>
    );
    return (
      <div
        className={classNames(className, 'p-jc-between', styles.uploaderHeader)}
      >
        {chooseButton}
        {progressBar}
      </div>
    );
  };

  const pdfItemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className={classNames('p-d-flex p-ai-center', styles.preview)}>
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ display: showElements }}
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={onTemplateRemove}
        />
      </div>
    );
  };

  const pdfEmptyTemplate = () => {
    if (plantInfo.pdfFile) {
      return (
        <div className="p-d-flex p-ai-center p-flex-wrap">
          <div className={classNames('p-d-flex p-ai-center', styles.preview)}>
            <a href={plantInfo.pdfFile.url} target="_blank" rel="noreferrer">
              {plantInfo.pdfFile.name}
            </a>
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            style={{ display: showElements }}
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={onRemove}
          />
        </div>
      );
    }
    return (
      <div className={classNames('p-d-flex p-ai-center p-dir-col')}>
        <i
          className={classNames(
            'pi pi-image p-mt-1 p-p-3',
            styles.emptyAreaIcon
          )}
        />
        <span className={classNames('p-my-2', styles.emptyAreaText)}>
          {isTrialTracker ? '' : 'Drag and Drop  PDF File Here'}
        </span>
      </div>
    );
  };

  const pdfChooseOptions = {
    icon: 'pi pi-fw pi-file-pdf',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className={classNames('p-d-flex p-ai-center', styles.preview)}>
          <img
            className={styles.plantImage}
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={180}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ display: showElements }}
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={onTemplateRemove}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    if (plantInfo.image && !isImageDeleted) {
      return (
        <div className="p-d-flex p-ai-center p-flex-wrap">
          <div className={classNames('p-d-flex p-ai-center', styles.preview)}>
            <img
              className={styles.plantImage}
              role="presentation"
              alt={plantInfo.name}
              src={plantInfo.image.full}
              width={250}
            />
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            style={{ display: showElements }}
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={onRemove}
          />
        </div>
      );
    }
    return (
      <div className={classNames('p-d-flex p-ai-center p-dir-col')}>
        <i
          className={classNames(
            'pi pi-image p-mt-1 p-p-3',
            styles.emptyAreaIcon
          )}
        />
        <span className={classNames('p-my-2', styles.emptyAreaText)}>
          {isTrialTracker ? '' : 'Drag and Drop Image Here'}
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: `custom-choose-btn p-button-rounded p-button-outlined
    ${(selectedImage || (plantInfo.image && !isImageDeleted)) && 'p-disabled'}`,
  };

  return (
    <>
      <FileUpload
        ref={fileRef}
        name={name}
        className={styles.fileUploader}
        accept={accept}
        maxFileSize={1000000}
        onSelect={onSelect}
        onValidationFail={onValidationFail}
        headerTemplate={
          fileType === fileTypes.IMAGE ? headerTemplate : pdfHeaderTemplate
        }
        itemTemplate={
          fileType === fileTypes.IMAGE ? itemTemplate : pdfItemTemplate
        }
        emptyTemplate={
          fileType === fileTypes.IMAGE ? emptyTemplate : pdfEmptyTemplate
        }
        chooseOptions={
          fileType === fileTypes.IMAGE ? chooseOptions : pdfChooseOptions
        }
        disabled={disabled}
      />
    </>
  );
};

export default FileUploader;
