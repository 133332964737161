import * as actionTypes from './actions';

const initialState = {
  groups: null,
  error: null,
};

export const profileFieldGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_FIELD_GROUPS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.PROFILE_FIELD_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.groups,
      };
    case actionTypes.PROFILE_FIELD_GROUPS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
