// Use this hook in react functional components
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  checkBlocked,
  getAuthConfig,
  isAuth,
} from '../../../auth/auth-service';
import {
  checkLimitExceeded,
  checkSubscription,
  showErrorMessage,
} from '../utils';
import { useAuth0 } from '@auth0/auth0-react';

function useRequest({ checkTokenExp }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { logout, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const sendRequest = useCallback(
    async ({ url, method, data, headers = [], responseType = 'json' }) => {
      setIsLoading(true);
      setError(null);
      try {
        const authConfig = await getAuthConfig(
          checkTokenExp,
          logout,
          getAccessTokenSilently
        );
        headers.push(authConfig.headers);
        headers = headers.reduce((r, c) => Object.assign(r, c), {});
        if (!isAuth()) {
          headers = {};
        }
        return await axios({
          method: method ? method : 'GET',
          headers: headers,
          url: `${authConfig.apiUrl}/${url}`,
          data: data ? data : {},
          responseType: responseType,
        });
      } catch (error) {
        if (error?.response?.data?.error?.code) {
          showErrorMessage(error.response.data.error.code, dispatch, url);
          error.response.data.error.code === 'NO_ACTIVE_SUBSCRIPTION' &&
            checkSubscription(error, dispatch);
          error.response.data.error.code === 'ENTITY_LIMIT_EXCEEDED' &&
            checkLimitExceeded(dispatch, true);
        }

        checkBlocked(error, logout);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [checkTokenExp, dispatch, getAccessTokenSilently, logout]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
}

export default useRequest;
