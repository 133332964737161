import * as actionTypes from './actions';

const initialState = {
  defaultLocation: {
    id: null,
    name: null,
    firstDay: null,
    dateFormat: null,
  },
  locations: null,
  trials: null,
  types: null,
  products: null,
  statuses: null,
  employees: null,
};

export const taskOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASK_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TASK_OPTIONS_SUCCESS:
      const defaultLocation = action.locations[0];
      return {
        ...state,
        loading: false,
        defaultLocation: {
          id: defaultLocation.id,
          name: defaultLocation.name,
          firstDay: defaultLocation.firstDay,
          dateFormat: defaultLocation.dateFormat,
        },
        locations: action.locations,
        trials: action.trials,
        types: action.types,
        products: action.products,
        statuses: action.statuses,
        employees: action.employees,
      };
    case actionTypes.TASK_OPTIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
