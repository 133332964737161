import * as yup from 'yup';

export const initialModalSchema = (companyInfo) => {
  return {
    email: companyInfo.email || '',
  };
};

export const modalSchema = yup.object().shape({
  email: yup
    .string()
    .required('Company Email should be provided.')
    .email('Invalid email format.')
    .max(255, 'Maximum length exceeded.'),
});
