import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import merge from 'deepmerge';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import EmployeeForm from '../EmployeeForm/EmployeeForm';
import { capitalize, getFormattedDate, wait } from '../../Common/utils';
import { dateFormat, popUp } from '../../Common/globalConstants';
import { employeeState } from '../constants';
import { urls, useRequest } from '../../Common/ApiServices';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import styles from './EmployeeList.module.scss';

const EmployeeList = () => {
  const initialEmployeeParams = {
    query: {},
    navigation: {
      sort: [
        {
          key: 'lastName',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [employees, setEmployees] = useState([]);
  const [employeeParams, setEmployeeParams] = useState(initialEmployeeParams);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null);
  const [sort, setSort] = useState({ field: 'lastName', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [displayEmployeeModal, setDisplayEmployeeModal] = useState(false);
  const [employeeIs, setEmployeeIs] = useState(employeeState.DEFAULT);
  const [newEmployeeId, setNewEmployeeId] = useState(null);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [deleteEmployeeDialog, setDeleteEmployeeDialog] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(380);

  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const { sendRequest } = useRequest({});

  const fetchEmployees = async () => {
    setLoading(true);
    let thumbnailIsReady = true;
    if (
      employeeIs === employeeState.UPDATED ||
      employeeIs === employeeState.CREATED
    ) {
      const employeeId =
        employeeIs === employeeState.CREATED
          ? newEmployeeId
          : selectedEmployee.id;
      thumbnailIsReady = await checkThumbnail(employeeId);
    }
    if (thumbnailIsReady) {
      const requestData = {
        url: urls.BROWSE_EMPLOYEES,
        method: 'POST',
        data: employeeParams,
      };

      const response = await sendRequest(requestData);

      if (response) {
        const employees = await response.data.results.map(({ employee }) => ({
          id: employee.id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          title: employee.title || '',
          phone: employee.phone || '',
          email: employee.email || '',
          department: employee.department || '',
          picture: employee.picture || {},
          roles: employee.roles
            ?.map((role) =>
              capitalize(role.replace('PPT_', '').replaceAll('_', ' '))
            )
            .join(', '),
          status: employee.status ? capitalize(employee.status) : '',
          lastLoginDate: getFormattedDate(
            employee.lastLoginDate,
            dateFormat[employee.dateFormat.datePattern]
          ),
          inactiveDate: getFormattedDate(
            employee.inactiveDate,
            dateFormat[employee.dateFormat.datePattern]
          ),
          lastInvitationDate: getFormattedDate(
            employee.lastInvitationDate,
            dateFormat[employee.dateFormat.datePattern]
          ),
        }));
        setEmployees(employees);
        setTotalRecords(response.data.page.total);
        setEmployeeIs(employeeState.DEFAULT);
      }
      setLoading(false);
    }
  };

  const checkThumbnail = async (employeeId) => {
    let isReady = false;
    let attempt = 0;

    const requestData = {
      url: urls.CHECK_EMPLOYEES_THUMBNAIL,
      method: 'POST',
      data: { employee: { id: { in: [employeeId] } } },
    };

    while (attempt < 10) {
      const response = await sendRequest(requestData);

      if (response) {
        if (response.data.employeeIds?.length === 0) {
          isReady = true;
          break;
        } else {
          await wait(3000);
          attempt += 1;
        }
      }
    }
    if (attempt === 10) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Failed to create a thumbnail.',
          life: 5000,
          sticky: null,
        })
      );
      isReady = true;
    }
    return isReady;
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onEmployeeSelect = (employee) => {
    setSelectedEmployee(employee);
    setDisplayEmployeeModal(true);
  };

  const onNewEmployeeCreate = () => {
    setDisplayEmployeeModal(true);
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || employees;
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = useCallback(
    (event) => {
      debouncedGlobalSearch(event.target.value);
    },
    [debouncedGlobalSearch]
  );

  const header = (
    <div className={styles.tableHeader}>
      <Button
        className="p-button-raised"
        label="New Employee"
        icon="pi pi-plus"
        onClick={() => onNewEmployeeCreate()}
      />
      <span className={classNames('p-input-icon-left', styles.searchField)}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage =
          'You don’t have location employees yet. Please select employees for the location.';
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const confirmDeleteEmployee = (employee) => {
    setEmployeeToDelete(employee);
    setDeleteEmployeeDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteEmployee(rowData)}
        />
      </React.Fragment>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <button
        className={styles.linkStyle}
        onClick={() => onEmployeeSelect(rowData)}
      >
        {rowData.firstName}
      </button>
    );
  };

  const lastNameBodyTemplate = (rowData) => {
    return (
      <button
        className={styles.linkStyle}
        onClick={() => onEmployeeSelect(rowData)}
      >
        {rowData.lastName}
      </button>
    );
  };

  const pictureBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.picture?.thumbnail}
        onError={(e) => {
          e.target.src = '';
        }}
        className={styles.employeeThumb}
      />
    );
  };

  const hideDeleteEmployeeDialog = () => {
    setDeleteEmployeeDialog(false);
  };

  const deleteEmployee = async () => {
    setDeleteEmployeeDialog(false);
    const employeeParams = {
      id: {
        is: employeeToDelete.id,
      },
    };
    const requestData = {
      url: urls.DELETE_EMPLOYEE,
      method: 'POST',
      data: employeeParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      setEmployeeIs(employeeState.DELETED);
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Employee ${employeeToDelete.firstName} ${employeeToDelete.lastName} was removed from the location.`,
          life: 5000,
          sticky: null,
        })
      );
    }
  };

  const changeFrozenWidth = (event) => {
    if (
      event.column.columnKey === 'firstName' ||
      event.column.columnKey === 'lastName' ||
      event.column.columnKey === 'picture'
    ) {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const deleteEmployeesDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteEmployeeDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteEmployee}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [{ label: 'Employees' }];

  useEffect(() => {
    if (sort) {
      setEmployeeParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    setEmployeeParams((oldParams) => {
      let params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    setEmployeeParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (globalSearch) {
        const searchParams = { query: { search: { is: globalSearch } } };
        params = merge(params, searchParams);
      } else if (!globalSearch && params.query.search) {
        delete params.query.search;
      }
      return params;
    });
  }, [globalSearch]);

  useEffect(() => {
    !loading && fetchEmployees();
  }, [employeeParams]);

  useEffect(() => {
    if (employeeIs === employeeState.DELETED) {
      fetchEmployees();
    } else if (employeeIs === employeeState.CREATED) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New employee was created.',
          life: 5000,
          sticky: null,
        })
      );
      fetchEmployees();
    } else if (employeeIs === employeeState.UPDATED) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Employee was updated.',
          life: 5000,
          sticky: null,
        })
      );
      fetchEmployees();
      setSelectedEmployee(null);
    }
  }, [employeeIs]);

  return (
    <div className={`${styles.employeeList} list-generic`}>
      <EmployeeForm
        employeeId={selectedEmployee?.id || null}
        displayEmployeeModal={displayEmployeeModal}
        setDisplayEmployeeModal={setDisplayEmployeeModal}
        setSelectedEmployee={setSelectedEmployee}
        employeeIs={employeeIs}
        setEmployeeIs={setEmployeeIs}
        setNewEmployeeId={setNewEmployeeId}
      />
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className="table-generic p-datatable-sm employeeList"
        reorderableColumns
        value={employees}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
      >
        <Column
          header="Picture"
          body={pictureBodyTemplate}
          headerStyle={{ width: '80px', height: '48px' }}
          columnKey="picture"
          frozen
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="firstName"
          body={nameBodyTemplate}
          bodyStyle={{ height: '63px' }}
          headerStyle={{ width: '150px' }}
          columnKey="firstName"
          header="First Name"
          sortable
          sortFunction={sortFunc}
          frozen
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="lastName"
          body={lastNameBodyTemplate}
          headerStyle={{ width: '150px' }}
          columnKey="lastName"
          header="Last Name"
          sortable
          sortFunction={sortFunc}
          frozen
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="email"
          columnKey="email"
          header="Email"
          headerStyle={{ width: '300px', height: '48px' }}
          bodyStyle={{ height: '63px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          headerStyle={{ width: '200px' }}
          field="roles"
          columnKey="roles"
          header="Roles"
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          headerStyle={{ width: '150px' }}
          field="title"
          columnKey="title"
          header="Title"
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          headerStyle={{ width: '200px' }}
          field="department"
          columnKey="department"
          header="Department"
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          headerStyle={{ width: '150px' }}
          field="phone"
          columnKey="phone"
          header="Phone"
        />
        <Column
          field="status"
          headerStyle={{ width: '100px' }}
          columnKey="status"
          header="Status"
        />
        <Column
          field="lastLoginDate"
          headerStyle={{ width: '150px' }}
          columnKey="lastLoginDate"
          header="Last Login Date"
        />
        <Column
          field="inactiveDate"
          headerStyle={{ width: '150px' }}
          columnKey="inactiveDate"
          header="Inactive Date"
        />
        <Column
          field="lastInvitationDate"
          headerStyle={{ width: '150px' }}
          columnKey="lastInvitationDate"
          header="Last Invitation Date"
        />
        <Column
          field="remove"
          header="Remove"
          body={actionBodyTemplate}
          headerStyle={{ width: '70px' }}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={deleteEmployeeDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        modal
        footer={deleteEmployeesDialogFooter}
        onHide={hideDeleteEmployeeDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {employeeToDelete && (
            <span>
              Are you sure you want to delete employee{' '}
              <b>
                {employeeToDelete.firstName} {employeeToDelete.lastName}
              </b>{' '}
              from the location?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default EmployeeList;
