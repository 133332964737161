import * as actionTypes from './actions';

const initialState = {
  isActive: true,
};

export const isActiveSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_ACTIVE_SUBSCRIPTION:
      return {
        ...initialState,
        isActive: action.payload,
      };
    default:
      return state;
  }
};
