const locationTypes = [{ name: 'GROWER' }, { name: 'BREEDER' }];

const firstDaysOfWeek = [
  { day: 'SUNDAY', name: 'Sunday' },
  { day: 'MONDAY', name: 'Monday' },
];

const errorCodes = {
  NOT_UNIQUE: 'Location Name should be unique.',
  NOT_PROVIDED: 'This field should be provided.',
  MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  INVALID_FIELD_FORMAT: 'Invalid field format',
};

export { locationTypes, firstDaysOfWeek, errorCodes };
