import * as yup from 'yup';
import moment from 'moment';

export const initialCropFormSchema = (cropInfo) => {
  return {
    section: cropInfo.section || null,
    startForm: cropInfo.startForm || null,
    potSize: cropInfo.potSize || null,
    startDate: cropInfo.startDate
      ? moment(cropInfo.startDate).toDate()
      : undefined,
    plantDate: cropInfo.plantDate
      ? moment(cropInfo.plantDate, 'YYYY-MM-DD').toDate()
      : null,
    spaceDate1: cropInfo.spaceDate1
      ? moment(cropInfo.spaceDate1).toDate()
      : undefined,
    spaceDate2: cropInfo.spaceDate2
      ? moment(cropInfo.spaceDate2).toDate()
      : undefined,
    transplantDate: cropInfo.transplantDate
      ? new Date(cropInfo.transplantDate)
      : undefined,
    quantity: cropInfo.quantity,
    plantsPerPot: cropInfo.plantsPerPot || '',
    note: cropInfo.note || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const cropFormSchema = yup.object().shape({
  quantity: yup
    .number()
    .typeError('Quantity should be a number.')
    .min(1, 'Quantity should be positive.')
    .max(999, 'Quantity can not be more than 999'),

  plantsPerPot: yup
    .number()
    .typeError('Plants per pot should be a number.')
    .min(1, 'Plants per pot should be positive.')
    .max(999, lengthError),
  note: yup.string().max(1024, lengthError),
});
