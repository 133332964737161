import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const DeleteDialog = ({
  activeField,
  deleteField,
  deleteFieldDialog,
  hideDeleteFieldDialog,
  setDeleteFieldDialog,
}) => {
  const deleteFieldDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteFieldDialog(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteField}
      />
    </>
  );
  return (
    <>
      <Dialog
        visible={deleteFieldDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        modal
        footer={deleteFieldDialogFooter}
        onHide={hideDeleteFieldDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {activeField && (
            <span>
              Are you sure you want to delete <b>{activeField.prettyName}</b>{' '}
              from the template?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
