import * as yup from 'yup';
import { firstDaysOfWeek } from '../constants';

export const initialLocationFormSchema = (selectedCountry, states) => {
  return {
    name: '',
    address: '',
    address2: '',
    country: selectedCountry,
    state: states[0],
    city: '',
    postalCode: '',
    type: { name: 'BREEDER' },
    primaryPhone: '',
    firstDay: firstDaysOfWeek[0],
    description: '',
  };
};

export const locationFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Location Name should be provided.')
    .max(20, 'Maximum length exceeded.'),
  type: yup.object().required('Location Type should be provided.'),
  address: yup
    .string()
    .required('Address Line 1 should be provided.')
    .max(512, 'Maximum length exceeded.'),
  address2: yup.string().max(128, 'Maximum length exceeded.'),
  city: yup
    .string()
    .required('City should be provided.')
    .max(256, 'Maximum length exceeded.'),
  state: yup.object().required('State should be provided.'),
  postalCode: yup
    .string()
    .required('Postal Code should be provided.')
    .max(32, 'Maximum length exceeded.'),
  country: yup.object().required('Country should be provided.'),
  description: yup.string().max(255, 'Maximum length exceeded.'),
  primaryPhone: yup.string().max(15, 'Maximum length exceeded.'),
});
