import { chartColors } from './constants';

export const bars = (data) =>
  data.map((result, index) => {
    return {
      type: 'bar',
      id: result.id,
      cropNumber: result.cropNumber,
      label: `Crop ${result.cropNumber}`,
      plantName: result.name,
      data: result.values,
      borderColor: chartColors[index],
      backgroundColor: chartColors[index],
      borderWidth: 2,
      showLegend: true,
    };
  });

export const lines = (data) => {
  const mappedData = data.flatMap((item, i) => {
    const res = [];
    const longestArrayIndex = item.goals
      .map((a) => a)
      .indexOf(Math.max(...item.goals.map((a) => a?.length)));
    const longestArrayLength = item.goals[longestArrayIndex]?.length;
    const longestArray = Array(longestArrayLength).fill(0);

    longestArray.forEach((el, index) =>
      res.push({
        cropIndex: i,
        cropNumber: item.cropNumber,
        id: item.id,
        plantName: item.name,
        values: item.goals.map((goal) => goal && goal[index].goalValue),
      })
    );
    return res;
  });
  return mappedData.map((result) => {
    return {
      type: 'line',
      label: `Crop ${result.cropNumber} goal`,
      cropNumber: result.cropNumber,
      plantName: result.plantName,
      data: result.values,
      fill: false,
      tension: 0.4,
      borderColor: chartColors[result.cropIndex],
      backgroundColor: chartColors[result.cropIndex],
      borderWidth: 2,
      showLegend: false,
    };
  });
};

export const getDataSets = (data) => {
  return [...lines(data), ...bars(data)];
};
