import jwtDecode from 'jwt-decode';
import {
  removeAuthCookie,
  updateAuthCookieExpiration,
  isAuthCookie,
} from './auth-cookie';
import ReactGA from 'react-ga4';

const getAuthConfig = async (
  checkTokenExp = true,
  logout,
  getAccessTokenSilently
) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  let token = localStorage.getItem('token');
  if (checkTokenExp) {
    token = await getAccessToken(logout, getAccessTokenSilently);
  }
  return { headers: { Authorization: `Bearer ${token}` }, apiUrl: apiUrl };
};

const getAccessToken = async (logout, getAccessTokenSilently) => {
  let token = localStorage.getItem('token');
  if (isAuthCookie() && (!token || isTokenExpired(token))) {
    updateAuthCookieExpiration();
    if (!token || isTokenExpired(token)) {
      token = await getAccessTokenSilently();
      localStorage.setItem('token', token);
    }
  }

  if (!isAuthCookie() && token && !isTokenExpired(token)) {
    updateAuthCookieExpiration();
  }
  if (!isAuthCookie() && token && isTokenExpired(token)) {
    customLogout(logout);
  }

  return token;
};

const isTokenExpired = (token) => {
  const { exp } = jwtDecode(token);
  const expirationTime = exp * 1000 - 60000;
  return Date.now() >= expirationTime;
};

const checkBlocked = (error, logout) => {
  if (
    error?.response?.data?.error?.code === 'BLOCKED_IDENTITY' ||
    error?.response?.data?.error?.code === 'NOT_FOUND_IDENTITY'
  ) {
    customLogout(logout);
  } else if (error?.response?.data?.error?.code === 'PERMISSION_CHANGED') {
    globalError.callback();
  }
};

const customLogout = (logout) => {
  ReactGA.set({ userId: null });
  removeAuthCookie();
  localStorage.clear();
  logout({ returnTo: window.location.origin });
};

const isAuth = () => {
  const token = localStorage.getItem('token');
  return isAuthCookie() || (token && !isTokenExpired(token));
};

export { getAuthConfig, checkBlocked, isAuth, customLogout };
