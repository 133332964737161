const getInitialHistoryParams = (id) => {
  return {
    query: {
      trial: {
        id: {
          in: [id],
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'cropNumber',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };
};

const getTrialPlantsParams = (id) => {
  return {
    query: {
      trial: {
        id: {
          is: id,
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'name',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };
};

const productsParams = {};
const plantsParams = {};

const sectionsParams = {};

export {
  getInitialHistoryParams,
  getTrialPlantsParams,
  productsParams,
  plantsParams,
  sectionsParams,
};
