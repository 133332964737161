import * as yup from 'yup';

export const initialSectionFormSchema = (sectionInfo, types) => {
  return {
    name: sectionInfo.name,
    type: sectionInfo.sectionType || (types && types[0]) || null,
    zone: sectionInfo.zone || null,
    description: sectionInfo.description || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const sectionFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Section Name should be provided.')
    .max(100, lengthError),
  description: yup.string().max(255, lengthError),
});
