const groupOptions = [
  { name: 'Log Week', code: 'logWeek' },
  { name: 'Log Date', code: 'createDate' },
  { name: 'Plant Week', code: 'plantWeek' },
  { name: 'Plant', code: 'cropPlantName' },
  { name: 'Section', code: 'cropSectionName' },
];

const sortableFields = {
  createDateSort: 'createDate',
  cropPlantDateSort: 'cropPlantDate',
  cropSectionName: 'cropSectionName',
  ownerId: 'ownerId',
};

const getInitialLogParams = (entity) => ({
  query: {
    crop: {},
    [entity.name]: {
      id: {
        is: entity.id,
      },
    },
  },
  navigation: {
    sort: [
      {
        key: 'logWeek',
        order: 'ASC',
      },
      {
        key: 'cropPlantName',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
});

const getTrialFieldParams = (trialId) => ({
  query: {
    trial: {
      id: {
        in: [trialId],
      },
    },
  },
  navigation: {
    sort: [
      {
        key: 'order',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 1000,
    },
  },
});

const mandatoryColumns = [
  { field: 'logWeek', header: 'Log Week', field_: 'log_week' },
  { field: 'createDateSort', header: 'Log Date', field_: 'create_date' },
  { field: 'plantWeek', header: 'Plant Week', field_: 'crop_plant_week' },
  {
    field: 'cropPlantDateSort',
    header: 'Plant Date',
    field_: 'crop_plant_date',
  },
  { field: 'cropSectionName', header: 'Section', field_: 'section_name' },
  { field: 'ownerId', header: 'Owner', field_: 'owner_first_name' },
  { field: 'note', header: 'Note', field_: 'note' },
];

const errorOptions = {
  INVALID_FIELD_ID: 'Invalid field ID.',
  NOT_PROVIDED: 'Value is not provided.',
  INVALID_RANGE_VALUE: 'Value is outside of allowed range',
  INVALID_VALUE_ID: 'Invalid value ID.',
  VALUE_NOT_FOUND: 'Value is not found',
  INVALID_FIELD_FORMAT: 'Invalid format',
  CANNOT_UPDATE_DATETIME: 'Can not update date_time field',
  CANNOT_DELETE_REQUIRED_FIELD:
    'can not be removed because this field is required',
  BAD_CONTENT: 'Invalid type for the log property.',
  CONFLICT:
    'This log has been updated by another user. Please refresh the page and then save your updates.',
};

const nonEditableColumns = [
  'cropPlantName',
  'createDateSort',
  'logWeek',
  'cropPlantDateSort',
  'plantWeek',
  'cropSectionName',
  'ownerId',
];

const firstDay = {
  'yyyy/MM/dd': 'MONDAY',
  'dd/MM/yyyy': 'MONDAY',
  'MM/dd/yyyy': 'SUNDAY',
};

export {
  errorOptions,
  groupOptions,
  sortableFields,
  getInitialLogParams,
  getTrialFieldParams,
  mandatoryColumns,
  nonEditableColumns,
  firstDay,
};
