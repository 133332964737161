import moment from 'moment';
import React from 'react';
import store from '../../reduxStore/store';
import { setIsActiveSubscription } from '../../reduxStore/isActiveSubscription/actions';
import { setLimitExceeded } from '../../reduxStore/limitExceeded/actions';
import { cloneDeep, mapValues } from 'lodash';
import merge from 'deepmerge';
import { errorCodes } from '../Trials/constants';
import { setPopUp } from '../../reduxStore/popUp/actions';
import { popUp } from './globalConstants';
import { globalErrorCodes, referencesExistCodes } from './globalErrorCodes';

const getDefaultDateFormat = () => {
  const reduxStore = store.getState();
  return reduxStore?.profileInfo?.dateFormat?.datePattern || 'MM/dd/yyyy';
};

const getDefaultFirstDay = () => {
  const reduxStore = store.getState();
  return reduxStore?.profileInfo?.firstDayOfWeek?.day || 'SUNDAY';
};

const getWeekNumber = (date, firstDayOfWeek) => {
  if (!date) return '';
  const tdt = new Date(date.valueOf());
  const day =
    firstDayOfWeek === 'SUNDAY'
      ? (date?.getDay() + 7) % 7
      : (date?.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - day + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

const getFormattedDate = (date, format = getDefaultDateFormat()) => {
  return date && moment(date.slice(0, 10)).format(format);
};

const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const wait = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

const checkEmailValidity = (e) => {
  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(String(e).toLowerCase());
};

const checkSubscription = (error, dispatch) => {
  if (error?.response?.data?.error?.code === 'NO_ACTIVE_SUBSCRIPTION') {
    dispatch(setIsActiveSubscription(false));
  }
};

const checkLimitExceeded = (dispatch, option) => {
  dispatch(setLimitExceeded(option));
};

const formatFilterDataToIsoString = (date) => {
  if (date) {
    const from = moment(date[0]).utcOffset(0, true).toISOString();
    const to = date[1]
      ? moment(date[1]).add(24, 'hours').utcOffset(0, true).toISOString()
      : moment(date[0]).add(24, 'hours').utcOffset(0, true).toISOString();
    return [from, to];
  }
};

const createFilterCategory = (obj) =>
  mapValues(obj, (value) => (!value ? null : value.map((item) => item.id)));

const fillFilterParams = (
  selectedFilters,
  oldParams,
  filtersNames,
  queryType
) => {
  let params = cloneDeep(oldParams);
  Object.entries(selectedFilters).map((item) => {
    if (item[1]) {
      const filterParams = {
        query: { [queryType]: { [item[0]]: { id: { in: null } } } },
      };

      params = merge(params, filterParams);
      params['query'][queryType][item[0]].id.in =
        selectedFilters[filtersNames[item[0]]];
    } else if (item && params?.query?.product) {
      params?.query?.product[item[0]] && delete params?.query?.product[item[0]];
    }
  });
  return params;
};

const getBlob = async (fileUri) => {
  const resp = await fetch(fileUri);
  return await resp.blob();
};

const getExportURL = (data, type) => {
  return window.URL.createObjectURL(new Blob(data, type));
};

const uploadImage = async (setUploading, image, path, setIsError) => {
  setUploading(true);
  const imageBody = await getBlob(image);
  try {
    await fetch(path, {
      method: 'PUT',
      body: imageBody,
    });
  } catch (error) {
    setIsError(true);
    console.log('error -> ', error);
  } finally {
    setUploading(false);
  }
};

const unloadCallback = (event) => {
  const e = event || window.event;
  e.preventDefault();
  if (e) {
    e.returnValue = '';
  }
  return '';
};

//ToDo should be refactored after common error code 'REFERENCES_EXIST' will be replaced with many specific codes
const showErrorMessage = (error, dispatch, url) => {
  if (
    error === 'REFERENCES_EXIST' &&
    Object.keys(referencesExistCodes).includes(url)
  ) {
    dispatch(
      setPopUp({
        severity: popUp.severities.ERROR,
        summary: popUp.summary.ERROR,
        detail: referencesExistCodes[url],
        life: 5000,
      })
    );
  } else {
    const message = Object.keys(globalErrorCodes).includes(error)
      ? globalErrorCodes[error]
      : errorCodes.DEFAULT_MESSAGE.text;
    dispatch(
      setPopUp({
        severity: popUp.severities.ERROR,
        summary: popUp.summary.ERROR,
        detail: message,
        life: 5000,
      })
    );
  }
};

const getFilteredCountries = (countries) => {
  const arrOfCountries = Object.keys(countries).map((key) => countries[key]);

  const countriesWithoutRegions = arrOfCountries.filter((country) => {
    if (country.regions.length > 0) {
      return country;
    }
  });

  const countriesWithoutISO = countriesWithoutRegions
    .filter((country) => {
      return country.regions.every((region) => {
        if (region.iso !== '') {
          return country;
        }
      });
    })
    .map((item) => ({ [item.iso]: item }));

  return Object.assign({}, ...countriesWithoutISO);
};

const getImportErrorMessage = (errors) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Column</th>
          <th>Row</th>
          <th>Cell</th>
          <th>Value</th>
          <th>Error detail</th>
        </tr>
      </thead>
      <tbody>
        {errors.map((error, index) => {
          return (
            <tr key={index}>
              <td>{error.name}</td>
              <td>{error.rowNumber}</td>
              <td>{error.cellAddress}</td>
              <td>{error.value}</td>
              <td>{error?.code ? errorCodes[error.code]?.text : ''}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const isISODateString = (str) => {
  return !!str.match(
    /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|\-)(?:\d{2}):?(?:\d{2}))/gm
  );
};

export {
  capitalize,
  getWeekNumber,
  wait,
  checkEmailValidity,
  getQueryStringParams,
  checkSubscription,
  checkLimitExceeded,
  createFilterCategory,
  fillFilterParams,
  getFormattedDate,
  getDefaultDateFormat,
  getDefaultFirstDay,
  uploadImage,
  formatFilterDataToIsoString,
  getExportURL,
  unloadCallback,
  getFilteredCountries,
  getImportErrorMessage,
  showErrorMessage,
  isISODateString,
};
