export const initialPlantInfo = {
  name: '',
  code: '',
  program: '',
  class: '',
  series: '',
  breeder: '',
  marketStatus: '',
  patentNumber: '',
  tradeName: '',
  scientificName: '',
  color: '',
  active: '',
  description: '',
  note: '',
  image: '',
};

export const initialCropInfo = {
  number: '',
  plant: '',
  plantDate: null,
  section: '',
  quantity: '',
  dateFormat: '',
  startDate: null,
  startForm: '',
  potSize: '',
  transplantDate: null,
  spaceDate1: null,
  spaceDate2: null,
  lotNumber: '',
  plantsPerPot: '',
  note: '',
};
