import React from 'react';
import Menu from '../../Common/Menu/Menu';

const LocationMenu = ({ id, name }) => {
  const locationId = id || localStorage.getItem('locationId');
  const locationName = name || localStorage.getItem('locationName');
  const locationLink = `/location/${locationId}`;

  const items = [
    { link: locationLink, value: 'Information' },
    { link: `${locationLink}/sections`, value: 'Sections' },
  ];

  return <Menu items={items} name={locationName} />;
};

export default LocationMenu;
