import * as yup from 'yup';

export const initialBatchFormSchema = (plantInfo) => {
  return {
    description: plantInfo.description || '',
    breeder: plantInfo.breeder || null,
    program: plantInfo.program || null,
    class: plantInfo.class || null,
    series: plantInfo.series || null,
    color: plantInfo.color || null,
    active: plantInfo.active,
    marketStatus: plantInfo.marketStatus || null,
    hardinessZone: plantInfo.hardinessZone || null,
    tradeName: plantInfo.tradeName || '',
    scientificName: plantInfo.scientificName || '',
    note: plantInfo.note || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const batchFormSchema = yup.object().shape({
  description: yup.string().max(256, lengthError),
  tradeName: yup.string().max(50, lengthError),
  scientificName: yup.string().max(50, lengthError),
  note: yup.string().max(1024, lengthError),
});
