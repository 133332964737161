import * as actionTypes from './actions';

const initialState = false;

export const isFirstLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_FIRST_LOGIN:
      return action.payload;
    default:
      return state;
  }
};
