import { all, put } from 'redux-saga/effects';
import * as actions from '../productFilters/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getProductFiltersSaga(action) {
  yield put(actions.productFiltersRequest());

  const actionData = action.actionData;

  const categoriesData = {
    url: urls.PRODUCT_CATEGORIES,
    method: 'GET',
    data: {},
  };
  const classificationsData = {
    url: urls.PRODUCT_CLASSIFICATIONS,
    method: 'GET',
    data: {},
  };
  const manufacturersData = {
    url: urls.PRODUCT_MANUFACTURERS,
    method: 'GET',
    data: {},
  };
  const mixersData = {
    url: urls.PRODUCT_MIXERS,
    method: 'GET',
    data: {},
  };
  const applicationMethodsData = {
    url: urls.PRODUCT_APPLICATION_METHODS,
    method: 'GET',
    data: {},
  };
  const measureUnitsData = {
    url: urls.PRODUCT_MEASURE_UNITS,
    method: 'POST',
    data: {},
  };
  const mixerMeasureUnitsData = {
    url: urls.PRODUCT_MIXER_MEASURE_UNITS,
    method: 'POST',
    data: {},
  };

  try {
    let [
      categories,
      classifications,
      manufacturers,
      mixers,
      applicationMethods,
      measureUnits,
      mixerMeasureUnits,
    ] = yield all([
      makeRequest(categoriesData, actionData),
      makeRequest(classificationsData, actionData),
      makeRequest(manufacturersData, actionData),
      makeRequest(mixersData, actionData),
      makeRequest(applicationMethodsData, actionData),
      makeRequest(measureUnitsData, actionData),
      makeRequest(mixerMeasureUnitsData, actionData),
    ]);

    categories = categories.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    classifications = classifications.data.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    manufacturers = manufacturers.data.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    mixers = mixers.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    applicationMethods = applicationMethods.data.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    measureUnits = measureUnits.data.measureUnits.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    mixerMeasureUnits = mixerMeasureUnits.data.measureUnits.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );

    yield put(
      actions.productFiltersSuccess(
        categories,
        classifications,
        manufacturers,
        mixers,
        applicationMethods,
        measureUnits,
        mixerMeasureUnits
      )
    );
  } catch (error) {
    yield put(actions.productFiltersFail(error.response));
  }
}
