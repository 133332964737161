const taskManagerParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'firstName',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 200,
    },
  },
};

const defaultStatus = {
  status: 'UNASSIGNED',
  name: 'Unassigned',
};

const taskTypesParams = {};

const taskStatusesParams = {};

const locationParams = {};

const trialParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'name',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 1000,
    },
  },
};

const getTaskParams = (taskId) => ({
  query: {
    task: {
      id: {
        is: taskId,
      },
    },
  },
});

const initialTaskInfo = {
  trial: '',
  description: '',
  product: null,
  applicationCode: null,
  taskManager: null,
  status: defaultStatus,
  type: null,
  note: '',
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  allDay: false,
};

const getInitialTasksParams = (id) => ({
  query: {
    location: {
      id: {
        in: [id],
      },
    },
  },
  navigation: {
    sort: [
      {
        key: 'description',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
});

const initialTasksParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'description',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
};

const initialProductParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'tradeName',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
};

const getExtractCropParams = (id) => ({
  task: {
    id: {
      is: id,
    },
  },
});

const getSearchCropsParams = (id) => ({
  query: {
    task: {
      id: {
        is: id,
      },
    },
  },
  navigation: {
    sort: [
      {
        key: 'sectionName',
        order: 'ASC',
      },
    ],
  },
});

const getDeleteCropsParams = (data, taskId) => {
  return data[0].allCrops
    ? {
        task: {
          id: {
            is: taskId,
          },
        },
        section: {
          id: {
            in: [data[0].section.id],
          },
        },
        crop: {
          id: {
            in: [],
          },
        },
      }
    : {
        task: {
          id: {
            is: taskId,
          },
        },
        section: {
          id: {
            in: [],
          },
        },
        crop: {
          id: {
            in: data.map((crop) => {
              return crop.crop.id;
            }),
          },
        },
      };
};

const assignedToTask =
  'can not be removed because section or crop are assigned to the task';
const messageText = {
  assignedToTask: 'Section/Crop is assigned to the task',
  productNotProvided: 'Product or Application is not provided.',
  canNotUpdate: 'Can not update completed task',
  taskCompleted: 'Selected task(s) were completed.',
  cropUpdated: 'Section/Crop was updated.',
  cropDeleted: 'Crop was deleted.',
  taskDeleted: 'Task was deleted.',
  taskCreated: 'New task was created.',
};

const sectionIdLength = 1;

const cropIdLength = 3;

const allDayStart = '1970-01-01T00:00:00';

const allDayEnd = '1970-01-01T23:59:59';

export {
  initialTasksParams,
  locationParams,
  taskManagerParams,
  trialParams,
  taskTypesParams,
  taskStatusesParams,
  getTaskParams,
  initialTaskInfo,
  getInitialTasksParams,
  initialProductParams,
  getExtractCropParams,
  getSearchCropsParams,
  getDeleteCropsParams,
  assignedToTask,
  sectionIdLength,
  cropIdLength,
  allDayStart,
  allDayEnd,
  defaultStatus,
  messageText,
};
