import * as yup from 'yup';
import { combineDateAndTime, getDateWithoutOffset } from '../utils';

export const initialTaskFormSchema = (taskInfo) => {
  return {
    trial: taskInfo.trial || '',
    description: taskInfo.description || '',
    product: taskInfo?.product || null,
    applicationCode: taskInfo?.applicationCode || null,
    taskManager: taskInfo.taskManager || null,
    status: taskInfo.status || null,
    type: taskInfo.type || null,
    allDay: taskInfo.allDay || false,
    startDate: taskInfo.startDate
      ? getDateWithoutOffset(taskInfo.startDate)
      : undefined,
    startTime: taskInfo.startTime
      ? getDateWithoutOffset(taskInfo.startTime)
      : undefined,
    endDate: taskInfo.endDate
      ? getDateWithoutOffset(taskInfo.endDate)
      : undefined,
    endTime: taskInfo.endTime
      ? getDateWithoutOffset(taskInfo.endTime)
      : undefined,
    note: taskInfo.note || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const taskFormSchema = yup.object().shape({
  trial: yup.object().required('Trial should be provided'),
  description: yup
    .string()
    .required('Description should be provided')
    .max(100, lengthError),
  allDay: yup.boolean(),
  startDate: yup.date().nullable().required('Start date should be provided'),
  startTime: yup.date().when('allDay', {
    is: true,
    then: yup.date().notRequired(),
    otherwise: yup.date().required('Start time should be provided'),
  }),
  endDate: yup
    .date()
    .nullable()
    .required('End date should be provided')
    .min(yup.ref('startDate'), 'End Date should be equal or after Start Date.'),
  endTime: yup
    .date()
    .when('allDay', {
      is: true,
      then: yup.date().notRequired(),
      otherwise: yup.date().required('End time should be provided'),
    })
    .test({
      name: 'check end time',
      exclusive: false,
      message: 'End Time should be after Start Time.',
      params: {},
      test: (fieldValue, formData) => {
        const parent = formData.parent;
        if (
          !parent.endDate ||
          !parent.startDate ||
          !parent.startTime ||
          !parent.endTime
        ) {
          return true;
        }

        const start = combineDateAndTime(parent.startDate, parent.startTime);

        const end = combineDateAndTime(parent.endDate, parent.endTime);

        return start.substring(0, 16) < end.substring(0, 16);
      },
    }),
  note: yup.string().max(1024, lengthError),
});
