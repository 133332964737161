const getInitialParams = (id) => {
  //const trialId = localStorage.getItem('trialId');
  return {
    query: {
      trial: {
        id: {
          in: [id],
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'sharedDate',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };
};

const getUnlinkCompanyParams = (trialId, companyId) => {
  return {
    trialId: {
      is: trialId,
    },
    invitedCompany: {
      id: {
        in: [companyId],
      },
    },
  };
};

export { getInitialParams, getUnlinkCompanyParams };
