import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Menu from '../../Common/Menu/Menu';
import { enterpriseAdminLinks, adminLinks, userLinks } from '../constants';
import { tier, userRoles } from '../../Common/globalConstants';

const SettingsMenu = () => {
  const permissions = useSelector((state) => state.permissions.permissions);
  const subscription = useSelector((state) => state.subscription?.uuid);

  const links = useMemo(() => {
    const isAdmin = permissions.includes(userRoles.PPT_MANAGE_COMPANY);
    const isEnterprise = subscription === tier.enterprise;
    if (!isAdmin) {
      return userLinks;
    } else if (isAdmin && !isEnterprise) {
      return adminLinks;
    } else if (isAdmin && isEnterprise) {
      return enterpriseAdminLinks;
    }
  }, [subscription, permissions]);

  return <Menu items={links} />;
};

export default SettingsMenu;
