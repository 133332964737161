const types = {
  LOCATIONS: 'locations',
  TRIALS: 'trials',
  TASKS: 'tasks',
};

const getTopTasksParams = (id) => ({
  query: {
    task: {
      status: { in: ['UNASSIGNED', 'ASSIGNED', 'IN_PROGRESS'] },
    },
    location: {
      id: {
        in: [id],
      },
    },
  },
  navigation: {
    sort: [
      {
        key: 'startDate',
        order: 'DESC',
      },
    ],
    page: {
      from: 0,
      size: 10,
    },
  },
});

const initialLocationParams = {
  navigation: {
    sort: [
      {
        key: 'name',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 5,
    },
  },
};

export { types, getTopTasksParams, initialLocationParams };
