import React, { useState } from 'react';
import LocationMenu from '../Menu/LocationMenu';
import LocationForm from './LocationForm';
import LeftBar from '../../LeftBar/LeftBar';
import { useParams } from 'react-router-dom';

const LocationInfo = () => {
  const { id } = useParams();
  const [locationName, setLocationName] = useState('');
  return (
    <React.Fragment>
      <LeftBar>
        <LocationMenu id={id} name={locationName} />
      </LeftBar>
      <LocationForm setLocationName={setLocationName} />
    </React.Fragment>
  );
};

export default LocationInfo;
