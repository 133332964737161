import React from 'react';
import ProductMenu from '../ProductInfo/ProductMenu/ProductMenu';
import ApplicationList from '../ApplicationList/ApplicationList';
import LeftBar from '../../LeftBar/LeftBar';

const ApplicationPage = () => {
  return (
    <>
      <LeftBar>
        <ProductMenu />
      </LeftBar>
      <ApplicationList />
    </>
  );
};

export default ApplicationPage;
