import * as yup from 'yup';

const maxLengthError = 'Maximum length exceeded.';

export const contactUsFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email should be provided.')
    .max(100, maxLengthError)
    .email('Invalid email format.'),
  name: yup
    .string()
    .required('Name should be provided.')
    .min(2, 'Name must be at least 2 characters.')
    .max(32, maxLengthError),
  companyName: yup
    .string()
    .required('Company Name should be provided.')
    .max(100, maxLengthError),
  phoneNumber: yup.string().max(15, 'Maximum length exceeded.'),
  subject: yup
    .string()
    .required('Subject should be provided.')
    .min(3, 'Subject must be at least 3 characters.')
    .max(32, maxLengthError),
  message: yup
    .string()
    .required('Message should be provided.')
    .min(10, 'Message must be at least 10 characters.')
    .max(255, 'Maximum length exceeded.'),
});
