import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Chart } from 'primereact/chart';
import isEqual from 'lodash/isEqual';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import urls from '../../../Common/ApiServices/urls';
import { useRequest } from '../../../Common/ApiServices';
import { getDataSets } from '../utils';
import styles from './ReportList.module.scss';

const ReportList = ({ filters }) => {
  const [reportParams, setReportParams] = useState({});
  const [report, setReport] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const { sendRequest } = useRequest({});

  const fetchReport = useCallback(async () => {
    if (isEqual(reportParams, {})) {
      setReport(null);
      return null;
    }
    const requestData = {
      url: urls.REPORT_PLANT_COMPARISON,
      method: 'POST',
      data: reportParams,
    };
    const response = await sendRequest(requestData);

    if (response) {
      const labels = response.data.labels;
      const report = {
        labels: labels,
        datasets: getDataSets(response.data.results),
      };

      setReport(report);
    }
  }, [reportParams, sendRequest]);

  const updateFilters = useCallback((newFilters) => {
    if (!newFilters) {
      setReportParams({});
      return null;
    }

    const { plants, trialField, startWeek, endWeek } = newFilters;
    if (!plants || !trialField || !startWeek || !endWeek) {
      return null;
    }

    const params = {
      trialField: {
        id: {
          is: trialField.id,
        },
      },
      logWeek: {
        from: startWeek.id,
        to: endWeek.id,
      },
      plant: {
        id: {
          in: plants.map((plant) => plant.id),
        },
      },
    };

    setReportParams({
      query: { ...params },
    });
  }, []);

  const breadCrumbItems = useMemo(
    () => [
      {
        label: 'Trials',
        command: () => {
          history.push('/trials');
        },
      },
      {
        label: localStorage.getItem('trialName') || location.state,
        command: () => {
          history.push(`/trial/${localStorage.getItem('trialId')}`);
        },
      },
      { label: 'Reports' },
    ],
    [history, location]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          titleAlign: 'center',
          callbacks: {
            label: (context) => {
              return (
                `${context.dataset.label} : ${
                  context.dataset.data[context.dataIndex]
                } ` || ''
              );
            },
            afterLabel: (tooltipItem) => {
              return tooltipItem.dataset.plantName;
            },
          },
        },
        legend: {
          labels: {
            filter: (item) => {
              return item.text.substring(item.text.length - 4) !== 'goal';
            },
          },
        },
      },
    }),
    []
  );

  const content = useCallback(() => {
    if (!report) {
      return (
        <div className={styles.message}>
          <h3 className="p-text-center">
            Please set up filters to get the report.
          </h3>
        </div>
      );
    } else {
      return <Chart type="bar" data={report} options={options} />;
    }
  }, [options, report]);

  useEffect(() => {
    updateFilters(filters);
  }, [filters, updateFilters]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  return (
    <div className={styles.reportList}>
      <BreadCrumb items={breadCrumbItems} />
      <div className={styles.reportForm}>{content()}</div>
    </div>
  );
};

export default ReportList;
