import { all, put } from 'redux-saga/effects';
import * as actions from '../taskOptions/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';
import {
  locationParams,
  trialParams,
  taskManagerParams,
  taskTypesParams,
  initialProductParams,
  taskStatusesParams,
} from '../../components/Tasks/constants';

export function* getTaskOptionsSaga(action) {
  yield put(actions.taskOptionsRequest());
  const actionData = action.actionData;

  const locationsData = {
    url: urls.SEARCH_LOCATIONS,
    method: 'POST',
    data: locationParams,
  };
  const trialsData = {
    url: urls.SEARCH_TRIALS,
    method: 'POST',
    data: trialParams,
  };
  const typesData = {
    url: urls.TASK_TYPES,
    method: 'POST',
    data: taskTypesParams,
  };
  const productsData = {
    url: urls.SEARCH_PRODUCTS,
    method: 'POST',
    data: initialProductParams,
  };
  const statusesData = {
    url: urls.TASK_STATUSES,
    method: 'POST',
    data: taskStatusesParams,
  };
  const employeesData = {
    url: urls.SEARCH_EMPLOYEES,
    method: 'POST',
    data: taskManagerParams,
  };

  try {
    let [locations, trials, types, products, statuses, employees] = yield all([
      makeRequest(locationsData, actionData),
      makeRequest(trialsData, actionData),
      makeRequest(typesData, actionData),
      makeRequest(productsData, actionData),
      makeRequest(statusesData, actionData),
      makeRequest(employeesData, actionData),
    ]);
    locations = locations.data.results
      .map((location) => ({
        id: location.location.id,
        name: location.location.name,
        firstDay: location.location.firstDayOfWeek.day,
        dateFormat: location.location.dateFormat.datePattern,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    trials = trials.data.results
      .map((trial) => ({
        id: trial.trial.id,
        name: trial.trial.name,
        firstDayOfWeek: trial.location.firstDayOfWeek.day,
        dateFormat: trial.location.dateFormat.datePattern,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    types = types.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    products = products.data.results
      .map((product) => ({
        id: product.product.id,
        name: product.product.tradeName,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    statuses = statuses.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    employees = employees.data.results
      .map((employee) => ({
        id: `${employee.employee.id}`,
        name: `${employee.employee.firstName} ${employee.employee.lastName}`,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    yield put(
      actions.taskOptionsSuccess(
        locations,
        trials,
        types,
        products,
        statuses,
        employees
      )
    );
  } catch (error) {
    yield put(actions.taskOptionsFail(error.response));
  }
}
