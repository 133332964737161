import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavigationItem.module.scss';

const NavigationItem = ({ hidden, clickable, className, children }) => (
  <li
    className={classNames(
      styles.navigationItem,
      className ? className : '',
      clickable ? 'clickable' : styles.noClickable,
      hidden ? styles.hidden : ''
    )}
  >
    {children}
  </li>
);

const NavigationLink = ({ hidden, link, clickable, ...props }) => (
  <NavigationItem hidden={hidden} clickable={clickable}>
    <NavLink exact to={link} activeClassName={styles.active} {...props} />
  </NavigationItem>
);

const NavigationButton = ({ onClick, children }) => (
  <NavigationItem>
    <a className={styles.clickableLink} onClick={onClick}>
      {children}
    </a>
  </NavigationItem>
);

export { NavigationButton };

export default NavigationLink;
