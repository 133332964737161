import moment from 'moment';
import {
  dateFormat,
  dateTimeFormat,
  fieldTypes,
  timeFormat,
} from '../../Common/globalConstants';

export const getMappedPlantResponse = (data) => ({
  name: data.plant.name,
  code: data.plant.code,
  patentNumber: data.plant.patentNumber,
  tradeName: data.plant.tradeName,
  scientificName: data.plant.scientificName,
  color: data.plant.color,
  active: data.plant.active,
  description: data.plant.description,
  note: data.plant.note,
  image: data.plant.image,
  hardinessZone: data.plant.hardinessZone,
  pdfFile: data.plant.pdfFile,
  breeder: data.breeder,
  program: data.program,
  series: data.series,
  marketStatus: data.marketStatus,
  class: data.class,
});

export const getMappedCropResponse = (data) => ({
  number: data.crop.number,
  plant: data.plant || '',
  plantDate: data.crop.plantDate
    ? moment(data.crop.plantDate, 'YYYY-MM-DD').toDate()
    : null,
  section: data.section || '',
  quantity: data.crop.quantity,
  dateFormat: data.crop.dateFormat?.datePattern,
  startDate: data.crop.startDate
    ? moment(data.crop.startDate).toDate()
    : undefined,
  startForm: data.crop.startForm || '',
  potSize: data.crop.potSize || '',
  transplantDate: data.crop.transplantDate
    ? new Date(data.crop.transplantDate)
    : null,
  spaceDate1: data.crop.spaceDate1
    ? moment(data.crop.spaceDate1).toDate()
    : null,
  spaceDate2: data.crop.spaceDate2
    ? moment(data.crop.spaceDate2).toDate()
    : null,
  lotNumber: data.crop.lotNumber || '',
  plantsPerPot: data.crop.plantsPerPot || '',
  note: data.crop.note || '',
});

export const getActual = (data, defaultDatePattern) => {
  switch (data.field?.type) {
    case fieldTypes.DATE:
      return moment(data.log.value).format(
        dateFormat[data.field?.dateFormat?.datePattern || defaultDatePattern]
      );
    case fieldTypes.TIME:
      return moment(`2022.01.01 ${data.log.value}`).format(
        timeFormat[data.field?.dateFormat?.datePattern || defaultDatePattern]
      );
    case fieldTypes.DATE_TIME:
      return moment
        .utc(data.log.value)
        .format(
          dateTimeFormat[
            data.field?.dateFormat?.datePattern || defaultDatePattern
          ]
        );
    case fieldTypes.CHECKBOX:
      return data.log.value === 'true' ? 'Yes' : 'No';
    default:
      return data.log.value;
  }
};
