import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import classNames from 'classnames';
import { camelCase } from 'lodash';
import { checkPrettyNameValidity } from '../utils';
import { urls, useRequest } from '../../../../Common/ApiServices';
import { fieldTypes, types } from '../constants';
import styles from './AddNewFieldDialog.module.scss';

const AddNewFieldDialog = ({
  addCustomFieldOption,
  addNewFieldDialog,
  hideAddNewFieldDialog,
  templateId,
  isTrialTracker,
  setShowPrettyName,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputPrettyName, setInputPrettyName] = useState('');
  const [type, setType] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [createdImage, setCreatedImage] = useState(null);
  const [validationFail, setValidationFail] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [prettyNameError, setPrettyNameError] = useState('');

  const fileUploadRef = useRef(null);
  const { error, sendRequest } = useRequest({});

  const headerTemplate = ({ className, chooseButton }) => {
    const progressBar = isUploading && selectedImage && (
      <div className="p-d-flex p-jc-end">
        <h4>Uploading image...</h4>
        <ProgressBar
          className={classNames('p-ml-5 p-as-center', styles.progressBar)}
          mode="indeterminate"
        />
      </div>
    );
    return (
      <div
        className={classNames(className, 'p-jc-between', styles.uploaderHeader)}
      >
        {chooseButton}
        {progressBar}
      </div>
    );
  };

  const onTemplateRemove = () => {
    setSelectedImage(null);
    fileUploadRef.current.clear();
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className={classNames('p-d-flex p-ai-center', styles.preview)}>
          <img
            className={styles.fieldImage}
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={180}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={onTemplateRemove}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className={classNames('p-d-flex p-ai-center p-dir-col')}>
        <i
          className={classNames(
            'pi pi-image p-mt-1 p-p-3',
            styles.emptyAreaIcon
          )}
        />
        <span className={classNames('p-my-2', styles.emptyAreaText)}>
          {isTrialTracker ? '' : 'Drag and Drop Image Here'}
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: `custom-choose-btn p-button-rounded p-button-outlined
    ${selectedImage && 'p-disabled'}`,
  };

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  const closeDialog = useCallback(() => {
    setType({});
    setInputValue('');
    setInputPrettyName('');
    hideAddNewFieldDialog();
  }, [hideAddNewFieldDialog]);

  const savePrettyName = (val) => {
    const validity = checkPrettyNameValidity(val);
    validity
      ? setPrettyNameError(
          "Pretty name can only contain numbers, letters, dashes, comma, :, ',&, #."
        )
      : setPrettyNameError('');
    setInputPrettyName(val);
  };

  const saveNewTextField = () => {
    addCustomFieldOption(inputValue, inputPrettyName, type.name);
    setType({});
    setInputValue('');
    setInputPrettyName('');
    hideAddNewFieldDialog();
  };

  const saveNewImageField = (createdImage) => {
    addCustomFieldOption(
      inputPrettyName,
      inputPrettyName,
      type.name,
      createdImage
    );
    setType({});
    setInputValue('');
    setInputPrettyName('');
    hideAddNewFieldDialog();
  };

  const onImageSelect = (e) => {
    setSelectedImage(e.files[0]);
  };
  const onValidationFail = () => {
    setValidationFail(true);
  };

  const createTemplateImage = async () => {
    setIsUploading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('templateId', templateId);
    bodyFormData.append('fieldName', camelCase(inputPrettyName));
    bodyFormData.append('fieldPrettyName', inputPrettyName);
    bodyFormData.append('image', selectedImage);

    const requestData = {
      url: urls.UPLOAD_CROP_TEMPLATE_IMAGE,
      method: 'POST',
      data: bodyFormData,
      headers: [{ 'Content-Type': 'multipart/form-data' }],
    };

    const response = await sendRequest(requestData);
    if (response) {
      setCreatedImage(response.data.url);
      setIsUploading(false);
    }

    return response;
  };

  const checkDisabled = () => {
    if (type.name === types.IMAGE) {
      return !inputPrettyName || !selectedImage || prettyNameError;
    } else if (type.name === types.TEXT) {
      return !inputPrettyName || !inputValue || prettyNameError;
    }
    return true;
  };

  const addNewFieldFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={closeDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        disabled={checkDisabled()}
        onClick={
          type.name === types.IMAGE ? createTemplateImage : saveNewTextField
        }
      />
    </>
  );

  useEffect(() => {
    if (createdImage) {
      saveNewImageField(createdImage);
      closeDialog();
    }
  }, [createdImage]);

  useEffect(() => {
    validationFail && closeDialog();
  }, [validationFail]);

  useEffect(() => {
    if (error) {
      setIsUploading(false);
    }
  }, [error]);
  useEffect(() => {
    setShowPrettyName(false);
  }, []);

  return (
    <Dialog
      visible={addNewFieldDialog}
      className="confirmDialog"
      header="Add New Field"
      modal
      footer={addNewFieldFooter}
      onHide={closeDialog}
    >
      <div className={styles.addNewFieldDialog}>
        <Dropdown
          value={type}
          options={fieldTypes}
          onChange={onTypeChange}
          optionLabel="name"
          placeholder="Select Field Type"
          className={styles.dialogElement}
        />
        <InputText
          value={inputPrettyName}
          placeholder="Enter Field Pretty Name"
          pattern="^[a-zA-Z:&#\-',. ]*"
          className={classNames(
            styles.dialogElement,
            prettyNameError && styles.errorBorder
          )}
          onChange={(e) => savePrettyName(e.target.value)}
        />
        {prettyNameError && (
          <span className={styles.errorMessage}>{prettyNameError}</span>
        )}
        {type.name === 'TEXT' && (
          <InputText
            value={inputValue}
            placeholder="Enter Field Value"
            className={styles.dialogElement}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
        {type.name === 'IMAGE' && (
          <FileUpload
            ref={fileUploadRef}
            accept=".jpeg,.jpg,.png"
            label="Image"
            maxFileSize={1000000}
            onSelect={onImageSelect}
            onValidationFail={onValidationFail}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            customUpload
            disabled={isTrialTracker}
            uploading={isUploading}
          />
        )}
      </div>
    </Dialog>
  );
};

export default AddNewFieldDialog;
