import { all, put } from 'redux-saga/effects';
import * as actions from '../section/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getSectionOptionsSaga(action) {
  yield put(actions.sectionOptionsRequest());
  const actionData = action.actionData;

  const sectionTypeData = {
    url: urls.SECTION_TYPES,
    method: 'GET',
    data: {},
  };
  const zoneData = {
    url: urls.ZONES,
    method: 'GET',
    data: {},
  };

  try {
    let [sectionTypes, zones] = yield all([
      makeRequest(sectionTypeData, actionData),
      makeRequest(zoneData, actionData),
    ]);

    sectionTypes = sectionTypes.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    yield put(actions.sectionOptionsSuccess(sectionTypes, zones.data));
  } catch (error) {
    yield put(actions.sectionOptionsFail(error.response.status));
  }
}
