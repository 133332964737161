import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { cloneDeep } from 'lodash';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { links, popUp } from '../../../Common/globalConstants';
import { getUnlinkCompanyParams, getInitialParams } from '../constants';
import Footer from '../../../Common/Footer/Footer';
import { urls, useRequest } from '../../../Common/ApiServices';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';

const SharedTrialList = ({
  sharedTrials,
  isLoading,
  totalRecords,
  sharedTrialsParams,
  setSharedTrialsParams,
  fetchSharedTrials,
  trialId,
  trialName,
}) => {
  const [sort, setSort] = useState({ field: 'companyName', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [companyToUnlink, setCompanyToUnlink] = useState(false);
  const [companyUnlinkDialog, setCompanyUnlinkDialog] = useState(false);

  const tableRef = useRef(null);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const { sendRequest } = useRequest({});
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
    setSharedTrialsParams((oldParams) => {
      const params = cloneDeep(oldParams);
      params.navigation.sort[0].key = sortField;
      params.navigation.sort[0].order = sortOrder === 1 ? 'ASC' : 'DESC';
      return params;
    });
  };

  const onPageSelect = (pagination) => {
    setPagination({ first: pagination.first, rows: pagination.rows });
    setSharedTrialsParams((oldParams) => {
      const params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  };

  const sortFunc = () => tableRef?.current?.props.value || sharedTrials;

  const hideUnlinkCompanyDialog = () => {
    setCompanyToUnlink(null);
    setCompanyUnlinkDialog(false);
  };
  const unlinkCompany = async () => {
    const requestData = {
      url: urls.UNLINK_SHARED_TRIAL,
      method: 'POST',
      data: getUnlinkCompanyParams(trialId, companyToUnlink.id),
    };
    const response = await sendRequest(requestData);
    if (response) {
      setCompanyToUnlink(null);
      setCompanyUnlinkDialog(false);
      fetchSharedTrials();
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Company ${companyToUnlink.name} was unlinked.`,
          life: 5000,
        })
      );
    }
  };
  const unlinkCompanyDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideUnlinkCompanyDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={unlinkCompany}
      />
    </>
  );
  const confirmUnlink = (e, rowData) => {
    e.stopPropagation();
    setCompanyToUnlink(rowData[0].invitedCompany);
    setCompanyUnlinkDialog(true);
  };
  const unlinkBodyTemplate = (rowData) => {
    return (
      !isTrialTracker && (
        <>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={(e) => confirmUnlink(e, [rowData])}
          />
        </>
      )
    );
  };
  const unlinkColumn = (
    <Column
      field="remove"
      header="Unlink"
      headerStyle={{ width: '70px' }}
      body={unlinkBodyTemplate}
    />
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push(links.TRIALS);
      },
    },
    {
      label: trialName,
      command: () => {
        history.push(`${links.TRIAL}/${id}`);
      },
    },
    { label: 'Shared With' },
  ];

  return (
    <div className="list-generic">
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className="table-generic p-datatable-sm"
        value={sharedTrials}
        resizableColumns
        columnResizeMode="expand"
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={isLoading}
        reorderableColumns
        scrollable
        emptyMessage=""
        frozenWidth="220px"
      >
        <Column
          field="invitedCompany.name"
          sortField="invitedCompanyName"
          reorderable
          columnKey="invitedCompanyName"
          header="Company Name"
          headerStyle={{ width: '220px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="sharedDate"
          sortField="sharedDate"
          reorderable
          columnKey="sharedDate"
          header="Shared Date"
          headerStyle={{ width: '220px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="confirmed"
          sortField="confirmed"
          reorderable
          columnKey="confirmed"
          header="Confirmed"
          headerStyle={{ width: '220px', height: '48px' }}
          sortable
          sortFunction={sortFunc}
        />
        {unlinkColumn}
      </DataTable>
      <Footer
        totalRecords={totalRecords}
        initParams={getInitialParams(trialId)}
        params={sharedTrialsParams}
        pagination={pagination}
        type="shared trial"
        onPageSelect={onPageSelect}
      />
      <Dialog
        visible={companyUnlinkDialog}
        header="Delete Confirmation"
        footer={unlinkCompanyDialogFooter}
        onHide={hideUnlinkCompanyDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-info-circle p-mr-3" />
          {companyToUnlink && (
            <span>
              Are you sure you want to unlink company
              <b> {companyToUnlink.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default SharedTrialList;
