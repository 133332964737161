import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { urls, useRequest } from '../../../Common/ApiServices';
import { modalType } from '../constants';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { popUp } from '../../../Common/globalConstants';
import {
  initialPlantGoalsBatchFormSchema,
  plantGoalsBatchFormSchema,
} from './plantGoalsBatchFormValidation';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { errorCodes } from '../../constants';

import styles from './PlantGoalsBatchForm.module.scss';

const PlantGoalsBatchForm = ({
  showModal,
  setShowModal,
  selectedPlantGoals,
  setSelectedPlantGoals,
  fetchGoals,
}) => {
  const [isEventType, setIsEventType] = useState(false);

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const initialPlantGoalsBatchInfo = useMemo(
    () => ({
      field: '',
      daysAfterPlanting: '',
      goal: '',
      isEvent: selectedPlantGoals
        .map((field) => field.fieldType)
        .includes('EVENT'),
    }),
    [selectedPlantGoals]
  );

  const updatePlantGoal = useCallback(
    async (data) => {
      const requestData = {
        url: urls.UPDATE_TRIAL_FIELD_GOALS,
        method: 'POST',
        data: data,
      };

      const response = await sendRequest(requestData);
      if (response) {
        onHide();
        fetchGoals();

        dispatch(
          setPopUp({
            severity: popUp.severities.SUCCESS,
            summary: popUp.summary.SUCCESSFUL,
            detail: 'New plant goal was updated successfully!',
            life: 5000,
            sticky: null,
          })
        );
      }
      return response;
    },
    [dispatch, fetchGoals, setSelectedPlantGoals, setShowModal]
  );

  const formik = useFormik({
    initialValues: initialPlantGoalsBatchFormSchema(initialPlantGoalsBatchInfo),
    enableReinitialize: true,
    validationSchema: plantGoalsBatchFormSchema,
    onSubmit: (values) => {
      const newParams = {
        ids: selectedPlantGoals.map((goal) => goal.id),
        ...(values.daysAfterPlanting && {
          daysAfterPlanting: values.daysAfterPlanting,
        }),
        goal: values.goal,
      };
      updatePlantGoal(newParams);
    },
  });

  const onHide = useCallback(() => {
    setShowModal(modalType.NONE);
    formik.resetForm();
    setSelectedPlantGoals([]);
  }, [formik, setSelectedPlantGoals, setShowModal]);

  const accept = useCallback(() => {
    onHide();
  }, [onHide]);

  const confirm = useCallback(
    (dirty) => {
      if (dirty) {
        confirmDialog({
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          message: 'Are you sure you want to discard the changes?',
          accept,
        });
      } else {
        accept();
      }
    },
    [accept]
  );

  useEffect(() => {
    const fieldTypes = selectedPlantGoals.map((goal) => goal.fieldType);
    const isEventType = fieldTypes.some((item) => item === 'EVENT');
    setIsEventType(isEventType);
  }, [selectedPlantGoals]);

  useEffect(() => {
    if (error?.response?.data?.errors) {
      error.response.data.errors.forEach((err) =>
        formik.setFieldError(err.fieldName, errorCodes[err.code].text)
      );
    }
  }, [error]);

  return (
    <Dialog
      className={styles.plantGoalsBatchFormDialog}
      header="Plant Goal Information"
      visible={showModal}
      onHide={onHide}
    >
      <PromptIfDirty dirty={formik.dirty} />
      <Divider />
      <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
        <div className="p-fluid">
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="plant" className="p-col-12 p-md-3 p-text-bold">
              Field*
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                className={classNames(
                  'p-text-nowrap p-text-truncate',
                  styles.fieldInput
                )}
                id="field"
                value={selectedPlantGoals
                  .map((goal) => goal.fieldName)
                  .join(', ')}
                tooltip={selectedPlantGoals
                  .map((goal) => goal.fieldName)
                  .join()}
                tooltipOptions={{ position: 'bottom' }}
                readOnly
              />
            </div>
          </div>
          {isEventType ? (
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="daysAfterPlanting" className="p-col-12 p-md-3">
                Days After Planting
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="daysAfterPlanting"
                  type="number"
                  min="0"
                  value={formik.values.daysAfterPlanting}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled
                />
                {formik.errors.daysAfterPlanting &&
                  formik.touched.daysAfterPlanting && (
                    <small id="goal-invalid" className="p-error p-d-block">
                      {formik.errors.daysAfterPlanting}
                    </small>
                  )}
              </div>
            </div>
          ) : (
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="daysAfterPlanting"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Days After Planting*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="daysAfterPlanting"
                  type="number"
                  min="0"
                  value={formik.values.daysAfterPlanting}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.daysAfterPlanting &&
                  formik.touched.daysAfterPlanting && (
                    <small id="goal-invalid" className="p-error p-d-block">
                      {formik.errors.daysAfterPlanting}
                    </small>
                  )}
              </div>
            </div>
          )}
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="goal" className="p-col-12 p-md-3 p-text-bold">
              Goal*
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="goal"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.goal}
              />
              {formik.errors.goal && formik.touched.goal && (
                <small id="goal-invalid" className="p-error p-d-block">
                  {formik.errors.goal}
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
          <Button
            label="Save"
            type="submit"
            icon="pi pi-check"
            disabled={!formik.dirty || !formik.isValid}
            autoFocus
          />
          <Button
            className="p-button-secondary"
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => confirm(formik.dirty)}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default PlantGoalsBatchForm;
