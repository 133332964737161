import React, { useEffect, useState } from 'react';
import MenuDivider from '../../Common/Menu/MenuDivider';
import MultiSelectFilter from '../../Filters/MultiSelectFilter';
import FilterControls from '../../Filters/FilterControls';

const ProductFilters = ({
  categories,
  classifications,
  manufacturers,
  applyNewFilters,
}) => {
  const [initialCategories, setInitialCategories] = useState(null);
  const [initialClassifications, setInitialClassifications] = useState(null);
  const [initialManufacturers, setInitialManufacturers] = useState(null);

  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedClassifications, setSelectedClassifications] = useState(null);
  const [selectedManufacturers, setSelectedManufacturers] = useState(null);

  const onResetFilters = () => {
    setSelectedCategories(null);
    setSelectedClassifications(null);
    setSelectedManufacturers(null);
    applyNewFilters(null, null, null, null, null);
  };

  const onApplyFilters = () => {
    applyNewFilters(
      selectedCategories,
      selectedClassifications,
      selectedManufacturers
    );
  };

  useEffect(() => {
    setInitialClassifications(classifications);
    setInitialCategories(categories);
    setInitialManufacturers(manufacturers);
  }, [categories, classifications, manufacturers]);

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <MultiSelectFilter
        label="Category"
        items={initialCategories}
        selectedItems={selectedCategories}
        setSelectedItems={setSelectedCategories}
        filter
        filterName="Select Categories"
      />
      <MultiSelectFilter
        label="Classification"
        items={initialClassifications}
        selectedItems={selectedClassifications}
        setSelectedItems={setSelectedClassifications}
        filter
        filterName="Select Classifications"
      />
      <MultiSelectFilter
        label="Manufacturer"
        items={initialManufacturers}
        selectedItems={selectedManufacturers}
        setSelectedItems={setSelectedManufacturers}
        filter
        filterName="Select Manufacturers"
      />
    </div>
  );
};

export default ProductFilters;
