import * as yup from 'yup';

const lengthError = 'Maximum length exceeded.';

export const initialProductFormSchema = (productInfo) => {
  return {
    productCode: productInfo.productCode || '',
    tradeName: productInfo.tradeName || '',
    productName: productInfo.productName || '',
    category: productInfo.category || undefined,
    classification: productInfo.classification || undefined,
    manufacturer: productInfo.manufacturer || undefined,
    measureUnit: productInfo.measureUnit || undefined,
    mixerMeasureUnit: productInfo.mixerMeasureUnit || undefined,
    mixer: productInfo.mixer || undefined,
    applicationMethod: productInfo.applicationMethod || undefined,
    sdsNumber: productInfo.sdsNumber || '',
    epaRegistrationNumber: productInfo.epaRegistrationNumber || '',
    sdsUrl: productInfo.sdsUrl || '',
    labelUrl: productInfo.labelUrl || '',
    equipment: productInfo.equipment || '',
    phone: productInfo.phone || '',
    note: productInfo.note || '',
  };
};

export const productFormSchema = yup.object().shape({
  productCode: yup
    .string()
    .required('Product code should be provided.')
    .max(32, lengthError),
  productName: yup.string().max(32, lengthError),
  tradeName: yup
    .string()
    .required('Trade name should be provided.')
    .max(32, lengthError),
  category: yup.object(),
  classification: yup.object(),
  manufacturer: yup.object(),
  measureUnit: yup.object(),
  mixerMeasureUnit: yup.object(),
  mixer: yup.object(),
  applicationMethod: yup.object(),
  sdsNumber: yup.string().max(32, lengthError),
  epaRegistrationNumber: yup.string().max(128, lengthError),
  sdsUrl: yup.string().url('SDS URL must be a valid URL'),
  labelUrl: yup.string().url('Label URL must be a valid URL'),
  equipment: yup.string().max(1024, lengthError),
  phone: yup.string().max(15, lengthError),
  note: yup.string().max(1024, lengthError),
});
