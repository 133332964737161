import React from 'react';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import styles from './DeleteDialog.module.scss';

const DeleteDialog = ({
  deleteLogDialog,
  hideDeleteLogDialog,
  logToDelete,
  deleteLog,
}) => {
  const deleteLogDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteLogDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteLog}
      />
    </>
  );
  return (
    <Dialog
      visible={deleteLogDialog}
      className={classNames(styles.dialog, 'confirmDialog')}
      header="Delete Confirmation"
      footer={deleteLogDialogFooter}
      onHide={hideDeleteLogDialog}
    >
      <div className="confirmation-content">
        <i
          className={classNames('pi pi-info-circle p-mr-3', styles.dialogIcon)}
        />
        {logToDelete && (
          <span>
            Are you sure you want to delete log for{' '}
            <b>{logToDelete.cropPlantName}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
