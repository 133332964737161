import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { defaultFontSize, fieldOrigin, types, zplFonts } from '../constants';
import classNames from 'classnames';
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';
import CustomSelect from '../../CustomSelect/CustomSelect';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import styles from './PanelHeader.module.scss';

const PanelHeader = ({
  fontSize,
  fontFamily,
  fieldType,
  fontBold,
  isSystem,
  activeField,
  fieldTypeOptions,
  barcodeHeight,
  setFontFamily,
  setFontSize,
  setFontBold,
  setDefaultSettings,
  setAddNewFieldDialog,
  changeFieldType,
  onDeleteCustomOption,
  isDropdownOptionsOpen,
  setIsDropdownOptionsOpen,
  setBarcodeHeight,
  showPrettyName,
  showBarcodeText,
  setShowPrettyName,
  setShowBarcodeText,
  prepareToDelete,
  pushFieldToArray,
}) => {
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const checkIsDefaultField = (name, fields) => {
    return (
      fields?.find((field) => field.name === name)?.origin ===
      fieldOrigin.SYSTEM
    );
  };

  const isDefaultField = checkIsDefaultField(fieldType?.name, fieldTypeOptions);

  return (
    <div className={styles.panelHeader}>
      <div className={styles.panelMenu}>
        <Dropdown
          id="family"
          value={fontFamily.name ? fontFamily : zplFonts[0]}
          className={classNames(styles.menuMargin)}
          options={zplFonts}
          optionLabel="name"
          disabled={
            isSystem ||
            fieldType?.type === types.IMAGE ||
            fieldType?.type === types.QR ||
            (fieldType?.type === types.BARCODE && !showBarcodeText) ||
            (!activeField && !fieldType) ||
            isTrialTracker
          }
          onChange={(e) => setFontFamily({ name: e.value.name })}
        />
        <InputNumber
          id="fontSize"
          value={fontSize || defaultFontSize}
          className={classNames(styles.menuMargin)}
          suffix="pt"
          onChange={(e) => setFontSize(e.value)}
          min="1"
          max="50"
          showButtons
          disabled={
            isSystem ||
            fieldType?.type === types.IMAGE ||
            fieldType?.type === types.QR ||
            (fieldType?.type === types.BARCODE && !showBarcodeText) ||
            (!activeField && !fieldType) ||
            isTrialTracker
          }
          decrementButtonClassName="p-button-outlined p-button-secondary "
          incrementButtonClassName="p-button-outlined p-button-secondary "
        />
        <ToggleButton
          className={classNames(
            styles.menuMargin,
            styles.toggleButton,
            fontBold ? styles.fontBold : styles.fontNormal,
            'p-button-outlined p-button-secondary'
          )}
          checked={fontBold}
          disabled={
            isSystem ||
            fieldType?.type === types.IMAGE ||
            fieldType?.type === types.QR ||
            (fieldType?.type === types.BARCODE && !showBarcodeText) ||
            (!activeField && !fieldType) ||
            isTrialTracker
          }
          onChange={(e) => setFontBold(e.value)}
          onLabel="B"
          offLabel="B"
        />
        <CustomSelect
          setAddNewFieldDialog={setAddNewFieldDialog}
          id="fieldType"
          value={fieldType}
          options={fieldTypeOptions}
          placeholder="Select field"
          optionLabel="prettyName"
          disabled={isSystem || isTrialTracker}
          onChange={(e) => changeFieldType(e)}
          onDeleteCustomOption={onDeleteCustomOption}
          isDropdownOptionsOpen={isDropdownOptionsOpen}
          setIsDropdownOptionsOpen={setIsDropdownOptionsOpen}
          setDefaultSettings={setDefaultSettings}
        />
        <InputNumber
          id="barcodeHeight"
          value={barcodeHeight}
          className={classNames(
            styles.menuMargin,
            fieldType?.type !== types.BARCODE && styles.hideElement
          )}
          suffix="pt"
          onChange={(e) => setBarcodeHeight(e.value)}
          min="1"
          max="50"
          showButtons
          disabled={isSystem || (!activeField && !fieldType) || isTrialTracker}
          decrementButtonClassName="p-button-outlined p-button-secondary "
          incrementButtonClassName="p-button-outlined p-button-secondary "
        />
        <InputSwitch
          id="showPrettyName"
          tooltip="Show field name"
          className={classNames(
            styles.menuMargin,
            (fieldType?.type === types.QR ||
              fieldType?.type === types.IMAGE ||
              fieldType?.type === types.BARCODE) &&
              styles.hideElement
          )}
          checked={showPrettyName}
          tooltipOptions={{ position: 'top' }}
          onChange={(e) => setShowPrettyName(e.value)}
          disabled={
            isSystem ||
            (!activeField && !fieldType) ||
            (activeField && !isDefaultField) ||
            !isDefaultField ||
            isTrialTracker
          }
        />
        <InputSwitch
          id="showBarcodeText"
          tooltip="Show barcode text"
          className={classNames(
            styles.menuMargin,
            fieldType?.type !== types.BARCODE && styles.hideElement
          )}
          checked={showBarcodeText}
          tooltipOptions={{ position: 'top' }}
          onChange={(e) => setShowBarcodeText(e.value)}
          disabled={
            isSystem ||
            (!activeField && !fieldType) ||
            (activeField && !isDefaultField) ||
            !isDefaultField ||
            isTrialTracker
          }
        />
        <Button
          icon="pi pi-trash"
          className={classNames(
            styles.menuMargin,
            'p-button-secondary p-button-text'
          )}
          onClick={(e) => {
            prepareToDelete(e);
          }}
          disabled={!activeField || isSystem || isTrialTracker}
        />
        <Button
          label="Create"
          type="button"
          onClick={() =>
            pushFieldToArray({
              fieldName: fieldType.name,
              fieldType: fieldType.type,
              prettyName: fieldType.prettyName,
              measureUnit: fieldType.measureUnit,
              fontSize: fontSize,
              fontFamily: fontFamily,
              fontBold: fontBold,
              barcodeHeight: barcodeHeight,
              showPrettyName: showPrettyName,
              image: {
                width: 100,
                height: 85,
                measureUnit: 'PIXEL',
                link: fieldType.val,
              },
            })
          }
          disabled={
            isSystem ||
            !fieldType ||
            (activeField && fieldType) ||
            isTrialTracker
          }
          className={classNames(styles.menuMargin, 'p-button-success')}
        />
      </div>
    </div>
  );
};

export default PanelHeader;
