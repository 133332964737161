import React from 'react';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

function Spinner() {
  return (
    <div className={styles.spinnerContainer}>
      <i
        className={classNames(
          styles.loadingSpinner,
          'p-datatable-loading-icon pi-spin pi pi-spinner'
        )}
      />
    </div>
  );
}

export default Spinner;
