import React from 'react';
import PlantGoalsList from '../PlantGoalsList/PlantGoalList';
import LeftBar from '../../../LeftBar/LeftBar';
import TrialMenu from '../../Menu/TrialMenu';

const PlantGoalsPage = () => {
  return (
    <>
      <LeftBar>
        <TrialMenu />
      </LeftBar>
      <PlantGoalsList />
    </>
  );
};

export default PlantGoalsPage;
