import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { unloadCallback } from './utils';

const PromptIfDirty = ({ dirty, message }) => {
  useEffect(() => {
    if (dirty) {
      window.addEventListener('beforeunload', unloadCallback);
    } else {
      window.removeEventListener('beforeunload', unloadCallback);
    }

    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, [dirty]);
  return (
    <Prompt
      when={dirty}
      message={
        message ||
        'You have made changes. They will be lost if you continue. Are you sure you want to leave?'
      }
    />
  );
};

export default PromptIfDirty;
