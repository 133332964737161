import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import { popUp, sortOrders } from '../../../Common/globalConstants';
import Footer from '../../../Common/Footer/Footer';
import PlantGoalsForm from '../PlantGoalsForm/PlantGoalsForm';
import PlantGoalsBatchForm from '../PlantGoalsBatchForm/PlantGoalsBatchForm';
import { getInitialPlantGoalsParams, modalType } from '../constants';
import { urls, useRequest } from '../../../Common/ApiServices';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import styles from './PlantGoalList.module.scss';

const PlantGoalList = () => {
  const { id } = useParams();

  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [showModal, setShowModal] = useState(modalType.NONE);
  const [goals, setGoals] = useState([]);
  const [plantGoalsParams, setPlantGoalsParams] = useState(
    getInitialPlantGoalsParams(id)
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedPlantGoals, setSelectedPlantGoals] = useState([]);
  const [selectedPlantGoal, setSelectedPlantGoal] = useState(null);
  const [goalToDelete, setGoalToDelete] = useState(false);
  const [deleteGoalDialog, setDeleteGoalDialog] = useState(false);
  const [sort, setSort] = useState({ field: 'plantName', order: 1 });
  const [frozenWidth, setFrozenWidth] = useState(320);

  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const { isLoading, sendRequest } = useRequest({});

  const trialName = useLocation();
  const history = useHistory();

  const fetchGoals = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_TRIAL_FIELD_GOALS,
      method: 'POST',
      data: plantGoalsParams,
    };

    const response = await sendRequest(requestData);
    if (response) {
      const goals = response.data.results.map((goal) => {
        return {
          id: goal.trialFieldGoal.id,
          goal: goal.trialFieldGoal.goal,
          daysAfterPlanting: goal.trialFieldGoal.daysAfterPlanting,
          fieldName: goal.trialField.label,
          fieldType: goal.trialField.eventType,
          plantName: goal.plant.name,
          breeder: goal.breeder?.name,
        };
      });
      setGoals(goals);
      setTotalRecords(response.data.page.total);
    }
    return response;
  }, [plantGoalsParams, sendRequest]);

  const deleteGoal = useCallback(async () => {
    const requestData = {
      url: urls.DELETE_TRIAL_FIELD_GOALS,
      method: 'POST',
      data: {
        goal: {
          id: {
            in: [goalToDelete[0].id],
          },
        },
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Goal ${goalToDelete[0].goal} was deleted successfully.`,
          life: 5000,
          sticky: null,
        })
      );
      setGoalToDelete(null);
      setDeleteGoalDialog(false);
      fetchGoals();
    }
    return response;
  }, [dispatch, fetchGoals, goalToDelete, sendRequest]);

  const onPageSelect = useCallback(
    ({ first, rows }) => {
      setPagination({ first, rows });
      setPlantGoalsParams({
        query: plantGoalsParams.query,
        navigation: {
          sort: [{ key: sort.field, order: sortOrders[sort.order] }],
          page: { from: first, size: rows },
        },
      });
    },
    [plantGoalsParams, sort]
  );

  const onSort = useCallback(
    ({ sortField, sortOrder }) => {
      setSort({ field: sortField, order: sortOrder });
      setPlantGoalsParams({
        query: plantGoalsParams.query,
        navigation: {
          sort: [{ key: sortField, order: sortOrders[sortOrder] }],
          page: { from: pagination.first, size: pagination.rows },
        },
      });
    },
    [pagination, plantGoalsParams]
  );

  const sortFunc = () => tableRef?.current?.props.value || goals;

  const onGlobalSearch = debounce((event) => {
    setPlantGoalsParams((oldParams) => {
      const { navigation, query } = oldParams;
      return {
        navigation: navigation,
        query: event.target.value
          ? { ...query, search: event.target.value }
          : { trial: query.trial },
      };
    });
  }, 500);

  const onNewGoalCreate = () => {
    setShowModal(modalType.ONE);
  };

  const onBatchUpdate = () => {
    setShowModal(modalType.BATCH);
  };

  const onGoalSelect = useCallback((goal) => {
    setSelectedPlantGoal(goal.id);
    setShowModal(modalType.ONE);
  }, []);

  const header = useMemo(
    () => (
      <div className={styles.tableHeader}>
        <div className={styles.buttonGroup}>
          <Button
            className="p-button-raised"
            label="New Plant Goal"
            style={{ display: showElements }}
            icon="pi pi-plus"
            onClick={() => onNewGoalCreate()}
          />
          <Button
            className="p-button-raised"
            label="Update"
            style={{ display: showElements }}
            icon="pi pi-plus"
            disabled={!selectedPlantGoals.length}
            onClick={() => onBatchUpdate()}
          />
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => onGlobalSearch(e)}
            placeholder="Search..."
          />
        </span>
      </div>
    ),
    [onGlobalSearch, selectedPlantGoals.length, showElements]
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <button
        className={styles.linkStyle}
        onClick={() => onGoalSelect(rowData)}
      >
        {rowData.plantName}
      </button>
    );
  };

  const confirmDeleteGoal = (e, goal) => {
    e.stopPropagation();
    setGoalToDelete(goal);
    setDeleteGoalDialog(true);
  };

  const removeGoalBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-warning"
        onClick={(e) => confirmDeleteGoal(e, [rowData])}
      />
    );
  };

  const removeColumn = !isTrialTracker && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={removeGoalBodyTemplate}
    />
  );

  const hideDeleteGoalDialog = () => {
    setGoalToDelete(null);
    setDeleteGoalDialog(false);
  };

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'plantName') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const deleteGoalDialogFooter = useMemo(
    () => (
      <>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDeleteGoalDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-raised p-button-danger"
          onClick={deleteGoal}
        />
      </>
    ),
    [deleteGoal]
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName') || trialName.state,
      command: () => {
        history.push(`/trial/${id}`);
      },
    },
    { label: 'Plant Goals' },
  ];

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);

  return (
    <div className={classNames(styles.plantGoalsList, 'list-generic')}>
      <BreadCrumb items={breadCrumbItems} />
      {showModal === modalType.ONE && (
        <PlantGoalsForm
          showModal={showModal === modalType.ONE}
          setShowModal={setShowModal}
          fetchGoals={fetchGoals}
          selectedGoalId={selectedPlantGoal}
          setSelectedGoalId={setSelectedPlantGoal}
        />
      )}
      {showModal === modalType.BATCH && (
        <PlantGoalsBatchForm
          showModal={showModal === modalType.BATCH}
          setShowModal={setShowModal}
          selectedPlantGoals={selectedPlantGoals}
          setSelectedPlantGoals={setSelectedPlantGoals}
          fetchGoals={fetchGoals}
        />
      )}

      <DataTable
        ref={tableRef}
        className={classNames(
          'table-generic p-datatable-sm',
          styles.customTableStyle
        )}
        value={goals}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={selectedPlantGoals}
        onSelectionChange={(e) => setSelectedPlantGoals(e.value)}
        selectionMode="checkbox"
        cellSelection
        dataKey="id"
        loading={isLoading}
        reorderableColumns
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{
            width: '40px',
            height: '48px',
            padding: '0 7px',
          }}
          frozen
        />
        <Column
          field="plantName"
          sortField="plantName"
          reorderable
          columnKey="plantName"
          header="Plant Name"
          body={(rowData) => nameBodyTemplate(rowData)}
          headerStyle={{ width: '250px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="fieldName"
          reorderable
          columnKey="fieldName"
          header="Field"
          headerStyle={{ width: '200px', height: '48px' }}
        />
        <Column
          field="breeder"
          sortField="breederName"
          reorderable
          columnKey="breeder"
          sortable
          sortFunction={sortFunc}
          header="Breeder"
          headerStyle={{ width: '200px', height: '48px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="daysAfterPlanting"
          reorderable
          columnKey="daysAfterPlanting"
          header="Days After Planting"
          headerStyle={{ width: '200px', height: '48px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="goal"
          reorderable
          columnKey="goal"
          header="Goal"
          headerStyle={{ width: '200px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
        />
        {removeColumn}
      </DataTable>
      <Footer
        totalRecords={totalRecords}
        initParams={getInitialPlantGoalsParams(id)}
        params={plantGoalsParams}
        pagination={pagination}
        type="goal"
        onPageSelect={onPageSelect}
      />
      <Dialog
        visible={deleteGoalDialog}
        className={classNames(styles.dialog, 'confirmDialog')}
        header="Delete Confirmation"
        footer={deleteGoalDialogFooter}
        onHide={hideDeleteGoalDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-info-circle p-mr-3" />
          {goalToDelete && (
            <span>
              Are you sure you want to delete goal
              <b> {goalToDelete[0].goal}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default PlantGoalList;
