export const getInitialFormInfo = (userEmail, firstName, lastName) => {
  const first = firstName ? firstName : '';
  const last = lastName ? lastName : '';
  return {
    email: userEmail || '',
    name: first + ' ' + last,
    companyName: '',
    phoneNumber: '',
    subject: '',
    message: '',
  };
};
