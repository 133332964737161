import * as actionTypes from './actions';

const initialState = {
  plants: null,
  trialFields: null,
  logWeekFrom: null,
  logWeekTo: null,
  reportFiltersReady: false,
};

export const reportFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORT_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        plants: action.plants,
        trialFields: action.trialFields,
        logWeeks: action.logWeeks,
        reportFiltersReady: true,
      };
    case actionTypes.REPORT_FILTERS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
