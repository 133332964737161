import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductsList from '../ProductList/ProductList';
import LeftBar from '../../LeftBar/LeftBar';
import ProductFilters from '../ProductFilters/ProductFilters';
import { isEmpty } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { getProductFilters } from '../../../reduxStore/productFilters/actions';
import { urls, useRequest } from '../../Common/ApiServices';
import { initialProductParams } from '../constants';
import { createFilterCategory, fillFilterParams } from '../../Common/utils';
import { filtersNames } from '../constants';
import { popUp, queryTypes } from '../../Common/globalConstants';
import { isAuth } from '../../../auth/auth-service';
import { setPopUp } from '../../../reduxStore/popUp/actions';

const ProductsPage = () => {
  const [filters, setFilters] = useState(null);
  const [products, setProducts] = useState([]);
  const [productParams, setProductParams] = useState(initialProductParams);
  const [totalRecords, setTotalRecords] = useState(null);
  const [productIsCreated, setProductIsCreated] = useState(false);

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.productFilters.categories);
  const classifications = useSelector(
    (state) => state.productFilters.classifications
  );
  const manufacturers = useSelector(
    (state) => state.productFilters.manufacturers
  );
  const productFiltersReady = useSelector(
    (state) => state.productFilters.productFiltersReady
  );

  const { isLoading, sendRequest } = useRequest({});

  const { logout, getAccessTokenSilently } = useAuth0();

  const actionData = useMemo(
    () => ({
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuth(),
      getAccessTokenSilently: getAccessTokenSilently,
    }),
    [dispatch, getAccessTokenSilently, logout]
  );

  const fetchProducts = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_PRODUCTS,
      method: 'POST',
      data: productParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      const products = response.data.results;
      setProducts(products);
      setTotalRecords(response?.data?.page?.total);
    }

    return response;
  }, [productParams, sendRequest]);

  const updateFilters = useCallback((newFilters) => {
    if (!newFilters) {
      return null;
    }
    const selectedFilters = createFilterCategory(newFilters);
    if (!isEmpty(selectedFilters)) {
      setProductParams((oldParams) => {
        return fillFilterParams(
          selectedFilters,
          oldParams,
          filtersNames,
          queryTypes.PRODUCT
        );
      });
    }
  }, []);

  const onApplyNewFilters = (
    selectedCategories,
    selectedClassifications,
    selectedManufacturers
  ) => {
    setFilters({
      category:
        selectedCategories && selectedCategories.length > 0
          ? selectedCategories
          : null,
      classification:
        selectedClassifications && selectedClassifications.length > 0
          ? selectedClassifications
          : null,
      manufacturer:
        selectedManufacturers && selectedManufacturers.length > 0
          ? selectedManufacturers
          : null,
    });
  };

  useEffect(() => {
    if (productIsCreated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New product was created.',
          life: 5000,
          sticky: null,
        })
      );
      fetchProducts();
      setProductIsCreated(false);
    }
  }, [dispatch, fetchProducts, productIsCreated]);

  useEffect(() => {
    !isLoading && fetchProducts();
  }, [fetchProducts, productParams]);

  useEffect(() => {
    !productFiltersReady && dispatch(getProductFilters(actionData));
  }, [actionData, dispatch, productFiltersReady]);

  useEffect(() => {
    updateFilters(filters);
  }, [filters, updateFilters]);

  return (
    <>
      <LeftBar>
        <ProductFilters
          categories={categories}
          classifications={classifications}
          manufacturers={manufacturers}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      <ProductsList
        loading={isLoading}
        products={products}
        productParams={productParams}
        totalRecords={totalRecords}
        categories={categories}
        classifications={classifications}
        manufacturers={manufacturers}
        fetchProducts={fetchProducts}
        setProductParams={setProductParams}
        setProductIsCreated={setProductIsCreated}
      />
    </>
  );
};

export default ProductsPage;
