import { all, put } from 'redux-saga/effects';
import * as actions from '../historyOptions/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';
import {
  getTrialPlantsParams,
  productsParams,
  sectionsParams,
} from '../../components/Trials/History/constants';

export function* getHistoryOptionsSaga(action) {
  yield put(actions.historyOptionsRequest());
  const actionData = action.actionData;

  const productsData = {
    url: urls.SEARCH_APPLICATION_HISTORY_PRODUCTS,
    method: 'POST',
    data: productsParams,
  };
  const plantsData = {
    url: urls.SEARCH_PLANTS,
    method: 'POST',
    data: getTrialPlantsParams(actionData.trialId),
  };
  const sectionsData = {
    url: urls.SEARCH_APPLICATION_SECTIONS,
    method: 'POST',
    data: sectionsParams,
  };

  try {
    let [products, plants, sections] = yield all([
      makeRequest(productsData, actionData),
      makeRequest(plantsData, actionData),
      makeRequest(sectionsData, actionData),
    ]);
    plants = plants.data.results
      .map((plant) => ({
        id: plant?.plant?.id,
        name: plant?.plant?.name,
        dateFormat: plant?.plant?.dateFormat?.datePattern,
        firstDayOfWeek: plant?.plant?.firstDayOfWeek?.day,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    products = products.data.products
      .map((product) => ({
        id: product.id,
        name: product.name,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    sections = sections.data.sections
      .map((section) => ({
        id: section.id,
        name: section.name,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    yield put(actions.historyOptionsSuccess(products, plants, sections));
  } catch (error) {
    yield put(actions.historyOptionsFail(error.response));
  }
}
