import React, { useState } from 'react';
import ProductMenu from './ProductMenu/ProductMenu';
import ProductForm from './ProductForm/ProductForm';
import LeftBar from '../../LeftBar/LeftBar';

const ProductInfo = () => {
  const [productName, setProductName] = useState('');
  return (
    <>
      <LeftBar>
        <ProductMenu productName={productName} />
      </LeftBar>
      <ProductForm setProductName={setProductName} />
    </>
  );
};

export default ProductInfo;
