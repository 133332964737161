import React, { useState } from 'react';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { customLogout } from '../../../auth/auth-service';
import styles from './ErrorDialog.module.scss';

const ErrorDialog = ({ userName, logout }) => {
  const [permissionChanged, setPermissionChanged] = useState(false);

  //ToDo: global variable is temporary decision, should be replaced
  window.globalError = {};
  globalError.callback = () => {
    setPermissionChanged(true);
  };

  const dialogMessage =
    'please re-login as your Administrator changed your permissions.';

  const renderFooter = () => {
    return (
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => {
          customLogout(logout), globalError.callback();
        }}
        autoFocus
      />
    );
  };

  return (
    <div>
      <Dialog
        header={`Dear ${userName},`}
        visible={permissionChanged}
        modal={true}
        footer={renderFooter()}
        className={classNames(styles.errorDialog, 'confirmDialog')}
        onHide={() => {
          customLogout(logout), globalError.callback();
        }}
      >
        {dialogMessage}
      </Dialog>
    </div>
  );
};

export default ErrorDialog;
