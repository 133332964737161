import React, { useState } from 'react';
import MenuDivider from '../../../Common/Menu/MenuDivider';
import FilterControls from '../../../Filters/FilterControls';
import CalendarFilter from '../../../Filters/CalendarFilter';
import { locales } from '../../../Common/globalConstants';

const SharedTrialsFilters = ({
  applyNewFilters,
  dateFormatPattern,
  firstDay,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const onResetFilters = () => {
    setSelectedDate(null);
    applyNewFilters(null);
  };

  const onApplyFilters = () => {
    applyNewFilters(selectedDate);
  };

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <CalendarFilter
        label="Shared Date"
        selectedDate={selectedDate}
        setDate={setSelectedDate}
        filterName="Select Shared Date Range"
        dateFormat={dateFormatPattern}
        locale={locales[firstDay]}
      />
    </div>
  );
};

export default SharedTrialsFilters;
