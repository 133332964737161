import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/primereact.all.esm';
import { setPopUp } from '../../../reduxStore/popUp/actions';

const Toaster = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const popUpData = useSelector((state) => state.popUpData);

  const showPopUp = useCallback(
    ({ severity, summary, detail, life, sticky, content }) => {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life,
        sticky: sticky,
        content: content,
      });
      setTimeout(() => dispatch(setPopUp(null)), 1000);
    },
    [dispatch]
  );

  useEffect(() => {
    if (popUpData) {
      showPopUp(popUpData);
    }
  }, [popUpData, showPopUp]);

  return <Toast ref={toast} />;
};

export default Toaster;
