const firstDay = {
  SUNDAY: 0,
  MONDAY: 1,
};

const locales = {
  'dd/MM/yyyy': 'en-gb',
  'MM/dd/yyyy': 'en',
  'yyyy/MM/dd': 'en-gb',
};
export { firstDay, locales };
