import { combineReducers } from 'redux';
import { pageReducer } from './page/reducer';
import { trialReducer } from './trial/reducer';
import { trialFiltersReducer } from './trialFilters/reducer';
import { cropReducer } from './crop/reducer';
import { sectionReducer } from './section/reducer';
import { reportFiltersReducer } from './reportFilters/reducer';
import { isSharedReducer } from './isShared/reducer';
import { isFirstLoginReducer } from './isFirstLogin/reducer';
import { isTrialTrackerReducer } from './isTrialTracker/reducer';
import { permissionsReducer } from './permissions/reducer';
import { subscriptionReducer } from './subscription/reducer';
import { isLimitExceededReducer } from './limitExceeded/reducer';
import { productFiltersReducer } from './productFilters/reducer';
import { isActiveSubscriptionReducer } from './isActiveSubscription/reducer';
import { profileFieldGroupsReducer } from './profileFieldGroups/reducer';
import { taskOptionsReducer } from './taskOptions/reducers';
import { historyOptionsReducer } from './historyOptions/reducer';
import { profileInfoReducer } from './profileInfo/reducer';
import { popUpReducer } from './popUp/reducer';

const rootReducer = combineReducers({
  page: pageReducer,
  trial: trialReducer,
  trialFilters: trialFiltersReducer,
  crop: cropReducer,
  section: sectionReducer,
  reportFilters: reportFiltersReducer,
  isShared: isSharedReducer,
  isFirstLogin: isFirstLoginReducer,
  isTrialTracker: isTrialTrackerReducer,
  permissions: permissionsReducer,
  subscription: subscriptionReducer,
  limitExceeded: isLimitExceededReducer,
  productFilters: productFiltersReducer,
  isActiveSubscription: isActiveSubscriptionReducer,
  profileFieldGroups: profileFieldGroupsReducer,
  taskOptions: taskOptionsReducer,
  historyOptions: historyOptionsReducer,
  profileInfo: profileInfoReducer,
  popUpData: popUpReducer,
});

export default rootReducer;
