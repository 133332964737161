import React, { useState, useEffect } from 'react';
import MenuDivider from '../../Common/Menu/MenuDivider';
import CalendarFilter from '../../Filters/CalendarFilter';
import MultiSelectFilter from '../../Filters/MultiSelectFilter';
import FilterControls from '../../Filters/FilterControls';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { locales } from '../../Common/globalConstants';
import styles from './TaskFilters.module.scss';

const TaskFilters = ({
  setActiveLocation,
  clearLocationState,
  locations,
  trials,
  types,
  managers,
  statuses,
  sections,
  initialDate,
  applyNewFilters,
}) => {
  const [initialLocations, setInitialLocations] = useState(null);
  const [initialTrials, setInitialTrials] = useState(null);
  const [initialTypes, setInitialTypes] = useState(null);
  const [initialManagers, setInitialManagers] = useState(null);
  const [initialStatuses, setInitialStatuses] = useState(null);
  const [initialSections, setInitialSections] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTaskDate, setSelectedTaskDate] = useState(null);
  const [selectedTrials, setSelectedTrials] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(null);
  const [selectedManagers, setSelectedManagers] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null);

  const defaultLocation = useSelector(
    (state) => state.taskOptions.defaultLocation
  );

  const onResetFilters = () => {
    const res = locations?.find(
      (location) => location.id === defaultLocation.id
    );
    setSelectedLocation(res);
    clearLocationState();
    setActiveLocation(res);
    setSelectedTaskDate(null);
    setSelectedTrials(null);
    setSelectedTypes(null);
    setSelectedManagers(null);
    setSelectedStatuses(null);
    setSelectedSections(null);
    applyNewFilters(null, null, null, null, null);
  };

  const onApplyFilters = () => {
    setActiveLocation(selectedLocation),
      applyNewFilters(
        selectedLocation,
        selectedTaskDate,
        selectedTrials,
        selectedTypes,
        selectedManagers,
        selectedStatuses,
        selectedSections
      );
  };

  const onLocationChange = (e) => {
    setSelectedLocation(e.value);
  };

  useEffect(() => {
    setInitialLocations(locations);
    setInitialTrials(trials);
    setInitialTypes(types);
    setInitialManagers(managers);
    setInitialStatuses(statuses);
    setInitialSections(sections);
    setSelectedTaskDate(initialDate);
  }, [locations, trials, types, managers, statuses, sections, initialDate]);

  useEffect(() => {
    if (locations) {
      const res = locations?.find(
        (location) => location?.id === defaultLocation?.id
      );
      setActiveLocation(res);
      setSelectedLocation(res);
    }
  }, [locations]);

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <div className="filtersInputs">
        <div className={styles.locationLabel}>Location</div>
        <Dropdown
          className={styles.locations}
          value={selectedLocation && selectedLocation}
          options={initialLocations || []}
          onChange={onLocationChange}
          optionLabel="name"
          placeholder="Select Location"
        />
        <CalendarFilter
          label="Start Date"
          selectedDate={selectedTaskDate}
          setDate={setSelectedTaskDate}
          filterName="Select Start Date Range"
          dateFormat={selectedLocation?.dateFormat}
          locale={locales[selectedLocation?.firstDay]}
        />
        <MultiSelectFilter
          label="Trial"
          items={initialTrials}
          selectedItems={selectedTrials}
          setSelectedItems={setSelectedTrials}
          filter
          filterName="Select Trials"
        />
        <MultiSelectFilter
          label="Task Type"
          items={initialTypes}
          selectedItems={selectedTypes}
          setSelectedItems={setSelectedTypes}
          filterName="Select Task Types"
        />
        <MultiSelectFilter
          label="Status"
          items={initialStatuses}
          selectedItems={selectedStatuses}
          setSelectedItems={setSelectedStatuses}
          filterName="Select Task Status"
        />
        <MultiSelectFilter
          label="Task Manager"
          items={initialManagers}
          selectedItems={selectedManagers}
          setSelectedItems={setSelectedManagers}
          filter
          filterName="Select Task Managers"
        />
        <MultiSelectFilter
          label="Section"
          items={initialSections}
          selectedItems={selectedSections}
          setSelectedItems={setSelectedSections}
          filter
          filterName="Select Task Sections"
        />
      </div>
    </div>
  );
};

export default TaskFilters;
