import React, { useState } from 'react';
import MenuDivider from '../../../Common/Menu/MenuDivider';
import CalendarFilter from '../../../Filters/CalendarFilter';
import MultiSelectFilter from '../../../Filters/MultiSelectFilter';
import FilterControls from '../../../Filters/FilterControls';
import {
  getDefaultFirstDay,
  getDefaultDateFormat,
} from '../../../Common/utils';
import { locales } from '../../../Common/globalConstants';

const LogFilters = ({
  firstDay,
  dateFormat,
  sections,
  programs,
  classes,
  series,
  applyNewFilters,
}) => {
  const [selectedPlantDate, setSelectedPlantDate] = useState(null);
  const [selectedLogDate, setSelectedLogDate] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);

  const firstDayOfWeek = firstDay ? firstDay : getDefaultFirstDay();
  const dateFormatting = dateFormat ? dateFormat : getDefaultDateFormat();

  const onResetFilters = () => {
    setSelectedPlantDate(null);
    setSelectedLogDate(null);
    setSelectedSections(null);
    setSelectedPrograms(null);
    setSelectedClasses(null);
    setSelectedSeries(null);
    applyNewFilters(null, null, null, null, null, null);
  };

  const onApplyFilters = () => {
    applyNewFilters(
      selectedPlantDate,
      selectedLogDate,
      selectedSections,
      selectedPrograms,
      selectedClasses,
      selectedSeries
    );
  };

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <div className="filtersInputs">
        <CalendarFilter
          label="Plant Date"
          selectedDate={selectedPlantDate}
          setDate={setSelectedPlantDate}
          filterName="Select Plant Date Range"
          dateFormat={dateFormatting}
          locale={locales[firstDayOfWeek]}
        />
        <CalendarFilter
          label="Log Date"
          selectedDate={selectedLogDate}
          setDate={setSelectedLogDate}
          filterName="Select Log Date Range"
          dateFormat={dateFormatting}
          locale={locales[firstDayOfWeek]}
        />
        <MultiSelectFilter
          panelClassName="hideSelectAll"
          label="Section"
          items={sections}
          selectedItems={selectedSections}
          setSelectedItems={setSelectedSections}
          filter
          filterName="Select Sections"
        />
        <MultiSelectFilter
          label="Program"
          items={programs}
          selectedItems={selectedPrograms}
          setSelectedItems={setSelectedPrograms}
          filter
          filterName="Select Programs"
        />
        <MultiSelectFilter
          label="Class"
          items={classes}
          selectedItems={selectedClasses}
          setSelectedItems={setSelectedClasses}
          filter
          filterName="Select Classes"
        />
        <MultiSelectFilter
          label="Series"
          items={series}
          selectedItems={selectedSeries}
          setSelectedItems={setSelectedSeries}
          filter
          filterName="Select Series"
        />
      </div>
    </div>
  );
};

export default LogFilters;
