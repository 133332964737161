import * as yup from 'yup';

export const initialEmployeeFormSchema = (employeeInfo) => {
  return {
    firstName: employeeInfo.firstName,
    lastName: employeeInfo.lastName,
    email: employeeInfo.email,
    phone: employeeInfo.phone || '',
    title: employeeInfo.title || '',
    roles: employeeInfo.roles || null,
    department: employeeInfo.department || '',
    status: employeeInfo.status === 'ACTIVE',
    note: employeeInfo.note || '',
    pictureId: employeeInfo.image || '',
    dateFormat: employeeInfo.version?.dateFormat?.datePattern.toLowerCase(),
    lastLoginDate: employeeInfo.lastLoginDate
      ? new Date(employeeInfo.lastLoginDate)
      : undefined,
    inactiveDate: employeeInfo.inactiveDate
      ? new Date(employeeInfo.inactiveDate)
      : undefined,
    lastInvitationDate: employeeInfo.lastInvitationDate
      ? new Date(employeeInfo.lastInvitationDate)
      : undefined,
  };
};

const lengthError = 'Maximum length exceeded.';

export const employeeFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Employee First Name should be provided.')
    .matches(
      /^[a-z](?:_?[a-z\s]+)*$/i,
      'First Name should start with a letter and can only contain letters and spaces.'
    )
    .max(100, lengthError),
  lastName: yup
    .string()
    .required('Employee Last Name should be provided.')
    .matches(
      /^[a-z](?:_?[a-z\s]+)*$/i,
      'Last Name should start with a letter and can only contain letters and spaces.'
    )
    .max(100, lengthError),
  email: yup
    .string()
    .required('Employee Email should be provided.')
    .email('Invalid email format.')
    .max(100, lengthError),
  title: yup.string().max(100, lengthError),
  department: yup.string().max(100, lengthError),
  phone: yup.string().max(15, lengthError),
  note: yup.string().max(1024, lengthError),
});
