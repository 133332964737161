import React from 'react';
import Routes from './routes/index';
import Navigation from './components/Navigation/Navigation';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_4);

function App() {
  return (
    <div className="App container py-3">
      <Navigation />
      <Routes />
    </div>
  );
}

export default App;
