import React, { useEffect, useState } from 'react';
import FilterControls from '../../../Filters/FilterControls';
import MenuDivider from '../../../Common/Menu/MenuDivider';
import MultiSelectFilter from '../../../Filters/MultiSelectFilter';
import CalendarFilter from '../../../Filters/CalendarFilter';
import { locales } from '../../../Common/globalConstants';

const HistoryFilters = ({
  applyNewFilters,
  plants,
  products,
  sections,
  dateFormatPattern,
  firstDay,
}) => {
  const [initialPlants, setInitialPlants] = useState(null);
  const [selectedPlants, setSelectedPlants] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null);
  const [selectedAppDate, setSelectedAppDate] = useState(null);

  const onResetFilters = () => {
    setSelectedPlants(null);
    setSelectedAppDate(null);
    setSelectedProducts(null);
    setSelectedSections(null);
    applyNewFilters(null, null, null, null, null);
  };

  const onApplyFilters = () => {
    applyNewFilters(
      selectedAppDate,
      selectedPlants,
      selectedProducts,
      selectedSections
    );
  };

  useEffect(() => {
    setInitialPlants(plants);
  }, [plants]);
  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <CalendarFilter
        label="Application Date"
        selectedDate={selectedAppDate}
        setDate={setSelectedAppDate}
        filterName="Select Date Range"
        dateFormat={dateFormatPattern}
        locale={locales[firstDay]}
      />
      <MultiSelectFilter
        label="Plant"
        items={initialPlants}
        selectedItems={selectedPlants}
        setSelectedItems={setSelectedPlants}
        filter
        filterName="Select Plants"
      />
      <MultiSelectFilter
        label="Product Trade Name"
        items={products}
        selectedItems={selectedProducts}
        setSelectedItems={setSelectedProducts}
        filter
        filterName="Select Product Trade Names"
      />
      <MultiSelectFilter
        label="Section"
        items={sections}
        selectedItems={selectedSections}
        setSelectedItems={setSelectedSections}
        filter
        filterName="Select Sections"
      />
    </div>
  );
};

export default HistoryFilters;
