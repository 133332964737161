import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import styles from './LabelsEditorHeader.module.scss';

const LabelsEditorHeader = ({
  formik,
  showSystemTemplates,
  originTemplate,
  labelOptions,
  changeOriginTemplate,
  isSystem,
}) => {
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  return (
    <>
      <div className={styles.labelInfo}>
        {showSystemTemplates && (
          <div className="p-field p-grid p-ai-start">
            <label
              htmlFor="originTemplate"
              className="p-col-12 p-md-2 p-text-bold"
            >
              Label Template*
            </label>
            <div className={classNames('p-col-12 p-md-5', styles.template)}>
              <Dropdown
                id="originTemplate"
                placeholder="select field"
                value={originTemplate}
                style={{ width: '200px' }}
                options={labelOptions}
                optionLabel="name"
                disabled={isTrialTracker}
                onChange={(e) => changeOriginTemplate(e.value)}
              />
            </div>
          </div>
        )}
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="name" className="p-col-12 p-md-2 p-text-bold">
            Label Name*
          </label>
          <div className={classNames('p-col-12 p-md-5')}>
            <InputText
              id="name"
              type="text"
              disabled={isSystem || isTrialTracker}
              value={formik.values.name ? formik.values.name : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={classNames(
                styles.nameInput,
                formik.errors.name && formik.touched.name && 'p-invalid'
              )}
            />
            {formik.errors.name && formik.touched.name && (
              <small id="name-invalid" className="p-error p-d-block">
                {formik.errors.name}
              </small>
            )}
          </div>
        </div>
        <div className="p-field p-grid p-ai-start">
          <label htmlFor="name" className="p-col-12 p-md-2 p-text-bold">
            Label Description*
          </label>
          <div className={classNames('p-col-12 p-md-5', styles.description)}>
            <InputText
              id="description"
              type="text"
              value={formik.values.description ? formik.values.description : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSystem || isTrialTracker}
              className={
                formik.errors.description &&
                formik.touched.description &&
                'p-invalid'
              }
            />
            {formik.errors.description && formik.touched.description && (
              <small id="description-invalid" className="p-error p-d-block">
                {formik.errors.description}
              </small>
            )}
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default LabelsEditorHeader;
