import React, { useRef } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import DatePicker, { registerLocale } from 'datepicker-special-week-numbers';
import 'datepicker-special-week-numbers/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import { getDefaultDateFormat } from '../Common/utils';
import { dateFormat } from '../Common/globalConstants';
import styles from './CalendarFilter.module.scss';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

const CalendarFilter = (props) => {
  const opCalendarRef = useRef(null);

  const dateFormatting = props.dateFormat
    ? dateFormat[props.dateFormat]
    : getDefaultDateFormat();

  return (
    <div className={styles.calendarFilter}>
      <div className={styles.labelText}>{props.label}</div>
      <Button
        className={`p-button-text p-button-plain ${styles.calendarButton}`}
        icon="pi pi-calendar"
        iconPos="right"
        label={
          props.selectedDate
            ? `${moment(props.selectedDate[0]).format(dateFormatting)} 
          ${
            props.selectedDate[1]
              ? ` - ${moment(props.selectedDate[1]).format(dateFormatting)}`
              : ''
          }`
            : props.filterName
        }
        onClick={(e) => opCalendarRef.current.toggle(e)}
        aria-haspopup
        aria-controls="op_calendar"
      />
      <OverlayPanel ref={opCalendarRef} showCloseIcon id="op_calendar">
        <DatePicker
          onChange={(dates) => props.setDate(dates)}
          startDate={props.selectedDate ? props.selectedDate[0] : null}
          endDate={props.selectedDate ? props.selectedDate[1] : null}
          selectsRange
          inline
          style={{ border: 'none' }}
          showWeekNumbers
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat={dateFormatting}
          locale={props.locale}
          weekLabel={'Wk'}
        />
        <Button
          type="button"
          className={`p-button-raised p-button-text ${styles.clearButton}`}
          onClick={() => {
            props.setDate(null);
          }}
        >
          Clear
        </Button>
      </OverlayPanel>
    </div>
  );
};

export default CalendarFilter;
