import React from 'react';
import CropMenu from '../Menu/CropMenu';
import CropForm from './CropForm';
import LeftBar from '../../../LeftBar/LeftBar';

const CropInfo = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <CropMenu />
      </LeftBar>
      <CropForm />
    </React.Fragment>
  );
};

export default CropInfo;
