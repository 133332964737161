import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getTrialFilters } from '../../../../reduxStore/trialFilters/actions';
import { getProfile } from '../../../../reduxStore/trial/actions';
import PlantFilters from '../PlantFilters/PlantFilters';
import PlantSelectionFilters from '../PlantselectionFilters/PlantSelectionFilters';
import PlantList from '../PlantList/PlantList';
import PlantSelectionList from '../PlantSelectionList/PlantSelectionList';
import LeftBar from '../../../LeftBar/LeftBar';
import { isAuth } from '../../../../auth/auth-service';

const PlantPage = () => {
  const [filters, setFilters] = useState(null);

  const location = useLocation();

  const programs = useSelector((state) => state.trialFilters.programs);
  const classes = useSelector((state) => state.trialFilters.classes);
  const series = useSelector((state) => state.trialFilters.series);
  const colors = useSelector((state) => state.trialFilters.colors);
  const breeders = useSelector((state) => state.trialFilters.breeders);
  const marketStatuses = useSelector(
    (state) => state.trialFilters.marketStatuses
  );
  const hardinessZones = useSelector(
    (state) => state.trialFilters.hardinessZones
  );
  const trialFiltersReady = useSelector(
    (state) => state.trialFilters.trialFiltersReady
  );
  const profileReady = useSelector((state) => state.trial.profileReady);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const onApplyNewFilters = useCallback(
    (programs, classes, series, breeders, active) => {
      setFilters({
        programs: programs && programs.length > 0 ? programs : null,
        classes: classes && classes.length > 0 ? classes : null,
        series: series && series.length > 0 ? series : null,
        breeders: breeders && breeders.length > 0 ? breeders : null,
        active: active ? active : null,
      });
    },
    []
  );

  const plantList =
    location.pathname === '/plants' ? (
      <PlantList
        newFilters={filters}
        programs={programs}
        classes={classes}
        series={series}
        colors={colors}
        breeders={breeders}
        marketStatuses={marketStatuses}
        hardinessZones={hardinessZones}
      />
    ) : (
      <PlantSelectionList newFilters={filters} />
    );

  const actionData = useMemo(
    () => ({
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuth(),
      getAccessTokenSilently: getAccessTokenSilently,
    }),
    [dispatch, getAccessTokenSilently, logout]
  );

  useEffect(() => {
    !profileReady && dispatch(getProfile(actionData));
  }, [actionData, dispatch, profileReady]);

  useEffect(() => {
    !trialFiltersReady && dispatch(getTrialFilters(actionData));
  }, [actionData, dispatch, trialFiltersReady]);

  return (
    <React.Fragment>
      <LeftBar>
        {location.pathname === '/plants' ? (
          <PlantFilters
            programs={programs}
            classes={classes}
            series={series}
            breeders={breeders}
            marketStatuses={marketStatuses}
            applyNewFilters={onApplyNewFilters}
          />
        ) : (
          <PlantSelectionFilters
            programs={programs}
            classes={classes}
            series={series}
            breeders={breeders}
            marketStatuses={marketStatuses}
            applyNewFilters={onApplyNewFilters}
          />
        )}
      </LeftBar>
      {plantList}
    </React.Fragment>
  );
};

export default PlantPage;
