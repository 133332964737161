import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { links } from '../../Common/globalConstants';
import styles from './BlockedUserDialog.module.scss';

const BlockedUserDialog = ({ showDialog, setShowDialog }) => {
  const history = useHistory();

  const goHome = () => {
    history.push(links.HOME);
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => {
            setShowDialog(false);
            goHome();
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <Dialog
      header="Dear User"
      visible={showDialog}
      modal={true}
      footer={renderFooter()}
      className={classNames(styles.dialog, 'confirmDialog')}
      onHide={() => {
        setShowDialog(false);
        goHome();
      }}
    >
      {'You are temporary inactive. Contact your Company Admin to manage it!'}
    </Dialog>
  );
};

export default BlockedUserDialog;
