import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { checkEmailValidity } from '../../../../Common/utils';
import { urls, useRequest } from '../../../../Common/ApiServices';
import styles from './Trialshare.module.scss';

const TrialShare = (props) => {
  const [emailValue, setEmailValue] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [emailError, setEmailError] = useState('');

  const { sendRequest } = useRequest({});

  const onHide = () => {
    props.setDisplayShareModal(false);
    setEmailList([]);
    setEmailValue('');
    setEmailError('');
  };
  const onTrialShare = async () => {
    const shareInfo = {
      trialId: props.trialInfo.id,
      employee: {
        emails: [...emailList],
      },
    };
    const requestData = {
      url: urls.SHARE_TRIAL,
      method: 'POST',
      data: shareInfo,
    };
    const response = await sendRequest(requestData);
    response &&
      props.trialShared({ ...shareInfo, trialName: props.trialInfo.name });
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Send Invitation"
          icon="pi pi-send"
          disabled={emailList.length === 0 || emailError || emailValue}
          onClick={() => {
            onTrialShare();
            onHide();
          }}
          autoFocus
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            onHide();
          }}
          className="p-button-secondary"
          style={{ marginRight: '0px' }}
        />
      </div>
    );
  };

  const addEmailToList = () => {
    const validity = checkEmailValidity(emailValue);
    if (validity) {
      setEmailList([...emailList, emailValue]);
      setEmailValue('');
    }
    setEmailError('Wrong email address');
  };

  const removeEmail = (email) => {
    const newList = emailList.filter((listItem) => listItem !== email);
    setEmailList(newList);
  };
  const errorStyle = emailError
    ? { border: '1px solid red', boxShadow: 'none' }
    : {};

  useEffect(() => {
    if (!emailValue) {
      setEmailError('');
    }
  }, [emailValue]);

  return (
    <div>
      <Dialog
        className={styles.trialShare}
        header={props.header}
        visible={props.displayShareModal}
        footer={renderFooter('displayBasic')}
        onHide={() => onHide()}
      >
        <div className={styles.emailList}>
          {emailList.map((email, index) => (
            <div key={index}>
              {email}
              &nbsp;
              <span
                id={email}
                className="p-multiselect-token-icon pi pi-times-circle"
                style={{ display: 'inline-block' }}
                onClick={(e) => removeEmail(e.target.id)}
              />
            </div>
          ))}
        </div>
        <div className="p-inputgroup">
          <InputText
            style={errorStyle}
            placeholder="Enter email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
          />
          <Button
            icon="pi pi-plus"
            disabled={!emailValue || emailError}
            onClick={() => {
              addEmailToList();
            }}
          />
        </div>
        {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
      </Dialog>
    </div>
  );
};

export default TrialShare;
