import React from 'react';
import LeftBar from '../../../LeftBar/LeftBar';
import TrialMenu from '../../Menu/TrialMenu';
import TrialPlantList from '../TrialPlantList/TrialPlantList';

const TrialPlantPage = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <TrialMenu />
      </LeftBar>
      <TrialPlantList />
    </React.Fragment>
  );
};

export default TrialPlantPage;
