import React from 'react';
import LeftBar from '../../../LeftBar/LeftBar';
import TrialMenu from '../../Menu/TrialMenu';
import TrialFieldList from '../TrialFieldList/TrialFieldList';

const TrialFieldPage = () => {
  return (
    <React.Fragment>
      <LeftBar>
        <TrialMenu />
      </LeftBar>
      <TrialFieldList />
    </React.Fragment>
  );
};

export default TrialFieldPage;
