import React from 'react';
import ROUTES, { ERROR_CODE, ERROR_TEXT, LINK_TEXT } from './routesConstants';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../auth/protected-route';
import CompanyInfo from '../components/Administration/CompanyInfo/CompanyInfo';
import CropInfo from '../components/Trials/Crops/Info/CropInfo';
import CropLogPage from '../components/Trials/Crops/CropLogPage/CropLogPage';
import EmployeePage from '../components/Employees/EmployeePage/EmployeePage';
import FieldPage from '../components/Trials/Fields/FieldPage/FieldPage';
import GenericErrorPage from '../components/Common/GenericErrorPage/GenericErrorPage';
import LandingPage from '../components/LandingPage/LandingPage';
import LocationInfo from '../components/Locations/Info/LocationInfo';
import LocationPage from '../components/Locations/LocationPage/LocationPage';
import LogPage from '../components/Trials/Logs/LogPage/LogPage';
import PlantPage from '../components/Trials/Plants/PlantPage/PlantPage';
import ReportPage from '../components/Trials/Reports/ReportPage/ReportPage';
import SectionsPage from '../components/Locations/Sections/SectionsPage/SectionsPage';
import SignUpPage from '../components/SignUp/SignUpPage';
import PrivacyPolicy from '../components/TermsAndConditions/PrivacyPolicy';
import SubscriptionPage from '../components/Subscriptions/SubscriptionPage/SubscriptionPage';
import TrialInfo from '../components/Trials/Info/TrialInfo';
import PlantGoalsPage from '../components/Trials/PlantGoals/PlantGoalsPage/PlantGoalsPage';
import PlantGoalResultsPage from '../components/Trials/PlantGoalResults/PlantGoalResultsPage/PlantGoalResultsPage';
import TrialCropPage from '../components/Trials/Crops/TrialCropPage/TrialCropPage';
import TrialFieldPage from '../components/Trials/Fields/TrialFieldPage/TrialFieldPage';
import TrialPage from '../components/Trials/Page/TrialPage';
import TrialPlantPage from '../components/Trials/Plants/TrialPlantPage/TrialPlantPage';
import UserAgreement from '../components/TermsAndConditions/UserAgreement';
import TaskPage from '../components/Tasks/TaskPage/TaskPage';
import ProductPage from '../components/Products/ProductPage/ProductPage';
import ProductInfo from '../components/Products/ProductInfo/ProductInfo';
import ApplicationPage from '../components/Products/ApplicationPage/ApplicationPage';
import Dashboard from '../components/Dashboard/Dashboard';
import SettingsPage from '../components/Labels/SettingsPage/SettingsPage';
import SystemPage from '../components/Labels/SystemPage/SystemPage';
import LabelInfo from '../components/Labels/LabelsTemplates/LabelTemplateInfo/LabelTemplateInfo';
import HistoryPage from '../components/Trials/History/HistoryPage/HistoryPage';
import ContactUsPage from '../components/ContactUs/ContactUsPage';
import SharedTrialsPage from '../components/Trials/SharedTrials/SharedTrialsPage/SharedTrialsPage';
import OutdatedPage from '../components/AuthUI/Outdated/OutdatedPage';

const Routes = () => {
  const queryString = window.location.search.replace('?', '').split('&')[0];

  return (
    <div className="content">
      <Switch>
        <Route exact path={ROUTES.HOME} component={LandingPage} />
        {queryString === 'error=unauthorized' ? (
          <Route
            exact
            path={ROUTES.DASHBOARD}
            render={() => <LandingPage blockedUser={true} />}
          />
        ) : (
          <ProtectedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
        )}
        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={ROUTES.USER_AGREEMENT} component={UserAgreement} />
        <Route exact path={ROUTES.PRICING} component={SubscriptionPage} />
        <ProtectedRoute exact path={ROUTES.TRIALS} component={TrialPage} />
        <ProtectedRoute exact path={ROUTES.CROPS} component={TrialCropPage} />
        <ProtectedRoute
          exact
          path={ROUTES.TRIAL_FIELDS}
          component={TrialFieldPage}
        />
        <ProtectedRoute exact path={ROUTES.TRIAL_LOGS} component={LogPage} />
        <ProtectedRoute exact path={ROUTES.REPORTS} component={ReportPage} />
        <ProtectedRoute
          exact
          path={ROUTES.TRIAL_PLANTS}
          component={TrialPlantPage}
        />
        <ProtectedRoute
          exact
          path={ROUTES.TRIAL_SELECT_FIELDS}
          component={FieldPage}
        />
        <ProtectedRoute
          exact
          path={ROUTES.TRIAL_SELECT_PLANTS}
          component={PlantPage}
        />
        <ProtectedRoute exact path={ROUTES.TRIAL} component={TrialInfo} />
        <ProtectedRoute exact path={ROUTES.CROP} component={CropInfo} />
        <ProtectedRoute exact path={ROUTES.CROP_LOGS} component={CropLogPage} />
        <ProtectedRoute exact path={ROUTES.PLANTS} component={PlantPage} />
        <ProtectedRoute
          exact
          path={ROUTES.PLANT_GOALS}
          component={PlantGoalsPage}
        />
        <ProtectedRoute
          exact
          path={ROUTES.PLANT_GOALS_RESULTS}
          component={PlantGoalResultsPage}
        />
        <ProtectedRoute
          exact
          path={ROUTES.LOCATIONS}
          component={LocationPage}
        />
        <ProtectedRoute exact path={ROUTES.LOCATION} component={LocationInfo} />
        <ProtectedRoute exact path={ROUTES.SECTIONS} component={SectionsPage} />
        <ProtectedRoute
          exact
          path={ROUTES.EMPLOYEES}
          component={EmployeePage}
        />
        <ProtectedRoute exact path={ROUTES.TASKS} component={TaskPage} />
        <ProtectedRoute exact path={ROUTES.PRODUCTS} component={ProductPage} />
        <ProtectedRoute exact path={ROUTES.PRODUCT} component={ProductInfo} />
        <ProtectedRoute
          exact
          path={ROUTES.APPLICATIONS}
          component={ApplicationPage}
        />
        <ProtectedRoute
          exact
          path={ROUTES.COMPANY_INFO}
          component={CompanyInfo}
        />
        <ProtectedRoute exact path={ROUTES.LABELS} component={SettingsPage} />
        <ProtectedRoute exact path={ROUTES.LABEL} component={LabelInfo} />
        <ProtectedRoute
          exact
          path={ROUTES.REPLICATION}
          component={SystemPage}
        />
        <ProtectedRoute exact path={ROUTES.HISTORY} component={HistoryPage} />
        <Route exact path={ROUTES.CONTACT_US} component={ContactUsPage} />
        <Route exact path={ROUTES.CONTACT_US} component={ContactUsPage} />
        <Route exact path={ROUTES.CONTACT_US} component={ContactUsPage} />
        <Route exact path={ROUTES.SHARED_TRIALS} component={SharedTrialsPage} />
        <Route exact path={ROUTES.OUTDATED} component={OutdatedPage} />
        <Route
          render={() => (
            <GenericErrorPage
              errorCode={ERROR_CODE}
              errorText={ERROR_TEXT}
              linkTo={ROUTES.HOME}
              linkText={LINK_TEXT}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default Routes;
