import React from 'react';
import { Field } from 'formik';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import classNames from 'classnames';
import interact from 'interactjs';
import { InputText } from 'primereact/inputtext';
import {
  types,
  multiplier,
  minSize,
  maxSize,
  fieldOrigin,
  defaultQRSize,
} from '../constants';
import styles from './LabelTemplateField.module.scss';

export const LabelTemplateField = ({
  fieldItem,
  name,
  axisX,
  axisY,
  prettyName,
  showPrettyName,
  showBarcodeText,
  fontSize,
  fontFamily,
  barcodeHeight,
  fontWeight,
  type,
  measureUnit,
  index,
  activeField,
  inputValue,
  setActiveField,
  setActiveFieldIndex,
  setActiveFieldAxisX,
  setActiveFieldAxisY,
  setActiveImageDimensions,
  setActiveFieldWrap,
  setActiveFieldPosition,
  setFontBold,
  setIsDropdownOptionsOpen,
  formik,
  setInputValue,
  getImageUrl,
  fullTemplate,
}) => {
  const isDefaultField = fullTemplate.source === fieldOrigin.SYSTEM;
  const resizableContainer = interact('#container');
  const draggable = interact('.draggable');
  draggable.on('tap', function (event) {
    draggable.reflow({ name: 'drag', axis: 'xy' });
    event.stopImmediatePropagation();
  });
  resizableContainer.resizable({
    edges: { bottom: true, right: true },
    modifiers: [
      interact.modifiers.restrictEdges({
        outer: 'html',
      }),
      interact.modifiers.restrictEdges({
        min: { width: 200, height: 200 },
      }),
    ],
    listeners: {
      move: function (event) {
        let { x, y } = event.target.dataset;

        x = (parseFloat(x) || 0) + event.deltaRect.left;
        y = (parseFloat(y) || 0) + event.deltaRect.top;

        const width =
          event.rect.width >= maxSize.width
            ? maxSize.width
            : event.rect.width <= minSize.width
            ? minSize.width
            : event.rect.width;

        const height =
          event.rect.height >= maxSize.height
            ? maxSize.height
            : event.rect.height <= minSize.height
            ? minSize.height
            : event.rect.height;
        Object.assign(event.target.style, {
          width: `${width}px`,
          height: `${height}px`,
          transform: `translate(${x}px, ${y}px)`,
        });

        Object.assign(event.target.dataset, { x, y });

        formik.setFieldValue(
          'height',
          +(height / multiplier['INCH']).toFixed(2)
        );
        formik.setFieldValue('width', +(width / multiplier['INCH']).toFixed(2));
      },
    },
  });

  draggable.draggable({
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: document.querySelector('#container'),
        endOnly: true,
      }),
    ],
    listeners: {
      move(event) {
        const x = (parseFloat(event.target.dataset.x) || 0) + event.dx;
        const y = (parseFloat(event.target.dataset.y) || 0) + event.dy;
        event.target.style.transform = `translate(${x}px, ${y}px)`;
        Object.assign(event.target.dataset, { x, y });
        Math.abs(event.delta.x) > 0.5 &&
          setActiveFieldAxisX(event.target.dataset.x);
        Math.abs(event.delta.y) > 0.5 &&
          setActiveFieldAxisY(event.target.dataset.y);
        event.stopImmediatePropagation();
      },
    },
  });

  window.dragMoveListener = (event) => {
    const target = event.target,
      x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
      y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    target.style.webkitTransform = target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)';

    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  };

  interact('.resize-drag')
    .resizable({
      edges: { left: true, right: true, bottom: true, top: true },

      listeners: {
        move(event) {
          setActiveImageDimensions({
            width: Math.floor(event.rect.width),
            height: Math.floor(event.rect.height),
          });
          const target = event.target;
          let x = parseFloat(target.getAttribute('data-x')) || 0;
          let y = parseFloat(target.getAttribute('data-y')) || 0;

          target.style.width = event.rect.width + 'px';
          target.style.height = event.rect.height + 'px';

          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.style.transform = 'translate(' + x + 'px,' + y + 'px)';

          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: 'parent',
        }),

        interact.modifiers.restrictSize({
          min: { width: 50, height: 50 },
        }),
      ],

      inertia: true,
    })
    .draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: document.querySelector('#container'),
          endOnly: true,
        }),
      ],
      listeners: {
        move(event) {
          const x = (parseFloat(event.target.dataset.x) || 0) + event.dx;
          const y = (parseFloat(event.target.dataset.y) || 0) + event.dy;

          event.target.style.transform = `translate(${x}px, ${y}px)`;
          Object.assign(event.target.dataset, { x, y });

          Math.abs(event.delta.x) > 0.5 &&
            setActiveFieldAxisX(event.target.dataset.x);
          Math.abs(event.delta.x) > 0.5 &&
            setActiveFieldAxisY(event.target.dataset.y);
        },
      },
    });
  const position = `translate(${axisX * multiplier[measureUnit]}px, ${
    axisY * multiplier[measureUnit]
  }px)`;

  const setActiveFieldProperties = (e) => {
    e.stopPropagation();
    setActiveField(fieldItem);
    setActiveFieldIndex(index);
    fieldItem.style.dimension &&
      setActiveImageDimensions({
        width: fieldItem.style.dimension.width,
        height: fieldItem.style.dimension.height,
      });
    setActiveFieldAxisX(+fieldItem.style.axisXY.x * multiplier[measureUnit]);
    setActiveFieldAxisY(+fieldItem.style.axisXY.y * multiplier[measureUnit]);
    setActiveFieldPosition(fieldItem.style.position);
    setActiveFieldWrap(fieldItem.style.wrapInTwoLines);
    setFontBold(fieldItem.style.font?.weight === 'BOLD');
    setIsDropdownOptionsOpen(false);
  };

  const updateValue = (e) => {
    e.preventDefault();
    setInputValue({ prettyName: e.target.value, name: e.target.value });
  };

  switch (type) {
    case types.TEXT:
      return (
        <div
          key={index}
          data-x={axisX ? axisX * multiplier[measureUnit] : 0}
          data-y={axisY ? axisY * multiplier[measureUnit] : 0}
          style={{
            padding: 0,
            position: 'absolute',
            transform: position,
          }}
          className={classNames(
            styles.labelField,
            styles.textField,
            'draggable'
          )}
          onMouseDown={(e) => setActiveFieldProperties(e)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Field name={name}>
            {({ field }) => (
              <div
                id={name}
                className={classNames(
                  name === activeField?.name && styles.highlightBg
                )}
                style={{
                  fontSize: fontSize,
                  fontWeight: fontWeight,
                  fontFamily: fontFamily,
                  padding: '1px',
                }}
              >
                {showPrettyName &&
                  (name !== activeField?.name ? (
                    <span
                      className={classNames(styles.label)}
                      style={{
                        fontSize: fontSize,
                      }}
                    >
                      {`${prettyName}:`}
                    </span>
                  ) : (
                    <InputText
                      type="text"
                      className={classNames(styles.label)}
                      style={{
                        fontSize: fontSize + 'px',
                        width: (inputValue?.prettyName?.length + 1) * 8 + 'px',
                        padding: '0px',
                        border: 'none',
                      }}
                      value={inputValue.prettyName}
                      onChange={updateValue}
                    />
                  ))}
                {isDefaultField ? (
                  <span>{'${' + field.name + '}'}</span>
                ) : (
                  <span>
                    {
                      fullTemplate.availableFields.find(
                        (aField) => aField.name === field.name
                      )?.val
                    }
                  </span>
                )}
              </div>
            )}
          </Field>
        </div>
      );

    case types.BARCODE:
      return (
        <div
          key={index}
          id={name}
          data-x={axisX ? axisX * multiplier[measureUnit] : 0}
          data-y={axisY ? axisY * multiplier[measureUnit] : 0}
          style={{
            padding: 0,
            position: 'absolute',
            transform: position,
          }}
          className={classNames(styles.labelField, 'draggable')}
          onMouseDown={(e) => setActiveFieldProperties(e)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Field name={name}>
            {() => (
              <Barcode
                value={'${' + name + '}'}
                displayValue={showBarcodeText}
                font={fontFamily}
                height={
                  barcodeHeight
                    ? barcodeHeight *
                      multiplier[fieldItem.style.dimension?.measureUnit]
                    : 14 * 1.333
                }
                background={
                  name === activeField?.name ? '#dee2e6' : 'transparent'
                }
                width={1}
                margin={0}
                marginBottom={5}
                fontSize={fontSize}
                textAlign="center"
                format="CODE128"
              />
            )}
          </Field>
        </div>
      );

    case types.QR:
      return (
        <div
          key={index}
          id={name}
          data-x={axisX ? axisX * multiplier[measureUnit] : 0}
          data-y={axisY ? axisY * multiplier[measureUnit] : 0}
          style={{
            padding: 0,
            position: 'absolute',
            transform: position,
          }}
          className={classNames(styles.labelField, 'resize-drag')}
          onMouseDown={(e) => setActiveFieldProperties(e)}
          onClick={(e) => {
            e.stopPropagation();
            setActiveFieldIndex(index);
          }}
        >
          <Field name={name}>
            {() => (
              <QRCode
                size={fieldItem.style?.dimension?.width || defaultQRSize}
                value={name}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              />
            )}
          </Field>
        </div>
      );

    case types.IMAGE:
      return (
        <div
          key={index}
          id={name}
          data-x={axisX ? axisX * multiplier[measureUnit] : 0}
          data-y={axisY ? axisY * multiplier[measureUnit] : 0}
          style={{
            padding: 0,
            position: 'absolute',
            transform: position,
            width: fieldItem.style?.dimension?.width,
            height: fieldItem.style?.dimension?.height,
          }}
          className={classNames(
            styles.labelField,
            styles.resizableItem,
            'resize-drag'
          )}
          onMouseDown={(e) => setActiveFieldProperties(e)}
          onClick={(e) => {
            e.stopPropagation();
            setActiveField(fieldItem);
            setActiveFieldIndex(index);
          }}
        >
          <Field name={name}>
            {({ field }) => (
              <img
                width="100%"
                height="100%"
                src={getImageUrl(field.name)}
                alt="logo"
              />
            )}
          </Field>
        </div>
      );

    default:
      return <div />;
  }
};
