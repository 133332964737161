import React from 'react';
import LeftBar from '../../LeftBar/LeftBar';
import SettingsMenu from '../SettingsMenu/SettingsMenu';
import SystemForm from './SystemForm/SystemForm';

function SystemPage() {
  return (
    <>
      <LeftBar>
        <SettingsMenu />
      </LeftBar>
      <SystemForm />
    </>
  );
}

export default SystemPage;
